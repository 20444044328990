import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Step,
  StepButton,
  Stepper
} from '@material-ui/core';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import SwipeableViews from 'react-swipeable-views';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  checkLicenseIntercom,
  getDeviceBase,
  getDeviceConfigurationSingle,
  getDeviceConnection,
  getDeviceGate,
  getDeviceGateEntrances,
  putDeviceBase,
  putDeviceConnection,
  setDevicesCache,
  setDevicesRefresh
} from '../../../store/actions/devices.actions';
import {
  showMessage,
  showServerErrorMessage
} from '../../../store/actions/messages.actions';
import { deviceWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import DeviceConnection from './steps/DeviceConnection';
import TabPanelWizard from '../../common/TabPanelWizard';
import DeviceAddress from './steps/DeviceAddress';
import DeviceSip from './steps/DeviceSip';
import DeviceSip2 from './steps/DeviceSip2';
import DeviceGate, { DEVICE_MODE } from './steps/DeviceGate';
import DeviceLan from './steps/DeviceLan';
import DevicePermissions from './steps/DevicePermissions';
import DeviceNotSavedDialog from './DeviceNotSavedDialog';
import removeEmptyFields from '../../../utils/removeEmptyFields';
import {
  deviceDialogStyles,
  deviceEmptyState
} from './common/DeviceDialogObjects';
import DeviceCheckConnectionDialog from './DeviceCheckConnectionDialog';
import DeviceEmail from './steps/DeviceEmail';
import DeviceDetector from './steps/DeviceDetector';
import DeviceOverlay from './steps/DeviceOverlay';
import DevicesDisplay from './steps/DeviceDisplay';
import DevicesDateTime from './steps/DeviceDateTime';
import DevicesSysLog from './steps/DeviceSysLog';
import {
  FIRST_NOT_WORKED_DEVICE_FOR_GATE,
  SECOND_NOT_WORKED_DEVICE_FOR_GATE
} from './consts';
import { EnumRoutes } from '../../../routes';
import ProgressDialog from './ProgressDialog';

moment.locale('ru');

const useStyles = makeStyles(() => createStyles(deviceDialogStyles));

const steps = [
  'Адрес',
  'Хост',
  'SIP#1',
  'SIP#2',
  'Калитка',
  'LAN',
  'Email',
  'Детектор',
  'Текст',
  'Дисплей',
  'Дата',
  'SysLog',
  'Права'
];
const stepsName = [
  'address',
  'host',
  'sip',
  'sip2',
  'gate',
  'lan',
  'email',
  'detector',
  'overlay',
  'display',
  'date',
  'syslog',
  'permissions',
  'finish',
  '',
  ''
];

const DeviceAddDialog = ({ isArchive, handleClose, ...props }) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { action1, actionId1, stepName } = useParams();

  const editPermission = useSelector(deviceWritePermission);

  const [stepValid, setStepValid] = useState(false);

  const keyTimeout = useRef(0);
  const keyCodeLast = useRef(0);
  const keyCodeLastTime = useRef(0);
  const currentStep = useRef(0);
  const step = useRef('');
  const completedRef = useRef({});
  const deviceUpdated = useRef({});
  const deviceUpdatedNextStep = useRef(0);

  const [isSingle, setIsSingle] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState({ ...deviceEmptyState });
  const [device, setDevice] = useState({ ...deviceEmptyState });

  const [addDialog, setAddDialog] = useState(false);
  const [notSavedDialog, setNotSavedDialog] = useState(false);
  const [addDialogTitle, setAddDialogTitle] = useState('');
  const [baseConfig, setBaseConfig] = useState(null);
  const [licenseError, setLicenseError] = useState(false);

  const devicesCache = useSelector((state) => state.devices.cache);
  const devices = useSelector((state) => state.devices.list);
  const isLoading = useSelector(
    (state) => state.devices.configurationStatus === 'loading'
  );

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [empty, setEmpty] = useState({});

  const isNotWorkedDevice =
    device.deviceModel === FIRST_NOT_WORKED_DEVICE_FOR_GATE ||
    device.deviceModel === SECOND_NOT_WORKED_DEVICE_FOR_GATE;

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completedRef.current).length;

  const firstUncompletedStep = (current) =>
    steps.findIndex((_, index) => !completedRef.current[index], current);

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const [showProgress, setShowProgress] = useState(false);

  const updateStep = (value) => {
    // console.log(completedRef.current, value)
    if (!completedRef.current[0]) {
      return;
    }
    if (!completedRef.current[1] && value > 1) {
      return;
    }
    currentStep.current = value;
    updateActiveButton(value);
    clearTimeout(keyTimeout.current);
    keyTimeout.current = setTimeout(() => {
      setActiveStep(currentStep.current);
      navigateStep(stepsName[currentStep.current]);
    }, 500);
  };

  const updateActiveButton = (index) => {
    const activeElement = document.querySelector(
      '.MuiStepIcon-root.Mui-active'
    );
    const iconElement = document.querySelectorAll('.MuiStepIcon-root')[index];
    if (activeElement) {
      activeElement.classList.remove('Mui-active');
    }
    if (iconElement) {
      iconElement.classList.add('Mui-active');
    }
  };

  const navigateStep = (name) => {
    navigate(location.pathname.replace(stepName, '') + name, { replace: true });
  };

  const handleNext = () => {
    if (currentStep.current < totalSteps() + 1) {
      updateStep(currentStep.current + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      navigateStep(stepsName[prevActiveStep - 1]);
      return prevActiveStep - 1;
    });
  };

  const handleStep = (value) => () => {
    if (action1 === 'edit' && Object.keys(deviceUpdated.current).length > 0) {
      setNotSavedDialog(true);
      deviceUpdatedNextStep.current = value;
      return;
    }
    currentStep.current = value;
    setActiveStep(value);
    step.current = stepsName[value];
    navigateStep(stepsName[value]);
  };

  const handleComplete = () => {
    const updated = deviceUpdated.current;
    if (currentStep.current === 0) {
      setDeviceDetails({
        ...deviceDetails,
        id: deviceUpdated.current.id,
        name: deviceUpdated.current.name,
        address: deviceUpdated.current.address
      });
    } else if (currentStep.current === 1) {
      setBaseConfig({
        id: updated.id || actionId1,
        connection: { ...updated.connection }
      });
      deviceUpdatedNextStep.current = 2;
      return;
    }
    const newCompleted = completed;
    newCompleted[currentStep.current] = true;
    setCompleted(newCompleted);
    completedRef.current[currentStep.current] = true;
    if (allStepsCompleted()) {
      handleStep(13)();
    } else if (currentStep.current === 12) {
      handleStep(firstUncompletedStep(0))();
    } else if (!completedRef.current[currentStep.current + 1]) {
      handleNext();
    } else {
      handleStep(firstUncompletedStep(currentStep.current + 1))();
    }
  };

  const handleReset = () => {
    if (activeStep !== 0) {
      currentStep.current = 0;
      setActiveStep(0);
      navigateStep(stepsName[0]);
    }
    setCompleted({});
    deviceUpdated.current = {};
  };

  const checkLicense = async () => {
    try {
      const { data } = await store.dispatch(checkLicenseIntercom());
    } catch (error) {
      console.log(error);
      setLicenseError(() => true);
      handleStep(14)();
    }
  };

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action1, actionId1, device.intercoms[0], devicesCache);
    const deviceFromList =
      actionId1 && devices.find((item) => item.id === actionId1);
    if (action1 === 'add') {
      setAddDialog(true);
      setAddDialogTitle('Добавить новое устройство');
      checkLicense();
    } else if (addDialog) {
      setAddDialog(false);
      setTimeout(() => {
        if (!unmounted && device.id) {
          handleReset();
          if (deviceDetails.address) {
            setDeviceDetails({ ...deviceEmptyState });
          }
          if (
            devicesCache.device &&
            (devicesCache.device.id || devicesCache.device.address)
          ) {
            // clear device cache
            setDeviceDetails({ ...deviceEmptyState });
            dispatch(setDevicesCache(deviceEmptyState));
          }
        }
      }, 200);
    }
    if (action1 === 'add' && actionId1) {
      setDevice({
        ...(devicesCache[actionId1] || deviceEmptyState),
        ...deviceDetails,
        ...deviceFromList
      });
    }
    // register key listener
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      unmounted = true;
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [action1, actionId1, devices, deviceDetails]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode, altKey } = event;
      if (keyCode === 33) {
        if (currentStep.current > 0) {
          if (
            empty[currentStep.current - 1] &&
            empty[currentStep.current - 2]
          ) {
            updateStep(currentStep.current - 3);
          } else if (empty[currentStep.current - 1]) {
            updateStep(currentStep.current - 2);
          } else {
            updateStep(currentStep.current - 1);
          }
        }
      } else if (keyCode === 34) {
        if (currentStep.current < totalSteps() - 1) {
          if (
            empty[currentStep.current + 1] &&
            empty[currentStep.current + 2]
          ) {
            updateStep(currentStep.current + 3);
          } else if (empty[currentStep.current + 1]) {
            updateStep(currentStep.current + 2);
          } else {
            updateStep(currentStep.current + 1);
          }
        }
      } else if (altKey && keyCode >= 48 && keyCode <= 58) {
        if (
          keyCodeLast.current === 49 &&
          Date.now() - keyCodeLastTime.current < 500
        ) {
          if (
            currentStep.current !== keyCode - 49 + 10 &&
            currentStep.current < 16
          ) {
            if (!empty[keyCode - 49 + 10]) {
              updateStep(keyCode - 49 + 10);
            }
          }
        } else if (currentStep.current !== keyCode - 49) {
          if (!empty[keyCode - 49]) {
            updateStep(keyCode - 49);
          }
        }
        keyCodeLastTime.current = Date.now();
        keyCodeLast.current = keyCode;
      }
    },
    [setActiveStep, handleStep]
  );

  // step name effect (update refs)
  useEffect(() => {
    const getConnectionData = async (deviceId) => {
      try {
        const { data } = await store.dispatch(getDeviceConnection(deviceId));
        setDeviceDetails({
          ...deviceDetails,
          connection: {
            host: '',
            port: '',
            username: '',
            password: '',
            ...data
          }
        });
        onUpdate({ connection: { ...data } });
      } catch (error) {
        dispatch(showServerErrorMessage(error));
      }
    };
    let unmounted = !location.pathname.includes('/devices/intercoms/add');
    if (!unmounted && !licenseError && !allStepsCompleted()) {
      step.current = stepName;
      completedRef.current = completed;
      const valid = isCurrentStepValid();
      setStepValid((previous) => valid);
      if (stepsName[activeStep] !== stepName) {
        const index = stepsName.indexOf(stepName);
        if (index !== -1) {
          currentStep.current = index;
          setActiveStep(index);
        }
      } else if (stepName === 'host') {
        // setCompleted([true]);
        if (actionId1 !== 'device') {
          if (!completed[0]) {
            setCompleted([true]);
          }
          if (!deviceDetails.connection) {
            getConnectionData(actionId1);
          }
        } else if (!deviceDetails.connection && deviceUpdated.current?.id) {
          getConnectionData(deviceUpdated.current?.id);
        }

        // state after clicking 'configure' in the list
      }
    }
    return () => {
      unmounted = true;
    };
  }, [activeStep, completed, stepName]);

  // base configuration effect
  useEffect(() => {
    let unmounted = !location.pathname.includes('/devices/intercoms/add');
    const getData = async () => {
      try {
        // const checkConnection = await store.dispatch(getDeviceConnection(baseConfig.id));
        if (!baseConfig.connection) {
          // state after clicking 'configure' in the list
          return;
        }
        let singleFlag = false;
        if (isSingle === null) {
          const { data: single } = await store.dispatch(
            getDeviceConfigurationSingle(baseConfig.id)
          );
          setIsSingle(single);
          singleFlag = single;
          if (single) {
            setEmpty({
              0: false,
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              7: true,
              8: false,
              9: false,
              10: false,
              11: false,
              12: false
            });
          } else {
            setEmpty({
              0: false,
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              7: false,
              8: false,
              9: false,
              10: false,
              11: false,
              12: false
            });
          }
        } else if (isNotWorkedDevice) {
          setEmpty({
            0: false,
            1: false,
            2: false,
            3: true,
            4: true,
            5: false,
            6: false,
            7: true,
            8: false,
            9: false,
            10: false,
            11: false,
            12: false
          });
        }
        const updateConnection = await store.dispatch(
          putDeviceConnection(baseConfig.id, { ...baseConfig.connection })
        );
        const { data } = await store.dispatch(getDeviceBase(baseConfig.id));
        if (data) {
          setDeviceDetails({
            ...deviceDetails,
            ...data
          });
          setDevice({
            ...device,
            ...deviceDetails
          });

          handleStep(deviceUpdatedNextStep.current)();
          dispatch(
            showMessage({
              open: true,
              text: 'Базовая конфигурация успешно загружена',
              severity: 'success'
            })
          );

          const newCompleted = completed;
          newCompleted[activeStep] = true;

          if (singleFlag) {
            newCompleted[7] = true;
          } else if (isNotWorkedDevice) {
            newCompleted[3] = true;
            newCompleted[4] = true;
          }

          setCompleted(newCompleted);
          setTimeout(() => dispatch(setDevicesRefresh()), 500);
        } else {
          dispatch(
            showMessage({
              open: true,
              text: 'Не удалось получить базовую конфигурацию домофона',
              severity: 'error'
            })
          );
        }
        setBaseConfig(null);
      } catch (error) {
        console.log(error);
        dispatch(
          showMessage({
            open: true,
            text: error.message || 'Не удалось соединиться с домофоном',
            severity: 'error'
          })
        );
        setBaseConfig(null);
      }
    };

    if (!unmounted && baseConfig) {
      getData();
    }
    return () => {
      unmounted = true;
    };
  }, [baseConfig, isNotWorkedDevice]);

  useEffect(() => {
    (async () => {
      if (step.current === 'gate') {
        try {
          const {
            data: { houseConfigurationResponseDtoList }
          } = await store.dispatch(getDeviceGateEntrances(deviceDetails.id));
          const { data } = await store.dispatch(
            getDeviceGate(deviceDetails.id)
          );

          setDeviceDetails({
            ...deviceDetails,
            gate: { ...data, houses: houseConfigurationResponseDtoList }
          });
        } catch (e) {
          console.log(e, 'gate entrance api error');
        }
      }
    })();
  }, [action1, deviceDetails.id, step.current, isNotWorkedDevice]);

  const isCurrentStepValid = useCallback(() => {
    const updated = deviceUpdated.current;
    if (completedRef.current[stepsName.indexOf(step.current)]) {
      return false;
    }
    if (step.current === 'address') {
      if (updated.id && updated.name /* && updated.address*/) {
        return true;
      }
    } else if (step.current === 'host') {
      if (
        updated.connection?.host &&
        updated.connection?.port &&
        updated.connection?.username &&
        updated.connection?.password
      ) {
        return true;
      }
      // return true;
    } else if (step.current === 'sip') {
      return true;
    } else if (step.current === 'sip2') {
      return true;
    } else if (step.current === 'gate') {
      return true;
    } else if (step.current === 'lan') {
      return true;
    } else if (step.current === 'email') {
      return true;
    } else if (step.current === 'detector') {
      return true;
    } else if (step.current === 'overlay') {
      return true;
    } else if (step.current === 'display') {
      return true;
    } else if (step.current === 'date') {
      return true;
    } else if (step.current === 'syslog') {
      return true;
    } else if (step.current === 'permissions') {
      return true;
    }
    return false;
  }, [completed]);

  // button done effect
  useEffect(() => {
    const valid = isCurrentStepValid();
    if (valid !== stepValid) {
      setStepValid(valid);
    }
  }, []);

  const onUpdate = useCallback((data) => {
    deviceUpdated.current = removeEmptyFields({
      ...deviceUpdated.current,
      ...data
    });
    if (data.sip2?.enabled) {
      deviceUpdated.current = {
        ...deviceUpdated.current,
        enableSip2: data.sip2?.enabled
      };
      deviceUpdated.current.sip2 = undefined;
    }
    // console.log(data)
    const valid = isCurrentStepValid();
    setStepValid((previous) => valid);
  }, []);

  const onCancelDeviceConnection = useCallback(() => {
    setBaseConfig(null);
  }, []);

  const onCancelDeviceUpdated = useCallback(() => {
    setNotSavedDialog(false);
  }, []);

  const onIgnoreDeviceUpdated = useCallback(() => {
    setNotSavedDialog(false);
    deviceUpdated.current = {};
    if (deviceUpdatedNextStep.current === -1) {
      handleClose(null, 'success');
    } else {
      handleStep(deviceUpdatedNextStep.current)();
    }
  }, []);

  const onSaveDeviceUpdated = useCallback(async () => {
    setNotSavedDialog(false);
    deviceUpdated.current = {};
    // save data here
    if (deviceUpdatedNextStep.current === -1) {
      handleClose(null, 'success');
    } else {
      handleStep(deviceUpdatedNextStep.current)();
    }
  }, []);

  const onSaveDevice = useCallback(async () => {
    if (showProgress) return;
    try {
      const data = {
        ...deviceDetails,
        ...deviceUpdated.current,
        updated: undefined,
        created: undefined,
        type: undefined,
        status: undefined,
        users: undefined,
        flatRanges: undefined
      };

      if (isNotWorkedDevice) {
        data.gate = null;
      }
      // console.log(data)
      setShowProgress(true);
      const response = await store.dispatch(putDeviceBase(data.id, data));
      setShowProgress(false);
      dispatch(
        showMessage({
          open: true,
          text: 'Настройки девайса успешно сохранены.',
          severity: 'success'
        })
      );
      setTimeout(() => dispatch(setDevicesRefresh()), 500);
      navigate(EnumRoutes.devices);
    } catch (error) {
      setShowProgress(false);
      dispatch(
        showMessage({
          open: true,
          text: `Не удалось сохранить настройки. ${error.message}`,
          severity: 'error'
        })
      );
    }
  }, [deviceDetails, isNotWorkedDevice]);

  const onClose = useCallback((reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    } else if (action1 === 'edit') {
      if (Object.keys(deviceUpdated.current).length > 0) {
        setNotSavedDialog(true);
        deviceUpdatedNextStep.current = -1;
      } else {
        handleClose();
      }
    }
  }, []);

  return (
    <>
      <Dialog
        {...props}
        open={addDialog}
        onClose={(e, reason) => onClose(reason)}
        fullWidth
        disableRestoreFocus
        disableEscapeKeyDown
        scroll="body"
        PaperProps={{ sx: { maxWidth: '1024px' } }}
      >
        <DialogContent className={classes.content}>
          <Stepper
            orientation="vertical"
            className={classes.stepperBig}
            nonLinear
            activeStep={activeStep}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton
                  className={isSingle !== null && empty[index] ? 'empty' : ''}
                  title={'Alt-' + (index + 1)}
                  color="inherit"
                  onClick={handleStep(index)}
                  disabled={
                    licenseError ||
                    (index > 0 && !completed[0]) ||
                    (index > 1 && !completed[1]) ||
                    empty[index]
                  }
                  sx={{
                    '& .MuiStepIcon-root.Mui-completed:not(.Mui-active)': {
                      color: empty[index] ? '#c4c4c4' : 'green.main'
                    },
                    '& .MuiStepLabel-label': {
                      marginTop: '3px'
                    },
                    '& .MuiStepLabel-label.Mui-completed:not(.Mui-active)': {
                      fontWeight: '400'
                    }
                  }}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Box className={classes.container}>
            <SwipeableViews className={classes.view} index={activeStep}>
              <TabPanelWizard
                value={activeStep}
                index={0}
                className={classes.panel}
              >
                <DeviceAddress
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={1}
                className={classes.panel}
              >
                <DeviceConnection
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={2}
                className={classes.panel}
              >
                <DeviceSip
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={3}
                className={classes.panel}
              >
                <DeviceSip2
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={4}
                className={classes.panel}
              >
                <DeviceGate
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={5}
                className={classes.panel}
              >
                <DeviceLan
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={6}
                className={classes.panel}
              >
                <DeviceEmail
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={7}
                className={classes.panel}
              >
                <DeviceDetector
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={8}
                className={classes.panel}
              >
                <DeviceOverlay
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={9}
                className={classes.panel}
              >
                <DevicesDisplay
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={10}
                className={classes.panel}
              >
                <DevicesDateTime
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={11}
                className={classes.panel}
              >
                <DevicesSysLog
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={12}
                className={classes.panel}
              >
                <DevicePermissions
                  device={device}
                  isArchive={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={13}
                className={classes.panel}
              >
                <Alert severity="success" sx={{ mt: -1 }}>
                  Настройка завершена! Можно сохранить настройки.
                </Alert>
                <Box sx={{ textAlign: 'center' }}>
                  <img
                    alt="Ready"
                    src="/static/images/website-maintenance.svg"
                    style={{
                      marginTop: 50,
                      display: 'inline-block',
                      maxWidth: '100%',
                      width: 1000
                    }}
                  />
                </Box>
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={14}
                className={classes.panel}
              >
                <Alert severity="error" sx={{ mt: -1 }}>
                  Невозможно добавить устройство, проверьте вашу лицензию!
                </Alert>
                <Box sx={{ textAlign: 'center' }}>
                  <img
                    alt="License error"
                    src="/static/images/license.svg"
                    style={{
                      marginTop: 50,
                      display: 'inline-block',
                      maxWidth: '100%',
                      width: 1000
                    }}
                  />
                </Box>
              </TabPanelWizard>
            </SwipeableViews>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              sx={{
                pb: 2,
                pt: 3
              }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleClose}
                >
                  Отменить
                </Button>
              </Grid>

              {allStepsCompleted() ? (
                <>
                  {editPermission && !isArchive && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSaveDevice}
                        disabled={isLoading}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <div className={classes.buttonWrapper}>
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        ''
                      ) : (
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          disabled={!stepValid}
                          onClick={handleComplete}
                        >
                          {completedSteps() === 0
                            ? action1 === 'add'
                              ? 'Готово'
                              : 'Сменить адрес'
                            : 'Готово'}
                        </Button>
                      ))}
                    {/*<Button
                      ml={1}
                      className={classes.button}
                      variant="contained"
                      color="purple"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Назад
                    </Button>
                    <Button
                      ml={1}
                      className={classes.button}
                      variant="contained"
                      color="green"
                      disabled={(activeStep < 2 && completedSteps() < 2) || activeStep + 1 === steps.length}
                      onClick={handleNext}
                    >
                      Дальше
                    </Button>*/}
                  </div>
                </>
              )}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <DeviceCheckConnectionDialog
        addDevice={baseConfig}
        onCancel={onCancelDeviceConnection}
      />

      <ProgressDialog
        open={showProgress}
        text="Внимание! Идет сохранение конфигурации на домофоне."
      />

      <DeviceNotSavedDialog
        open={notSavedDialog}
        onCancel={onCancelDeviceUpdated}
        onSave={onSaveDeviceUpdated}
        onIgnore={onIgnoreDeviceUpdated}
        handleClose={handleClose}
      />
    </>
  );
};

DeviceAddDialog.propTypes = {
  isArchive: PropTypes.bool,
  handleClose: PropTypes.func
};

DeviceAddDialog.defaultProps = {
  isArchive: false,
  handleClose: null
};

export default memo(DeviceAddDialog);
