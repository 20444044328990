import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  useMediaQuery
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { contractWritePermission } from '../../../store/selectors/account.selector';
import theme from '../../../theme';
import { showMessage } from '../../../store/actions/messages.actions';
import store from '../../../store/store';
import { createFaq, putFaq, setFaqsRefresh } from '../../../store/actions/faq.actions';

const QuestionDialog = ({ handleClose, onChange, ...props }) => {
  const dispatch = useDispatch();

  const { action1, actionId1 } = useParams();

  const editPermission = useSelector(contractWritePermission);
  const faqs = useSelector((state) => state.faq.list);

  const [questionDialog, setQuestionDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [faq, setFaq] = useState({ title: '', text: '' });

  // state effect
  useEffect(() => {
    let unmounted = false;

    if (action1 === 'add') {
      setQuestionDialog(true);
      setDialogTitle('Добавить вопрос');
    } else if (action1 === 'edit') {
      setQuestionDialog(true);
      setDialogTitle('Редактировать вопрос');
      const faqFromList = faqs.find((item) => item.id === actionId1) || { title: '', text: '' }
      setFaq({ ...faqFromList });
    } else if (action1 === 'show') {
      setQuestionDialog(true);
      setDialogTitle('Смотреть вопрос');
      const faqFromList = faqs.find((item) => item.id === actionId1) || { title: '', text: '' }
      setFaq({ ...faqFromList });
    } else {
      setQuestionDialog(false);
      setTimeout(() => {
        if (!unmounted) {
          setFaq({ title: '', text: '' });
        }
      }, 200);
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1]);

  const onSaveQuestion = useCallback(async ({ title, text }) => {
    try {
      const data = {
        title,
        text,
      };
      const response = await store.dispatch(actionId1 !== 'faq' ? putFaq(actionId1, data) : createFaq(data));
      dispatch(showMessage({ open: true, text: 'Вопрос успешно сохранен.', severity: 'success' }));
      setTimeout(() => dispatch(setFaqsRefresh()), 500);
      handleClose(null, 'success');
    } catch (error) {
      console.log(error);
    }
  }, [actionId1]);

  return (
    <>
      {/* faq dialog */}
      <Dialog {...props} open={questionDialog} PaperProps={{ sx: { maxWidth: '750px' } }} onClose={handleClose} disableRestoreFocus scroll="body">
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{ fontSize: '22px' }}>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={faq}
            validationSchema={
              Yup.object().shape({
                question: Yup.string().max(255).required('Забыли указать название'),
                title: Yup.string().min(10, 'Неверный телефон').required('Забыли указать телефон'),
              })
            }
            onSubmit={async (values, { setTouched, validateForm }) => {
              const validationErrors = await validateForm();
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{
                    minWidth: useMediaQuery(theme.breakpoints.up('sm')) ? '420px' : '',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        htmlFor="outlined-question"
                      >
                        Вопрос
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        id="outlined-question"
                        type="text"
                        multiline
                        autoComplete="off"
                        onChange={handleChange('title')}
                        value={values.title}
                        readOnly={!editPermission}
                        label="Вопрос"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* title field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        htmlFor="outlined-title"
                      >
                        Ответ
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        id="outlined-title"
                        type="text"
                        multiline
                        minRows={3}
                        autoComplete="off"
                        onChange={handleChange('text')}
                        value={values.text}
                        readOnly={!editPermission}
                        label="Ответ"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 4,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Grid
                    item
                  >
                    {editPermission && (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => onSaveQuestion(values)}
                      >
                        Сохранить
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

QuestionDialog.propTypes = {
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
};

QuestionDialog.defaultProps = {
  handleClose: null,
  onChange: null,
};

export default QuestionDialog;
