import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const ProgressDialog = ({ show, text, ...props }) => {
  const handleClose = useCallback(() => false, []);

  return (
    <>
      <Dialog {...props} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>{text}</Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Box
            sx={{ width: '100%', textAlign: 'center' }}
          >
            <CircularProgress sx={{ ml: 1 }} size="20px" color="primary" />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

ProgressDialog.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string
};

ProgressDialog.defaultProps = {
  show: null,
  text: ''
};

export default memo(ProgressDialog);
