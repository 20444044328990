import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { v4 as uuid } from 'uuid';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  addBuildingToArea,
  createBuilding,
  getBuilding,
  putBuilding,
  removeBuildingFromArea,
  searchArea,
  searchSuggestions,
  setBuildingsCache,
  setBuildingsRefresh
} from '../../../store/actions/buildings.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import { placeWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import useDebounce from '../../../utils/useDebounce';
import IntercomDialog from './IntercomDialog';
import copyToClipboard from '../../../utils/copyToClipboard';

const buildingEmptyState = {
  id: 'home',
  fiasId: '',
  area: '',
  address: '',
  buildingType: { code: 'APARTMENT_HOUSE', name: 'Многоквартирный дом' },
  intercoms: [],
  prefix: ''
};

const BuildingDialog = ({
  territory,
  isArchive,
  onUpdateBuilding,
  onUpdate,
  onRemove,
  handleClose,
  ...props
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action0, actionId0, action1, actionId1, action2, actionId2 } =
    useParams();

  const editPermission = useSelector(placeWritePermission);

  const [areas, setAreas] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [areaSearch, setAreaSearch] = useState(null);
  const [addressSearch, setAddressSearch] = useState(null);
  const [addressLoading, setAddressLoading] = useState(false);
  const [debouncedArea] = useDebounce(areaSearch, 100);
  const [debouncedAddress] = useDebounce(addressSearch, 100);
  const [buildingDetails, setBuildingDetails] = useState({
    ...buildingEmptyState
  });
  const [building, setBuilding] = useState({ ...buildingEmptyState });
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const buildingsCache = useSelector((state) => state.buildings.cache);
  const buildings = useSelector((state) => state.buildings.list);
  const buildingTypes = useSelector((state) => state.buildings.buildingTypes);

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action1, action2, actionId1, actionId2, building.intercoms[0], buildingsCache);
    const buildingFromList =
      actionId1 && buildings.find((item) => item.id === actionId1);
    if (action1 === 'edit' || action1 === 'show' || action1 === 'add') {
      setEditDialog(true);
      if (action1 === 'edit') {
        setEditDialogTitle('Редактировать дом');
      } else if (action1 === 'show') {
        setEditDialogTitle('Смотреть дом');
      } else if (action1 === 'add') {
        setEditDialogTitle('Создать дом');
      }
    } else {
      setEditDialog(false);
      setTimeout(() => {
        if (!unmounted && building.id) {
          if (
            building.id !== 'home' ||
            building.prefix ||
            building.intercoms?.length
          ) {
            setBuilding({ ...buildingEmptyState });
          }
          if (
            buildingDetails.id !== 'home' ||
            buildingDetails.intercoms?.length
          ) {
            setBuildingDetails({ ...buildingEmptyState });
          }
          if (areaSearch !== null) {
            setAreaSearch(null);
          }
          if (
            buildingsCache.home &&
            (buildingsCache.home.address ||
              buildingsCache.home.prefix ||
              buildingsCache.home.intercoms?.length)
          ) {
            // clear home cache
            dispatch(setBuildingsCache(buildingEmptyState));
          }
        }
      }, 200);
    }
    if (
      (action1 === 'edit' || action1 === 'show' || action1 === 'add') &&
      actionId1
    ) {
      setBuilding({
        ...(buildingsCache[actionId1] || buildingEmptyState),
        ...buildingFromList,
        ...((buildingDetails.id === actionId1 || actionId1 === 'home') &&
          buildingDetails)
      });
    }
    if (territory && actionId1 === 'territory') {
      setEditDialog(false);
    }
    return () => {
      unmounted = true;
    };
  }, [
    action1,
    action2,
    actionId1,
    actionId2,
    buildings,
    buildingDetails,
    buildingTypes
  ]);

  // building details effect
  useEffect(async () => {
    let unmounted = false;
    if (actionId1 && actionId1 !== 'home' && actionId1 !== 'territory') {
      try {
        const { data } = await store.dispatch(getBuilding(actionId1));
        if (!data.buildingType.code) {
          data.buildingType =
            buildingTypes.find((item) => item.code === data.buildingType) ||
            buildingEmptyState.buildingType;
          // console.log('wrong format from server', data.buildingType);
        }
        dispatch(setBuildingsCache(data));
        if (data && data.id === actionId1) {
          setBuildingDetails({
            ...data
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [actionId1]);

  // area details effect
  useEffect(async () => {
    let unmounted = false;
    if (debouncedArea !== null) {
      try {
        setAddressLoading(true);
        const { data } = await store.dispatch(
          searchArea({ term: debouncedArea })
        );
        if (data && !unmounted) {
          setAreas(
            data.slice(0, 9).map((item) => ({
              ...item,
              matches: match(item.name.toLowerCase(), areaSearch.toLowerCase())
            }))
          );
          setAddressLoading(false);
        }
      } catch (error) {
        console.log(error);
        setAddressLoading(false);
      }
    } else if (buildingsCache[actionId1]?.area) {
      setAreas(
        buildingsCache[actionId1]?.area
          ? [{ ...buildingsCache[actionId1].area, matches: [] }]
          : []
      );
    } else {
      setAreas([]);
    }
    return () => {
      unmounted = true;
    };
  }, [debouncedArea]);

  // fias address details effect
  useEffect(async () => {
    let unmounted = false;
    if (debouncedAddress !== null) {
      try {
        const { data } = await store.dispatch(
          searchSuggestions({ term: debouncedAddress, count: 9 })
        );
        if (data && !unmounted) {
          setSuggestions(
            data.slice(0, 9).map((item) => {
              const matches = match(
                item.fullAddress.toLowerCase(),
                debouncedAddress.toLowerCase() || ''
              );
              const parts = parse(item.fullAddress, matches);
              // console.log(item, matches, parts)
              return { ...item, parts };
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else if (buildingsCache[actionId1]?.address) {
      setSuggestions(
        buildingsCache[actionId1]?.address
          ? [{ ...buildingsCache[actionId1].address, matches: [] }]
          : []
      );
    } else {
      setSuggestions([]);
    }
    return () => {
      unmounted = true;
    };
  }, [debouncedAddress]);

  const getDiapason = (intercomItem) =>
    intercomItem?.flatRanges
      ?.map((flat) =>
        (flat.min === flat.max ? flat.min : flat.min + '-' + flat.max))
      .join('\r\n') ?? '';

  const onAddIntercomClick = useCallback(
    (values) => {
      if (action2 === undefined) {
        setBuildingDetails({
          ...buildingDetails,
          ...values
        });
        setTimeout(() => navigate('add/intercom'), 10);
      }
    },
    [navigate, building, building.buildingType]
  );

  const onOpenIntercomClick = useCallback(
    (intercomItem) => {
      if (action2 === undefined) {
        if (editPermission && intercomItem.status !== 'CONFIGURED') {
          navigate(`edit/${intercomItem.id || 'intercom'}`);
        } else {
          if (intercomItem.status === 'CONFIGURED' && editPermission) {
            dispatch(
              showMessage({
                open: true,
                text: 'Редактирование входа при подключенном домофоне невозможно!',
                severity: 'error'
              })
            );
          }
          navigate(`show/${intercomItem.id || 'intercom'}`);
        }
      }
    },
    [navigate]
  );

  const onDeleteIntercomClick = useCallback(
    (intercomItem) => {
      if (action2 === undefined) {
        navigate(`delete/${intercomItem.id}`);
      }
    },
    [navigate]
  );

  const onUpdateIntercom = useCallback(
    (intercom) => {
      if (territory && actionId1 === 'territory') {
        // update territory dialog if needed
        if (onUpdate) {
          onUpdate(intercom);
        }
      } else {
        const buildingUpdated = {
          ...buildingDetails,
          intercoms: [...buildingDetails.intercoms]
        };
        // console.log(444, intercom)
        const intercomIndex = buildingUpdated.intercoms.findIndex(
          (item) => item.id === intercom.id
        );
        if (intercomIndex !== -1) {
          const intercomStatus = buildingUpdated.intercoms[intercomIndex].status;
          buildingUpdated.intercoms[intercomIndex] = { ...intercom, status: intercomStatus };
        } else {
          buildingUpdated.intercoms[buildingUpdated.intercoms.length] =
            intercom;
        }
        dispatch(setBuildingsCache(buildingUpdated));
        setBuildingDetails({
          ...buildingUpdated
        });
      }
    },
    [territory, buildingDetails, actionId1]
  );

  const onRemoveIntercom = useCallback(
    (intercom) => {
      if (territory && actionId1 === 'territory') {
        // update territory dialog if present
        if (onRemove) {
          onRemove(intercom);
        }
      } else {
        const buildingUpdated = {
          ...buildingDetails,
          intercoms: [...buildingDetails.intercoms]
        };
        buildingUpdated.intercoms = buildingUpdated.intercoms.filter(
          (item) => item.id !== intercom.id
        );
        dispatch(setBuildingsCache(buildingUpdated));
        setBuildingDetails({
          ...buildingUpdated
        });
      }
    },
    [territory, buildingDetails, actionId1]
  );

  const onSaveBuilding = useCallback(
    async (values) => {
      const data = {
        id: values.id !== 'home' ? values.id : '',
        fiasId: values.fiasId || '',
        address: values.address || '',
        prefix: values.prefix || '',
        buildingType: values.buildingType.code || '',
        intercoms: [...building.intercoms]
      };
      // console.log(data)
      if (territory) {
        // update territory dialog if present
        if (onUpdateBuilding) {
          onUpdateBuilding(data);
        }
        handleClose(null, 'success');
      } else {
        try {
          // remove local ids
          data.intercoms =
            data.intercoms.map((item) =>
              (item.idIsLocal ? { ...item, id: '', idIsLocal: undefined } : item)) || [];
          const response = await store.dispatch(
            data.id ? putBuilding(data) : createBuilding(data)
          );
          // update area buildings
          // console.log(values.areaUpdated, values.area)
          if (values.area?.id && values.areaUpdated) {
            const buildingToAdd =
              data.id || response.headers.location?.split('/')?.pop();
            if (buildingToAdd) {
              await store.dispatch(
                addBuildingToArea(values.area.id, buildingToAdd)
              );
            }
          } else if (!values.area?.id && values.areaUpdated) {
            // remove building from area
            const buildingToRemove =
              data.id || response.headers.location.split('/').pop();
            if (buildingToRemove) {
              await store.dispatch(
                removeBuildingFromArea(building.area?.id, buildingToRemove)
              );
            }
          }
          dispatch(
            showMessage({
              open: true,
              text: 'Дом успешно сохранён.',
              severity: 'success'
            })
          );
          dispatch(setBuildingsCache(values));
          handleClose(null, 'success');
          setTimeout(() => dispatch(setBuildingsRefresh()), 500);
        } catch (error) {
          dispatch(
            showMessage({
              open: true,
              text: `Не удалось сохранить дом. ${error.message}`,
              severity: 'error'
            })
          );
        }
      }
    },
    [building]
  );
  const getPlaceType = (type) => {
    switch (type) {
      case 'GATE':
        return 'Калитка/шлагбаум';
      case 'ENTRANCE':
        return 'Подъезд';

      case 'ADDITIONAL_ENTRANCE':
        return 'Дополнительный подъезд';

      default:
        return '';
    }
  };
  return (
    <>
      <Dialog
        {...props}
        open={editDialog}
        onClose={handleClose}
        fullWidth
        scroll="body"
        disableRestoreFocus
        PaperProps={{ sx: { maxWidth: '900px' } }}
      >
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{ fontSize: '22px' }}>{editDialogTitle}</Box>
        </DialogTitle>
        <DialogContent
          sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}
        >
          <Formik
            enableReinitialize
            initialValues={building}
            validationSchema={Yup.object().shape({
              id: Yup.string(),
              area: Yup.string().nullable().max(255),
              address: Yup.string().max(255).required('Забыли указать адрес'),
              prefix: Yup.number()
                .typeError('Префекс может быть только числом')
                .positive()
                .notRequired(),
              buildingType: Yup.string()
                .nullable()
                .max(255)
                .required('Забыли указать тип входа')
            })}
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                {/* <Paper
                  elevation={3}
                  sx={{
                    minHeight: '100%',
                    mb: 3
                  }}
                >
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="Адрес и входы"
                  >
                    <LinkTab label="Дом" onClick={() => setTab(0)} color="green" />
                    <LinkTab label="Входы" onClick={() => setTab(1)} />
                  </Tabs>
                </Paper> */}
                {/* first form step */}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    {/* address field */}
                    <Autocomplete
                      disableListWrap
                      id="combo-box-address-fias"
                      options={suggestions || []}
                      value={values?.address || ''}
                      getOptionLabel={(item) => item?.fullAddress || item}
                      onOpen={() => setAddressSearch(values?.address)}
                      onInput={($event) =>
                        setAddressSearch($event.target.value)}
                      onChange={(event, option) => {
                        setFieldValue('addressUpdated', true);
                        setFieldValue('address', option?.fullAddress || '');
                        setFieldValue('fiasId', option?.fiasId || '');
                        setAddressSearch(option?.fullAddress || '');
                      }}
                      onBlur={() => {
                        setAddressSearch(values?.address);
                      }}
                      noOptionsText={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {values.address === addressSearch
                            ? 'Нет вариантов'
                            : 'Адреса нет в ФИАС'}
                          {!addressLoading &&
                            suggestions.length === 0 &&
                            values.address !== addressSearch && (
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  setFieldValue('addressUpdated', true);
                                  setFieldValue('address', addressSearch || '');
                                  // setFieldValue('id', 'home');
                                  setFieldValue('fiasId', '');
                                  setAddressSearch(addressSearch || '');
                                  const ref = document.activeElement;
                                  if (ref) {
                                    ref.blur();
                                  }
                                }}
                              >
                                Добавить без ФИАС
                              </Button>
                            )}
                        </Box>
                      }
                      disabled={!editPermission || isArchive}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          label={values.fiasId ? 'Адрес по ФИАС' : 'Адрес'}
                          /*InputProps={{
                            startAdornment: (
                              <>
                                {suggestions.length === 0 && (
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Добавить
                                  </Button>
                                )}
                              </>
                            )
                          }}*/
                        />
                      )}
                      filterSelectedOptions
                      filterOptions={(options) => options}
                      getOptionDisabled={(option) =>
                        option.active || option.address === building.address}
                      renderOption={(renderProps, option) => (
                        <li {...renderProps} key={uuid()}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              {option.parts.map((part, index) => (
                                <span
                                  key={'span' + uuid()}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                    color: part.highlight ? '#00AFF3' : ''
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </Grid>
                          </Grid>
                        </li>
                      )}
                    />
                    <FormHelperText
                      sx={{ ml: 1 }}
                      onClick={() =>
                        copyToClipboard(
                          values.fiasId ? values.fiasId : values.id,
                          () =>
                            dispatch(
                              showMessage({
                                open: true,
                                text:
                                  (values.fiasId ? 'ФИАС ID' : 'ID') +
                                  ' скопирован в буфер обмена',
                                severity: 'success'
                              })
                            )
                        )}
                    >
                      {values.fiasId
                        ? 'ФИАС ID: ' + values.fiasId
                        : values.id !== 'home'
                        ? 'ID: ' + values.id
                        : ''}
                    </FormHelperText>
                    {/*<FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.address && errors.address)}
                        htmlFor="outlined-address"
                      >
                        Адрес
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.address && errors.address)}
                        id="outlined-address"
                        type="text"
                        multiline
                        autoFocus
                        autoComplete="off"
                        value={values.address}
                        onChange={handleChange('address')}
                        readOnly={!editPermission || isArchive}
                        label="Адрес"
                      />
                      {touched.address && errors.address && (
                        <FormHelperText error id="outlined-address-error">
                          {errors.address}
                        </FormHelperText>
                      )}
                    </FormControl>*/}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {/* prefix field */}
                    <FormControl
                      sx={{ m: 0, width: '100%' }}
                      variant="outlined"
                    >
                      <InputLabel
                        error={Boolean(touched.prefix && errors.prefix)}
                        htmlFor="outlined-prefix"
                      >
                        Префикс дома
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.prefix && errors.prefix)}
                        id="outlined-prefix"
                        type="text"
                        value={values.prefix || ''}
                        onChange={handleChange('prefix')}
                        onBlur={handleBlur('prefix')}
                        readOnly={!editPermission || isArchive}
                        label="Префикс дома"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      />
                      {touched.prefix && errors.prefix && (
                        <FormHelperText error id="outlined-prefix-error">
                          {errors.prefix}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* building area field */}
                    <Autocomplete
                      disableListWrap
                      id="combo-box-area"
                      options={areas || []}
                      value={territory ?? values?.area ?? ''}
                      getOptionLabel={(item) => item?.name ?? item}
                      onOpen={() => setAreaSearch('')}
                      onInput={($event) => setAreaSearch($event.target.value)}
                      onChange={(event, value) => {
                        // console.log(111, value)
                        setFieldValue('areaUpdated', true);
                        setFieldValue('area', value);
                      }}
                      onBlur={handleBlur('area')}
                      noOptionsText="Нет вариантов"
                      disabled={!editPermission || isArchive || !!territory}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.area && errors.area)}
                          helperText={touched.area && errors.area}
                          label="Территория"
                        />
                      )}
                      filterSelectedOptions
                      filterOptions={(options) => options}
                      renderOption={(renderProps, option) => {
                        const parts = parse(option.name, option.matches);
                        return (
                          <li {...renderProps}>
                            <Grid container alignItems="center">
                              <Grid item xs>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                      color: part.highlight ? '#00AFF3' : ''
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {/* building type field */}
                    <Autocomplete
                      disableListWrap
                      id="combo-box-buildingType"
                      options={buildingTypes}
                      value={values.buildingType}
                      getOptionLabel={(item) => item.name}
                      onChange={(event, value) =>
                        setFieldValue('buildingType', value)}
                      onBlur={handleBlur('buildingType')}
                      disabled={!editPermission || isArchive}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            touched.buildingType && errors.buildingType
                          )}
                          helperText={
                            touched.buildingType && errors.buildingType
                          }
                          label="Тип дома"
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {/* intercoms table */}
                <Box sx={{ my: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '100%' }}>Вход</TableCell>
                        <TableCell>Диапазон</TableCell>
                        <TableCell
                          sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                        >
                          Тип входа
                        </TableCell>
                        <TableCell
                          sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                        >
                          {building.intercoms.some((v) =>
                            Object.prototype.hasOwnProperty.call(
                              v,
                              'entranceNumber'
                            )) &&
                            building.intercoms.some(
                              (v) => v.placingType === 'ENTRANCE'
                            ) &&
                            'Номер подъезда'}
                        </TableCell>
                        <TableCell style={{ width: 120 }}>
                          {/* Редактирование */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {building.intercoms.map((intercomItem, intercomIndex) => (
                        <TableRow hover key={intercomItem.id || intercomIndex}>
                          <TableCell>
                            {intercomItem.name || intercomItem.type}
                          </TableCell>
                          <TableCell
                            sx={{ whiteSpace: 'no-wrap', textAlign: 'center' }}
                          >
                            {getDiapason(intercomItem)}
                          </TableCell>
                          <TableCell>
                            {getPlaceType(intercomItem.placingType)}
                          </TableCell>

                          <TableCell
                            sx={{ whiteSpace: 'no-wrap', textAlign: 'center' }}
                          >
                            {intercomItem.placingType === 'ENTRANCE'
                              ? intercomItem.entranceNumber
                              : ''}
                          </TableCell>
                          <TableCell size="small">
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex'
                              }}
                            >
                              <Tooltip
                                title={
                                  editPermission ? 'Редактировать' : 'Смотреть'
                                }
                                placement="top"
                                followCursor
                                enterDelay={1000}
                              >
                                <IconButton
                                  aria-label="edit"
                                  color="primary"
                                  onClick={() =>
                                    onOpenIntercomClick(intercomItem)}
                                >
                                  {intercomItem.id && (
                                    <SvgIcon fontSize="medium" color="primary">
                                      {editPermission && !isArchive && intercomItem.status !== 'CONFIGURED' ? (
                                        <Edit />
                                      ) : (
                                        <VisibilityOutlined />
                                      )}
                                    </SvgIcon>
                                  )}
                                </IconButton>
                              </Tooltip>
                              {editPermission && !isArchive && (
                                <Tooltip
                                  title="Удалить"
                                  placement="top"
                                  followCursor
                                  enterDelay={1000}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    color="secondary"
                                    onClick={() =>
                                      onDeleteIntercomClick(intercomItem)}
                                  >
                                    {intercomItem.id && (
                                      <SvgIcon
                                        fontSize="medium"
                                        color="secondary"
                                      >
                                        <Delete />
                                      </SvgIcon>
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3
                  }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '12px',
                      pt: 2,
                      pl: 2
                    }}
                  >
                    {editPermission && !isArchive && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="purple"
                          onClick={() => onAddIntercomClick(values)}
                        >
                          Добавить вход
                        </Button>
                      </Grid>
                    )}
                    {editPermission && !isArchive && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          aria-disabled={
                            !isValid ||
                            isSubmitting ||
                            !values.address ||
                            !values.intercoms?.length
                          }
                          onClick={async () => {
                            const validationErrors = await validateForm();
                            if (Object.keys(validationErrors).length === 0) {
                              onSaveBuilding(values);
                            } else {
                              setTouched(
                                setNestedObjectValues(validationErrors, true)
                              );
                              dispatch(
                                showMessage({
                                  open: true,
                                  text: 'Проверьте ошибки заполнения формы',
                                  severity: 'error'
                                })
                              );
                            }
                          }}
                        >
                          Сохранить
                        </Button>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* intercom dialog */}
      <IntercomDialog
        isArchive={isArchive}
        isPrivate={
          building?.buildingType?.code === 'PRIVATE_HOUSE' ||
          actionId1 === 'territory'
        }
        intercoms={
          territory && actionId1 === 'territory'
            ? [...territory.intercomsFromBuildings, ...territory.intercoms]
            : building?.intercoms || []
        }
        onUpdate={(value) => onUpdateIntercom(value)}
        onRemove={(value) => onRemoveIntercom(value)}
        handleClose={() => handleClose()}
      />
    </>
  );
};

BuildingDialog.propTypes = {
  territory: PropTypes.object,
  isArchive: PropTypes.bool,
  onUpdateBuilding: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  handleClose: PropTypes.func
};

BuildingDialog.defaultProps = {
  territory: null,
  isArchive: false,
  onUpdateBuilding: null,
  onUpdate: null,
  onRemove: null,
  handleClose: null
};

export default BuildingDialog;
