const initialState = {
  cache: {},
  list: [],
  listTotal: 0,
  listVersion: 0,
  listState: 'idle',
  statusTypes: [],
  timezones: [],
  timezonesStatus: 'idle',
  configurationStatus: 'idle',
};

function devicesReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case 'devices/timezones/loading': {
      return {
        ...state,
        timezonesStatus: 'loading',
      };
    }
    case 'devices/timezones': {
      return {
        ...state,
        timezones: action.data,
        timezonesStatus: 'success',
      };
    }
    case 'devices/statusTypes': {
      return {
        ...state,
        statusTypes: action.data,
      };
    }
    case 'devices/list': {
      return {
        ...state,
        list: action.data.content,
        listTotal: action.data.pageable.totalElements,
        listState: 'success'
      };
    }
    case 'devices/refresh': {
      return {
        ...state,
        listVersion: state.listVersion + 1,
        listState: 'refresh',
      };
    }
    case 'devices/cache': {
      return {
        ...state,
        cache: { ...state.cache, [action.data.id]: action.data },
      };
    }
    case 'devices/listState': {
      return {
        ...state,
        listState: 'refresh',
      };
    }
    case 'devices/configurationStatus': {
      return {
        ...state,
        configurationStatus: action.data,
      };
    }
    default:
      return state;
  }
}

export default devicesReducer;
