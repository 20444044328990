import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { DomainOutlined, MarkunreadMailboxOutlined, VisibilityOutlined } from '@material-ui/icons';

import { contractWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import { blockSip, deleteSip, getSips, setSipsRefresh } from '../../../store/actions/sips.actions';
import SipsMulti from './SipsMulti';
import useDebounce from '../../../utils/useDebounce';
import { showMessage } from '../../../store/actions/messages.actions';
import copyToClipboard from '../../../utils/copyToClipboard';
import formatPhone from '../../../utils/formatPhone';
import EnhancedTableHead from '../../TableHead';

const SipsList = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editPermission = useSelector(contractWritePermission);
  const sipsIsLoading = useSelector((state) => state.sips.listState !== 'success');
  const sipsVersion = useSelector((state) => state.sips.listVersion);
  const sipsTotal = useSelector((state) => state.sips.listTotal);
  const sips = useSelector((state) => state.sips.list);

  const [phone, setPhone] = useState('');
  const [number, setNumber] = useState('');
  const [debouncedPhone] = useDebounce(phone, 800);
  const [debouncedNumber] = useDebounce(number, 800);

  const [selectedSipIds, setSelectedSipIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = []
      return setOrderBy('');
    }
    sortRef.current.push(property)
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  useEffect(async () => {
    try {
      await store.dispatch(getSips({ page,
size,
number,
phone,
        sort: orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}` }));
      setSelectedSipIds([]);
    } catch (error) {
      console.log(error);
    }
  }, [sipsVersion, order, orderBy, size, page, debouncedNumber, debouncedPhone]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [size, debouncedNumber, debouncedPhone]);

  const handleSelectAll = (event) => {
    let newSelectedSipIds;

    if (event.target.checked) {
      newSelectedSipIds = sips.map((sip) => sip.id);
    } else {
      newSelectedSipIds = [];
    }

    setSelectedSipIds(newSelectedSipIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedSipIds.indexOf(id);
    let newSelectedSipIds = [];

    if (selectedIndex === -1) {
      newSelectedSipIds = newSelectedSipIds.concat(selectedSipIds, id);
    } else if (selectedIndex === 0) {
      newSelectedSipIds = newSelectedSipIds.concat(selectedSipIds.slice(1));
    } else if (selectedIndex === selectedSipIds.length - 1) {
      newSelectedSipIds = newSelectedSipIds.concat(selectedSipIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedSipIds = newSelectedSipIds.concat(
        selectedSipIds.slice(0, selectedIndex),
        selectedSipIds.slice(selectedIndex + 1)
      );
    }

    setSelectedSipIds(newSelectedSipIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const openSipClick = (sip) => {
    if (editPermission) {
      navigate(`/app/contracts/sips/show/${sip.id}`);
      // navigate(`/app/contracts/sips/edit/${sip.id}`);
    } else {
      navigate(`/app/contracts/sips/show/${sip.id}`);
    }
  };

  const onDeleteSipClick = (sip) => {
    if (sip.status.code !== 'DELETED') {
      navigate(`/app/contracts/sips/delete/${sip.id}`);
    } else {
      navigate(`/app/contracts/sips/restore/${sip.id}`);
    }
  };

  // not used for now
  const onSipsDeleteClick = async (sip) => {
    try {
      await Promise.all(selectedSipIds.map((item) => store.dispatch(deleteSip(item))));
      dispatch(showMessage({ open: true, text: 'Абоненты успешно удалены', severity: 'success' }));
      setTimeout(() => dispatch(setSipsRefresh()), 500);
      setSelectedSipIds([]);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось удалить. ${error.message}`, severity: 'error' }));
    }
  };

  // not used for now
  const onSipsBlockClick = async (sip) => {
    try {
      await Promise.all(selectedSipIds.map((item) => store.dispatch(blockSip(item))));
      dispatch(showMessage({ open: true, text: 'Абоненты успешно заблокированы', severity: 'success' }));
      setTimeout(() => dispatch(setSipsRefresh()), 500);
      setSelectedSipIds([]);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось заблокировать. ${error.message}`, severity: 'error' }));
    }
  };

  const hideElement = { visibility: 'hidden' };

  return (
    <>
      <Card {...rest} style={{ backgroundColor: sipsIsLoading ? 'lightyellow' : 'white', transition: 'background-color 400ms ease-in' }}>
        <Helmet>
          <title>SIP-аккаунты | Терион</title>
        </Helmet>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>

              <EnhancedTableHead
                isLoading={sipsIsLoading}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: true,
                    id: 'phone',
                    label: 'Номер телефона',
                    style: { width: 230 }
                  },
                  {
                    sortable: false,
                    id: 'number',
                    label: 'Номер SIP аккаунта',
                    style: { width: 230 }
                  },
                ]}
                hasActions
              />

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" style={hideElement}>
                    <Checkbox
                      checked={selectedSipIds.length > 0 && selectedSipIds.length === sips.length}
                      color="primary"
                      indeterminate={
                        selectedSipIds.length > 0
                        && selectedSipIds.length < sips.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%',
                      }}
                      placeholder="Поиск по телефону"
                      variant="outlined"
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField
                      sx={{
                        minWidth: '100%',
                      }}
                      placeholder="Поиск по номеру"
                      variant="outlined"
                      onChange={(event) => setNumber(event.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sips.slice(0, size).map((sip) => (
                  <TableRow
                    hover
                    key={sip.id}
                    selected={selectedSipIds.indexOf(sip.id) !== -1}
                  >
                    <TableCell padding="checkbox" style={hideElement}>
                      <Checkbox
                        checked={selectedSipIds.indexOf(sip.id) !== -1}
                        onChange={(event) => handleSelectOne(event, sip.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          onClick={() => copyToClipboard('+' + sip.phone, () => dispatch(showMessage({ open: true, text: 'Телефон скопирован в буфер обмена', severity: 'success' })))}
                        >
                          {formatPhone(sip.phone) || 'Нет телефона'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        onClick={() => copyToClipboard(sip.number, () => dispatch(showMessage({ open: true, text: 'Номер SIP аккаунта скопирован в буфер обмена', severity: 'success' })))}
                      >
                        {sip.number || 'Нет номера'}
                      </Typography>
                    </TableCell>
                    <TableCell size="small">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Tooltip title="Смотреть" placement="top" followCursor enterDelay={1000}>
                          <IconButton aria-label="edit" color="primary" onClick={() => openSipClick(sip)}>
                            <SvgIcon
                              fontSize="medium"
                              color="primary"
                            >
                              <VisibilityOutlined />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={sipsTotal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      <SipsMulti
        sipsSelected={selectedSipIds.length}
        sipsDeleteClick={() => onSipsDeleteClick()}
        sipsBlockClick={() => onSipsBlockClick()}
        sx={{ my: 2 }}
      />
    </>
  );
};

SipsList.propTypes = {
};

export default SipsList;
