import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setBuildingsRefresh, unarchiveBuilding } from '../../../store/actions/buildings.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import { placeWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';

const BuildingUnArchiveDialog = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const { action1, actionId1, action2, actionId2 } = useParams();

  const editPermission = useSelector(placeWritePermission);

  const buildings = useSelector((state) => state.buildings.archive);
  const [buildingSelected, setBuildingSelected] = useState({});
  const [unArchiveDialog, setUnArchiveDialog] = useState(false);

  // state effect
  useEffect(() => {
    let unmounted = false;
    const buildingFromList = actionId1 && (buildings.find((item) => item.id === actionId1));
    if (action1 === 'restore') {
      setUnArchiveDialog(true);
    } else {
      setUnArchiveDialog(false);
    }
    if (action1 === 'restore' && actionId1) {
      setBuildingSelected({
        ...buildingFromList,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [action1, action2, actionId1, actionId2, buildings]);

  const onUnArchiveBuilding = async () => {
    try {
      await store.dispatch(unarchiveBuilding(actionId1));
      dispatch(showMessage({ open: true, text: 'Дом успешно восстановлен из архива', severity: 'success' }));
      handleClose(null, 'success');
      setTimeout(() => dispatch(setBuildingsRefresh()), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось восстановить из архива. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      {/* unarchive dialog */}
      <Dialog {...props} open={unArchiveDialog} onClose={handleClose} disableRestoreFocus PaperProps={{ sx: { minWidth: '350px' } }}>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Восстановить из архива?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Дом с адресом </span>
            <strong>{buildingSelected.address}</strong>
            <span> будет перемещен в основной жилфонд</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              {editPermission && (
                <Button
                  variant="contained"
                  color="purple"
                  fullWidth
                  onClick={onUnArchiveBuilding}
                >
                  Восстановить
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

BuildingUnArchiveDialog.propTypes = {
  handleClose: PropTypes.func,
};

BuildingUnArchiveDialog.defaultProps = {
  handleClose: null,
};

export default BuildingUnArchiveDialog;
