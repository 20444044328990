import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { userWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const UsersMulti = ({
  customersActive,
  customersDeleteClick,
  ...props
}) => {
  const editPermission = useSelector(userWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {editPermission && !!customersActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => customersDeleteClick()}
          >
            Удалить&nbsp;
            { customersActive }
            &nbsp;
            { decOfNum(customersActive, ['пользователя', 'пользователей', 'пользователей']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

UsersMulti.propTypes = {
  customersActive: PropTypes.number,
  customersDeleteClick: PropTypes.func,
};

UsersMulti.defaultProps = {
  customersActive: 0,
  customersDeleteClick: null,
};

export default UsersMulti;
