import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import Users from './pages/Users';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Settings from './pages/Settings';
import SetPassword from './pages/password/SetPassword';
import ResetPassword from './pages/password/ResetPassword';
import Places from './pages/Places';
import Contracts from './pages/Contracts';
import Intercoms from './pages/Intercoms';
import Faq from './pages/Faq';
import Logs from './pages/Logs';

const routes = (isLoggedIn, redirectUrl = '/app/users') => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'users', element: <Navigate to="/app/users/customers" /> },
      { path: 'places', element: <Navigate to="/app/places/homes" /> },
      { path: 'customers', element: <Navigate to="/app/users/customers" /> },
      // users
      { path: 'users/customers', element: <Users /> },
      { path: 'users/customers/:action', element: <Users /> },
      { path: 'users/customers/:action/:customerId', element: <Users /> },
      { path: 'users/roles', element: <Users /> },
      { path: 'users/roles/:action', element: <Users /> },
      { path: 'users/roles/:action/:roleId', element: <Users /> },
      { path: 'users/departments', element: <Users /> },
      { path: 'users/departments/:action', element: <Users /> },
      { path: 'users/departments/:action/:departmentId', element: <Users /> },
      // homes
      { path: 'places/homes', element: <Places /> },
      { path: 'places/homes/:action1', element: <Places /> },
      { path: 'places/homes/:action1/:actionId1', element: <Places /> },
      {
        path: 'places/homes/:action1/:actionId1/:action2',
        element: <Places />
      },
      {
        path: 'places/homes/:action1/:actionId1/:action2/:actionId2',
        element: <Places />
      },
      // homes archive
      { path: 'places/archive-homes', element: <Places /> },
      { path: 'places/archive-homes/:action1', element: <Places /> },
      { path: 'places/archive-homes/:action1/:actionId1', element: <Places /> },
      {
        path: 'places/archive-homes/:action1/:actionId1/:action2',
        element: <Places />
      },
      {
        path: 'places/archive-homes/:action1/:actionId1/:action2/:actionId2',
        element: <Places />
      },
      // territories
      { path: 'places/territories', element: <Places /> },
      { path: 'places/territories/:action0', element: <Places /> },
      { path: 'places/territories/:action0/:actionId0', element: <Places /> },
      {
        path: 'places/territories/:action0/:actionId0/:action1',
        element: <Places />
      },
      {
        path: 'places/territories/:action0/:actionId0/:action1/:actionId1',
        element: <Places />
      },
      {
        path: 'places/territories/:action0/:actionId0/:action1/:actionId1/:action2',
        element: <Places />
      },
      {
        path: 'places/territories/:action0/:actionId0/:action1/:actionId1/:action2/:actionId2',
        element: <Places />
      },
      // territories archive
      { path: 'places/archive-territories', element: <Places /> },
      { path: 'places/archive-territories/:action0', element: <Places /> },
      {
        path: 'places/archive-territories/:action0/:actionId0',
        element: <Places />
      },
      {
        path: 'places/archive-territories/:action0/:actionId0/:action1',
        element: <Places />
      },
      {
        path: 'places/archive-territories/:action0/:actionId0/:action1/:actionId1',
        element: <Places />
      },
      {
        path: 'places/archive-territories/:action0/:actionId0/:action1/:actionId1/:action2',
        element: <Places />
      },
      {
        path: 'places/archive-territories/:action0/:actionId0/:action1/:actionId1/:action2/:actionId2',
        element: <Places />
      },
      // contracts
      { path: 'contracts', element: <Navigate replace to="subscribers" /> },
      { path: 'contracts/subscribers', element: <Contracts /> },
      { path: 'contracts/subscribers/:action1', element: <Contracts /> },
      {
        path: 'contracts/subscribers/:action1/:actionId1',
        element: <Contracts />
      },
      {
        path: 'contracts/subscribers/:action1/:actionId1/:action2',
        element: <Contracts />
      },
      {
        path: 'contracts/subscribers/:action1/:actionId1/:action2/:actionId2',
        element: <Contracts />
      },
      { path: 'contracts/sips', element: <Contracts /> },
      { path: 'contracts/sips/:action1', element: <Contracts /> },
      { path: 'contracts/sips/:action1/:actionId1', element: <Contracts /> },
      // devices
      { path: 'devices', element: <Navigate replace to="intercoms" /> },
      { path: 'devices/intercoms', element: <Intercoms /> },
      { path: 'devices/intercoms/:action1', element: <Intercoms /> },
      { path: 'devices/intercoms/:action1/:actionId1', element: <Intercoms /> },
      {
        path: 'devices/intercoms/:action1/:actionId1/:stepName',
        element: <Intercoms />
      },
      {
        path: 'devices/intercoms/:action1/:actionId1/:stepName/:action2',
        element: <Intercoms />
      },
      {
        path: 'devices/intercoms/:action1/:actionId1/:stepName/:action2/:actionId2',
        element: <Intercoms />
      },
      {
        path: 'devices/intercoms/:action1/:actionId1/:stepName/:action2/:actionId2/:action3/',
        element: <Intercoms />
      },
      {
        path: 'devices/intercoms/:action1/:actionId1/:stepName/:action2/:actionId2/:action3/:actionId3',
        element: <Intercoms />
      },
      { path: 'devices/archive-intercoms', element: <Intercoms /> },
      { path: 'devices/archive-intercoms/:action1', element: <Intercoms /> },
      {
        path: 'devices/archive-intercoms/:action1/:actionId1',
        element: <Intercoms />
      },
      {
        path: 'devices/archive-intercoms/:action1/:actionId1/:stepName',
        element: <Intercoms />
      },
      {
        path: 'devices/archive-intercoms/:action1/:actionId1/:stepName/:action2',
        element: <Intercoms />
      },
      {
        path: 'devices/archive-intercoms/:action1/:actionId1/:stepName/:action2/:actionId2',
        element: <Intercoms />
      },
      {
        path: 'devices/archive-intercoms/:action1/:actionId1/:stepName/:action2/:actionId2/:action3/',
        element: <Intercoms />
      },
      {
        path: 'devices/archive-intercoms/:action1/:actionId1/:stepName/:action2/:actionId2/:action3/:actionId3',
        element: <Intercoms />
      },
      // faq
      { path: 'faq', element: <Faq /> },
      { path: 'faq/:action1', element: <Faq /> },
      { path: 'faq/:action1/:actionId1', element: <Faq /> },
      // logs
      { path: 'logs', element: <Logs /> },
      { path: 'logs/:action1', element: <Logs /> },
      { path: 'logs/:action1/:actionId1', element: <Logs /> },
      // other
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate replace to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'users', element: <Navigate to="/app/users/customers" /> },
      { path: 'reset', element: <ResetPassword /> },
      { path: 'update', element: <SetPassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export const EnumRoutes = {
  devices: '/app/devices/intercoms',
  places: '/app/places/archive-homes'
};

export default routes;
