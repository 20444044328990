import api from '../../api/client';
import { handleServerError } from './utils';

export function departments(data) {
  return { type: 'departments/loaded', data };
}

// async thunk actions

export function getDepartments(data) {
  return async (dispatch) => {
    try {
      const response = await api.getDepartments(data);
      dispatch(departments(response.data));
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDepartment(id) {
  return async () => {
    try {
      const response = await api.getDepartment(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createDepartment(data) {
  return async () => {
    try {
      const response = await api.createDepartment(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putDepartment(data) {
  return async () => {
    try {
      const response = await api.putDepartment(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteDepartment(id) {
  return async () => {
    try {
      const response = await api.deleteDepartment(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
