import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Delete,
  Edit,
  RestoreFromTrash,
  VisibilityOutlined
} from '@material-ui/icons';
import moment from 'moment';
import { contractWritePermission } from '../../store/selectors/account.selector';
import store from '../../store/store';
import {
  blockSubscriber,
  deleteSubscriber,
  getSubscribers,
  restoreSubscriber,
  setSubscribersRefresh,
  unblockSubscriber
} from '../../store/actions/subscribers.actions';
import SubscribersMulti from './SubscribersMulti';
import useDebounce from '../../utils/useDebounce';
import { showMessage } from '../../store/actions/messages.actions';
import getInitials from '../../utils/getInitials';
import copyToClipboard from '../../utils/copyToClipboard';
import formatPhone from '../../utils/formatPhone';
import SubscribersToolbar from './SubscribersToolbar';
import SubscribersConfirm from './SubscribersConfirm';
import EnhancedTableHead from '../TableHead';

const statuses = [
  { code: '', name: 'Все' },
  { code: 'ACTIVE', name: 'Активные' },
  { code: 'BLOCKED', name: 'Заблокированные' },
  { code: 'DELETED', name: 'Удалённые' }
];

const SubscribersList = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editPermission = useSelector(contractWritePermission);
  const subscribersIsLoading = useSelector(
    (state) => state.subscribers.listState !== 'success'
  );
  const subscribersVersion = useSelector(
    (state) => state.subscribers.listVersion
  );
  const subscribersTotal = useSelector((state) => state.subscribers.listTotal);
  const subscribers = useSelector((state) => state.subscribers.list);

  const [status, setStatus] = useState('ACTIVE');
  const [phone, setPhone] = useState('');
  const [number, setNumber] = useState('');
  const [address, setAddress] = useState('');
  const [debouncedPhone] = useDebounce(phone, 800);
  const [debouncedNumber] = useDebounce(number, 800);
  const [debouncedAddress] = useDebounce(address, 800);

  const [selectedSubscriberIds, setSelectedSubscriberIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = [];
      return setOrderBy('');
    }
    sortRef.current.push(property);
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      try {
        // console.log(page, size, address, number, phone, status)
        await store.dispatch(
          getSubscribers({
            page,
            size,
            address,
            number,
            phone,
            status,
            sort: orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}`
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    if (!unmounted) {
      getData();
    }
    return () => {
      unmounted = true;
    };
  }, [
    subscribersVersion,
    size,
    page,
    order,
    orderBy,
    debouncedAddress,
    debouncedNumber,
    debouncedPhone,
    status
  ]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [debouncedAddress, debouncedNumber, debouncedPhone, status, size]);

  useEffect(() => {
    if (selectedSubscriberIds.length) {
      const ids = subscribers.map((item) => item.id);
      const newSelectedSubscriberIds = selectedSubscriberIds.filter((item) =>
        ids.includes(item)
      );
      setSelectedSubscriberIds(newSelectedSubscriberIds);
    }
  }, [subscribers]);

  const handleSelectAll = (event) => {
    let newSelectedSubscriberIds;

    if (event.target.checked) {
      newSelectedSubscriberIds = subscribers.map((subscriber) => subscriber.id);
    } else {
      newSelectedSubscriberIds = [];
    }

    setSelectedSubscriberIds(newSelectedSubscriberIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedSubscriberIds.indexOf(id);
    let newSelectedSubscriberIds = [];

    if (selectedIndex === -1) {
      newSelectedSubscriberIds = newSelectedSubscriberIds.concat(
        selectedSubscriberIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedSubscriberIds = newSelectedSubscriberIds.concat(
        selectedSubscriberIds.slice(1)
      );
    } else if (selectedIndex === selectedSubscriberIds.length - 1) {
      newSelectedSubscriberIds = newSelectedSubscriberIds.concat(
        selectedSubscriberIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedSubscriberIds = newSelectedSubscriberIds.concat(
        selectedSubscriberIds.slice(0, selectedIndex),
        selectedSubscriberIds.slice(selectedIndex + 1)
      );
    }

    setSelectedSubscriberIds(newSelectedSubscriberIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const subscriberColor = (code) => {
    if (code === 'ACTIVE') {
      return '#4caf50';
    }
    if (code === 'BLOCKED') {
      return 'secondary';
    }
    return 'primary';
  };

  const openSubscriberClick = (subscriber) => {
    if (editPermission) {
      navigate(`/app/contracts/subscribers/edit/${subscriber.id}`);
    } else {
      navigate(`/app/contracts/subscribers/show/${subscriber.id}`);
    }
  };

  const onRestoreSubscriber = (subscriber) =>
    navigate(`/app/contracts/subscribers/restore/${subscriber.id}`);

  const onDeleteSubscriber = (subscriber) =>
    navigate(`/app/contracts/subscribers/delete/${subscriber.id}`);

  const updateSubscribersStatus = async (
    filterFunc,
    updateAction,
    successMessage,
    errorMessage
  ) => {
    try {
      const toUpdate = subscribers
        .filter((item) => selectedSubscriberIds.includes(item.id))
        .filter(filterFunc);
      await Promise.all(
        toUpdate.map((item) => store.dispatch(updateAction(item.id)))
      );
      dispatch(
        showMessage({ open: true, text: successMessage, severity: 'success' })
      );
      setTimeout(() => dispatch(setSubscribersRefresh()), 500);
      setSelectedSubscriberIds([]);
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          open: true,
          text: errorMessage + ' ' + error.message,
          severity: 'error'
        })
      );
    }
  };

  const onSubscribersBlockClick = (subscriber) => {
    updateSubscribersStatus(
      (item) => item.status.code === 'ACTIVE',
      blockSubscriber,
      'Абоненты успешно заблокированы.',
      'Не удалось заблокировать.'
    );
  };

  const onSubscribersRestoreClick = (subscriber) => {
    updateSubscribersStatus(
      (item) => item.status.code === 'DELETED',
      restoreSubscriber,
      'Абоненты успешно восстановлены.',
      'Не удалось восстановить.'
    );
  };

  const onSubscribersUnblockClick = (subscriber) => {
    updateSubscribersStatus(
      (item) => item.status.code === 'BLOCKED',
      unblockSubscriber,
      'Абоненты успешно разблокированы.',
      'Не удалось разблокировать.'
    );
  };

  const onSubscribersDeleteClick = (subscriber) => {
    setConfirmDialog(true);
  };

  const onSubscribersConfirmClick = (subscriber) => {
    updateSubscribersStatus(
      (item) =>
        item.status.code === 'ACTIVE' ||
        item.status.code === 'BLOCKED' ||
        item.status.code === 'DELETED',
      deleteSubscriber,
      'Абоненты успешно удалены.',
      'Не удалось удалить.'
    );
    setConfirmDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>Абоненты | Терион</title>
      </Helmet>
      <SubscribersToolbar
        subscribersActive={
          subscribers.filter(
            (item) =>
              selectedSubscriberIds.includes(item.id) &&
              item.status.code === 'ACTIVE'
          ).length
        }
        subscribersDeleted={
          subscribers.filter(
            (item) =>
              selectedSubscriberIds.includes(item.id) &&
              item.status.code === 'DELETED'
          ).length
        }
        subscribersBlocked={
          subscribers.filter(
            (item) =>
              selectedSubscriberIds.includes(item.id) &&
              item.status.code === 'BLOCKED'
          ).length
        }
        subscribersActiveAndBlocked={
          subscribers.filter(
            (item) =>
              selectedSubscriberIds.includes(item.id) &&
              (item.status.code === 'ACTIVE' ||
                item.status.code === 'BLOCKED' ||
                item.status.code === 'DELETED')
          ).length
        }
        subscribersBlockClick={() => onSubscribersBlockClick()}
        subscribersRestoreClick={() => onSubscribersRestoreClick()}
        subscribersUnblockClick={() => onSubscribersUnblockClick()}
        subscribersDeleteClick={() => onSubscribersDeleteClick()}
      />
      <Card
        {...rest}
        style={{
          backgroundColor: subscribersIsLoading ? 'lightyellow' : 'white',
          transition: 'background-color 400ms ease-in'
        }}
      >
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <EnhancedTableHead
                isLoading={subscribersIsLoading}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: true,
                    id: 'created',
                    label: 'Создан',
                    style: { width: 230 }
                  },
                  {
                    sortable: false,
                    id: 'phone',
                    label: 'Телефон',
                    style: { width: 230 }
                  },
                  {
                    sortable: true,
                    id: 'number',
                    label: 'Номер договора',
                    style: { width: 230 }
                  },
                  {
                    sortable: true,
                    id: 'address',
                    label: 'Адрес',
                    style: { width: 230 }
                  }
                ]}
                hasActions
              />

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedSubscriberIds.length > 0 &&
                        selectedSubscriberIds.length === subscribers.length
                      }
                      color="primary"
                      indeterminate={
                        selectedSubscriberIds.length > 0 &&
                        selectedSubscriberIds.length < subscribers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell colSpan={1} />
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по телефону"
                      variant="outlined"
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по номеру"
                      variant="outlined"
                      onChange={(event) => setNumber(event.target.value)}
                    />
                  </TableCell>

                  <TableCell colSpan={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        maxWidth: '100%'
                      }}
                    >
                      <TextField
                        sx={{}}
                        placeholder="Поиск по адресу"
                        variant="outlined"
                        onChange={(event) => setAddress(event.target.value)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Box
                      sx={{
                        display: 'flex',
                        maxWidth: '100%',
                        width: '100%'
                      }}
                    >
                      <Autocomplete
                        sx={{
                          minWidth: '170px',
                          ml: 0
                        }}
                        id="combo-box-status"
                        options={statuses}
                        disableClearable
                        disableListWrap
                        defaultValue={statuses[1]}
                        getOptionLabel={(item) => item.name}
                        onChange={(event, value) => setStatus(value.code)}
                        renderInput={(params) => (
                          <TextField {...params} label="Статус" />
                        )}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscribers.slice(0, size).map((subscriber) => (
                  <TableRow
                    hover
                    key={subscriber.id}
                    selected={
                      selectedSubscriberIds.indexOf(subscriber.id) !== -1
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedSubscriberIds.indexOf(subscriber.id) !== -1
                        }
                        onChange={(event) =>
                          handleSelectOne(event, subscriber.id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        onClick={() =>
                          copyToClipboard(subscriber.created, () =>
                            dispatch(
                              showMessage({
                                open: true,
                                text: 'Адрес скопирован в буфер обмена',
                                severity: 'success'
                              })
                            )
                          )
                        }
                      >
                        {moment(subscriber.created).format(
                          'DD-MM-YYYY HH:mm:ss'
                        ) || '. . .'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={subscriber.avatarUrl}
                          sx={{
                            mr: 1,
                            bgcolor: subscriberColor(subscriber.status.code)
                          }}
                        >
                          {/* {subscriberAvatar(subscriber.status.code)} */}
                          {getInitials(subscriber.status.name)}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          onClick={() =>
                            copyToClipboard(subscriber.phone, () =>
                              dispatch(
                                showMessage({
                                  open: true,
                                  text: 'Телефон скопирован в буфер обмена',
                                  severity: 'success'
                                })
                              )
                            )
                          }
                        >
                          {formatPhone(subscriber.phone) || 'Нет телефона'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        onClick={() =>
                          copyToClipboard(subscriber.number, () =>
                            dispatch(
                              showMessage({
                                open: true,
                                text: 'Номер договора скопирован в буфер обмена',
                                severity: 'success'
                              })
                            )
                          )
                        }
                      >
                        {subscriber.number || 'Нет номера'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        onClick={() =>
                          copyToClipboard(subscriber.address, () =>
                            dispatch(
                              showMessage({
                                open: true,
                                text: 'Адрес скопирован в буфер обмена',
                                severity: 'success'
                              })
                            )
                          )
                        }
                      >
                        {subscriber.address || 'Нет адреса'}
                      </Typography>
                    </TableCell>

                    <TableCell size="small">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Tooltip
                          title={editPermission ? 'Редактировать' : 'Смотреть'}
                          placement="top"
                          followCursor
                          enterDelay={1000}
                        >
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            onClick={() => openSubscriberClick(subscriber)}
                          >
                            <SvgIcon fontSize="medium" color="primary">
                              {editPermission ? (
                                <Edit />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                        {editPermission && (
                          <>
                            {subscriber.status.code === 'DELETED' && (
                              <Tooltip
                                title="Восстановить"
                                placement="top"
                                followCursor
                                enterDelay={1000}
                              >
                                <IconButton
                                  aria-label="delete"
                                  color="secondary"
                                  onClick={() =>
                                    onRestoreSubscriber(subscriber)
                                  }
                                >
                                  <SvgIcon fontSize="medium" color="secondary">
                                    <RestoreFromTrash />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip
                              title={
                                subscriber.status.code === 'DELETED'
                                  ? 'Удалить полностью'
                                  : 'Удалить'
                              }
                              placement="top"
                              followCursor
                              enterDelay={1000}
                            >
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => onDeleteSubscriber(subscriber)}
                              >
                                <SvgIcon
                                  fontSize="medium"
                                  color="secondary"
                                  /* sx={{ color: '#ef6c00' }} */
                                >
                                  <Delete />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={subscribersTotal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      {editPermission && (
        <SubscribersMulti
          subscribersActive={
            subscribers.filter(
              (item) =>
                selectedSubscriberIds.includes(item.id) &&
                item.status.code === 'ACTIVE'
            ).length
          }
          subscribersDeleted={
            subscribers.filter(
              (item) =>
                selectedSubscriberIds.includes(item.id) &&
                item.status.code === 'DELETED'
            ).length
          }
          subscribersBlocked={
            subscribers.filter(
              (item) =>
                selectedSubscriberIds.includes(item.id) &&
                item.status.code === 'BLOCKED'
            ).length
          }
          subscribersActiveAndBlocked={
            subscribers.filter(
              (item) =>
                selectedSubscriberIds.includes(item.id) &&
                (item.status.code === 'ACTIVE' ||
                  item.status.code === 'BLOCKED')
            ).length
          }
          subscribersBlockClick={() => onSubscribersBlockClick()}
          subscribersRestoreClick={() => onSubscribersRestoreClick()}
          subscribersUnblockClick={() => onSubscribersUnblockClick()}
          subscribersDeleteClick={() => onSubscribersDeleteClick()}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <SubscribersConfirm
          showDialog={confirmDialog}
          counter={
            subscribers.filter((item) =>
              selectedSubscriberIds.includes(item.id)
            ).length
          }
          handleConfirm={() => onSubscribersConfirmClick()}
          handleClose={() => setConfirmDialog(false)}
        />
      )}
    </>
  );
};

export default SubscribersList;
