import api from '../../api/client';
import { showInternetErrorMessage, showServerErrorMessage } from './messages.actions';
import { roles } from './roles.actions';
import { departments } from './departments.actions';
import { handleServerError } from './utils';

export function setCustomers(data) {
  return { type: 'customers/list', data };
}

export function setCustomersCache(data) {
  return { type: 'customers/cache', data };
}

export function setCustomersRefresh(data) {
  return { type: 'customers/refresh', data };
}

// async thunk actions

export function getCustomersAndRoles(data) {
  return async (dispatch) => {
    try {
      Promise.all([api.getCustomers(data), api.getRoles(data), api.getDepartments(data)])
        .then(([customersResponse, rolesResponse, departmentsResponse]) => {
          dispatch(setCustomers(customersResponse.data));
          dispatch(roles(rolesResponse.data));
          dispatch(departments(departmentsResponse.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(showInternetErrorMessage());
        });
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getCustomers(data) {
  return async (dispatch) => {
    try {
      const response = await api.getCustomers(data);
      dispatch(setCustomers(response.data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showInternetErrorMessage());
      return handleServerError(error);
    }
  };
}

export function getCustomer(id) {
  return async (dispatch) => {
    try {
      const response = await api.getCustomer(id);
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showInternetErrorMessage());
      return handleServerError(error);
    }
  };
}

export function createCustomer(data) {
  return async () => {
    try {
      const response = await api.createCustomer(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putCustomer(data) {
  return async () => {
    try {
      const response = await api.putCustomer(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteCustomer(id) {
  return async () => {
    try {
      const response = await api.deleteCustomer(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
