import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DevicesLan = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.lan || { dhcp: false }}
      validationSchema={
        Yup.object().shape({
          dhcp: Yup.boolean(),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки LAN устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* dhcp field */}
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{ ml: 1 }}
                    checked={values.dhcp}
                    onChange={
                      (event) => {
                        if (!editPermission || isReadonly) {
                          return
                        }
                        setFieldValue('dhcp', event.target.checked);
                        onUpdate({ lan: { ...values, dhcp: event.target.checked } });
                      }
                    }
                  />
                )}
                label="DHCP"
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DevicesLan.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesLan.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DevicesLan, isDevicePropEqual);
