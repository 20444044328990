import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Autocomplete, Grid, Slider, Stack, TextField, Typography } from '@material-ui/core';
import { Equalizer, GraphicEq, VpnKey } from '@material-ui/icons';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

function simpleValues(values) {
  return Object.fromEntries(Object.entries(values).map((entrie) => {
    if (entrie[0] === 'encodings') {
      return ['encoding', entrie[1]?.find((item) => item.active)?.code || ''];
    }
    if (entrie[0] === 'bitFlows') {
      return ['bitFlow', entrie[1]?.find((item) => item.active)?.code || ''];
    }
    if (entrie[0] === 'resolutions') {
      return ['resolution', entrie[1]?.find((item) => item.active)?.code || ''];
    }
    if (entrie[0] === 'imageQualities') {
      return ['imageQuality', entrie[1]?.find((item) => item.active)?.code || ''];
    }
    if (typeof entrie[1] === 'object' && entrie[1].value) {
      return [entrie[0], entrie[1].value];
    }
    return entrie;
  }));
}

function getMarks(min, max) {
  if (max - min < 20) {
    return Array(max - min + 1).fill(0).map((item, index) => ({ value: index + min, label: index + min }))
  }
  if (max - min < 50) {
    return Array((max - min) / 2 + 1).fill(0).map((item, index) => ({ value: index * 2 + min, label: index * 2 + min }))
  }
  return [{ value: min, label: min }, { value: max, label: max }];
}

const emptyVideoData = {
  id: '',
  additional: {
    encodings: [],
    bitFlows: [],
    imageQualities: [],
    resolutions: [],
    bitrate: { from: 1, to: 15, value: 0 },
    frameRate: { from: 1, to: 15, value: 0 },
    keyInterval: { from: 1, to: 15, value: 0 },
  },
  main: {
    encodings: [],
    bitFlows: [],
    imageQualities: [],
    resolutions: [],
    bitrate: { from: 1, to: 15, value: 0 },
    frameRate: { from: 1, to: 15, value: 0 },
    keyInterval: { from: 1, to: 15, value: 0 },
  }
}

const DevicesVideo = ({ isReadonly, device, onUpdate, ...props }) => {
  const location = useLocation();
  const editPermission = useSelector(deviceWritePermission);

  const { action1, actionId1, stepName } = useParams();
  const [values, setValues] = useState(device.video?.main?.frameRate ? device.video : emptyVideoData);

  // console.log(device.video, values)

  // device change effect
  useEffect(() => {
    let unmounted = !location.pathname.includes('/devices/intercoms/') || !location.pathname.includes('/video');
    if (!unmounted && actionId1) {
      setValues({ ...(device.video || emptyVideoData) }); // deep copy
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, stepName, device.video]);

  const setFieldValue = (key, value) => {
    setValues({ ...values, [key]: value });
  }

  if (!device.video) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
        {'Настройки видео устройства ' + (device.macAddress || '')}
      </Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={12}
            >
              <Typography variant="h5" mt={-1}>
                Основной поток
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* encoding field */}
              <Autocomplete
                id="combo-box-video-main-encoding"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly}
                options={values.main?.encodings || []}
                getOptionLabel={(item) => item.name}
                value={values.main?.encodings?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.main.encodings?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('main', { ...values.main, encodings: update });
                  onUpdate({ video: { main: { ...simpleValues(values.main), encoding: value.code }, additional: { ...simpleValues(values.additional) } } });
                }}
                renderInput={(params) => <TextField {...params} label="Тип кодирования" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* resolutions field */}
              <Autocomplete
                id="combo-box-video-main-resolutions"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly}
                options={values.main?.resolutions || []}
                getOptionLabel={(item) => item.name}
                value={values.main?.resolutions?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.main.resolutions?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('main', { ...values.main, resolutions: update });
                  onUpdate({ video: { main: { ...simpleValues(values.main), resolution: value.code }, additional: { ...simpleValues(values.additional) } } });
                }}
                renderInput={(params) => <TextField {...params} label="Разрешение" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* bitFlow field */}
              <Autocomplete
                id="combo-box-video-main-bitFlow"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly}
                options={values.main?.bitFlows || []}
                getOptionLabel={(item) => item.name}
                value={values.main?.bitFlows?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.main.bitFlows?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('main', { ...values.main, bitFlows: update });
                  onUpdate({ video: { main: { ...simpleValues(values.main), bitFlow: value.code }, additional: { ...simpleValues(values.additional) } } });
                }}
                renderInput={(params) => <TextField {...params} label="Тип сжатия" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* imageQuality field */}
              <Autocomplete
                id="combo-box-video-main-imageQuality"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly || !values.main?.bitFlows.find((item) => item.active)?.requiresImageQualityParam}
                options={values.main?.imageQualities || []}
                getOptionLabel={(item) => item.name}
                value={values.main?.imageQualities?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.main.imageQualities?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('main', { ...values.main, imageQualities: update });
                  onUpdate({
                    video: {
                      main: { ...simpleValues(values.main), imageQuality: value.code },
                      additional: { ...simpleValues(values.additional) }
                    }
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Качество картинки" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* bitrate field */}
              <Typography gutterBottom>
                Битрейт:&nbsp;
                <strong>{values.main?.bitrate.value}</strong>
              </Typography>
              <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
                <Equalizer />
                <Slider
                  size="small"
                  aria-label="Restricted values"
                  disabled={!editPermission || isReadonly}
                  value={values.main.bitrate.value}
                  step={1}
                  min={values.main.bitrate.from}
                  max={values.main.bitrate.to}
                  marks={getMarks(values.main.bitrate.from, values.main.bitrate.to)}
                  onChange={(event, value) => {
                    setFieldValue('main', { ...values.main, bitrate: { ...values.main.bitrate, value } });
                    onUpdate({ video: { main: { ...simpleValues(values.main), bitrate: value }, additional: { ...simpleValues(values.additional) } } });
                  }}
                  valueLabelDisplay="auto"
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* frameRate field */}
              <Typography gutterBottom>
                Частота кадров:&nbsp;
                <strong>{values.main.frameRate.value}</strong>
              </Typography>
              <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
                <GraphicEq />
                <Slider
                  size="small"
                  aria-label="Restricted values"
                  disabled={!editPermission || isReadonly}
                  value={values.main.frameRate.value}
                  step={1}
                  min={values.main.frameRate.from}
                  max={values.main.frameRate.to}
                  marks={getMarks(values.main.frameRate.from, values.main.frameRate.to)}
                  onChange={(event, value) => {
                    setFieldValue('main', { ...values.main, frameRate: { ...values.main.frameRate, value } });
                    onUpdate({ video: { main: { ...simpleValues(values.main), frameRate: value }, additional: { ...simpleValues(values.additional) } } });
                  }}
                  valueLabelDisplay="auto"
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* keyInterval field */}
              <Typography gutterBottom>
                Ключевые кадры:&nbsp;
                <strong>{values.main.keyInterval.value}</strong>
              </Typography>
              <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
                <VpnKey />
                <Slider
                  size="small"
                  disabled={!editPermission || isReadonly}
                  aria-label="Restricted values"
                  value={values.main.keyInterval.value}
                  step={1}
                  min={values.main.keyInterval.from}
                  max={values.main.keyInterval.to}
                  marks={getMarks(values.main.keyInterval.from, values.main.keyInterval.to)}
                  onChange={(event, value) => {
                    setFieldValue('main', { ...values.main, keyInterval: { ...values.main.keyInterval, value } });
                    onUpdate({ video: { main: { ...simpleValues(values.main), keyInterval: value }, additional: { ...simpleValues(values.additional) } } });
                  }}
                  valueLabelDisplay="auto"
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={12}
            >
              <Typography variant="h5" mt={-1}>
                Дополнительный поток
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* encoding field */}
              <Autocomplete
                id="combo-box-video-additional-encoding"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly}
                options={values.additional?.encodings || []}
                getOptionLabel={(item) => item.name}
                value={values.additional?.encodings?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.additional.encodings?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('additional', { ...values.additional, encodings: update });
                  onUpdate({ video: { additional: { ...simpleValues(values.additional), encoding: value.code }, main: { ...simpleValues(values.main) } } });
                }}
                renderInput={(params) => <TextField {...params} label="Тип кодирования" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* resolutions field */}
              <Autocomplete
                id="combo-box-video-additional-resolutions"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly}
                options={values.additional?.resolutions || []}
                getOptionLabel={(item) => item.name}
                value={values.additional?.resolutions?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.additional.resolutions?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('additional', { ...values.additional, resolutions: update });
                  onUpdate({ video: { additional: { ...simpleValues(values.additional), resolution: value.code }, main: { ...simpleValues(values.main) } } });
                }}
                renderInput={(params) => <TextField {...params} label="Разрешение" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* bitFlow field */}
              <Autocomplete
                id="combo-box-video-additional-bitFlow"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly}
                options={values.additional?.bitFlows || []}
                getOptionLabel={(item) => item.name}
                value={values.additional?.bitFlows?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.additional.bitFlows?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('additional', { ...values.additional, bitFlows: update });
                  onUpdate({ video: { additional: { ...simpleValues(values.additional), bitFlow: value.code }, main: { ...simpleValues(values.main) } } });
                }}
                renderInput={(params) => <TextField {...params} label="Тип сжатия" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* imageQuality field */}
              <Autocomplete
                id="combo-box-video-additional-imageQuality"
                size="small"
                disableClearable
                disabled={!editPermission || isReadonly || !values.additional?.bitFlows.find((item) => item.active)?.requiresImageQualityParam}
                options={values.additional?.imageQualities || []}
                getOptionLabel={(item) => item.name}
                value={values.additional?.imageQualities?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.additional.imageQualities?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('additional', { ...values.additional, imageQualities: update });
                  onUpdate({
                    video: {
                      additional: { ...simpleValues(values.additional), imageQuality: value.code },
                      main: { ...simpleValues(values.main) }
                    }
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Качество картинки" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* bitrate field */}
              <Typography gutterBottom>
                Битрейт:&nbsp;
                <strong>{values.additional?.bitrate.value}</strong>
              </Typography>
              <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
                <Equalizer />
                <Slider
                  size="small"
                  aria-label="Restricted values"
                  disabled={!editPermission || isReadonly}
                  value={values.additional.bitrate.value}
                  step={1}
                  min={values.additional.bitrate.from}
                  max={values.additional.bitrate.to}
                  marks={getMarks(values.additional.bitrate.from, values.additional.bitrate.to)}
                  onChange={(event, value) => {
                    setFieldValue('additional', { ...values.additional, bitrate: { ...values.additional.bitrate, value } });
                    onUpdate({ video: { additional: { ...simpleValues(values.additional), bitrate: value }, main: { ...simpleValues(values.main) } } });
                  }}
                  valueLabelDisplay="auto"
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* frameRate field */}
              <Typography gutterBottom>
                Частота кадров:&nbsp;
                <strong>{values.additional.frameRate.value}</strong>
              </Typography>
              <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
                <GraphicEq />
                <Slider
                  size="small"
                  aria-label="Restricted values"
                  disabled={!editPermission || isReadonly}
                  value={values.additional.frameRate.value}
                  step={1}
                  min={values.additional.frameRate.from}
                  max={values.additional.frameRate.to}
                  marks={getMarks(values.additional.frameRate.from, values.additional.frameRate.to)}
                  onChange={(event, value) => {
                    setFieldValue('additional', { ...values.additional, frameRate: { ...values.additional.frameRate, value } });
                    onUpdate({ video: { additional: { ...simpleValues(values.additional), frameRate: value }, main: { ...simpleValues(values.main) } } });
                  }}
                  valueLabelDisplay="auto"
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* keyInterval field */}
              <Typography gutterBottom>
                Ключевые кадры:&nbsp;
                <strong>{values.additional.keyInterval.value}</strong>
              </Typography>
              <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
                <VpnKey />
                <Slider
                  size="small"
                  aria-label="Restricted values"
                  disabled={!editPermission || isReadonly}
                  value={values.additional.keyInterval.value}
                  step={1}
                  min={values.additional.keyInterval.from}
                  max={values.additional.keyInterval.to}
                  marks={getMarks(values.additional.keyInterval.from, values.additional.keyInterval.to)}
                  onChange={(event, value) => {
                    setFieldValue('additional', { ...values.additional, keyInterval: { ...values.additional.keyInterval, value } });
                    onUpdate({ video: { additional: { ...simpleValues(values.additional), keyInterval: value }, main: { ...simpleValues(values.main) } } });
                  }}
                  valueLabelDisplay="auto"
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

DevicesVideo.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesVideo.defaultProps = {
  isReadonly: false,
  device: {},
}

export default memo(DevicesVideo, isDevicePropEqual);
