import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import DeviceKeysDialog from './DeviceKeysDialog';

const DeviceKeysEditor = ({ tagType, isArchive, keys, ciphers, updateKeys, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action2, actionId2 } = useParams();

  const [navigationDepth, setNavigationDepth] = useState(0);
  const [actionLast, setActionLast] = useState('');
  const [pathname, setPathname] = useState('');

  // navigation effect
  useEffect(() => {
    if (pathname === '') {
      // first call
    } else if (pathname.includes(location.pathname)) {
      setNavigationDepth((depth) => Math.max(0, depth - 1));
    } else {
      setNavigationDepth((depth) => depth + 1);
    }
    setPathname(location.pathname);
  }, [location]);

  const handleClose = useCallback(() => {
    let unmounted = false;
    if (navigationDepth) {
      navigate(-1);
    } else if (actionId2) {
      navigate('../../', { replace: true });
    } else {
      navigate('../', { replace: true });
    }
    setActionLast(action2);
    setTimeout(() => {
      if (!unmounted) {
        setActionLast('');
      }
    }, 300);
    return () => {
      unmounted = true;
    };
  }, [navigationDepth, action2, actionId2]);

  return (
    <>
      <DeviceKeysDialog
        tagType={tagType}
        ciphers={ciphers}
        keys={keys}
        updateKeys={updateKeys}
        handleClose={handleClose}
        isReadonly={action2 === 'show'}
        updateCiphers={updateKeys}
      />
    </>
  );
};

DeviceKeysEditor.propTypes = {
  tagType: PropTypes.string,
  isArchive: PropTypes.bool,
  ciphers: PropTypes.array,
  keys: PropTypes.array,
  updateKeys: PropTypes.func,
};

DeviceKeysEditor.defaultProps = {
  isArchive: false,
  ciphers: [],
  keys: [],
};

export default DeviceKeysEditor;
