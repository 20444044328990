import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField, Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DevicesSysLog = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.systemLog || { active: false, host: '', port: '', username: '', password: '' }}
      validationSchema={
        Yup.object().shape({
          // name: Yup.string().max(255).required('Забыли указать название'),
          host: Yup.string().max(255).required('Забыли указать хост'),
          port: Yup.string().max(255).required('Забыли указать порт'),
          username: Yup.string().max(255).required('Забыли указать логин'),
          password: Yup.string().max(255).required('Забыли указать пароль'),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки SysLog устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* active field */}
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{ ml: 1 }}
                    checked={values.active}
                    onChange={
                      (event) => {
                        if (!editPermission || isReadonly) {
                          return
                        }
                        setFieldValue('active', event.target.checked);
                        onUpdate({ systemLog: { ...values, active: event.target.checked } });
                      }
                    }
                  />
                )}
                label="Разрешить"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              {/* protocol field */}
              <Autocomplete
                id="combo-box-video-main-protocol"
                disableClearable
                options={values.protocols || []}
                disabled={!editPermission || isReadonly}
                getOptionLabel={(item) => item.name}
                value={values.protocols?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.protocols?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('protocols', update);
                  setFieldValue('protocol', value.code);
                  onUpdate({ systemLog: { ...values, protocol: value.code } });
                }}
                renderInput={(params) => <TextField {...params} label="Протокол" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              {/* level field */}
              <Autocomplete
                id="combo-box-video-main-level"
                disableClearable
                options={values.levels || []}
                disabled={!editPermission || isReadonly}
                getOptionLabel={(item) => item.name}
                value={values.levels?.find((item) => item.active) || { name: '' }}
                onChange={(event, value) => {
                  const update = values.levels?.map((item) => ({ ...item, active: item === value }));
                  setFieldValue('levels', update);
                  setFieldValue('level', value.code);
                  onUpdate({ systemLog: { ...values, level: value.code } });
                }}
                renderInput={(params) => <TextField {...params} label="Уровень" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* host field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.host && errors.host)}
                  htmlFor="outlined-systemLog-host"
                >
                  Хост
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.host && errors.host)}
                  id="outlined-systemLog-host"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  value={values.host || ''}
                  onChange={(event) => {
                    setFieldValue('host', event.target.value);
                    onUpdate({ systemLog: { ...values, host: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Хост"
                />
                {touched.host && errors.host && (
                  <FormHelperText error id="outlined-systemLog-host-error">
                    {errors.host}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* port field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.port && errors.port)}
                  htmlFor="outlined-systemLog-port"
                >
                  Порт
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.port && errors.port)}
                  id="outlined-systemLog-port"
                  type="text"
                  autoComplete="off"
                  value={values.port || ''}
                  onChange={(event) => {
                    setFieldValue('port', event.target.value);
                    onUpdate({ systemLog: { ...values, port: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Порт"
                />
                {touched.port && errors.port && (
                  <FormHelperText error id="outlined-systemLog-port-error">
                    {errors.port}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DevicesSysLog.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesSysLog.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DevicesSysLog, isDevicePropEqual);
