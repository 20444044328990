import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Autocomplete,
  Box,
  Card, Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow, TableSortLabel,
  TextField, Tooltip,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../store/store';
import useDebounce from '../../utils/useDebounce';
import LogsToolbar from './LogsToolbar';
import { getLogs, getLogsTypes } from '../../store/actions/logs.actions';
import 'moment/locale/ru';

moment.locale('ru');

const LogsList = ({ isArchive, ...rest }) => {
  const { action1 } = useParams();

  // const editPermission = useSelector(logWritePermission);
  const logs = useSelector((state) => state.logs.list);
  const logsTotal = useSelector((state) => state.logs.listTotal);
  const customers = useSelector((state) => [{ username: 'Все', code: '' }, ...state.logs.customersList]);
  const sections = useSelector((state) => state.logs.sections);
  const types = useSelector((state) => state.logs.types);
  const logsIsLoading = useSelector((state) => state.logs.listState !== 'success');

  const [selectedFaqIds, setSelectedFaqIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [type, setType] = useState('');
  const [section, setSection] = useState(getSectionCode(action1));
  const [userId, setUserId] = useState('');
  const [sort, setSort] = useState('created,DESC');

  const [descriptionSearch, setDescriptionSearch] = useState('');
  const [debouncedDescriptionSearch] = useDebounce(descriptionSearch, 300);

  function getSectionCode(path) {
    return sections.find((item) => item.path === path)?.code || '';
  }

  // init data effect (get users list)
  useEffect(() => {
    let unmounted = false;
    const getInitData = async () => {
      try {
        await store.dispatch(getLogsTypes());
      } catch (error) {
        console.log(error);
      }
    }
    if (!unmounted) {
      getInitData();
    }
    return () => {
      unmounted = true;
    };
  }, []);

  // change tab effect
  useEffect(() => {
    setSection(getSectionCode(action1));
  }, [action1]);

  // logs effect
  useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      try {
        // console.log(page, size, type, section, userId, debouncedDescriptionSearch)
        await store.dispatch(getLogs({ page, size, type, section, userId, sort, description: debouncedDescriptionSearch }));
      } catch (error) {
        console.log(error);
      }
    }
    if (!unmounted) {
      getData();
    }
    return () => {
      unmounted = true;
    };
  }, [size, page, type, section, userId, sort, debouncedDescriptionSearch]);

  const handleSelectAll = (event) => {
    let newSelectedFaqIds;

    if (event.target.checked) {
      newSelectedFaqIds = logs.map((log) => log.id);
    } else {
      newSelectedFaqIds = [];
    }

    setSelectedFaqIds(newSelectedFaqIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedFaqIds.indexOf(id);
    let newSelectedFaqIds = [];

    if (selectedIndex === -1) {
      newSelectedFaqIds = newSelectedFaqIds.concat(selectedFaqIds, id);
    } else if (selectedIndex === 0) {
      newSelectedFaqIds = newSelectedFaqIds.concat(selectedFaqIds.slice(1));
    } else if (selectedIndex === selectedFaqIds.length - 1) {
      newSelectedFaqIds = newSelectedFaqIds.concat(selectedFaqIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedFaqIds = newSelectedFaqIds.concat(
        selectedFaqIds.slice(0, selectedIndex),
        selectedFaqIds.slice(selectedIndex + 1)
      );
    }

    setSelectedFaqIds(newSelectedFaqIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const clickSort = useCallback((order) => {
    setSort((current) => {
      if (current === 'created,DESC') {
        return 'created,ASC'
      }
      return 'created,DESC'
    });
  }, []);

  return (
    <>
      {/*<Paper
        sx={{
          backgroundColor: 'white',
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          mb: 3,
          mt: 3,
        }}
      >
        <Autocomplete
          sx={{ minWidth: '220px' }}
          id="combo-box-status"
          options={sections}
          disableClearable
          disableListWrap
          defaultValue={sections[0]}
          getOptionLabel={(item) => item.name}
          onChange={(event, value) => setSection(value.code)}
          renderInput={(params) => <TextField {...params} label="Раздел админки" />}
        />
      </Paper>*/}

      <LogsToolbar />

      <Card style={{ backgroundColor: logsIsLoading ? 'lightyellow' : 'white', transition: 'background-color 400ms ease-in' }}>

        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell style={{ width: '20%' }}>
                    Дата
                  </TableCell>*/}
                  <TableCell sortDirection="desc" style={{ width: '120px' }}>
                    <Tooltip
                      enterDelay={300}
                      title={sort.includes('created') ? sort === 'created,DESC' ? 'Сортировка по убыванию' : 'Сортировка по возрастанию' : ''}
                    >
                      <TableSortLabel
                        active={sort.includes('created')}
                        direction={sort.includes('created') ? sort.split(',')[1]?.toLowerCase() : 'asc'}
                        onClick={() => clickSort('created')}
                      >
                        Дата
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    Событие
                  </TableCell>
                  <TableCell style={{ width: '165px' }}>
                    Тип
                  </TableCell>
                  <TableCell style={{ width: '190px' }}>
                    Логин
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {/*sort*/}
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%',
                      }}
                      placeholder="Поиск по тексту события"
                      variant="outlined"
                      onChange={(event) => setDescriptionSearch(event.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      sx={{
                        marginLeft: '-16px',
                      }}
                      id="combo-box-status"
                      options={types}
                      disableClearable
                      disableListWrap
                      defaultValue={types[0]}
                      getOptionLabel={(item) => item.name}
                      onChange={(event, value) => setType(value.code)}
                      renderInput={(params) => <TextField {...params} label="По типу" />}
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      sx={{
                        marginLeft: '-16px',
                      }}
                      id="combo-box-status"
                      options={customers}
                      disableClearable
                      disableListWrap
                      defaultValue={customers[0]}
                      getOptionLabel={(item) => item.username}
                      onChange={(event, value) => setUserId(value.id)}
                      renderInput={(params) => <TextField {...params} label="По логину" />}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log) => (
                  <TableRow
                    hover
                    key={log.id}
                    selected={selectedFaqIds.indexOf(log.id) !== -1}
                  >
                    <TableCell>
                      <Typography>
                        {moment(log.created).format('D MMMM YYYY HH:mm')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {log.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {log.type.code}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {log.user?.username}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={logsTotal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

LogsList.propTypes = {
  isArchive: PropTypes.bool,
};

LogsList.defaultProps = {
  isArchive: false,
}

export default LogsList;
