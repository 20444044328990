const initialState = {
  cache: {},
  list: [],
  listTotal: 0,
  listVersion: 0,
  listState: 'idle',
  statusTypes: [],
};

function sipsReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case 'sips/statusTypes': {
      return {
        ...state,
        statusTypes: action.data,
      };
    }
    case 'sips/list': {
      return {
        ...state,
        list: action.data.content,
        listTotal: action.data.pageable.totalElements,
        listState: 'success'
      };
    }
    case 'sips/refresh': {
      return {
        ...state,
        listVersion: state.listVersion + 1,
        listState: 'refresh',
      };
    }
    case 'sips/cache': {
      return {
        ...state,
        cache: { ...state.cache, [action.data.id]: action.data },
      };
    }
    case 'sips/listState': {
      return {
        ...state,
        listState: 'refresh',
      };
    }
    default:
      return state;
  }
}

export default sipsReducer;
