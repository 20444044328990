import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import decOfNum from '../../../utils/decOfNum';

const TerritoryMultiArchiveDialog = ({ showDialog, counter, handleConfirm, handleClose, ...props }) => {
  const dispatch = useDispatch();

  return (
    <>
      {/* archive dialog */}
      <Dialog {...props} open={showDialog} onClose={handleClose} disableRestoreFocus PaperProps={{ sx: { minWidth: '350px' } }}>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Отправить в архив?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <strong>{counter}</strong>
            <span>&nbsp;</span>
            <strong>{ decOfNum(counter, ['территория', 'территории', 'территорий']) }</strong>
            <span>&nbsp;</span>
            <span>из списка</span>
            <span>&nbsp;</span>
            <span>{ decOfNum(counter, ['будет отправлена', 'будут отправлены', 'будут отправлены']) }</span>
            <span> в архив</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                variant="contained"
                color="purple"
                fullWidth
                onClick={handleConfirm}
              >
                Архивировать
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

TerritoryMultiArchiveDialog.propTypes = {
  showDialog: PropTypes.bool,
  counter: PropTypes.number,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func,
};

TerritoryMultiArchiveDialog.defaultProps = {
  showDialog: false,
  counter: 0,
  handleConfirm: null,
  handleClose: null,
};

export default TerritoryMultiArchiveDialog;
