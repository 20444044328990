const emptyState = {
  token: '',
  username: '',
  permissions: [],
  expirationDate: '',
};

function getAccount() {
  try {
    return sessionStorage.account ? JSON.parse(sessionStorage.account) ?? emptyState : emptyState;
  } catch (error) {
    return emptyState;
  }
}

function getNavbarIsOpen() {
  try {
    return sessionStorage.navbarIsOpen ? JSON.parse(sessionStorage.navbarIsOpen) : true;
  } catch (error) {
    return true;
  }
}

const initialState = {
  token: getAccount().token,
  username: getAccount().username,
  permissions: getAccount().permissions,
  expirationDate: getAccount().expirationDate,
  navbarIsOpen: getNavbarIsOpen(),
  message: '',
  redirectUrl: '',
  state: 'idle'
};

function accountReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case 'account/navbarSwitch': {
      sessionStorage.navbarIsOpen = JSON.stringify(!state.navbarIsOpen);
      return { ...state, navbarIsOpen: !state.navbarIsOpen };
    }
    case 'account/logged': {
      sessionStorage.account = JSON.stringify(action.data);
      return { ...state, ...action.data, message: 'Добро пожаловать' };
    }
    case 'account/logout': {
      document.cookie = 'Authorization=;-1; path=/';
      sessionStorage.account = null;
      return { ...state, ...emptyState, redirectUrl: (action.data) };
    }
    case 'account/expired': {
      return { ...state, ...emptyState, message: 'Сессия устарела' };
    }
    case 'account/message': {
      return { ...state, message: (action.data) };
    }
    case 'account/redirectUrl': {
      return { ...state, redirectUrl: (action.data) };
    }
    case 'account/state': {
      return {
        ...state,
        state: (action.state)
      };
    }
    default:
      return state;
  }
}

export default accountReducer;
