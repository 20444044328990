import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import store from '../../../store/store';
import {
  createDepartment,
  deleteDepartment,
  getDepartments,
  putDepartment
} from '../../../store/actions/departments.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import { userWritePermission } from '../../../store/selectors/account.selector';

const DepartmentsEditor = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action } = useParams();
  const { departmentId } = useParams();

  const departmentEmptyState = {
    id: '',
    name: '',
    permissions: [{ code: 'KEY', name: 'Ключи', attrs: [] }, { code: 'MOBILE_USER', name: 'Юзеры', attrs: [] }, { code: 'PLACE', name: 'Юзеры', attrs: [] }, { code: 'USER', name: 'Юзеры', attrs: [] }, { code: 'DEVICE', name: 'Юзеры', attrs: [] }],
  };

  const editPermission = useSelector(userWritePermission);
  const [department, setDepartment] = useState({ ...departmentEmptyState });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const departments = useSelector((state) => state.departments.list);

  useEffect(() => {
    let unmounted = false;
    if (action === 'edit' || action === 'show' || action === 'add') {
      setEditDialog(true);
      if (action === 'edit') {
        setEditDialogTitle('Редактировать отдел');
      } else if (action === 'show') {
        setEditDialogTitle('Смотреть отдел');
      } else if (action === 'add') {
        setEditDialogTitle('Создать отдел');
      }
    } else if (action === 'delete') {
      setDeleteDialog(true);
    } else {
      setEditDialog(false);
      setDeleteDialog(false);
      setTimeout(() => {
        if (!unmounted && department.id) {
          setDepartment({ ...departmentEmptyState });
        }
      }, 300);
    }
    if (action === 'delete' && departmentId) {
      const departmentToEdit = departments.find((item) => item.id === departmentId);
      setDepartment({
        ...departmentEmptyState,
        ...departmentToEdit,
      });
    }
    if ((action === 'edit' || action === 'show') && departmentId) {
      const departmentToEdit = departments.find((item) => item.id === departmentId);
      if (departmentToEdit) {
        setDepartment({
          ...departmentEmptyState,
          ...departmentToEdit,
        });
      }
    }
    return () => {
      unmounted = true;
    };
  }, [action, departmentId, departments]);

  const handleClose = () => {
    // console.log(reason);
    navigate(-1);
  };

  const saveDepartment = async (values) => {
    try {
      const data = {
        id: values.id,
        name: values.name,
        permissions: values.permissions,
      };
      await store.dispatch(values.id ? putDepartment(data) : createDepartment(data));
      dispatch(showMessage({ open: true, text: 'Отдел успешно сохранён.', severity: 'success' }));
      handleClose(null, 'success');
      store.dispatch(getDepartments());
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось сохранить отдел. ${error.message}`, severity: 'error' }));
    }
  };

  const removeDepartment = async () => {
    try {
      await store.dispatch(deleteDepartment(department.id));
      dispatch(showMessage({ open: true, text: 'Отдел успешно удалён.', severity: 'success' }));
      handleClose(null, 'success');
      store.dispatch(getDepartments());
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось удалить отдел. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      <Dialog {...props} open={editDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{
            fontSize: '22px',
          }}
          >
            {editDialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={department}
            validationSchema={
              Yup.object().shape({
                name: Yup.string().max(255).required('Название забыли ввести'),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              validateForm,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* name field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.name && errors.name)}
                        htmlFor="outlined-name"
                      >
                        Название отдела
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.name && errors.name)}
                        id="outlined-name"
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange('name')}
                        readOnly={!editPermission}
                        label="Название отдела"
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error id="outlined-name-error">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* permissions table */}
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  {editPermission && (
                    <Grid
                      item
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        aria-disabled={
                          !isValid || isSubmitting || !values.name
                        }
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (Object.keys(validationErrors).length === 0) {
                            saveDepartment(values);
                          } else {
                            setTouched(setNestedObjectValues(validationErrors, true));
                            dispatch(showMessage({ open: true, text: 'Проверьте ошибки заполнения формы', severity: 'error' }));
                          }
                        }}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* delete department dialog */}
      <Dialog {...props} open={deleteDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px', }}>
            Удалить отдел?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Отдел </span>
            <strong>{department.name}</strong>
            <span> будет удалён навсегда!</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                variant="contained"
                color="purple"
                fullWidth
                onClick={removeDepartment}
              >
                Удалить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DepartmentsEditor;
