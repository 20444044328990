import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import PropTypes from 'prop-types';
import store from '../../../../../store/store';
import { createCipher, deleteCipher } from '../../../../../store/actions/devices.actions';
import { deviceWritePermission } from '../../../../../store/selectors/account.selector';
import { showMessage } from '../../../../../store/actions/messages.actions';

const cipherEmptyState = {
  cipher: '',
};

const DeviceCipherDialog = ({ tagType, isReadonly, handleClose, ciphers, updateCiphers, ...props }) => {
  const dispatch = useDispatch();
  const editPermission = useSelector(deviceWritePermission);
  const { actionId1, action3, actionId3 } = useParams();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');
  const [cipher, setCipher] = useState({ ...cipherEmptyState });

  useEffect(async () => {
    let unmounted = false;
    if (!unmounted) {
      if (action3 === 'edit' || action3 === 'show' || action3 === 'add') {
        setEditDialog(true);
        if (action3 === 'add') {
          setEditDialogTitle('Добавить шифр');
          if (cipher.cipher === '' && ciphers.length) {
            const next = ciphers.reduce((result, item) => Math.max(result, +item.cipher), 0)
            // console.log(ciphers, next)
            setCipher({
              ...cipherEmptyState,
              cipher: next === 0 ? '990699069901' : next + 1
            });
          }
        }
      } else if (action3 === 'delete') {
        setDeleteDialog(true);
      } else {
        setDeleteDialog(false);
        setEditDialog(false);
        setTimeout(() => {
          if (!unmounted && cipher.cipher) {
            setCipher({ ...cipherEmptyState });
          }
        }, 300);
      }
      if ((action3 === 'edit' || action3 === 'show' || action3 === 'delete') && actionId3) {
        const cipherToEdit = ciphers.find((item) => item.index === +actionId3);
        if (cipherToEdit) {
          setCipher({
            ...cipherEmptyState,
            ...cipherToEdit,
          });
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, [action3, actionId3, ciphers]);

  const onDeleteCipher = async (values) => {
    try {
      await store.dispatch(deleteCipher(actionId1, actionId3)); // cipher.type is сервисный или пользовательский
      dispatch(showMessage({ open: true, text: 'Шифр успешно удалён', severity: 'success' }));
      handleClose(null, 'success');
      setTimeout(() => updateCiphers(), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось удалить шифр. ${error.message}`, severity: 'error' }));
    }
  }

  const onSaveCipher = async (values) => {
    try {
      // console.log(values)
      const data = {
        cipher: values.cipher,
        type: 'Crypto1 keyA',
      };
      await store.dispatch(createCipher(actionId1, data));
      dispatch(showMessage({ open: true, text: 'Шифр успешно сохранён', severity: 'success' }));
      handleClose(null, 'success');
      setTimeout(() => updateCiphers(), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось сохранить шифр. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      <Dialog {...props} open={editDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{
            fontSize: '22px',
          }}
          >
            {editDialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={cipher}
            validationSchema={
              Yup.object().shape({
                cipher: Yup.string(),
                flatNumber: Yup.string(),
                owner: Yup.string().max(255),
                tagType: Yup.string().max(255),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* cipher field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.cipher && errors.cipher)}
                        htmlFor="outlined-cipher"
                      >
                        Cipher
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.cipher && errors.cipher)}
                        style={{ backgroundColor: isReadonly || !editPermission || action3 !== 'add' ? '#f5f5f5' : '' }}
                        id="outlined-cipher"
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={values.cipher}
                        onChange={handleChange('cipher')}
                        readOnly={isReadonly || !editPermission || action3 !== 'add'}
                        label="Шифр"
                      />
                      {touched.cipher && errors.cipher && (
                        <FormHelperText error id="outlined-cipher-error">
                          {errors.cipher}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  {!isReadonly && editPermission && (
                    <Grid
                      item
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (values.cipher.length !== 0) {
                            onSaveCipher(values);
                          }
                        }}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* cipher delete dialog */}
      <Dialog {...props} open={deleteDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px', }}>
            Удалить шифр?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Шифр </span>
            <strong>{cipher.cipher || ''}</strong>
            <span> будет удалён навсегда!</span>
          </Typography>

          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                variant="contained"
                color="purple"
                fullWidth
                onClick={onDeleteCipher}
              >
                Удалить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeviceCipherDialog.propTypes = {
  tagType: PropTypes.string,
  isReadonly: PropTypes.bool,
  handleClose: PropTypes.func,
  ciphers: PropTypes.array,
  updateCiphers: PropTypes.func,
};

DeviceCipherDialog.defaultProps = {
  isReadonly: false,
  handleClose: null,
  ciphers: [],
};

export default DeviceCipherDialog;
