import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
// import AccountProfile from 'src/components/account/AccountProfile';
// import AccountProfileDetails from 'src/components/account/AccountProfileDetails';
// import SettingsNotifications from '../components/settings/SettingsNotifications';
// import UpdatePassword from '../components/account/UpdatePassword';

const Account = () => (
  <>
    <Helmet>
      <title>Профиль | Терион</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ pt: 0 }}>
          update password form will be here
        </Box>
      </Container>
    </Box>
  </>
);

export default Account;
