const copyToClipboard = async (text, onSuccess) => {
  // console.log(text)
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      onSuccess();
    } else {
      const ta = document.createElement('textarea');
      ta.innerText = text;
      document.body.appendChild(ta);
      ta.select();
      document.execCommand('copy');
      ta.remove();
      onSuccess();
    }
  } catch (error) {
    console.log(error);
  }
};

export default copyToClipboard;
