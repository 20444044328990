import api from '../../api/client';
import { handleServerError } from './utils';
import { showInternetErrorMessage, showServerErrorMessage } from './messages.actions';

export function logs(data) {
  return { type: 'events/list', data };
}

export function customers(data) {
  return { type: 'events/customersList', data };
}

export function loading() {
  return { type: 'events/loading' };
}

// async thunk actions

export function getLogsTypes() {
  return async (dispatch) => {
    try {
      Promise.all([api.getCustomers()])
        .then(([customersResponse]) => {
          dispatch(customers(customersResponse.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(showInternetErrorMessage());
        });
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getLogs(data) {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const response = await api.getLogs(data);
      dispatch(logs(response.data));
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
