import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Step,
  StepButton,
  Stepper
} from '@material-ui/core';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import SwipeableViews from 'react-swipeable-views';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  getDeviceCallOnMobileWhileActivationInfo,
  getDeviceConfiguration,
  getDeviceConfigurationAudio,
  getDeviceConfigurationDate,
  getDeviceConfigurationDetector,
  getDeviceConfigurationDisplay,
  getDeviceConfigurationEmail,
  getDeviceConfigurationExtRfid,
  getDeviceConfigurationLan,
  getDeviceConfigurationLevels,
  getDeviceConfigurationLog,
  getDeviceConfigurationMifareV2,
  getDeviceConfigurationOverlay,
  getDeviceConfigurationRfid,
  getDeviceConfigurationSingle,
  getDeviceConfigurationSwitch,
  getDeviceConfigurationSysLog,
  getDeviceConfigurationUser,
  getDeviceConfigurationVideo,
  getDeviceGate,
  getDeviceGateEntrances,
  getDeviceSip,
  getDeviceSip2,
  setDeviceConfiguration,
  setDeviceConfigurationAudio,
  setDeviceConfigurationDate,
  setDeviceConfigurationDetector,
  setDeviceConfigurationDisplay,
  setDeviceConfigurationEmail,
  setDeviceConfigurationLan,
  setDeviceConfigurationLevels,
  setDeviceConfigurationOverlay,
  setDeviceConfigurationSwitch,
  setDeviceConfigurationSysLog,
  setDeviceConfigurationUser,
  setDeviceConfigurationVideo,
  setDeviceGate,
  setDevicesCache,
  setDeviceSip,
  setDeviceSip2
} from '../../../store/actions/devices.actions';
import {
  showMessage,
  showServerErrorMessage
} from '../../../store/actions/messages.actions';
import { deviceWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import TabPanelWizard from '../../common/TabPanelWizard';
import DeviceSip from './steps/DeviceSip';
import DeviceSip2 from './steps/DeviceSip2';
import DeviceGate, { DEVICE_MODE } from './steps/DeviceGate';

import DevicePermissions from './steps/DevicePermissions';
import DeviceNotSavedDialog from './DeviceNotSavedDialog';
import removeEmptyFields from '../../../utils/removeEmptyFields';
import DeviceSummary from './common/DeviceSummary';
import {
  deviceDialogStyles,
  deviceEmptyState
} from './common/DeviceDialogObjects';
import DeviceAudio from './steps/DeviceAudio';
import DeviceVideo from './steps/DeviceVideo';
import DeviceLan from './steps/DeviceLan';
import DeviceEmail from './steps/DeviceEmail';
import DeviceDetector from './steps/DeviceDetector';
import DeviceOverlay from './steps/DeviceOverlay';
import DevicesDisplay from './steps/DeviceDisplay';
import DevicesDateTime from './steps/DeviceDateTime';
import DevicesSysLog from './steps/DeviceSysLog';
import DeviceLevel from './steps/DeviceLevel';
import DeviceAddress from './steps/DeviceAddress';
import DeviceSwitch from './steps/DeviceSwitch';
import DeviceKeys from './steps/DeviceKeys';
import DeviceLog from './steps/DeviceLog';
import ProgressDialog from './ProgressDialog';

moment.locale('ru');

const useStyles = makeStyles(() => createStyles(deviceDialogStyles));

const titles = [
  'устройство',
  'настройки адреса',
  'настройки SIP#1',
  'настройки SIP#2',
  'настройки калитки',
  'настройки ключей',
  'настройки LAN',
  'настройки Email',
  'настройки детектора движения',
  'настройки текста',
  'настройки дисплея',
  'настройки даты и времени',
  'настройки SysLog',
  'настройки доступа',
  'настройки аудио',
  'настройки видео',
  'настройки уровней',
  'настройки ККМ',
  'журнал событий'
];
const steps = [
  'Инфо',
  'Адрес',
  'SIP#1',
  'SIP#2',
  'Калитка',
  'RFID',
  'LAN',
  'Email',
  'Детектор',
  'Текст',
  'Дисплей',
  'Дата',
  'SysLog',
  'Права',
  'Аудио',
  'Видео',
  'Уровни',
  'ККМ',
  'Журнал'
];
const stepsName = [
  'review',
  'address',
  'sip',
  'sip2',
  'gate',
  'rfid',
  'lan',
  'email',
  'detector',
  'overlay',
  'display',
  'date',
  'syslog',
  'permissions',
  'audio',
  'video',
  'levels',
  'kkm',
  'log'
];

const DeviceEditDialog = ({ isArchive, handleClose, ...props }) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { action1, actionId1, stepName } = useParams();

  const editPermission = useSelector(deviceWritePermission);

  const [stepValid, setStepValid] = useState(false);

  const isValidStep = useRef(true);
  const keyTimeout = useRef(0);
  const keyCodeLast = useRef(0);
  const keyCodeLastTime = useRef(0);
  const currentStep = useRef(0);
  const step = useRef(stepName);
  const deviceUpdated = useRef({});
  const deviceUpdatedNextStep = useRef(0);

  const [isSingle, setIsSingle] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState({ ...deviceEmptyState });
  const [device, setDevice] = useState({ ...deviceEmptyState });
  const [editDialog, setEditDialog] = useState(false);
  const [notSavedDialog, setNotSavedDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const devicesCache = useSelector((state) => state.devices.cache);
  const devices = useSelector((state) => state.devices.list);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [empty, setEmpty] = useState({});

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const [showProgress, setShowProgress] = useState(false);

  const updateStep = (value) => {
    if (action1 === 'edit' && Object.keys(deviceUpdated.current).length > 0) {
      setNotSavedDialog(true);
      deviceUpdatedNextStep.current = value;
      return;
    }
    currentStep.current = value;
    updateActiveButton(value);
    clearTimeout(keyTimeout.current);
    keyTimeout.current = setTimeout(() => {
      setActiveStep(currentStep.current);
      navigateStep(stepsName[currentStep.current]);
    }, 500);
  };

  const updateActiveButton = (index) => {
    const activeElement = document.querySelector(
      '.MuiStepIcon-root.Mui-active'
    );
    const iconElement = document.querySelectorAll('.MuiStepIcon-root')[index];
    if (activeElement) {
      activeElement.classList.remove('Mui-active');
    }
    if (iconElement) {
      iconElement.classList.add('Mui-active');
    }
  };

  const navigateStep = (name) => {
    // console.log(stepName, name, location.pathname.replace(stepName, '') + name);
    navigate(location.pathname.replace(stepName, '') + name, { replace: true });
  };

  const handleStep = (value) => () => {
    if (action1 === 'edit' && Object.keys(deviceUpdated.current).length > 0) {
      setNotSavedDialog(true);
      deviceUpdatedNextStep.current = value;
      return;
    }
    currentStep.current = value;
    setActiveStep(value);
    step.current = stepsName[value];
    navigateStep(stepsName[value]);
  };

  const handleReset = () => {
    if (activeStep !== 0) {
      currentStep.current = 0;
      setActiveStep(0);
      navigateStep(stepsName[0]);
    }
    setCompleted({});
    setEmpty({});
    deviceUpdated.current = {};
  };

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action1, actionId1, device.intercoms[0], devicesCache);
    const deviceFromList =
      actionId1 && devices.find((item) => item.id === actionId1);
    if (action1 === 'edit') {
      setEditDialog(true);
    } else if (editDialog) {
      setEditDialog(false);
      setTimeout(() => {
        if (!unmounted && device.id) {
          handleReset();
          if (deviceDetails.address) {
            setDeviceDetails({ ...deviceEmptyState });
          }
          if (
            devicesCache.device &&
            (devicesCache.device.id || devicesCache.device.address)
          ) {
            // clear device cache
            setDeviceDetails({ ...deviceEmptyState });
            dispatch(setDevicesCache(deviceEmptyState));
          }
        }
      }, 200);
    }
    if (action1 === 'edit' && actionId1) {
      setDevice({
        name: '',
        address: '',
        macAddress: '',
        ...(devicesCache[actionId1] || deviceEmptyState),
        ...deviceFromList,
        ...deviceDetails
      });
    }
    // register key listener
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      unmounted = true;
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [action1, actionId1, devices, deviceDetails]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode, altKey } = event;
      if (keyCode === 33) {
        if (currentStep.current > 0) {
          if (
            empty[currentStep.current - 1] &&
            empty[currentStep.current - 2]
          ) {
            updateStep(currentStep.current - 3);
          } else if (empty[currentStep.current - 1]) {
            updateStep(currentStep.current - 2);
          } else {
            updateStep(currentStep.current - 1);
          }
        }
      } else if (keyCode === 34) {
        if (currentStep.current < totalSteps() - 1) {
          if (
            empty[currentStep.current + 1] &&
            empty[currentStep.current + 2]
          ) {
            updateStep(currentStep.current + 3);
          } else if (empty[currentStep.current + 1]) {
            updateStep(currentStep.current + 2);
          } else {
            updateStep(currentStep.current + 1);
          }
        }
      } else if (altKey && keyCode >= 48 && keyCode <= 58) {
        if (
          keyCodeLast.current === 49 &&
          Date.now() - keyCodeLastTime.current < 500
        ) {
          if (
            currentStep.current !== keyCode - 49 + 10 &&
            keyCode - 49 + 10 < totalSteps()
          ) {
            if (!empty[keyCode - 49 + 10]) {
              updateStep(keyCode - 49 + 10);
            }
          }
        } else if (currentStep.current !== keyCode - 49) {
          if (!empty[keyCode - 49]) {
            updateStep(keyCode - 49);
          }
        }
        keyCodeLastTime.current = Date.now();
        keyCodeLast.current = keyCode;
      }
    },
    [setActiveStep, handleStep]
  );

  // device details effect
  useEffect(() => {
    let unmounted = !location.pathname.includes('/devices/intercoms/edit');
    let deviceStatusCode = deviceDetails.status.code;
    const getData = async () => {
      try {
        step.current = stepName;
        // dispatch(setDevicesCache(data));
        if (isSingle === null) {
          const { data: single } = await store.dispatch(
            getDeviceConfigurationSingle(actionId1)
          );
          setIsSingle(single);
          if (single) {
            setEmpty({
              0: false,
              1: false,
              2: false,
              3: true,
              4: true,
              5: false,
              6: false,
              7: false,
              8: false,
              9: true,
              10: false,
              11: false,
              12: false,
              13: false,
              14: false,
              15: true,
              16: true,
              17: false,
              18: false,
              19: false
            });
          } else {
            setEmpty({
              0: false,
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              7: false,
              8: false,
              9: false,
              10: false,
              11: false,
              12: false,
              13: false,
              14: false,
              15: false,
              16: false,
              17: false,
              18: false,
              19: false
            });
          }
          return;
        }
        setCompleted({
          0: true,
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
          6: true,
          7: true,
          8: true,
          9: true,
          10: true,
          11: true,
          12: true,
          13: true,
          14: true,
          15: true,
          16: true,
          17: true,
          18: true,
          19: true
        });
        if (!deviceStatusCode) {
          //info already loaded in review

          const { data } = await store.dispatch(
            getDeviceConfiguration(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, ...data });

          console.log(data, 'data');

          deviceStatusCode = data.status?.code;
        }
        if (step.current === 'review') {
          //
        } else if (step.current === 'address') {
          const { data } = await store.dispatch(
            getDeviceCallOnMobileWhileActivationInfo(actionId1)
          );

          setDeviceDetails({
            ...deviceDetails,
            callOnMobileWhileActivation: data
          });
        } else if (step.current === 'sip') {
          const { data } = await store.dispatch(getDeviceSip(actionId1));
          setDeviceDetails({ ...deviceDetails, sip: { ...data } });
        } else if (step.current === 'sip2') {
          const { data } = await store.dispatch(getDeviceSip2(actionId1));
          setDeviceDetails({ ...deviceDetails, sip2: { ...data } });
        } else if (step.current === 'gate') {
          const {
            data: { houseConfigurationResponseDtoList }
          } = await store.dispatch(getDeviceGateEntrances(actionId1));
          const { data } = await store.dispatch(getDeviceGate(actionId1));
          const houses =
            deviceStatusCode === 'CONFIGURED'
              ? data.houses ?? []
              : houseConfigurationResponseDtoList;
          setDeviceDetails({
            ...deviceDetails,
            gate: { ...data, houses }
          });
        } else if (step.current === 'rfid') {
          if (deviceDetails.mifareSupported) {
            const value = await Promise.all([
              store.dispatch(getDeviceConfigurationMifareV2(actionId1)),
              store.dispatch(getDeviceConfigurationExtRfid(actionId1))
            ]);
            const deviceKeys = { mifare: { ...value[0].data }, rfid: { ...value[1].data } };
            setDeviceDetails({ ...deviceDetails, ...deviceKeys });
          } else {
            const { data } = await store.dispatch(
              getDeviceConfigurationRfid(actionId1)
            );
            setDeviceDetails({ ...deviceDetails, rfid: { ...data } });
          }
        } else if (step.current === 'lan') {
          const { data } = await store.dispatch(
            getDeviceConfigurationLan(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, lan: { ...data } });
        } else if (step.current === 'email') {
          const { data } = await store.dispatch(
            getDeviceConfigurationEmail(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, email: { ...data } });
        } else if (step.current === 'audio') {
          const { data } = await store.dispatch(
            getDeviceConfigurationAudio(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, audio: { ...data } });
        } else if (step.current === 'video') {
          const { data } = await store.dispatch(
            getDeviceConfigurationVideo(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, video: { ...data } });
        } else if (step.current === 'display') {
          const { data } = await store.dispatch(
            getDeviceConfigurationDisplay(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, display: { ...data } });
        } else if (step.current === 'date') {
          const { data } = await store.dispatch(
            getDeviceConfigurationDate(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, dateTime: { ...data } });
        } else if (step.current === 'overlay') {
          const { data } = await store.dispatch(
            getDeviceConfigurationOverlay(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, videoTextOverlay: { ...data } });
        } else if (step.current === 'detector') {
          const { data } = await store.dispatch(
            getDeviceConfigurationDetector(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, motionAlarm: { ...data } });
        } else if (step.current === 'kkm') {
          const { data } = await store.dispatch(
            getDeviceConfigurationSwitch(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, switch: { ...data } });
        } else if (step.current === 'levels') {
          const { data } = await store.dispatch(
            getDeviceConfigurationLevels(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, levels: { ...data } });
        } else if (step.current === 'syslog') {
          const { data } = await store.dispatch(
            getDeviceConfigurationSysLog(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, systemLog: { ...data } });
        } else if (step.current === 'permissions') {
          const { data } = await store.dispatch(
            getDeviceConfigurationUser(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, users: [...data.users] });
        } else if (step.current === 'log') {
          const { data } = await store.dispatch(
            getDeviceConfigurationLog(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, log: data });
        }
      } catch (error) {
        dispatch(showServerErrorMessage(error));
        console.log(error);
      }
    };
    if (!unmounted && actionId1 && actionId1 !== 'device') {
      getData();
    }
    if (stepsName[activeStep] !== stepName) {
      const stepIndex = stepsName.indexOf(stepName);
      if (stepIndex !== -1) {
        currentStep.current = stepIndex;
        setActiveStep(stepIndex);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, stepName, isSingle]);

  const onUpdateLog = useCallback(async () => {
    const { data } = await store.dispatch(getDeviceConfigurationLog(actionId1));
    setDeviceDetails({ ...deviceDetails, log: data });
  }, []);

  // title effect
  useEffect(() => {
    const unmounted = !location.pathname.includes('/devices/intercoms/edit');
    if (!unmounted) {
      const deviceFromList =
        actionId1 && devices.find((item) => item.id === actionId1);
      setEditDialogTitle(
        'Редактировать ' +
          titles[activeStep] +
          ' ' +
          (device.macAddress || deviceFromList?.macAddress || '')
      );
    }
  }, [activeStep, devices]);

  const isCurrentStepValid = useCallback(() => {
    const updated = deviceUpdated.current;
    if (step.current === 'address') {
      // console.log(updated.id, updated.name, updated.address)
      if (updated.id && updated.id !== deviceDetails.place?.intercom?.id) {
        return true;
      }
      if (undefined !== updated.callOnMobileWhileActivation) {
        return true;
      }
    } else if (step.current === 'host') {
      if (
        updated.connection?.host &&
        updated.connection?.port &&
        updated.connection?.username &&
        updated.connection?.password
      ) {
        return true;
      }
    } else if (step.current === 'sip') {
      if (
        updated.sip?.host &&
        updated.sip?.port &&
        updated.sip?.number &&
        updated.sip?.password
      ) {
        return true;
      }
    } else if (step.current === 'sip2') {
      if (updated.sip2) {
        return true;
      }
    } else if (step.current === 'gate') {
      const notEmpty = (v) => (!v ? false : v?.toString().trim() !== '');

      const deviceMode = updated.gate?.mode ?? deviceDetails.gate?.mode;
      if (updated.gate?.isIntercomOnGate) {
        if (updated.gate?.houses.length > 0) {
          return updated.gate?.houses.every(
            (house) =>
              notEmpty(house.address) &&
              (deviceMode === DEVICE_MODE.MODE_2.value
                ? notEmpty(house.prefix)
                : true) &&
              notEmpty(house.startNumber) &&
              notEmpty(house.endNumber)
          );
        }

        return true;
      }
      if (updated.gate) {
        return true;
      }
    } else if (step.current === 'rfid') {
      if (updated.keys) {
        return true;
      }
    } else if (step.current === 'lan') {
      if (updated.lan) {
        return true;
      }
    } else if (step.current === 'email') {
      if (updated.email) {
        return true;
      }
    } else if (step.current === 'audio') {
      if (updated.audio) {
        return true;
      }
    } else if (step.current === 'video') {
      if (updated.video) {
        return true;
      }
    } else if (step.current === 'display') {
      if (updated.display) {
        return true;
      }
    } else if (step.current === 'date') {
      if (updated.dateTime) {
        return true;
      }
    } else if (step.current === 'overlay') {
      if (updated.videoTextOverlay) {
        return true;
      }
    } else if (step.current === 'detector') {
      // console.log(updated?.motionAlarm?.timeRanges.some((item) => item.fromTime.includes('_') || item.toTime.includes('_')))
      if (
        updated.motionAlarm &&
        !updated.motionAlarm.timeRanges.some(
          (item) => item.fromTime.includes('_') || item.toTime.includes('_')
        )
      ) {
        return true;
      }
    } else if (step.current === 'kkm') {
      if (updated.switch) {
        return true;
      }
    } else if (step.current === 'levels') {
      if (updated.levels) {
        return true;
      }
    } else if (step.current === 'syslog') {
      if (updated.systemLog) {
        return true;
      }
    } else if (step.current === 'permissions') {
      // console.log(updated.user)
      if (updated.user && updated.user.password) {
        return true;
      }
    } else if (step.current === 'log') {
      // console.log(updated.user)
      if (updated.log) {
        return true;
      }
    }
    return false;
  }, [deviceDetails]);

  // button save effect
  useEffect(() => {
    isValidStep.current = isCurrentStepValid();
    if (isValidStep.current !== stepValid) {
      setStepValid(isValidStep.current);
    }
  }, [stepName, stepValid, deviceDetails]);

  const onUpdate = useCallback(
    (data) => {
      deviceUpdated.current = removeEmptyFields({
        ...deviceUpdated.current,
        ...data
      });
      // console.log('update', data, step.current);
      // console.log('update', data.display.temporaryMessage, step.current);
      isValidStep.current = isCurrentStepValid();
      setStepValid((previous) => isValidStep.current);
    },
    [isCurrentStepValid]
  );

  const updateExtRfid = useCallback(async () => {
    if (deviceDetails.mifareSupported) {
      const { data } = await store.dispatch(
        getDeviceConfigurationExtRfid(actionId1)
      );
      setDeviceDetails({ ...deviceDetails, rfid: { ...data } });
    }
  }, [deviceDetails, actionId1]);

  const updateRfid = useCallback(async () => {
    if (deviceDetails.mifareSupported) {
      const { data } = await store.dispatch(
        getDeviceConfigurationMifareV2(actionId1)
      );
      setDeviceDetails({ ...deviceDetails, mifare: { ...data } });
    } else {
      const { data } = await store.dispatch(
        getDeviceConfigurationRfid(actionId1)
      );
      setDeviceDetails({ ...deviceDetails, rfid: { ...data } });
    }
  }, [deviceDetails, actionId1]);

  const onCancelDeviceUpdated = useCallback(() => {
    setNotSavedDialog(false);
  }, []);

  const onIgnoreDeviceUpdated = useCallback(() => {
    setNotSavedDialog(false);
    deviceUpdated.current = {};
    if (deviceUpdatedNextStep.current === -1) {
      handleClose(null, 'success');
    } else {
      handleStep(deviceUpdatedNextStep.current)();
    }
  }, []);

  const onSaveCurrent = useCallback(() => {
    deviceUpdatedNextStep.current = activeStep;
    onSaveDeviceUpdated();
  }, [activeStep]);

  const onSaveDeviceUpdated = useCallback(async () => {
    if (showProgress) return;
    setNotSavedDialog(false);
    try {
      const updated = deviceUpdated.current;

      const valid = isValidStep.current;

      deviceUpdated.current = {};

      if (valid) {
        if (step.current === 'review') {
          // nothing
        } else if (step.current === 'address') {
          let deviceDetailsData = { ...deviceDetails };
          if (!deviceDetails.status?.code) {
            const { data } = await store.dispatch(
              getDeviceConfiguration(actionId1)
            );
            deviceDetailsData = data;
            setDeviceDetails({ ...deviceDetails, ...deviceDetailsData });
          }
          const apartmentAccessRights = updated.callOnMobileWhileActivation ??
            deviceDetailsData.callOnMobileWhileActivation;
          setShowProgress(true);

          await store.dispatch(
            setDeviceConfiguration(actionId1, {
              callOnMobileWhileActivation: apartmentAccessRights,
              status: deviceDetailsData.status.code,
              host: deviceDetailsData.host,
              port: deviceDetailsData.port,
              username: deviceDetailsData.username,
              password: deviceDetailsData.password,
              location: { id: updated.id || deviceDetailsData.id }
            })
          );
          setShowProgress(false);
          dispatch(
            showMessage({
              open: true,
              text: 'Адрес устройства успешно изменён',
              severity: 'success'
            })
          );

          // setTimeout(() => dispatch(setDevicesRefresh()), 500);
          // handleClose(null, 'success');
          // navigate(location.pathname.replace(actionId1, updated.id), { replace: true });
        } else if (step.current === 'sip') {
          await store.dispatch(setDeviceSip(actionId1, { ...updated.sip }));
          dispatch(
            showMessage({
              open: true,
              text: 'Sip#1 настройки успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'sip2') {
          const valueToUpdate = updated.sip2
            ? { ...updated.sip2 }
            : { ...deviceDetails.sip2 };

          if (updated.sip2) {
            setDeviceDetails((prevState) => ({
              ...prevState,
              sip2: updated.sip2
            }));

            await store.dispatch(setDeviceSip2(actionId1, valueToUpdate));
            dispatch(
              showMessage({
                open: true,
                text: 'Sip#2 настройки успешно сохранены',
                severity: 'success'
              })
            );
          }
        } else if (step.current === 'gate') {
          setShowProgress(true);
          await store.dispatch(setDeviceGate(actionId1, { ...updated.gate }));
          setShowProgress(false);
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки калитки успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'keys') {
          await store.dispatch(
            setDeviceConfigurationLan(actionId1, { ...updated.keys })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки ключей успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'lan') {
          await store.dispatch(
            setDeviceConfigurationLan(actionId1, { ...updated.lan })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки LAN успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'email') {
          await store.dispatch(
            setDeviceConfigurationEmail(actionId1, { ...updated.email })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки email успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'audio') {
          if (updated.audio.isSingle) {
            // remove useless parameters
            await store.dispatch(
              setDeviceConfigurationAudio(actionId1, {
                ...updated.audio,
                isSingle: undefined,
                systemVolume: undefined,
                ahsSensitivity: undefined,
                ahsVolume: undefined,
                echoCancellation: undefined,
                secondTrack: undefined
              })
            );
          } else {
            await store.dispatch(
              setDeviceConfigurationAudio(actionId1, { ...updated.audio })
            );
          }
          dispatch(
            showMessage({
              open: true,
              text: 'Аудио настройки успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'video') {
          await store.dispatch(
            setDeviceConfigurationVideo(actionId1, { ...updated.video })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Видео настройки успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'display') {
          // console.log(updated.display.temporaryMessage.fromDateTime)
          await store.dispatch(
            setDeviceConfigurationDisplay(actionId1, { ...updated.display })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки дисплея успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'date') {
          await store.dispatch(
            setDeviceConfigurationDate(actionId1, { ...updated.dateTime })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки даты и времени успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'overlay') {
          await store.dispatch(
            setDeviceConfigurationOverlay(actionId1, {
              ...updated.videoTextOverlay
            })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки текста успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'detector') {
          await store.dispatch(
            setDeviceConfigurationDetector(actionId1, {
              ...updated.motionAlarm
            })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки детектора движения успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'kkm') {
          await store.dispatch(
            setDeviceConfigurationSwitch(actionId1, {
              switchModel: { id: updated.switch.switchModel.id },
              flatNumbers: updated.switch.flatNumbers
            })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки ККМ успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'levels') {
          await store.dispatch(
            setDeviceConfigurationLevels(actionId1, { ...updated.levels })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки уровней успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'syslog') {
          await store.dispatch(
            setDeviceConfigurationSysLog(actionId1, {
              ...updated.systemLog,
              protocols: undefined,
              levels: undefined
            })
          );
          dispatch(
            showMessage({
              open: true,
              text: 'Настройки логгирования успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'permissions') {
          await store.dispatch(
            setDeviceConfigurationUser(actionId1, { ...updated.user })
          );

          const { data } = await store.dispatch(
            getDeviceConfigurationUser(actionId1)
          );
          setDeviceDetails({ ...deviceDetails, users: [...data.users] });

          dispatch(
            showMessage({
              open: true,
              text: 'Настройки доступа успешно сохранены',
              severity: 'success'
            })
          );
        } else if (step.current === 'log') {
          // nothing
        }

        if (deviceUpdatedNextStep.current === -1) {
          handleClose(null, 'success');
        } else {
          handleStep(deviceUpdatedNextStep.current)();
        }
      } else {
        dispatch(
          showMessage({
            open: true,
            text: 'Не заполнены обязательные поля',
            severity: 'error'
          })
        );
      }
      setStepValid(false);
    } catch (error) {
      console.log(error);
      setShowProgress(false);
      dispatch(
        showMessage({
          open: true,
          text: 'Не удалось сохранить настройки. ' + error.message + '.',
          severity: 'error'
        })
      );
    }
  }, [stepName, isCurrentStepValid]);

  const onClose = useCallback(async (reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    } else if (action1 === 'edit') {
      if (Object.keys(deviceUpdated.current).length > 0) {
        setNotSavedDialog(true);
        deviceUpdatedNextStep.current = -1;
      } else {
        handleClose();
      }
    }
  }, []);

  return (
    <>
      <Dialog
        {...props}
        open={editDialog}
        onClose={(e, reason) => onClose(reason)}
        fullWidth
        disableRestoreFocus
        disableEscapeKeyDown
        scroll="body"
        PaperProps={{ sx: { maxWidth: '1024px' } }}
      >
        <DialogContent className={classes.content}>
          <Stepper
            orientation="vertical"
            className={classes.stepperBig}
            nonLinear
            activeStep={activeStep}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton
                  className={isSingle !== null && empty[index] ? 'empty' : ''}
                  title={'Alt-' + (index + 1)}
                  color="inherit"
                  onClick={handleStep(index)}
                  disabled={(index > 0 && !completed[0]) || empty[index]}
                  sx={{
                    '& .MuiStepIcon-root.Mui-completed:not(.Mui-active)': {
                      color: empty[index] ? '#c4c4c4' : 'green.main'
                    },
                    '& .MuiStepLabel-label': {
                      marginTop: '3px'
                    },
                    '& .MuiStepLabel-label.Mui-completed:not(.Mui-active)': {
                      fontWeight: '400'
                    }
                  }}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Box className={classes.container}>
            <SwipeableViews className={classes.view} index={activeStep}>
              <TabPanelWizard
                value={activeStep}
                index={0}
                className={classes.panel}
              >
                <DeviceSummary
                  device={device}
                  isReadonly={isArchive}
                  isSingle={isSingle}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={1}
                className={classes.panel}
              >
                <DeviceAddress
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={2}
                className={classes.panel}
              >
                <DeviceSip
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={3}
                className={classes.panel}
              >
                <DeviceSip2
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={4}
                className={classes.panel}
              >
                <DeviceGate
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={5}
                className={classes.panel}
              >
                <DeviceKeys
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                  tagType="rfid"
                  updateKeys={updateRfid}
                  updateExtKeys={updateExtRfid}
                />

              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={6}
                className={classes.panel}
              >
                <DeviceLan
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={7}
                className={classes.panel}
              >
                <DeviceEmail
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={8}
                className={classes.panel}
              >
                <DeviceDetector
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={9}
                className={classes.panel}
              >
                <DeviceOverlay
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={10}
                className={classes.panel}
              >
                <DevicesDisplay
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={11}
                className={classes.panel}
              >
                <DevicesDateTime
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={12}
                className={classes.panel}
              >
                <DevicesSysLog
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={13}
                className={classes.panel}
              >
                <DevicePermissions
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={14}
                className={classes.panel}
              >
                <DeviceAudio
                  device={device}
                  isReadonly={isArchive}
                  isSingle={isSingle}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={15}
                className={classes.panel}
              >
                <DeviceVideo
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={16}
                className={classes.panel}
              >
                <DeviceLevel
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={17}
                className={classes.panel}
              >
                <DeviceSwitch
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
              <TabPanelWizard
                value={activeStep}
                index={18}
                className={classes.panel}
              >
                <DeviceLog
                  device={device}
                  isReadonly={isArchive}
                  onUpdate={onUpdate}
                />
              </TabPanelWizard>
            </SwipeableViews>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              sx={{
                pb: 2,
                pt: 1
              }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleClose}
                >
                  Отменить
                </Button>
              </Grid>
              {editPermission &&
                !isArchive &&
                stepName !== 'review' &&
                stepName !== 'log' && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!stepValid}
                      onClick={onSaveCurrent}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                )}
              {stepName === 'log' && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onUpdateLog}
                  >
                    Обновить
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <ProgressDialog
        open={showProgress}
        text="Внимание! Идет сохранение конфигурации на домофоне."
      />

      <DeviceNotSavedDialog
        open={notSavedDialog}
        onCancel={onCancelDeviceUpdated}
        onSave={onSaveDeviceUpdated}
        onIgnore={onIgnoreDeviceUpdated}
        disabledSave={!stepValid}
        handleClose={handleClose}
      />
    </>
  );
};

DeviceEditDialog.propTypes = {
  isArchive: PropTypes.bool,
  handleClose: PropTypes.func
};

DeviceEditDialog.defaultProps = {
  isArchive: false,
  handleClose: null
};

export default memo(DeviceEditDialog);
