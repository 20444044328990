import api from '../../api/client';
import { handleServerError } from './utils';

export function roles(data) {
  return { type: 'roles/loaded', data };
}

export function setRolesRefresh(data) {
  return { type: 'roles/refresh', data };
}

// async thunk actions

export function getRoles(data) {
  return async (dispatch) => {
    try {
      const response = await api.getRoles(data);
      dispatch(roles(response.data));
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getRole(id) {
  return async () => {
    try {
      const response = await api.getRole(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createRole(data) {
  return async () => {
    try {
      const response = await api.createRole(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putRole(data) {
  return async () => {
    try {
      const response = await api.putRole(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteRole(id) {
  return async () => {
    try {
      const response = await api.deleteRole(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
