import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { deviceWritePermission } from '../../../../../store/selectors/account.selector';
import decOfNum from '../../../../../utils/decOfNum';

const DeviceKeysMulti = ({
  tagType,
  keysActive,
  keysDeleteClick,
  showAutoCollect,
  isAutoCollect,
  onAutoCollectClick,
  ...props
}) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 1,
        }}
      >
        {editPermission && showAutoCollect && (
          <Button
            sx={{ ml: 1 }}
            color={isAutoCollect ? 'red' : 'orange'}
            variant="contained"
            onClick={() => onAutoCollectClick()}
          >
            {isAutoCollect ? 'Выключить автосбор ключей' : 'Включить автосбор ключей'}
          </Button>
        )}
        {editPermission && !!keysActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => keysDeleteClick()}
          >
            Удалить&nbsp;
            { keysActive }
            &nbsp;
            { decOfNum(keysActive, ['ключ', 'ключа', 'ключей']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

DeviceKeysMulti.propTypes = {
  tagType: PropTypes.string,
  keysActive: PropTypes.number,
  keysDeleteClick: PropTypes.func,
  showAutoCollect: PropTypes.bool,
  isAutoCollect: PropTypes.bool,
  onAutoCollectClick: PropTypes.func,
};

DeviceKeysMulti.defaultProps = {
  tagType: '',
  keysActive: 0,
  keysDeleteClick: null,
  showAutoCollect: false,
  isAutoCollect: null,
  onAutoCollectClick: null,
};

export default DeviceKeysMulti;
