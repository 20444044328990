import PropTypes from 'prop-types';
import { Box, DialogTitle, FormControl, Grid, InputLabel, OutlinedInput, Typography } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/ru';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { green, red, orange } from '@material-ui/core/colors';
import { useParams } from 'react-router';
import DeviceCamera from './DeviceCamera';

const connectionColors = {
  OFFLINE: red[100],
  ONLINE: green[100],
};

const sipColors = {
  NOT_REGISTERED: red[100],
  REGISTERED: green[100],
};

const statusColors = {
  NEW: red[100],
  PENDING_CONFIGURATION: orange[100],
  CONFIGURED: green[100],
};

const DeviceSummary = ({ isReadonly, isSingle, device, ...props }) => {
  const { action1, actionId1 } = useParams();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device}
      validationSchema={
        Yup.object().shape({
        })
      }
    >
      {({
        handleChange,
        values,
      }) => (
        <form autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Общая информация об устройстве ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* intercom address field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  shrink
                  htmlFor="outlined-address"
                >
                  Адрес
                </InputLabel>
                <OutlinedInput
                  size="small"
                  notched
                  style={{ backgroundColor: '#f5f5f5' }}
                  id="outlined-address"
                  type="text"
                  autoComplete="off"
                  value={values.place?.address || values.address || ''}
                  readOnly
                  label="Адрес"
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* intercom name field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-name"
                    >
                      Имя
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-name"
                      type="text"
                      autoComplete="off"
                      value={values.place?.intercom?.name || values.name || ''}
                      readOnly
                      label="Имя"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* intercom type field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-from"
                    >
                      Квартиры
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-from"
                      type="text"
                      autoComplete="off"
                      value={values?.flatRanges?.map((flat) => (flat.min === flat.max ? flat.min : flat.min + '-' + flat.max)).join(', ') ?? ''}
                      onChange={handleChange('from')}
                      readOnly
                      label="Квартиры"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* intercom type field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-type"
                    >
                      Модель
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-type"
                      type="text"
                      autoComplete="off"
                      value={values.deviceModel || 'Beward'}
                      readOnly={action1 === 'show'}
                      label="Модель"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* intercom type field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-type"
                    >
                      Серийный номер
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-type"
                      type="text"
                      autoComplete="off"
                      value={values.deviceId || ''}
                      readOnly={action1 === 'show'}
                      label="Серийный номер"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* intercom macAddress field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-mac"
                    >
                      MAC адрес
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-mac"
                      type="text"
                      autoComplete="off"
                      value={values.macAddress || '18:68:82:30:f4:A6'}
                      readOnly
                      label="MAC адрес"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* intercom host field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-summary-host"
                    >
                      Сетевой адрес
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-summary-host"
                      type="text"
                      autoComplete="off"
                      value={values.host || ''}
                      onChange={handleChange('host')}
                      readOnly
                      label="Сетевой адрес"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* intercom login field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-login"
                    >
                      Логин
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-login"
                      type="text"
                      autoComplete="off"
                      value={values.connection?.username || 'admin'}
                      onChange={handleChange('login')}
                      readOnly
                      label="Логин"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  {/* created field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-created"
                    >
                      Дата создания
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-created"
                      type="text"
                      autoComplete="off"
                      value={moment(values?.created)?.format('Do MMMM YYYY')}
                      readOnly
                      label="Дата создания"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  {/* updated field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-updated"
                    >
                      Дата изменения
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: '#f5f5f5' }}
                      id="outlined-updated"
                      type="text"
                      autoComplete="off"
                      value={moment(values?.updated)?.format('Do MMMM YYYY')}
                      readOnly
                      label="Дата изменения"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* status field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-review-status"
                    >
                      Статус
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: statusColors[values?.status?.code] || 'purple lighten-3' }}
                      id="outlined-review-status"
                      type="text"
                      autoComplete="off"
                      value={values?.status?.name || 'неизвестен'}
                      readOnly
                      label="Статус"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  {/* connectionStatus field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-review-connectionStatus"
                    >
                      Сетевой статус
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: connectionColors[values?.connectionStatus?.code] || 'purple lighten-3' }}
                      id="outlined-review-connectionStatus"
                      type="text"
                      autoComplete="off"
                      value={values?.connectionStatus?.name || 'неизвестен'}
                      readOnly
                      label="Сетевой статус"
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  {/* sipStatus field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      shrink
                      htmlFor="outlined-review-sipStatus"
                    >
                      Sip статус
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      notched
                      style={{ backgroundColor: sipColors[values?.sipStatus?.code] || 'purple lighten-3' }}
                      id="outlined-review-sipStatus"
                      type="text"
                      autoComplete="off"
                      value={values?.sipStatus?.name || 'неизвестен'}
                      readOnly
                      label="Sip статус"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <DeviceCamera isReadonly={isReadonly} isSingle={isSingle} device={device} />
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DeviceSummary.propTypes = {
  isReadonly: PropTypes.bool,
  isSingle: PropTypes.bool,
  device: PropTypes.object,
};

DeviceSummary.defaultProps = {
  isReadonly: false,
  isSingle: false,
  device: null,
}

export default DeviceSummary;
