import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import store from '../../../store/store';
import { createRole, deleteRole, getRole, getRoles, putRole } from '../../../store/actions/roles.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import { userWritePermission } from '../../../store/selectors/account.selector';

const RolesEditor = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action } = useParams();
  const { roleId } = useParams();

  const roleEmptyState = {
    id: '',
    name: '',
    permissions: [{ code: 'PLACE', name: 'Жилфонд', attribute: '' }, { code: 'DEVICE', name: 'Устройства', attribute: '' }, { code: 'KEY', name: 'Ключи', attribute: '' }, { code: 'USER', name: 'Пользователи', attribute: '' }, { code: 'MOBILE_USER', name: 'Абоненты', attribute: '' }],
  };

  const editPermission = useSelector(userWritePermission);
  const [base, setBase] = useState({ id: '', name: 'Супер админ' });
  const [role, setRole] = useState({ ...roleEmptyState });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const roles = useSelector((state) => state.roles.list);

  useEffect(async () => {
    if (!base.id && roles.length) {
      setBase(roles[0]);
    }
    if (!base || !base.id || action !== 'add') {
      return;
    }
    try {
      const { data } = await store.dispatch(getRole(base.id));
      if (data) {
        setRole({
          ...role,
          permissions: data.permissions,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [action, base, roles]);

  useEffect(async () => {
    let unmounted = false;
    if (action === 'edit' || action === 'show' || action === 'add') {
      setEditDialog(true);
      if (action === 'edit') {
        setEditDialogTitle('Редактировать роль');
      } else if (action === 'show') {
        setEditDialogTitle('Смотреть роль');
      } else if (action === 'add') {
        setEditDialogTitle('Создать роль');
      }
    } else if (action === 'delete') {
      setDeleteDialog(true);
    } else {
      setEditDialog(false);
      setDeleteDialog(false);
      setTimeout(() => {
        if (!unmounted && role.id) {
          setRole({ ...roleEmptyState });
        }
      }, 300);
    }
    if (action === 'delete' && roleId) {
      const roleToEdit = roles.find((item) => item.id === roleId);
      setRole({
        ...roleEmptyState,
        ...roleToEdit,
      });
    }
    if ((action === 'edit' || action === 'show') && roleId) {
      const roleToEdit = roles.find((item) => item.id === roleId);
      if (roleToEdit) {
        setRole({
          ...roleEmptyState,
          ...roleToEdit,
        });
        try {
          const { data } = await store.dispatch(getRole(roleToEdit.id));
          if (data) {
            setRole({
              ...role,
              ...data,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, [action, roleId, roles]);

  const handleClose = () => {
    // console.log(reason);
    navigate(-1);
  };

  const setBaseValue = (value, name) => {
    if (!value) {
      setBase({ id: '', name: '' });
      setRole({
        ...role,
        permissions: role.permissions.map((item) => ({ ...item, attribute: '' })),
      });
    } else {
      setRole({ ...role, name });
      setBase({ ...value });
    }
  };

  const handleSelect = (event, code, type) => {
    if (!editPermission) {
      return;
    }
    const newPermissions = [...role.permissions];
    const permission = newPermissions.find((item) => item.code === code);
    if (permission) {
      if (permission.attribute === 'READ_WRITE') {
        if (type === 'WRITE') {
          permission.attribute = 'READ';
        } else if (type === 'READ') {
          permission.attribute = '';
        }
      } else if (permission.attribute === 'READ') {
        if (type === 'WRITE') {
          permission.attribute = 'READ_WRITE';
        } else if (type === 'READ') {
          permission.attribute = '';
        }
      } else if (!permission.attribute) {
        if (type === 'WRITE') {
          permission.attribute = 'READ_WRITE';
        } else if (type === 'READ') {
          permission.attribute = 'READ';
        }
      }
      setRole({
        ...role,
        permissions: [...newPermissions],
      });
    }
  };

  const saveRole = async (values) => {
    try {
      const data = {
        id: values.id,
        name: values.name,
        permissions: values.permissions.filter((permission) => !!permission.attribute),
      };
      await store.dispatch(values.id ? putRole(data) : createRole(data));
      dispatch(showMessage({ open: true, text: 'Роль успешно сохранена', severity: 'success' }));
      handleClose(null, 'success');
      store.dispatch(getRoles());
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось сохранить роль. ${error.message}`, severity: 'error' }));
    }
  };

  const removeRole = async () => {
    try {
      await store.dispatch(deleteRole(role.id));
      dispatch(showMessage({ open: true, text: 'Роль успешно удалена', severity: 'success' }));
      handleClose(null, 'success');
      store.dispatch(getRoles());
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось удалить роль. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      <Dialog {...props} open={editDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{
            fontSize: '22px',
          }}
          >
            {editDialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={role}
            validationSchema={
              Yup.object().shape({
                name: Yup.string().max(255).required('Название забыли ввести'),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              validateForm,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={role.id ? 12 : 6}
                  >
                    {/* name field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.name && errors.name)}
                        htmlFor="outlined-name"
                      >
                        Имя роли
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.name && errors.name)}
                        id="outlined-name"
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange('name')}
                        readOnly={!editPermission}
                        label="Имя роли"
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error id="outlined-name-error">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {!role.id && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {/* base role field */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <Autocomplete
                          disableListWrap
                          id="combo-box-base"
                          options={roles}
                          disableClearable
                          value={base}
                          getOptionLabel={(item) => item.name}
                          onChange={(event, value) => setBaseValue(value, values.name)}
                          disabled={!editPermission}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.base && errors.base)}
                              helperText={touched.base && errors.base}
                              label="Базовая роль"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                {/* permissions table */}
                <PerfectScrollbar>
                  <Box sx={{ minWidth: 450, minHeight: 315, my: 3 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Раздел
                          </TableCell>
                          <TableCell>
                            Чтение
                          </TableCell>
                          <TableCell>
                            Редактирование
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {role.permissions.map && role.permissions.map((permission) => (
                          <TableRow
                            hover
                            key={permission.code}
                          >
                            <TableCell>
                              {permission.name || permission.code}
                            </TableCell>
                            <TableCell padding="checkbox" align="center">
                              <Checkbox
                                color="primary"
                                checked={!!permission.attribute && permission.attribute.includes('READ')}
                                onChange={(event) => handleSelect(event, permission.code, 'READ')}
                                value="true"
                              />
                            </TableCell>
                            <TableCell padding="checkbox" align="center">
                              <Checkbox
                                checked={!!permission.attribute && permission.attribute.includes('READ_WRITE')}
                                onChange={(event) => handleSelect(event, permission.code, 'WRITE')}
                                value="true"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </PerfectScrollbar>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  {editPermission && (
                    <Grid
                      item
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        aria-disabled={
                          !isValid || isSubmitting || !values.name
                        }
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (Object.keys(validationErrors).length === 0) {
                            saveRole(values);
                          } else {
                            setTouched(setNestedObjectValues(validationErrors, true));
                            dispatch(showMessage({ open: true, text: 'Проверьте ошибки заполнения формы', severity: 'error' }));
                          }
                        }}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* delete role dialog */}
      <Dialog {...props} open={deleteDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px', }}>
            Удалить роль?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Роль </span>
            <strong>{role.name}</strong>
            <span> будет удалёна навсегда!</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                variant="contained"
                color="purple"
                fullWidth
                onClick={removeRole}
              >
                Удалить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RolesEditor;
