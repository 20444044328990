import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import mockPermissions from './mockPermissions';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const compareUsers = (users, updatedUsers) => {
  for (let i = 0; i < updatedUsers.length; i++) {
    for (let j = 0; j < updatedUsers[i].userPermissions.length; j++) {
      if (users[i].userPermissions[j].value !== updatedUsers[i].userPermissions[j].value) {
        return true;
      }
    }
  }
  return false;
}

const emptyUser = {
  userType: {
    id: '',
    name: 'Тестовый юзер не задан'
  },
  username: '',
  userPermissions: []
};

const DevicesPermissions = ({ isReadonly, device, onUpdate, ...props }) => {
  const location = useLocation();
  const editPermission = useSelector(deviceWritePermission);
  const [expanded, setExpanded] = useState(false);
  const { action1, actionId1, stepName } = useParams();
  const [users, setUsers] = useState(action1 === 'add' ? [emptyUser, ...(device.users || mockPermissions)] : device.users || mockPermissions);

  const updatePermissions = (index, value, values) => {
    const updatedPermissions = [...values.userPermissions];
    updatedPermissions[index].value = value;
    // const hasChanges = compareUsers(device.user.userPermissions, updatedPermissions);
    onUpdate({ user: { ...values, userPermissions: updatedPermissions } });
  }

  // permissions change effect
  useEffect(() => {
    let unmounted = !location.pathname.includes('/devices/intercoms/edit') && !location.pathname.includes('/devices/intercoms/add');
    if (!unmounted && actionId1) {
      // setUsers(device.users?.map((user) => ({ ...user, userPermissions: user.userPermissions?.map((p) => ({ ...p })) })) || []); // deep copy
      setUsers(device.users || []);
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, device.users]);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.users?.length ? [...device.users][0] : { username: mockPermissions[0]?.username, password: '', userType: mockPermissions[0]?.userType, userPermissions: mockPermissions[0]?.userPermissions }}
      validationSchema={
        Yup.object().shape({
          // name: Yup.string().max(255).required('Забыли указать название'),
          username: Yup.string().max(255).required('Забыли указать логин'),
          password: Yup.string().max(255).required('Забыли указать пароль'),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки прав пользователей устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* type field */}
              <Autocomplete
                id="combo-box-test-users-type"
                disabled={!editPermission || isReadonly}
                disableClearable
                options={users}
                getOptionLabel={(item) => item?.userType?.name || item?.name || ''}
                isOptionEqualToValue={(option, value) => (option?.userType?.id === value?.userType?.id)}
                value={values.userType || { name: '' }}
                onChange={(event, value) => {
                  const permissions = [...value.userPermissions];
                  setFieldValue('userType', value.userType);
                  setFieldValue('username', value.username);
                  setFieldValue('password', value.password ?? '');
                  setFieldValue('userPermissions', permissions);
                  if (value.userType?.id) {
                    onUpdate({ user: { ...values, username: value.username, userType: value.userType, userPermissions: permissions } });
                  } else {
                    onUpdate({ user: undefined });
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Пользователь" />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* username field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.username && errors.username)}
                  htmlFor="outlined-test-users-username"
                >
                  Логин
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.username && errors.username)}
                  id="outlined-test-users-username"
                  type="text"
                  autoComplete="off"
                  value={values.username}
                  onChange={(event) => {
                    setFieldValue('username', event.target.value);
                    onUpdate({ user: { ...values, username: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Логин"
                />
                {touched.username && errors.username && (
                  <FormHelperText error id="outlined-test-users-username-error">
                    {errors.username}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* password field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.password && errors.password)}
                  htmlFor="outlined-test-users-password"
                >
                  Пароль
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.password && errors.password)}
                  id="outlined-test-users-password"
                  type={values.showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  value={values.password || ''}
                  onChange={(event) => {
                    setFieldValue('password', event.target.value);
                    onUpdate({ user: { ...values, password: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="показать пароль"
                        tabIndex={-1}
                        onClick={() => {
                          setFieldValue('showPassword', !values.showPassword);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Пароль"
                />
                {touched.password && errors.password && (
                  <FormHelperText error id="outlined-test-users-password-error">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <PerfectScrollbar>
            {/* permissions table */}
            {!!values.userPermissions?.length && (
              <>
                <Collapse in={expanded} timeout={1000} collapsedSize={435} sx={{ mt: 2 }}>
                  <Box sx={{ minWidth: 450 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Функция
                          </TableCell>
                          <TableCell>
                            Разрешено
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(values.userPermissions || Array(32)).map((row, index) => (
                          <TableRow
                            hover
                            key={row.id || uuid()}
                          >
                            <TableCell>
                              {row.name || (index + 1)}
                            </TableCell>
                            <TableCell padding="checkbox" align="center" key={uuid()}>
                              <Checkbox
                                id={`checkbox_${index}`}
                                disabled={!editPermission || isReadonly}
                                color="primary"
                                defaultChecked={values.userPermissions[index].value}
                                onChange={(event) => {
                                  // console.log(userPermissions[index].value, event.target.checked)
                                  // event.target.checked = !event.target.checked; // eslint-disable-line
                                  updatePermissions(index, event.target.checked, values);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
                {values.userPermissions?.length > 1 && (
                  <Button
                    sx={{ mt: 1 }}
                    color="orange"
                    size="small"
                    variant="contained"
                    onClick={() => setExpanded((value) => !value)}
                  >
                    {(expanded ? 'Спрятать настройки' : 'Показать все настройки')}
                    <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                  </Button>
                )}
              </>
            )}
          </PerfectScrollbar>
        </form>
      )}
    </Formik>
  );
}

DevicesPermissions.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func,
};

DevicesPermissions.defaultProps = {
  isReadonly: false,
}

export default memo(DevicesPermissions, isDevicePropEqual);
