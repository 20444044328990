import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { reset } from '../../store/actions/account.actions';
import store from '../../store/store';
import { showMessage } from '../../store/actions/messages.actions';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  async function onSubmit(data) {
    try {
      await store.dispatch(reset(data));
      dispatch(showMessage({ open: true, text: 'Письмо с инструкциями отправлено вам на почту', severity: 'success' }));
      // setOpen(true);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось найти пользователя', severity: 'error' }));
    }
  }

  return (
    <>
      <Helmet>
        <title>Сбросить пароль | Терион</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Проверьте email').max(255).required('Введите корректный email'),
            })}
            onSubmit={async (data, { setSubmitting }) => {
              await onSubmit(data);
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Восстановление пароля
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle1"
                  >
                    Введите свой email для восстановления пароля
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pt: 2
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                  >
                    Восстановить
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Dialog
        open={open}
      >
        <DialogTitle>
          Восстановление пароля
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Письмо с инструкциями отправлено вам на почту
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={() => setOpen(false)}>ОК</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetPassword;
