import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { showMessage } from '../../../store/actions/messages.actions';
import { placeWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import { archiveTerritory, setTerritoriesRefresh } from '../../../store/actions/territories.actions';

const TerritoryArchiveDialog = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const { action0, actionId0, action1, actionId1 } = useParams();

  const editPermission = useSelector(placeWritePermission);

  const territories = useSelector((state) => state.territories.list);
  const [territorySelected, setTerritorySelected] = useState({});
  const [archiveDialog, setArchiveDialog] = useState(false);

  // state effect
  useEffect(() => {
    let unmounted = false;
    const territoryFromList = actionId0 && (territories.find((item) => item.id === actionId0));
    if (action0 === 'archive') {
      setArchiveDialog(true);
    } else {
      setArchiveDialog(false);
    }
    if (action0 === 'archive' && actionId0) {
      setTerritorySelected({
        ...territoryFromList,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [action0, action1, actionId0, actionId1, territories]);

  const onArchiveTerritory = async () => {
    try {
      await store.dispatch(archiveTerritory(actionId0));
      dispatch(showMessage({ open: true, text: 'Территория успешно архивирована', severity: 'success' }));
      handleClose(null, 'success');
      setTimeout(() => dispatch(setTerritoriesRefresh()), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось архивировать. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      {/* archive dialog */}
      <Dialog {...props} open={archiveDialog} onClose={handleClose} disableRestoreFocus PaperProps={{ sx: { minWidth: '350px' } }}>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Отправить в архив?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Территория с названием </span>
            <strong>{territorySelected.name}</strong>
            <span> будет отправлена в архив</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              {editPermission && (
                <Button
                  variant="contained"
                  color="purple"
                  fullWidth
                  onClick={onArchiveTerritory}
                >
                  Архивировать
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

TerritoryArchiveDialog.propTypes = {
  handleClose: PropTypes.func,
};

TerritoryArchiveDialog.defaultProps = {
  handleClose: null,
};

export default TerritoryArchiveDialog;
