import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { experimentalStyled, useMediaQuery } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { navbarSwitch } from '../store/actions/account.actions';
import { navbarIsOpen } from '../store/selectors/account.selector';

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256
    // }
  })
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navbarIsOpenDesktop = useSelector(navbarIsOpen);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const switchNav = () => {
    if (isMobile) {
      setMobileNavOpen(true);
    } else {
      dispatch(navbarSwitch())
    }
  }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        isOpen={isMobile ? isMobileNavOpen : navbarIsOpenDesktop}
        onNavIconClick={() => switchNav()}
      />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobile && isMobileNavOpen}
      />
      <DashboardLayoutWrapper
        sx={{ transition: 'padding-left 525ms cubic-bezier(0, 0, 0.1, 1) 0ms', paddingLeft: !isMobile && navbarIsOpenDesktop ? '256px' : '0' }}
      >
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
