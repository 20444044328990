import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { showMessage } from '../../../store/actions/messages.actions';
import { deviceWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import { archiveDevice, setDevicesRefresh } from '../../../store/actions/devices.actions';
import formatPhone from '../../../utils/formatPhone';

const DeviceCheckConnectionDialog = ({ addDevice, onCancel, ...props }) => {
  const handleClose = useCallback(() => false, []);

  return (
    <>
      {/* connection dialog */}
      <Dialog {...props} open={!!addDevice} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Проверка соединения с устройством
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Box
            sx={{ width: '100%', textAlign: 'center' }}
          >
            <CircularProgress sx={{ ml: 1 }} size="20px" color="primary" />
          </Box>

          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={onCancel}
              >
                Отменить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeviceCheckConnectionDialog.propTypes = {
  addDevice: PropTypes.object,
  onCancel: PropTypes.func,
};

DeviceCheckConnectionDialog.defaultProps = {
  addDevice: null,
};

export default memo(DeviceCheckConnectionDialog);
