import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import TimeMask from '../../../common/TimeMask';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const sensitivities = [
  { name: '0 (минимальная)', value: 0 },
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5 (максимальная)', value: 5 },
];

const DeviceDetector = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  const handleRadioChange = useCallback((event, range, index, values, setFieldValue) => {
    if (!editPermission || isReadonly) {
      return
    }
    if (event.target.checked) {
      const updated = values.timeRanges.map((time) => ({ ...time, active: time === range }));
      setFieldValue('timeRanges', updated);
      onUpdate({ motionAlarm: { ...values, timeRanges: updated } });
    }
  }, []);

  const handleTimeChange = useCallback((value, range, index, key, values, setFieldValue) => {
    const updated = [...values.timeRanges.map((item) => ({ ...item }))];
    const timeArr = value.replace(/_/ig, '0').split(':');
    if (+timeArr[0] < 24 && +timeArr[1] < 60) {
      // console.log(values.timeRanges, value, index, updated)
      if (values.timeRanges[index][key] !== value) {
        updated[index][key] = value;
        setFieldValue('timeRanges', updated);
        onUpdate({ motionAlarm: { ...values, timeRanges: updated } });
      }
    } else {
      setFieldValue('timeRanges', updated);
    }
  }, []);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.motionAlarm
      || {
        active: true,
        sensitivity: 0,
        record: {
          email: true,
          http: true,
          httpParam: '',
          snap: { active: true, email: true, ftp: true, interval: 1, number: 1 },
          video: { active: false, duration: 30, ftp: true }
        },
        timeRanges: [{
          active: true,
          fromTime: '00:00',
          toTime: '23:59',
        }]
      }}
      validationSchema={
        Yup.object().shape({
          active: Yup.bool(),
          sensitivity: Yup.number(),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки детектора движения устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={12}
            >
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* sensitivity field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <Autocomplete
                      disableListWrap
                      id="combo-box-sensitivity"
                      options={sensitivities}
                      disableClearable
                      value={values.sensitivity}
                      getOptionLabel={(item) => (typeof item === 'object' ? item.name : item.toString())}
                      onChange={(event, object) => {
                        setFieldValue('sensitivity', object.value);
                        onUpdate({ motionAlarm: { ...values, sensitivity: object.value } });
                      }}
                      onBlur={handleBlur('sensitivity')}
                      disabled={isReadonly || !editPermission}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.sensitivity && errors.sensitivity)}
                          helperText={touched.sensitivity && errors.sensitivity}
                          label="Чувствительность"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* active field */}
                  <FormControlLabel
                    control={(
                      <Checkbox
                        sx={{ ml: 1, my: -2 }}
                        checked={values.active}
                        onChange={
                          (event) => {
                            if (!editPermission || isReadonly) {
                              return
                            }
                            setFieldValue('active', event.target.checked);
                            onUpdate({ motionAlarm: { ...values, active: event.target.checked } });
                          }
                        }
                      />
                    )}
                    label="Разрешить"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  mb={1}
                >
                  {(values.timeRanges?.map((range, index) => (
                    <Stack key={'range' + index} spacing={2} direction="row" sx={{ ml: 1, mt: 1, mr: 3 }} alignItems="center">
                      <FormControlLabel
                        label=""
                        value={index}
                        control={<Radio checked={range.active} onChange={(event) => handleRadioChange(event, range, index, values, setFieldValue)} />}
                      />
                      <OutlinedInput
                        size="small"
                        style={{ backgroundColor: 'white', marginLeft: '-10px', marginRight: '15px' }}
                        type="text"
                        autoComplete="off"
                        value={range.fromTime}
                        inputComponent={TimeMask}
                        onChange={(value) => handleTimeChange(value, range, index, 'fromTime', values, setFieldValue)}
                        readOnly={!editPermission || isReadonly}
                      />
                      -
                      <OutlinedInput
                        size="small"
                        style={{ backgroundColor: 'white' }}
                        type="text"
                        autoComplete="off"
                        value={range.toTime}
                        inputComponent={TimeMask}
                        onChange={(value) => handleTimeChange(value, range, index, 'toTime', values, setFieldValue)}
                        readOnly={!editPermission || isReadonly}
                      />
                    </Stack>
                  )))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  <Stack spacing={2} direction="row" sx={{ mr: 3 }} alignItems="center">
                    {/* record.http field */}
                    <FormControlLabel
                      control={(
                        <Checkbox
                          sx={{ ml: 1, mt: 0 }}
                          checked={values.record.http}
                          onChange={
                            (event) => {
                              if (!editPermission || isReadonly) {
                                return
                              }
                              setFieldValue('record', { ...values.record, http: event.target.checked });
                              onUpdate({ motionAlarm: { ...values, record: { ...values.record, http: event.target.checked } } });
                            }
                          }
                        />
                      )}
                      label="HTTP уведомление"
                    />
                    {/* record.httpParam field */}
                    <OutlinedInput
                      sx={{ ml: 1, mr: 1 }}
                      size="small"
                      type="text"
                      autoComplete="off"
                      value={values.record.httpParam || ''}
                      onChange={
                        (event) => {
                          setFieldValue('record', { ...values.record, httpParam: event.target.value });
                          onUpdate({ motionAlarm: { ...values, record: { ...values.record, httpParam: event.target.value } } });
                        }
                      }
                      readOnly={!editPermission || isReadonly}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* record.email field */}
                  <FormControlLabel
                    control={(
                      <Checkbox
                        sx={{ ml: 1, my: 0 }}
                        checked={values.record.email}
                        onChange={
                          (event) => {
                            if (!editPermission || isReadonly) {
                              return
                            }
                            setFieldValue('record', { ...values.record, email: event.target.checked });
                            onUpdate({ motionAlarm: { ...values, record: { ...values.record, email: event.target.checked } } });
                          }
                        }
                      />
                    )}
                    label="Email"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*<Grid
              item
              xs={12}
              sm={6}
            >
              <img
                alt="intercom preview"
                src="/static/images/table.jpg"
                style={{
                  marginTop: 0,
                  display: 'inline-block',
                  maxWidth: '100%',
                  width: '100%'
                }}
              />
            </Grid>*/}
            <Grid
              item
              xs={12}
              sm={12}
            >
              <Stack spacing={2} direction="row" sx={{ mr: 3 }} alignItems="center">
                {/* record.active field */}
                <FormControlLabel
                  control={(
                    <Checkbox
                      sx={{ ml: 1, my: -2 }}
                      checked={values.record.snap.active}
                      onChange={
                        (event) => {
                          if (!editPermission || isReadonly) {
                            return
                          }
                          setFieldValue('record', { ...values.record, snap: { ...values.record.snap, active: event.target.checked } });
                          onUpdate({ motionAlarm: { ...values, record: { ...values.record, snap: { ...values.record.snap, active: event.target.checked } } } });
                        }
                      }
                    />
                  )}
                  label="Кадр"
                />
                <OutlinedInput
                  sx={{ ml: 1, mr: 1, maxWidth: '80px' }}
                  size="small"
                  type="number"
                  autoComplete="off"
                  value={values.record.snap.interval}
                  onChange={
                    (event) => {
                      const value = Math.max(1, Math.min(999, +event.target.value));
                      setFieldValue('record', { ...values.record, snap: { ...values.record.snap, interval: value } });
                      onUpdate({ motionAlarm: { ...values, record: { ...values.record, snap: { ...values.record.snap, interval: value } } } });
                    }
                  }
                  readOnly={!editPermission || isReadonly}
                />
                <Typography>кадров</Typography>
                <Typography>Интервал:</Typography>
                <OutlinedInput
                  sx={{ ml: 1, maxWidth: '80px' }}
                  size="small"
                  type="number"
                  autoComplete="off"
                  value={values.record.snap.number}
                  onChange={
                    (event) => {
                      const value = Math.max(0, Math.min(999, +event.target.value));
                      setFieldValue('record', { ...values.record, snap: { ...values.record.snap, number: value } });
                      onUpdate({ motionAlarm: { ...values, record: { ...values.record, snap: { ...values.record.snap, number: value } } } });
                    }
                  }
                  readOnly={!editPermission || isReadonly}
                />
                <Typography>сек</Typography>
                <FormControlLabel
                  control={(
                    <Checkbox
                      sx={{ ml: 1, my: -2 }}
                      checked={values.record.snap.email}
                      onChange={
                        (event) => {
                          if (!editPermission || isReadonly) {
                            return
                          }
                          setFieldValue('record', { ...values.record, snap: { ...values.record.snap, email: event.target.checked } });
                          onUpdate({ motionAlarm: { ...values, record: { ...values.record, snap: { ...values.record.snap, email: event.target.checked } } } });
                        }
                      }
                    />
                  )}
                  label="Email"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      sx={{ ml: 1, my: -2 }}
                      checked={values.record.snap.ftp}
                      onChange={
                        (event) => {
                          if (!editPermission || isReadonly) {
                            return
                          }
                          setFieldValue('record', { ...values.record, snap: { ...values.record.snap, ftp: event.target.checked } });
                          onUpdate({ motionAlarm: { ...values, record: { ...values.record, snap: { ...values.record.snap, ftp: event.target.checked } } } });
                        }
                      }
                    />
                  )}
                  label="FTP"
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <Stack spacing={2} direction="row" sx={{ mr: 3 }} alignItems="center">
                {/* record.active field */}
                <FormControlLabel
                  control={(
                    <Checkbox
                      sx={{ ml: 1, my: -2 }}
                      checked={values.record.video.active}
                      onChange={
                        (event) => {
                          if (!editPermission || isReadonly) {
                            return
                          }
                          setFieldValue('record', { ...values.record, video: { ...values.record.video, active: event.target.checked } });
                          onUpdate({ motionAlarm: { ...values, record: { ...values.record, video: { ...values.record.video, active: event.target.checked } } } });
                        }
                      }
                    />
                  )}
                  label="Видео"
                />
                <Typography>Длительность:</Typography>
                <OutlinedInput
                  sx={{ ml: 1, mr: 1, maxWidth: '80px' }}
                  size="small"
                  type="number"
                  autoComplete="off"
                  value={values.record.video.duration}
                  onChange={
                    (event) => {
                      const value = Math.max(1, Math.min(999, +event.target.value));
                      setFieldValue('record', { ...values.record, video: { ...values.record.video, duration: value } });
                      onUpdate({ motionAlarm: { ...values, record: { ...values.record, video: { ...values.record.video, duration: value } } } });
                    }
                  }
                  readOnly={!editPermission || isReadonly}
                />
                <Typography>сек</Typography>
                <FormControlLabel
                  control={(
                    <Checkbox
                      sx={{ ml: 1, my: -2 }}
                      checked={values.record.video.ftp}
                      onChange={
                        (event) => {
                          if (!editPermission || isReadonly) {
                            return
                          }
                          setFieldValue('record', { ...values.record, video: { ...values.record.video, ftp: event.target.checked } });
                          onUpdate({ motionAlarm: { ...values, record: { ...values.record, video: { ...values.record.video, ftp: event.target.checked } } } });
                        }
                      }
                    />
                  )}
                  label="FTP"
                />
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DeviceDetector.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DeviceDetector.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DeviceDetector, isDevicePropEqual);
