import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Checkbox, FormControlLabel, Grid,
  Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DeviceSip2 = ({ tagType, isReadonly, device, onUpdate, updateKeys, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action1, actionId1, stepName, action2, actionId2 } = useParams();

  const editPermission = useSelector(deviceWritePermission);
  useEffect(() => {}, [device?.sip2]);

  return (
    <Formik
      {...props}
      initialValues={device?.sip2}
      enableReinitialize
      validationSchema={Yup.object().shape({
        enabled: Yup.boolean()
      })}
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Параметры SIP#2 устройства ' + (device.macAddress || '')}
          </Typography>

          <Grid item xs={12} sm={8}>
            {/* active field */}
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ ml: 1 }}
                  checked={values?.enabled}
                  value={values?.enabled}
                  onChange={(event) => {
                    setFieldValue('enabled', event.target.checked);
                    onUpdate({
                      sip2: { ...values, enabled: event.target.checked }
                    });
                  }}
                />
              }
              label="Разрешить SIP #2"
            />
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DeviceSip2.propTypes = {
  tagType: PropTypes.string,
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  updateKeys: PropTypes.func,
};

DeviceSip2.defaultProps = {
  tagType: '',
  isReadonly: false,
  device: null,
}

export default memo(DeviceSip2, isDevicePropEqual);
