import { createTheme, colors } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';
import { blue } from '@material-ui/core/colors';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#00AFF3',
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#f50057',
    },
    red: {
      contrastText: '#ffffff',
      main: '#f50057',
    },
    green: {
      contrastText: '#ffffff',
      main: '#4caf50',
      dark: '#377d3a',
    },
    orange: {
      contrastText: '#ffffff',
      main: '#ef6c00',
      dark: '#c46111',
    },
    purple: {
      contrastText: '#ffffff',
      main: '#a127ee',
      dark: '#8332b6',
    },
    grey: {
      contrastText: '#333333',
      main: '#aaaaaa',
    },
    circular: {
      contrastText: '#ffffff',
      main: '#ffffff',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
      success: '#4caf50',
    },
  },
  components: {
    MuiTabs: {
      style: {
        backgroundColor: '#eee',
        border: `2px dashed ${blue[500]}`,
      },
    },
  },
  shadows,
  typography
}, ruRU);

export default theme;
