import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { contractWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const SubscribersMulti = ({
  subscribersActive,
  subscribersDeleted,
  subscribersBlocked,
  subscribersActiveAndBlocked,
  subscribersDeleteClick,
  subscribersRestoreClick,
  subscribersUnblockClick,
  subscribersBlockClick,
  ...props
}) => {
  const editPermission = useSelector(contractWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {editPermission && !!subscribersActive && (
          <Button
            sx={{ ml: 1 }}
            color="purple"
            variant="contained"
            onClick={() => subscribersBlockClick()}
          >
            Заблокировать&nbsp;
            { subscribersActive }
            &nbsp;
            { decOfNum(subscribersActive, ['абонента', 'абонентов', 'абонентов']) }
          </Button>
        )}
        {editPermission && !!subscribersDeleted && (
          <Button
            sx={{ ml: 1 }}
            color="green"
            variant="contained"
            onClick={() => subscribersRestoreClick()}
          >
            Восстановить&nbsp;
            { subscribersDeleted }
            &nbsp;
            { decOfNum(subscribersDeleted, ['абонента', 'абонентов', 'абонентов']) }
          </Button>
        )}
        {editPermission && !subscribersActive && !!subscribersBlocked && (
          <Button
            sx={{ ml: 1 }}
            color="green"
            variant="contained"
            onClick={() => subscribersUnblockClick()}
          >
            Разблокировать&nbsp;
            { subscribersBlocked }
            &nbsp;
            { decOfNum(subscribersBlocked, ['абонента', 'абонентов', 'абонентов']) }
          </Button>
        )}
        {editPermission && !!subscribersActiveAndBlocked && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => subscribersDeleteClick()}
          >
            Удалить&nbsp;
            { subscribersActiveAndBlocked }
            &nbsp;
            { decOfNum(subscribersActiveAndBlocked, ['абонента', 'абонентов', 'абонентов']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

SubscribersMulti.propTypes = {
  subscribersActive: PropTypes.number,
  subscribersDeleted: PropTypes.number,
  subscribersBlocked: PropTypes.number,
  subscribersActiveAndBlocked: PropTypes.number,
  subscribersBlockClick: PropTypes.func,
  subscribersRestoreClick: PropTypes.func,
  subscribersUnblockClick: PropTypes.func,
  subscribersDeleteClick: PropTypes.func,
};

SubscribersMulti.defaultProps = {
  subscribersActive: 0,
  subscribersDeleted: 0,
  subscribersBlocked: 0,
  subscribersActiveAndBlocked: 0,
  subscribersBlockClick: null,
  subscribersRestoreClick: null,
  subscribersUnblockClick: null,
  subscribersDeleteClick: null,
};

export default SubscribersMulti;
