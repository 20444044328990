import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Checkbox,
  FormControl, FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput, Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DeviceEmail = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.email || { mailTitle: '', sender: '', receiver: '', smtpUser: '', smtpPassword: '', showPassword: '', smtpServer: '', smtpPort: '', ssl: true }}
      validationSchema={
        Yup.object().shape({
          mailTitle: Yup.string(),
          smtpUser: Yup.string(),
          smtpPassword: Yup.string(),
          showPassword: Yup.string(),
          smtpServer: Yup.string(),
          smtpPort: Yup.string(),
          sender: Yup.string(),
          receiver: Yup.string(),
          ssl: Yup.bool(),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки Email устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={12}
            >
              {/* mailTitle field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.mailTitle && errors.mailTitle)}
                  htmlFor="outlined-email-mailTitle"
                >
                  Заголовок сообщения
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.mailTitle && errors.mailTitle)}
                  id="outlined-email-mailTitle"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  value={values.mailTitle || ''}
                  onChange={(event) => {
                    setFieldValue('mailTitle', event.target.value);
                    onUpdate({ email: { ...values, mailTitle: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Заголовок сообщения"
                />
                {touched.mailTitle && errors.mailTitle && (
                  <FormHelperText error id="outlined-mailTitle-error">
                    {errors.mailTitle}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* sender field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.sender && errors.sender)}
                  htmlFor="outlined-email-sender"
                >
                  Отправитель
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.sender && errors.sender)}
                  id="outlined-email-sender"
                  type="text"
                  autoComplete="off"
                  value={values.sender || ''}
                  onChange={(event) => {
                    setFieldValue('sender', event.target.value);
                    onUpdate({ email: { ...values, sender: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Отправитель"
                />
                {touched.sender && errors.sender && (
                  <FormHelperText error id="outlined-sender-error">
                    {errors.sender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* receiver field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.receiver && errors.receiver)}
                  htmlFor="outlined-email-receiver"
                >
                  Получатель
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.receiver && errors.receiver)}
                  id="outlined-email-receiver"
                  type="text"
                  autoComplete="off"
                  value={values.receiver || ''}
                  onChange={(event) => {
                    setFieldValue('receiver', event.target.value);
                    onUpdate({ email: { ...values, receiver: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Получатель"
                />
                {touched.receiver && errors.receiver && (
                  <FormHelperText error id="outlined-receiver-error">
                    {errors.receiver}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* smtpUser field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.smtpUser && errors.smtpUser)}
                  htmlFor="outlined-email-smtpUser"
                >
                  Smtp логин
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.smtpUser && errors.smtpUser)}
                  id="outlined-email-smtpUser"
                  type="text"
                  autoComplete="off"
                  value={values.smtpUser || ''}
                  onChange={(event) => {
                    setFieldValue('smtpUser', event.target.value);
                    onUpdate({ email: { ...values, smtpUser: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Smtp логин"
                />
                {touched.smtpUser && errors.smtpUser && (
                  <FormHelperText error id="outlined-smtpUser-error">
                    {errors.smtpUser}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* smtpPassword field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.smtpPassword && errors.smtpPassword)}
                  htmlFor="outlined-test-users-smtpPassword"
                >
                  Пароль
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.smtpPassword && errors.smtpPassword)}
                  id="outlined-test-users-smtpPassword"
                  type={values.showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  value={values.smtpPassword || ''}
                  onChange={(event) => {
                    setFieldValue('smtpPassword', event.target.value);
                    onUpdate({ email: { ...values, smtpPassword: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="показать пароль"
                        tabIndex={-1}
                        onClick={() => {
                          setFieldValue('showPassword', !values.showPassword);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Пароль"
                />
                {touched.smtpPassword && errors.smtpPassword && (
                  <FormHelperText error id="outlined-test-users-smtpPassword-error">
                    {errors.smtpPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* smtpServer field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.smtpServer && errors.smtpServer)}
                  htmlFor="outlined-email-smtpServer"
                >
                  Smtp сервер
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.smtpServer && errors.smtpServer)}
                  id="outlined-email-smtpServer"
                  type="text"
                  autoComplete="off"
                  value={values.smtpServer || ''}
                  onChange={(event) => {
                    setFieldValue('smtpServer', event.target.value);
                    onUpdate({ email: { ...values, smtpServer: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Smtp сервер"
                />
                {touched.smtpServer && errors.smtpServer && (
                  <FormHelperText error id="outlined-smtpServer-error">
                    {errors.smtpServer}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* smtpPort field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.smtpPort && errors.smtpPort)}
                  htmlFor="outlined-email-smtpPort"
                >
                  Smtp порт
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.smtpPort && errors.smtpPort)}
                  id="outlined-email-smtpPort"
                  type="text"
                  autoComplete="off"
                  value={values.smtpPort || ''}
                  onChange={(event) => {
                    setFieldValue('smtpPort', event.target.value);
                    onUpdate({ email: { ...values, smtpPort: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Smtp порт"
                />
                {touched.smtpPort && errors.smtpPort && (
                  <FormHelperText error id="outlined-smtpPort-error">
                    {errors.smtpPort}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
            >
              {/* ssl field */}
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{ ml: 1 }}
                    checked={values.ssl}
                    onChange={
                      (event) => {
                        if (!editPermission || isReadonly) {
                          return
                        }
                        setFieldValue('ssl', event.target.checked);
                        onUpdate({ email: { ...values, ssl: event.target.checked } });
                      }
                    }
                  />
                )}
                label="SSL"
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DeviceEmail.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DeviceEmail.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DeviceEmail, isDevicePropEqual);
