import {
  TableCell,
  Box,
  TableSortLabel,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useLabelStyles = makeStyles({
  root: {
    flex: '0 0 100%',
    justifyContent: 'space-between !important',
    width: '100%',
    whiteSpace: 'nowrap',
    '& span': {
      maxWidth: '1px !important'
    }
  },
  icon: {
    marginLeft: '8px !important',
    minWidth: '1em',
    boxSizing: 'content-box'
  }
});

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    ActionBox,
    isLoading,
    hasActions
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const classes = useLabelStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* padding */}
          {ActionBox && <ActionBox />}
          {isLoading && (
            <CircularProgress sx={{ ml: 1 }} size="20px" color="primary" />
          )}
        </TableCell>
        {headCells.map(({ id, label, sortable, ...rest }) => (
          <TableCell
            key={id}
            sortDirection={
              !sortable ? undefined : orderBy === id ? order : false
            }
            {...rest}
          >
            {sortable ? (
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={createSortHandler(id)}
                classes={classes}
              >
                {label}
                {orderBy === id ? (
                  <Box
                    component="span"
                    sx={{ opacity: 0, visibility: 'hidden' }}
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
        {hasActions && (
          <TableCell style={{ width: 120 }} align="center">
            {/* Действия */}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.object),
  hasActions: PropTypes.bool,
  isLoading: PropTypes.bool,
  ActionBox: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string
};

export default EnhancedTableHead;
