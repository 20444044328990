import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { faqWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const FaqMulti = ({
  faqsActive,
  faqsDeleteClick,
  ...props
}) => {
  const editPermission = useSelector(faqWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {editPermission && !!faqsActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => faqsDeleteClick()}
          >
            Удалить&nbsp;
            { faqsActive }
            &nbsp;
            { decOfNum(faqsActive, ['вопрос', 'вопроса', 'вопросов']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

FaqMulti.propTypes = {
  faqsActive: PropTypes.number,
  faqsDeleteClick: PropTypes.func,
};

FaqMulti.defaultProps = {
  faqsActive: 0,
  faqsDeleteClick: null,
};

export default FaqMulti;
