import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  useMediaQuery
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { contractWritePermission } from '../../../store/selectors/account.selector';
import PhoneMask from '../../common/PhoneMask';
import theme from '../../../theme';
import { showMessage } from '../../../store/actions/messages.actions';

const SubscriberPhoneDialog = ({ handleClose, onChange, contacts, ...props }) => {
  const dispatch = useDispatch();

  const { action2, actionId2 } = useParams();

  const editPermission = useSelector(contractWritePermission);

  const [phoneDialog, setPhoneDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [contact, setContact] = useState({ phone: '', name: '' });

  // state effect
  useEffect(() => {
    let unmounted = false;
    if (action2 === 'add') {
      setPhoneDialog(true);
      setDialogTitle('Добавить жильца');
    } else if (action2 === 'edit') {
      setPhoneDialog(true);
      setDialogTitle('Редактировать телефон');
      const contactFromList = contacts.find((item) => item.id === actionId2) || { phone: '', name: '' }
      setContact({ ...contactFromList });
    } else if (action2 === 'show') {
      setPhoneDialog(true);
      setDialogTitle('Смотреть телефон');
      const contactFromList = contacts.find((item) => item.id === actionId2) || { phone: '', name: '' }
      setContact({ ...contactFromList });
    } else {
      setPhoneDialog(false);
      setTimeout(() => {
        if (!unmounted) {
          setContact({ phone: '', name: '' });
        }
      }, 200);
    }
    return () => {
      unmounted = true;
    };
  }, [action2, actionId2]);

  const onSavePhone = ({ phone, name }) => {
    try {
      if (action2 === 'add') {
        onChange([...contacts, { phone, name, id: uuid(), idIsLocal: true }])
      } else if (action2 === 'edit') {
        const updated = [...contacts];
        const index = updated.findIndex((item) => item.id === actionId2);
        if (index !== -1) {
          updated[index] = { ...updated[index], name, phone };
        }
        onChange(updated)
      }
      handleClose(null, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* phone dialog */}
      <Dialog {...props} open={phoneDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{ fontSize: '22px' }}>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={contact}
            validationSchema={
              Yup.object().shape({
                name: Yup.string().max(255).required('Забыли указать название'),
                phone: Yup.string().min(10, 'Неверный телефон').required('Забыли указать телефон'),
              })
            }
            onSubmit={async (values, { setTouched, validateForm }) => {
              const validationErrors = await validateForm();
              if (Object.keys(validationErrors).length === 0) {
                onSavePhone({ ...values, phone: values.phone.length === 10 ? '7' + values.phone : values.phone });
              } else {
                setTouched(setNestedObjectValues(validationErrors, true));
                dispatch(showMessage({ open: true, text: 'Проверьте ошибки заполнения формы', severity: 'error' }));
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{
                    minWidth: useMediaQuery(theme.breakpoints.up('sm')) ? '420px' : '',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        htmlFor="outlined-phone"
                        shrink
                      >
                        Имя
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        id="outlined-address"
                        autoFocus
                        type="text"
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange('name')}
                        readOnly={!editPermission}
                        label="Имя"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* phone field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        htmlFor="outlined-phone"
                        shrink
                      >
                        Телефон
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        id="outlined-phone"
                        type="text"
                        autoComplete="off"
                        notched
                        value={values.phone.length > 10 ? values.phone.substr(-10) : values.phone}
                        onChange={handleChange('phone')}
                        readOnly={!editPermission}
                        label="Телефон"
                        inputComponent={PhoneMask}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            event.stopPropagation();
                            handleSubmit();
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 4,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Grid
                    item
                  >
                    {editPermission && (
                      <Button
                        variant="contained"
                        color="purple"
                        fullWidth
                        onClick={() => handleSubmit()}
                        aria-disabled={values.name.length < 1 || values.phone.length < 10}
                      >
                        Сохранить
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

SubscriberPhoneDialog.propTypes = {
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
  contacts: PropTypes.array,
};

SubscriberPhoneDialog.defaultProps = {
  handleClose: null,
  onChange: null,
  contacts: [],
};

export default SubscriberPhoneDialog;
