import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import decOfNum from '../../../../../utils/decOfNum';

const DeviceKeysConfirm = ({ showDialog, counter, handleConfirm, handleClose, ...props }) => {
  const dispatch = useDispatch();

  return (
    <>
      {/* archive dialog */}
      <Dialog {...props} open={showDialog} onClose={handleClose} disableRestoreFocus PaperProps={{ sx: { minWidth: '350px' } }}>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Удалить ключи?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <strong>{counter}</strong>
            <span>&nbsp;</span>
            <strong>{ decOfNum(counter, ['ключ', 'ключа', 'ключей']) }</strong>
            <span>&nbsp;</span>
            <span>из списка</span>
            <span>&nbsp;</span>
            <span>{ decOfNum(counter, ['будет удален', 'будут удалены', 'будут удалены']) }</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                variant="contained"
                color="purple"
                fullWidth
                onClick={handleConfirm}
              >
                Удалить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeviceKeysConfirm.propTypes = {
  showDialog: PropTypes.bool,
  counter: PropTypes.number,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func,
};

DeviceKeysConfirm.defaultProps = {
  showDialog: false,
  counter: 0,
  handleConfirm: null,
  handleClose: null,
};

export default DeviceKeysConfirm;
