import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, List } from '@material-ui/core';
import { BarChart as BarChartIcon, User as UserIcon, Users as UsersIcon } from 'react-feather';
import {
  BadgeOutlined,
  ContactPhoneOutlined,
  DomainDisabledOutlined,
  DomainOutlined,
  GroupsOutlined,
  HomeWorkOutlined, LiveHelp,
  LocationDisabled,
  LocationSearching,
  MobileOff,
  Sip,
  StayCurrentPortrait,
  AddToHomeScreen,
  AddCircle,
  AddIcCallOutlined,
  ShareLocationOutlined,
  AddLocationOutlined,
  GroupAddOutlined,
  AssignmentRounded,
} from '@material-ui/icons';
import NavItem from './NavItem';
import {
  contractReadPermission,
  contractWritePermission,
  deviceReadPermission,
  deviceWritePermission,
  faqReadPermission,
  faqWritePermission,
  logsReadPermission,
  navbarIsOpen,
  placeReadPermission,
  placeWritePermission,
  userReadPermission,
  userWritePermission
} from '../store/selectors/account.selector';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const { pathname } = useLocation();
  const navbarIsOpenDesktop = useSelector(navbarIsOpen);

  const placeWrite = useSelector(placeWritePermission);
  const placeRead = useSelector(placeReadPermission);
  const userWrite = useSelector(userWritePermission);
  const userRead = useSelector(userReadPermission);
  const contractWrite = useSelector(contractWritePermission);
  const contractRead = useSelector(contractReadPermission);
  const deviceWrite = useSelector(deviceWritePermission);
  const deviceRead = useSelector(deviceReadPermission);
  const faqWrite = useSelector(faqWritePermission);
  const faqRead = useSelector(faqReadPermission);
  const logRead = useSelector(logsReadPermission);

  const items = [
    {
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Главная',
      visible: false,
    },
    {
      href: '/app/users',
      icon: UsersIcon,
      title: 'Пользователи',
      visible: userRead,
    },
    {
      href: '/app/users/customers/add',
      icon: GroupAddOutlined,
      title: 'Создать',
      sx: { pl: 2 },
      visible: userWrite,
    },
    {
      href: '/app/users/roles',
      icon: BadgeOutlined,
      title: 'Роли',
      sx: { pl: 2 },
      visible: userRead,
    },
    {
      href: '/app/users/departments',
      icon: GroupsOutlined,
      title: 'Отделы',
      sx: { pl: 2 },
      visible: userRead,
    },
    {
      href: '/app/places',
      icon: HomeWorkOutlined,
      title: 'Жилфонд',
      visible: placeRead,
    },
    {
      href: '/app/places/homes',
      icon: DomainOutlined,
      title: 'Дома',
      sx: { pl: 2 },
      visible: placeRead,
    },
    {
      href: '/app/places/homes/add/home',
      icon: AddLocationOutlined,
      title: 'Создать дом',
      sx: { pl: 2 },
      visible: placeWrite,
    },
    {
      href: '/app/places/territories',
      icon: LocationSearching,
      title: 'Территории',
      sx: { pl: 2 },
      visible: placeRead,
    },
    {
      href: '/app/places/territories/add/territory',
      icon: ShareLocationOutlined,
      title: 'Создать территорию',
      sx: { pl: 2 },
      visible: placeWrite,
    },
    /*{
      href: '/app/places/archive-homes',
      icon: DomainDisabledOutlined,
      title: 'Архив домов',
      sx: { pl: 2 },
    },
    {
      href: '/app/places/archive-territories',
      icon: LocationDisabled,
      title: 'Архив территорий',
      sx: { pl: 2, pb: 2 },
    },*/
    {
      href: '/app/contracts',
      icon: ContactPhoneOutlined,
      title: 'Абоненты',
      visible: contractRead,
    },
    {
      href: '/app/contracts/subscribers/add/subscriber',
      icon: AddIcCallOutlined,
      title: 'Создать абонента',
      sx: { pl: 2 },
      visible: contractWrite,
    },
    {
      href: '/app/contracts/sips',
      icon: Sip,
      title: 'SIP-аккаунты',
      sx: { pl: 2 },
      visible: contractRead,
    },
    // {
    //   href: '#',
    //   icon: PersonAddDisabled,
    //   title: 'Удаленные абоненты',
    //   sx: { pl: 2 },
    // },
    {
      href: '/app/devices',
      icon: StayCurrentPortrait,
      title: 'Устройства',
      visible: deviceRead,
    },
    {
      href: '/app/devices/intercoms/add/device/address',
      icon: AddToHomeScreen,
      title: 'Добавить устройство',
      sx: { pl: 2 },
      visible: deviceWrite,
    },
    /*{
      href: '/app/devices/archive-intercoms',
      icon: MobileOff,
      title: 'Архив устройств',
      sx: { pl: 2 },
    },*/
    {
      href: '/app/faq',
      icon: LiveHelp,
      title: 'Популярные вопросы',
      visible: faqRead,
    },
    {
      href: '/app/faq/add/faq',
      icon: AddCircle,
      title: 'Добавить вопрос',
      sx: { pl: 2 },
      visible: faqWrite,
    },
    {
      href: '/app/logs',
      icon: AssignmentRounded,
      title: 'Логи системы',
      visible: logRead,
    },
    {
      href: '/app/account',
      icon: UserIcon,
      title: 'Аккаунт',
      visible: false,
    },
    // {
    //   href: '/app/settings',
    //   icon: SettingsIcon,
    //   title: 'Settings'
    // },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      {/*
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      */
      }
      <Box sx={{ px: 2 }}>
        <List>
          {items.filter((item) => item.visible).map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              sx={{ py: 0, ...item.sx }}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            width: 256,
            display: { lg: 'none', xs: 'block' },
          }
        }}
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open={navbarIsOpenDesktop}
        variant="persistent"
        PaperProps={{
          sx: {
            width: 256,
            top: 64,
            height: 'calc(100% - 64px)',
            display: { lg: 'block', xs: 'none' },
          }
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
