/* eslint-disable */
export default
[
  {
    "userType": {
      "id": 0,
      "name": "Administrator"
    },
    "username": "admin",
    "userPermissions": []
  },
  {
    "userType": {
      "id": 1,
      "name": "User 1",
      "defaultType": true
    },
    "username": "user1",
    "userPermissions": []
  },
  {
    "userType": {
      "id": 2,
      "name": "User 2"
    },
    "username": "user2",
    "userPermissions": []
  },
  {
    "userType": {
      "id": 3,
      "name": "User 3"
    },
    "username": "user3",
    "userPermissions": []
  },
  {
    "userType": {
      "id": 4,
      "name": "User 4"
    },
    "username": "user4",
    "userPermissions": []
  }
]
