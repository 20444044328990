import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { deviceWritePermission } from '../../../store/selectors/account.selector';

function addressAndName(address, name) {
  return address + ', ' + name;
}

const DeviceNotSavedDialog = ({
  handleClose,
  onCancel,
  onIgnore,
  onSave,
  disabledSave,
  ...props
}) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <>
      {/* not saved dialog */}
      <Dialog {...props} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>Предупреждение</Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography color="textSecondary" variant="body1">
            <span>Текущая страница содержит несохранённые данные </span>
          </Typography>
          {disabledSave && (
            <Typography color="secondary" variant="body1">
              <span>Не заполнены обязательные поля</span>
            </Typography>
          )}
        </DialogContent>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4
            }}
          >
            <Grid item>
              {editPermission && (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                >
                  Отменить
                </Button>
              )}
            </Grid>
            <Grid item>
              {editPermission && (
                <Button
                  variant="contained"
                  color="purple"
                  fullWidth
                  onClick={onIgnore}
                >
                  Игнорировать
                </Button>
              )}
            </Grid>
            <Grid item>
              {editPermission && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={disabledSave}
                  onClick={onSave}
                >
                  Сохранить
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeviceNotSavedDialog.propTypes = {
  handleClose: PropTypes.func,
  onSave: PropTypes.func,
  onIgnore: PropTypes.func,
  onCancel: PropTypes.func,
  disabledSave: PropTypes.bool
};

DeviceNotSavedDialog.defaultProps = {
  handleClose: null,
};

export default memo(DeviceNotSavedDialog);
