import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput, Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DevicesConnection = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.connection || { host: '', port: '', username: '', password: '' }}
      validationSchema={
        Yup.object().shape({
          // name: Yup.string().max(255).required('Забыли указать название'),
          host: Yup.string().max(255).required('Забыли указать хост'),
          port: Yup.string().max(255).required('Забыли указать порт'),
          username: Yup.string().max(255).required('Забыли указать логин'),
          password: Yup.string().max(255).required('Забыли указать пароль'),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Параметры соединения с устройством ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* host field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.host && errors.host)}
                  htmlFor="outlined-connection-host"
                >
                  Хост
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.host && errors.host)}
                  id="outlined-connection-host"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  value={values.host}
                  onChange={(event) => {
                    setFieldValue('host', event.target.value);
                    onUpdate({ connection: { ...values, host: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly || !!device.users}
                  disabled={!editPermission || isReadonly || !!device.users}
                  label="Хост"
                />
                {touched.host && errors.host && (
                  <FormHelperText error id="outlined-connection-host-error">
                    {errors.host}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* port field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.port && errors.port)}
                  htmlFor="outlined-connection-port"
                >
                  Порт
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.port && errors.port)}
                  id="outlined-connection-port"
                  type="text"
                  autoComplete="off"
                  value={values.port}
                  onChange={(event) => {
                    setFieldValue('port', event.target.value);
                    onUpdate({ connection: { ...values, port: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly || !!device.users}
                  disabled={!editPermission || isReadonly || !!device.users}
                  label="Порт"
                />
                {touched.port && errors.port && (
                  <FormHelperText error id="outlined-connection-port-error">
                    {errors.port}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* username field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.username && errors.username)}
                  htmlFor="outlined-username"
                >
                  Логин
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.username && errors.username)}
                  id="outlined-connection-username"
                  type="text"
                  autoComplete="off"
                  value={values.username}
                  onChange={(event) => {
                    setFieldValue('username', event.target.value);
                    onUpdate({ connection: { ...values, username: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly || !!device.users}
                  disabled={!editPermission || isReadonly || !!device.users}
                  label="Логин"
                />
                {touched.username && errors.username && (
                  <FormHelperText error id="outlined-connection-username-error">
                    {errors.username}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              {/* password field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.password && errors.password)}
                  htmlFor="outlined-connection-password"
                >
                  Пароль
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.password && errors.password)}
                  id="outlined-connection-password"
                  type={values.showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  value={values.password}
                  onChange={(event) => {
                    setFieldValue('password', event.target.value);
                    onUpdate({ connection: { ...values, password: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly || !!device.users}
                  disabled={!editPermission || isReadonly || !!device.users}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="показать пароль"
                        tabIndex={-1}
                        onClick={() => {
                          setFieldValue('showPassword', !values.showPassword);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Пароль"
                />
                {touched.password && errors.password && (
                  <FormHelperText error id="outlined-connection-password-error">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DevicesConnection.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesConnection.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DevicesConnection, isDevicePropEqual);
