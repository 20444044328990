import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import {
  DomainOutlined,
  HouseOutlined,
  MarkunreadMailboxOutlined
} from '@material-ui/icons';
import { match, parse } from '../../../../utils/highlight';
import store from '../../../../store/store';
import {
  getBuilding,
  searchAddress
} from '../../../../store/actions/buildings.actions';
import useDebounce from '../../../../utils/useDebounce';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';
import { showMessage } from '../../../../store/actions/messages.actions';
import { getTerritory } from '../../../../store/actions/territories.actions';

const placeAvatar = (type, buildingType) => {
  if (type === 'AREA') {
    return <MarkunreadMailboxOutlined />;
  }
  if (type === 'BUILDING') {
    if (buildingType === 'PRIVATE_HOUSE') {
      return <HouseOutlined />;
    }
    return <DomainOutlined />;
  }
  return <DomainOutlined />;
};

const placeColor = (type, buildingType) => {
  if (type === 'AREA') {
    return '#4caf50';
  }
  if (type === 'BUILDING') {
    if (buildingType === 'PRIVATE_HOUSE') {
      return '#a127ee';
    }
    return '#ef6c00';
  }
  return '#ef6c00';
};

const DevicesAddress = ({ isReadonly, device, onUpdate, ...props }) => {
  const dispatch = useDispatch();
  const editPermission = useSelector(deviceWritePermission);
  const [intercoms, setIntercoms] = useState([]);
  const { action1, actionId1, stepName } = useParams();
  const [addresses, setAddresses] = useState([]);
  const [addressSearch, setAddressSearch] = useState(null);
  const [debouncedAddress] = useDebounce(addressSearch, 300);
  const [open, setOpen] = useState(false);
  const [firstOpen, setFirstOpen] = useState(true);

  // address details effect
  useEffect(() => {
    let unmounted = false;
    if (stepName === 'address') {
      if (!device.address && firstOpen) {
        const ref = document.getElementById('size-small-outlined');
        if (ref) {
          setFirstOpen(false);
          ref.focus();
          setAddressSearch('');
          setTimeout(() => {
            if (!unmounted && stepName === 'address') {
              setOpen(true);
            }
          }, 600);
        }
      }
      if (device.place?.id && firstOpen) {
        // get intercoms for current address
        getIntercoms(device.place.id);
      }
    } else {
      setOpen(false);
    }
    return () => {
      unmounted = true;
    };
  }, [stepName]);

  // address details effect
  useEffect(() => {
    if (addressSearch !== null) {
      const filteredData = addresses
        .filter(
          (item) =>
            match(item.address?.toLowerCase(), addressSearch.toLowerCase(), {
              insideWords: true,
              requireMatchAll: true
            }).length
        )
        .slice(0, 7);
      // console.log(addresses, addresses[0].address?.toLowerCase(), addressSearch.toLowerCase())
      setAddresses(
        filteredData.map((item) => ({
          ...item,
          matches: match(
            item.address?.toLowerCase(),
            addressSearch.toLowerCase(),
            { insideWords: true, requireMatchAll: true }
          )
        }))
      );
    } else {
      setAddresses([]);
    }
  }, [addressSearch]);

  // address debounced effect
  useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      try {
        const { data } = await store.dispatch(
          searchAddress({ term: debouncedAddress, intercomStatus: 'NEW' })
        );
        if (data && !unmounted) {
          const currentAddress = device.address;
          const filteredData = data
            .filter((item) => !item.address !== currentAddress)
            .slice(0, 7);
          setAddresses(
            filteredData.map((item) => ({
              ...item,
              matches: match(
                item.address?.toLowerCase(),
                addressSearch.toLowerCase(),
                { insideWords: true, requireMatchAll: true }
              )
            }))
          );
        }
      } catch (error) {
        console.log(error.message);
        dispatch(
          showMessage({
            open: true,
            text: `${error.message}`,
            severity: 'error'
          })
        );
      }
    };
    if (debouncedAddress !== null) {
      // console.log(debouncedAddress.length)
      // if (debouncedAddress.length === 0) {
      //   setAddresses([]);
      // }
      getData();
    } else {
      setAddresses([]);
    }
    return () => {
      unmounted = true;
    };
  }, [debouncedAddress]);

  const getIntercoms = async (placeId, type, setFieldValue) => {
    try {
      const { data } = await store.dispatch(
        type === 'BUILDING' ? getBuilding(placeId) : getTerritory(placeId)
      );
      // console.log(data, placeId, data.id === placeId)

      if (data && data.id === placeId) {
        const { address } = data;
        setIntercoms(data.intercoms);
        if (data.intercoms?.length) {
          const intercom =
            data.intercoms.find((item) => item.status === 'NEW') ||
            data.intercoms[0];
          if (setFieldValue) {
            setFieldValue('id', intercom?.id);
            setFieldValue('name', intercom?.name);
            const { id, name } = intercom;
            onUpdate({ id, name, address });
          }
        } else {
          onUpdate({ id: '', name: '', address });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device}
      validationSchema={Yup.object().shape({
        id: Yup.string(),
        callOnMobileWhileActivation: Yup.boolean(),
        name: Yup.string().max(255).required('Забыли указать название'),
        address: Yup.string().max(255).required('Забыли указать адрес'),
        host: Yup.string().max(255).required('Забыли указать хост'),
        port: Yup.string().max(255).required('Забыли указать порт'),
        login: Yup.string().max(255).required('Забыли указать логин'),
        password: Yup.string().max(255).required('Забыли указать пароль')
      })}
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Адрес и имя входа устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {/* address field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <Autocomplete
                  open={open}
                  disableClearable
                  filterOptions={(item) => item}
                  id="size-small-outlined"
                  disabled={!editPermission || isReadonly || !!device.users}
                  options={addresses || []}
                  getOptionLabel={(item) =>
                    (typeof item === 'object' ? item.address : item)}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id ||
                    option?.address === value.address ||
                    option?.address === value}
                  value={values.address || ''}
                  onOpen={() => setAddressSearch('')}
                  onBlur={() => {
                    setOpen(false);
                    setAddressSearch('');
                  }}
                  onFocus={() => setOpen(true)}
                  onInput={($event) => setAddressSearch($event.target.value)}
                  onChange={(event, value) => {
                    const { id, address, type } = value;
                    setFieldValue('address', address);
                    onUpdate({
                      address,
                      callOnMobileWhileActivation:
                        values?.callOnMobileWhileActivation
                    });
                    setOpen(false);
                    setAddressSearch('');
                    const ref = document.activeElement;
                    if (ref) {
                      ref.blur();
                    }
                    getIntercoms(id, type, setFieldValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Адрес"
                      placeholder={
                        !editPermission || values.address?.length ? '' : ''
                      }
                    />
                  )}
                  renderOption={(renderProps, option) => {
                    const parts = parse(option.address, option.matches);
                    return (
                      <li {...renderProps} key={uuid()}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                lineHeight: '32px'
                              }}
                            >
                              <Avatar
                                sx={{
                                  mr: 1,
                                  width: 32,
                                  height: 32,
                                  bgcolor: placeColor(
                                    option.type,
                                    option.buildingType
                                  )
                                }}
                              >
                                {placeAvatar(option.type, option.buildingType)}
                              </Avatar>
                              {parts.map((part) => (
                                <span
                                  key={Math.random()}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                    color: part.highlight ? '#00AFF3' : ''
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
                {touched.address && errors.address && (
                  <FormHelperText error id="outlined-address-error">
                    {errors.address}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* intercom name field */}
              <Autocomplete
                id="combo-box-name"
                disableClearable
                disableListWrap
                options={intercoms}
                getOptionDisabled={(item) => item.status !== 'NEW'}
                value={values.name || ''}
                getOptionLabel={(item) =>
                  (typeof item === 'object' ? item.name : item)}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id ||
                  option?.name === value?.name ||
                  option?.name === value}
                onChange={(event, value) => {
                  // setFieldValue('id', value?.id || 'device');
                  setFieldValue('name', value?.name || '');
                  const { id, name } = value;
                  onUpdate({
                    id,
                    name,
                    callOnMobileWhileActivation:
                      values?.callOnMobileWhileActivation
                  });
                }}
                onBlur={handleBlur('name')}
                disabled={!editPermission || isReadonly || !!device.users}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label="Имя входа"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ ml: 1 }}
                  value={values?.callOnMobileWhileActivation}
                  checked={values?.callOnMobileWhileActivation}
                  onChange={(event) => {
                    setFieldValue(
                      'callOnMobileWhileActivation',
                      event.target.checked
                    );
                    onUpdate({
                      callOnMobileWhileActivation: event.target.checked
                    });
                  }}
                />
              }
              label="Установка прав доступа для квартир"
            />
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DevicesAddress.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired
};

DevicesAddress.defaultProps = {
  isReadonly: false,
  device: null
};

// DevicesAddress.whyDidYouRender = true;

export default memo(DevicesAddress, isDevicePropEqual);
