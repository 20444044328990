import api from '../../api/client';
import { handleServerError } from './utils';
import { showInternetErrorMessage, showServerErrorMessage } from './messages.actions';

export function setSubscribers(data) {
  return { type: 'subscribers/list', data };
}

export function setSubscribersCache(data) {
  return { type: 'subscribers/cache', data };
}

export function setSubscribersRefresh(data) {
  return { type: 'subscribers/refresh', data };
}

export function setSubscribersListState(data) {
  return { type: 'subscribers/listState', data };
}

// async thunk actions

export function getSubscribers(data) {
  return async (dispatch) => {
    try {
      dispatch(setSubscribersListState('loading'));
      const response = await api.getSubscribers(data);
      dispatch(setSubscribers(response.data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getSubscriber(id) {
  return async () => {
    try {
      const response = await api.getSubscriber(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createSubscriber(data) {
  return async () => {
    try {
      const response = await api.createSubscriber(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putSubscriber(data) {
  return async () => {
    try {
      const response = await api.putSubscriber(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteSubscriber(data) {
  return async () => {
    try {
      const response = await api.deleteSubscriber(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function restoreSubscriber(data) {
  return async () => {
    try {
      const response = await api.restoreSubscriber(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function blockSubscriber(data) {
  return async () => {
    try {
      const response = await api.blockSubscriber(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function unblockSubscriber(data) {
  return async () => {
    try {
      const response = await api.unblockSubscriber(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function checkLicenseSubscriber() {
  return async () => {
    try {
      const response = await api.checkLicenseContract();
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
