import api from '../../api/client';
import { handleServerError } from './utils';

export function faqs(data) {
  return { type: 'faq/list', data };
}

export function faq(data) {
  return { type: 'faq/single', data };
}

export function setFaqsRefresh(data) {
  return { type: 'faq/refresh', data };
}

// async thunk actions

export function getFaqs() {
  return async (dispatch) => {
    try {
      const response = await api.getFaqs();
      dispatch(faqs(response.data));
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getFaq(id) {
  return async (dispatch) => {
    try {
      const response = await api.getFaq(id);
      dispatch(faq(response.data));
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createFaq(data) {
  return async () => {
    try {
      const response = await api.createFaq(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putFaq(id, data) {
  return async () => {
    try {
      const response = await api.putFaq(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function archiveFaq(id) {
  return async () => {
    try {
      const response = await api.archiveFaq(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
