import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Breadcrumbs, Container, Link, Paper, Tabs } from '@material-ui/core';
import SubscribersEditor from '../components/contracts/SubscribersEditor';
import SubscribersList from '../components/contracts/SubscribersList';
import SubscribersToolbar from '../components/contracts/SubscribersToolbar';
import LinkTab from '../components/common/LinkTab';
import TabPanel from '../components/common/TabPanel';
import { showNoAccessMessage } from '../store/actions/messages.actions';
import { contractReadPermission } from '../store/selectors/account.selector';
import SipsToolbar from '../components/contracts/sips/SipsToolbar';
import SipsList from '../components/contracts/sips/SipsList';
import SipsEditor from '../components/contracts/sips/SipsEditor';

const Contracts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = useState(0);

  const readPermission = useSelector(contractReadPermission);

  const handleChange = (event, newTab) => {
    if (newTab === 1) {
      navigate('/app/contracts/sips', { replace: true });
    } else {
      navigate('/app/contracts/subscribers', { replace: true });
    }
  };

  useEffect(() => {
    if (pathname.includes('/app/contracts/sips')) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [pathname]);

  useEffect(() => {
    if (!readPermission) {
      dispatch(showNoAccessMessage());
      navigate('/app/dashboard');
    } else {
      // store.dispatch(getCustomersAndSips());
    }
  }, []);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/app/dashboard">Главная</Link>
              <Link color="textPrimary" href="/app/contracts/subscribers">Абоненты</Link>
              {tab === 1 && <Link color="textPrimary" href="/app/contracts/sips">SIP-аккаунты</Link>}
            </Breadcrumbs>
          </Box>

          <Paper
            sx={{
              backgroundColor: 'white',
              minHeight: '100%',
              my: 3
            }}
          >
            <Tabs value={tab} onChange={handleChange} aria-label="Абоненты, SIP-аккаунты">
              <LinkTab label="Абоненты" href="/app/contracts/subscribers" />
              <LinkTab label="SIP-аккаунты" href="/app/contracts/sips" />
            </Tabs>
          </Paper>
          <TabPanel value={tab} index={0}>
            <SubscribersList />
            <SubscribersEditor />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <SipsToolbar />
            <SipsList />
            <SipsEditor />
          </TabPanel>
        </Container>
      </Box>
    </>
  );
};

export default Contracts;
