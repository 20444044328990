const initialState = {
  nextMessages: [],
  message: { open: false, text: '', severity: 'success' },
  state: 'idle',
};

function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case 'messages/showMessage': {
      return { ...state, message: action.data };
    }
    case 'messages/hideMessage': {
      return { ...state, message: { ...state.message, open: false } };
    }
    case 'messages/nextMessages': {
      return { ...state, nextMessages: [...state.nextMessages, action.data] };
    }
    case 'messages/showNextMessages': {
      if (state.nextMessages.length) {
        return { ...state, message: state.nextMessages[0], nextMessages: state.nextMessages.slice(1) };
      }
      return { ...state, nextMessages: [{ ...state.message, open: true }] }
    }
    default:
      return state;
  }
}

export default messagesReducer;
