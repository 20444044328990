import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { contractWritePermission } from '../../../store/selectors/account.selector';
import decOfNum from '../../../utils/decOfNum';

const SipsMulti = ({ sipsSelected, sipsDeleteClick, sipsBlockClick, ...props }) => {
  const editPermission = useSelector(contractWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/* {editPermission && !!sipsSelected && (
          <RouterLink to="/app/places/homes/area">
            <Button
              sx={{ ml: 1 }}
              color="purple"
              variant="contained"
              onClick={() => sipsBlockClick()}
            >
              Заблокировать&nbsp;
              { sipsSelected }
              &nbsp;
              { decOfNum(sipsSelected, ['абонента', 'абонентов', 'абонентов']) }
            </Button>
          </RouterLink>
        )}
        {editPermission && !!sipsSelected && (
          <RouterLink to="/app/places/homes/area">
            <Button
              sx={{ ml: 1 }}
              color="secondary"
              variant="contained"
              onClick={() => sipsDeleteClick()}
            >
              Удалить&nbsp;
              { sipsSelected }
              &nbsp;
              { decOfNum(sipsSelected, ['абонента', 'абонентов', 'абонентов']) }
            </Button>
          </RouterLink>
        )} */}
      </Box>
    </Box>
  );
};

SipsMulti.propTypes = {
  sipsSelected: PropTypes.number,
  sipsDeleteClick: PropTypes.func,
  sipsBlockClick: PropTypes.func,
};

SipsMulti.defaultProps = {
  sipsSelected: 0,
  sipsDeleteClick: null,
  sipsBlockClick: null,
};

export default SipsMulti;
