import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import { userWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import { showMessage } from '../../../store/actions/messages.actions';
import {
  deleteDepartment,
  getDepartments
} from '../../../store/actions/departments.actions';
import DepartmentsMulti from './DepartmentsMulti';
import DepartmentsConfirm from './DepartmentsConfirm';
import DepartmentsToolbar from './DepartmentsToolbar';
import EnhancedTableHead from '../../TableHead';

const DepartmentsList = ({ ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editPermission = useSelector(userWritePermission);
  const departments = useSelector((state) => state.departments.list);

  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = [];
      return setOrderBy('');
    }
    sortRef.current.push(property);
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  const handleSelectAll = (event) => {
    let newSelectedDepartmentIds;

    if (event.target.checked) {
      newSelectedDepartmentIds = departments.map((department) => department.id);
    } else {
      newSelectedDepartmentIds = [];
    }

    setSelectedDepartmentIds(newSelectedDepartmentIds);
  };

  useEffect(async () => {
    try {
      await store.dispatch(
        getDepartments({
          page,
          size,
          sort: !orderBy
            ? undefined
            : orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}`
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [page, size, orderBy, order]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [size]);

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedDepartmentIds.indexOf(id);
    let newSelectedDepartmentIds = [];

    if (selectedIndex === -1) {
      newSelectedDepartmentIds = newSelectedDepartmentIds.concat(
        selectedDepartmentIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedDepartmentIds = newSelectedDepartmentIds.concat(
        selectedDepartmentIds.slice(1)
      );
    } else if (selectedIndex === selectedDepartmentIds.length - 1) {
      newSelectedDepartmentIds = newSelectedDepartmentIds.concat(
        selectedDepartmentIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedDepartmentIds = newSelectedDepartmentIds.concat(
        selectedDepartmentIds.slice(0, selectedIndex),
        selectedDepartmentIds.slice(selectedIndex + 1)
      );
    }

    setSelectedDepartmentIds(newSelectedDepartmentIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const editDepartment = (department) => {
    if (editPermission) {
      navigate(`/app/users/departments/edit/${department.id}`);
    } else {
      navigate(`/app/users/departments/show/${department.id}`);
    }
  };

  const onDeleteDepartmentClick = (department) => {
    navigate(`/app/users/departments/delete/${department.id}`);
  };

  const updateDepartmentsStatus = async (
    filterFunc,
    updateAction,
    successMessage,
    errorMessage
  ) => {
    try {
      const toUpdate = departments
        .filter((item) => selectedDepartmentIds.includes(item.id))
        .filter(filterFunc);
      await Promise.all(
        toUpdate.map((item) => store.dispatch(updateAction(item.id)))
      );
      dispatch(
        showMessage({ open: true, text: successMessage, severity: 'success' })
      );
      setTimeout(() => store.dispatch(getDepartments()), 500);
      setSelectedDepartmentIds([]);
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          open: true,
          text: errorMessage + ' ' + error.message,
          severity: 'error'
        })
      );
    }
  };

  const onDepartmentsDeleteClick = () => {
    setConfirmDialog(true);
  };

  const onDepartmentsConfirmClick = () => {
    updateDepartmentsStatus(
      (item) => item,
      deleteDepartment,
      'Отделы успешно удалены.',
      'Не удалось удалить.'
    );
    setConfirmDialog(false);
  };

  return (
    <>
      <DepartmentsToolbar
        departmentsActive={
          departments.filter((item) => selectedDepartmentIds.includes(item.id))
            .length
        }
        departmentsDeleteClick={() => onDepartmentsDeleteClick()}
      />
      <Card {...rest}>
        <Helmet>
          <title>Отделы | Терион</title>
        </Helmet>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <EnhancedTableHead
                ActionBox={() => <Checkbox
                  checked={
                    selectedDepartmentIds.length > 0 &&
                    selectedDepartmentIds.length === departments.length
                  }
                  color="primary"
                  indeterminate={
                    selectedDepartmentIds.length > 0 &&
                    selectedDepartmentIds.length < departments.length
                  }
                  onChange={handleSelectAll}
                />}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: false,
                    id: 'id',
                    label: 'ID',
                  },
                  {
                    sortable: true,
                    id: 'name',
                    label: 'Название',
                    style: { width: '30%' }
                  },
                ]}
                hasActions
              />

              <TableBody>
                {departments
                  .slice(page * size, page * size + size)
                  .map((department) => (
                    <TableRow
                      hover
                      key={department.id}
                      selected={
                        selectedDepartmentIds.indexOf(department.id) !== -1
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            selectedDepartmentIds.indexOf(department.id) !== -1
                          }
                          onChange={(event) =>
                            handleSelectOne(event, department.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>{department.id}</TableCell>
                      <TableCell>{department.name}</TableCell>
                      <TableCell size="small">
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Tooltip
                            title={
                              editPermission ? 'Редактировать' : 'Смотреть'
                            }
                            placement="top"
                            followCursor
                            enterDelay={1000}
                          >
                            <IconButton
                              aria-label="edit"
                              color="primary"
                              onClick={() => editDepartment(department)}
                            >
                              <SvgIcon fontSize="medium" color="primary">
                                {editPermission ? (
                                  <Edit />
                                ) : (
                                  <VisibilityOutlined />
                                )}
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                          {editPermission && (
                            <Tooltip
                              title="Удалить"
                              placement="top"
                              followCursor
                              enterDelay={1000}
                            >
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() =>
                                  onDeleteDepartmentClick(department)}
                              >
                                <SvgIcon fontSize="medium" color="secondary">
                                  <Delete />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={departments.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={size}
          rowsPerPageOptions={[5, 10, 100, 1000]}
        />
      </Card>

      {editPermission && (
        <DepartmentsMulti
          departmentsActive={
            departments.filter((item) =>
              selectedDepartmentIds.includes(item.id)).length
          }
          departmentsDeleteClick={() => onDepartmentsDeleteClick()}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <DepartmentsConfirm
          showDialog={confirmDialog}
          counter={
            departments.filter((item) =>
              selectedDepartmentIds.includes(item.id)).length
          }
          handleConfirm={() => onDepartmentsConfirmClick()}
          handleClose={() => setConfirmDialog(false)}
        />
      )}
    </>
  );
};

DepartmentsList.propTypes = {};

export default DepartmentsList;
