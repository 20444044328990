import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { v4 as uuid } from 'uuid';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  createTerritory,
  getTerritory,
  putTerritory,
  setTerritoriesCache,
  setTerritoriesRefresh
} from '../../../store/actions/territories.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import { placeWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import useDebounce from '../../../utils/useDebounce';
import {
  createBuilding,
  getBuilding,
  putBuilding,
  searchAddress,
  searchBuilding
} from '../../../store/actions/buildings.actions';
import BuildingDialog from './BuildingDialog';

const territoryEmptyState = {
  id: 'territory',
  name: '',
  buildings: [],
  intercoms: [],
  intercomsFromBuildings: []
};

const getShortAddress = (address) => {
  const addArray = address.split(/,[\s]*/gi).splice(-2);
  return addArray.join(', ');
};

const getBuildingsIntercoms = (buildings) => {
  // console.log(data)
  let intercomsConcat = [];
  buildings.forEach((item) => {
    intercomsConcat = intercomsConcat.concat(
      item.intercoms.map((subItem) => ({
        ...subItem,
        prefix: item.prefix,
        address: getShortAddress(item.address)
      }))
    );
  });
  return intercomsConcat;
};

const TerritoryDialog = ({ isArchive, handleClose, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action0, actionId0, action1, actionId1, action2 } = useParams();

  const editPermission = useSelector(placeWritePermission);

  const [addresses, setAddresses] = useState([]);
  const [addressSearch, setAddressSearch] = useState(null);
  const [debouncedAddress] = useDebounce(addressSearch, 300);
  const [territoryDetails, setTerritoryDetails] = useState({
    ...territoryEmptyState
  });
  const [territory, setTerritory] = useState({ ...territoryEmptyState });
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const territoriesCache = useSelector((state) => state.territories.cache);
  const territories = useSelector((state) => state.territories.list);
  const territoryTypes = useSelector(
    (state) => state.territories.territoryTypes
  );

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action0, action1, actionId0, actionId1, territory.intercoms[0], territoriesCache);
    const territoryFromList =
      actionId1 && territories.find((item) => item.id === actionId1);
    if (action0 === 'edit' || action0 === 'show' || action0 === 'add') {
      setEditDialog(true);
      if (action0 === 'edit') {
        setEditDialogTitle('Редактировать территорию');
      } else if (action0 === 'show') {
        setEditDialogTitle('Смотреть территорию');
      } else if (action0 === 'add') {
        setEditDialogTitle('Создать территорию');
      }
    } else {
      setEditDialog(false);
      setTimeout(() => {
        if (!unmounted && territory.id) {
          if (
            territory.id !== 'territory' ||
            territory.intercomsFromBuildings?.length ||
            territory.intercoms?.length
          ) {
            setTerritory({ ...territoryEmptyState });
          }
          if (
            territoryDetails.id !== 'territory' ||
            territoryDetails.intercomsFromBuildings?.length ||
            territoryDetails.intercoms?.length
          ) {
            setTerritoryDetails({ ...territoryEmptyState });
          }
          if (addressSearch !== null) {
            setAddressSearch(null);
          }
          if (
            territoriesCache.territory &&
            (territoriesCache.territory.name ||
              territoriesCache.territory.intercomsFromBuildings?.length ||
              territoriesCache.territory.intercoms?.length)
          ) {
            // clear territory cache
            // setTerritoryDetails({ ...territoryEmptyState, intercomsFromBuildings: [] });
            dispatch(setTerritoriesCache(territoryEmptyState));
          }
        }
      }, 200);
    }
    if (
      (action0 === 'edit' || action0 === 'show' || action0 === 'add') &&
      actionId0
    ) {
      setTerritory({
        ...(territoriesCache[actionId1] || territoryEmptyState),
        ...territoryFromList,
        ...(territoryDetails.id === actionId0 && territoryDetails)
      });
    }
    return () => {
      unmounted = true;
    };
  }, [
    action0,
    action1,
    actionId0,
    actionId1,
    territories,
    territoryDetails,
    territoryTypes
  ]);

  // territory details effect
  useEffect(async () => {
    let unmounted = false;
    if (actionId0 && actionId0 !== 'territory') {
      try {
        const { data } = await store.dispatch(getTerritory(actionId0));
        dispatch(setTerritoriesCache(data));
        if (data && data.id === actionId0) {
          setTerritoryDetails({
            ...data,
            intercoms: data.intercoms.map((item) => ({
              ...item,
              isTerritory: true
            })),
            intercomsFromBuildings: getBuildingsIntercoms(data.buildings)
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [actionId0]);

  // address details effect
  useEffect(async () => {
    let unmounted = false;
    if (debouncedAddress !== null) {
      try {
        const { data } = await store.dispatch(
          searchBuilding({ term: debouncedAddress })
        );
        if (data && !unmounted) {
          const currentIds = territory.buildings.map((item) => item.id);
          const filteredData = data.filter(
            (item) => !currentIds.includes(item.id)
          );
          setAddresses(
            filteredData.map((item) => ({
              ...item,
              intercoms: [],
              matches: match(
                item.address.toLowerCase(),
                addressSearch.toLowerCase()
              )
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setAddresses([]);
    }
    return () => {
      unmounted = true;
    };
  }, [debouncedAddress]);

  const getDiapason = (intercomItem) =>
    intercomItem?.flatRanges
      ?.map((flat) =>
        (flat.min === flat.max ? flat.min : flat.min + '-' + flat.max))
      .join('\r\n') ?? '';

  const onAddBuildingClick = (values) => {
    if (action2 === undefined) {
      setTerritoryDetails({
        ...territory,
        ...values
      });
      navigate('add/home/');
    }
  };

  const onAddIntercomClick = (values) => {
    if (action2 === undefined) {
      setTerritoryDetails({
        ...territory,
        ...values
      });
      navigate('edit/territory/add/intercom');
    }
  };

  const onOpenIntercomClick = (intercomItem) => {
    if (action2 === undefined) {
      if (editPermission && intercomItem.status !== 'CONFIGURED') {
        navigate(`edit/territory/edit/${intercomItem.id || 'intercom'}`);
      } else {
        if (intercomItem.status === 'CONFIGURED' && editPermission) {
          dispatch(
            showMessage({
              open: true,
              text: 'Редактирование входа при подключенном домофоне невозможно!',
              severity: 'error'
            })
          );
        }
        navigate(`show/territory/show/${intercomItem.id || 'intercom'}`);
      }
    }
  };

  const onDeleteIntercomClick = (intercomItem) => {
    if (action2 === undefined) {
      navigate(`edit/territory/delete/${intercomItem.id}`);
    }
  };

  const onChangeBuildings = async (values, value) => {
    const currentIds = territory.buildings.map((item) => item.id);
    const addedBuilding = value.filter(
      (item) => !currentIds.includes(item.id)
    )[0];
    setTerritoryDetails({
      ...territory,
      name: values.name,
      buildings: [...value],
      intercomsFromBuildings: getBuildingsIntercoms(value)
    });
    if (addedBuilding) {
      const { data } = await store.dispatch(getBuilding(addedBuilding.id));
      setTerritoryDetails((territoryCurrent) => {
        const territoryUpdated = { ...territoryCurrent };
        const buildingIndex = territoryUpdated.buildings.findIndex(
          (item) => item.id === data.id
        );
        if (buildingIndex !== -1) {
          territoryUpdated.buildings[buildingIndex] = { ...data };
          territoryUpdated.intercomsFromBuildings = getBuildingsIntercoms(
            territoryUpdated.buildings
          );
        }
        return {
          ...territoryUpdated
        };
      });
    }
  };

  const onUpdateBuilding = (building) => {
    const territoryUpdated = {
      ...territory,
      buildings: [
        ...territory.buildings,
        {
          ...building,
          id: building.id || uuid(),
          idIsLocal: !building.id,
          updated: true
        }
      ]
    };
    territoryUpdated.intercomsFromBuildings = getBuildingsIntercoms(
      territoryUpdated.buildings
    );
    dispatch(setTerritoriesCache(territoryUpdated));
    setTerritoryDetails({
      ...territoryUpdated
    });
  };

  const onUpdateIntercom = (intercom) => {
    const territoryUpdated = {
      ...territory,
      intercoms: [...territory.intercoms],
      buildings: [...territory.buildings]
    };
    const intercomIndex = territoryUpdated.intercoms.findIndex(
      (item) => item.id === intercom.id
    );
    const intercomFromBuildingsIndex =
      territoryUpdated.intercomsFromBuildings.findIndex(
        (item) => item.id === intercom.id
      );
    if (intercomIndex !== -1) {
      const intercomStatus = territoryUpdated.intercoms[intercomIndex].status;
      territoryUpdated.intercoms[intercomIndex] = { ...intercom, status: intercomStatus };
    } else if (intercomFromBuildingsIndex !== -1) {
      const buildingIndex = territoryUpdated.buildings?.findIndex((building) =>
        building.intercoms.find((item) => item.id === intercom.id));
      if (buildingIndex !== -1) {
        const internalIntercom = territoryUpdated.buildings[
          buildingIndex
        ].intercoms.find((item) => item.id === intercom.id);
        internalIntercom.updated = true;
        internalIntercom.name = intercom.name;
        internalIntercom.type = intercom.type;
        internalIntercom.flatRanges = intercom.flatRanges;
        territoryUpdated.intercomsFromBuildings = getBuildingsIntercoms(
          territoryUpdated.buildings
        );
        territoryUpdated.buildings[buildingIndex].updated = true;
      }
    } else {
      territoryUpdated.intercoms[territory.intercoms.length] = intercom;
    }
    dispatch(setTerritoriesCache(territoryUpdated));
    setTerritoryDetails({
      ...territoryUpdated
    });
  };

  const onRemoveIntercom = (intercom) => {
    const territoryUpdated = {
      ...territory,
      intercoms: [...territory.intercoms],
      buildings: [...territory.buildings]
    };
    const intercomIndex = territoryUpdated.intercoms.findIndex(
      (item) => item.id === intercom.id
    );
    const intercomFromBuildingsIndex =
      territoryUpdated.intercomsFromBuildings.findIndex(
        (item) => item.id === intercom.id
      );
    if (intercomIndex !== -1) {
      territoryUpdated.intercoms = territoryUpdated.intercoms.filter(
        (item) => item.id !== intercom.id
      );
    } else if (intercomFromBuildingsIndex !== -1) {
      const buildingIndex = territoryUpdated.buildings?.findIndex((building) =>
        building.intercoms.find((item) => item.id === intercom.id));
      if (buildingIndex !== -1) {
        territoryUpdated.buildings[buildingIndex].intercoms =
          territoryUpdated.buildings[buildingIndex].intercoms.filter(
            (item) => item.id !== intercom.id
          );
        territoryUpdated.intercomsFromBuildings = getBuildingsIntercoms(
          territoryUpdated.buildings
        );
        territoryUpdated.buildings[buildingIndex].updated = true;
      }
    }
    dispatch(setTerritoriesCache(territoryUpdated));
    setTerritoryDetails({
      ...territoryUpdated
    });
  };

  const onSaveTerritory = async (values) => {
    console.log(values, 'values');
    try {
      const buildingsUpdated = [...values.buildings];
      await Promise.all(
        buildingsUpdated
          .filter((building) => building.updated)
          .map(async (building) => {
            const data = {
              id:
                building.id !== 'home' && !building.idIsLocal
                  ? building.id
                  : '',
              address: building.address,
              prefix: building.prefix,
              buildingType: building.buildingType,
              intercoms:
                building.intercoms.map((item) =>
                  (item.idIsLocal
                    ? { ...item, id: '', idIsLocal: undefined }
                    : item)) || []
            };
            const response = await store.dispatch(
              data.id ? putBuilding(data) : createBuilding(data)
            );
            const index = buildingsUpdated.findIndex(
              (item) => item.id === building.id
            );
            if (index !== -1) {
              const updatedId = response.headers.location?.split('/')?.pop();
              if (updatedId) {
                buildingsUpdated[index].id = updatedId;
              }
            }
          })
      );
      const data = {
        id: values.id !== 'territory' ? values.id : '',
        name: values.name,
        buildings: buildingsUpdated,
        intercoms: [
          ...(territory.intercoms.map((item) =>
            (item.idIsLocal ? { ...item, id: '', idIsLocal: undefined } : item)) || []),
          ...(territory.intercomsFromBuildings.filter((intercom) =>
            ['GATE', 'ADDITIONAL_ENTRANCE'].includes(intercom.placingType)) || [])
        ]
      };
      const response = await store.dispatch(
        data.id ? putTerritory(data) : createTerritory(data)
      );
      dispatch(
        showMessage({
          open: true,
          text: 'Территория успешно сохранена.',
          severity: 'success'
        })
      );
      dispatch(setTerritoriesCache(values));
      handleClose(null, 'success');
      setTimeout(() => dispatch(setTerritoriesRefresh()), 500);
    } catch (error) {
      console.error(error);
      dispatch(
        showMessage({
          open: true,
          text: `Не удалось сохранить территорию. ${error.message}`,
          severity: 'error'
        })
      );
    }
  };

  return (
    <>
      <Dialog
        {...props}
        open={editDialog}
        onClose={handleClose}
        fullWidth
        scroll="body"
        disableRestoreFocus
        PaperProps={{ sx: { maxWidth: '900px' } }}
        /* PaperProps={{ sx: { backgroundColor: 'background.default' } }} */
      >
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{ fontSize: '22px' }}>{editDialogTitle}</Box>
        </DialogTitle>
        <DialogContent
          sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}
        >
          <Formik
            enableReinitialize
            initialValues={territory}
            validationSchema={Yup.object().shape({
              id: Yup.string(),
              name: Yup.string().max(255).required('Забыли указать название')
            })}
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    {/* name field */}
                    <FormControl
                      sx={{ m: 0, width: '100%' }}
                      variant="outlined"
                    >
                      <InputLabel
                        error={Boolean(touched.name && errors.name)}
                        htmlFor="outlined-name"
                      >
                        Название территории
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.name && errors.name)}
                        id="outlined-name"
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange('name')}
                        readOnly={!editPermission || isArchive}
                        label="Название территории"
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error id="outlined-name-error">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/* buildings field */}
                    <FormControl
                      sx={{ m: 0, width: '100%' }}
                      variant="outlined"
                    >
                      <Autocomplete
                        id="size-small-outlined"
                        multiple
                        disabled={!editPermission || isArchive}
                        disableCloseOnSelect
                        filterSelectedOptions
                        options={addresses || []}
                        getOptionLabel={(option) => option.address || ''}
                        onOpen={() => setAddressSearch('')}
                        onInput={($event) =>
                          setAddressSearch($event.target.value)}
                        onChange={(event, value) =>
                          onChangeBuildings(values, value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Здания на территории"
                            placeholder={
                              !editPermission || isArchive
                                ? ''
                                : 'Введите адрес'
                            }
                          />
                        )}
                        value={values?.buildings || []}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={option.id}
                              label={getShortAddress(option.address)}
                              size="small"
                              {...getTagProps({ index })}
                            />
                          ))}
                        renderOption={(renderProps, option) => {
                          const parts = parse(option.address, option.matches);
                          return (
                            <li {...renderProps}>
                              <Grid container alignItems="center">
                                <Grid item xs>
                                  {parts.map((part) => (
                                    <span
                                      key={uuid()}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                        color: part.highlight ? '#00AFF3' : ''
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* intercoms table */}
                <Box sx={{ my: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>Дом</TableCell>
                        <TableCell>Префикс</TableCell>
                        <TableCell>Вход</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          Диапазон
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }}>
                          {/* Редактирование */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                        ...territory.intercomsFromBuildings,
                        ...territory.intercoms
                      ].map((intercomItem, intercomIndex) => (
                        <TableRow hover key={intercomItem.id || intercomIndex}>
                          <TableCell>{intercomItem.address}</TableCell>
                          <TableCell>{intercomItem.prefix}</TableCell>
                          <TableCell>
                            {intercomItem.name || intercomItem.type}
                          </TableCell>
                          <TableCell
                            sx={{ whiteSpace: 'pre', textAlign: 'center' }}
                          >
                            {getDiapason(intercomItem)}
                          </TableCell>
                          <TableCell size="small">
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex'
                              }}
                            >
                              <Tooltip
                                title={
                                  editPermission && !isArchive && intercomItem.status !== 'CONFIGURED'
                                    ? 'Редактировать'
                                    : 'Смотреть'
                                }
                                placement="top"
                                followCursor
                                enterDelay={1000}
                              >
                                <IconButton
                                  aria-label="edit"
                                  color="primary"
                                  onClick={() =>
                                    onOpenIntercomClick(intercomItem)}
                                >
                                  {intercomItem.id && (
                                    <SvgIcon fontSize="medium" color="primary">
                                      {editPermission && !isArchive && intercomItem.status !== 'CONFIGURED' ? (
                                        <Edit />
                                      ) : (
                                        <VisibilityOutlined />
                                      )}
                                    </SvgIcon>
                                  )}
                                </IconButton>
                              </Tooltip>
                              {editPermission && !isArchive && (
                                <Tooltip
                                  title="Удалить"
                                  placement="top"
                                  followCursor
                                  enterDelay={1000}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    color="secondary"
                                    onClick={() =>
                                      onDeleteIntercomClick(intercomItem)}
                                  >
                                    {intercomItem.id && (
                                      <SvgIcon
                                        fontSize="medium"
                                        color="secondary"
                                      >
                                        <Delete />
                                      </SvgIcon>
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3
                  }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent="flex-start" spacing={2}>
                      {editPermission && !isArchive && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="orange"
                            onClick={() => onAddBuildingClick(values)}
                            disabled={!values.name}
                          >
                            Добавить дом
                          </Button>
                        </Grid>
                      )}
                      {editPermission && !isArchive && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="purple"
                            onClick={() => onAddIntercomClick(values)}
                            disabled={!values.name}
                          >
                            Добавить вход
                          </Button>
                        </Grid>
                      )}
                      {editPermission && !isArchive && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            aria-disabled={
                              !isValid ||
                              isSubmitting ||
                              !values.name ||
                              !values.intercoms
                                ?.length /* && !values.intercomsFromBuildings?.length */
                            }
                            onClick={async () => {
                              const validationErrors = await validateForm();
                              if (Object.keys(validationErrors).length === 0) {
                                onSaveTerritory(values);
                              } else {
                                setTouched(
                                  setNestedObjectValues(validationErrors, true)
                                );
                                dispatch(
                                  showMessage({
                                    open: true,
                                    text: 'Проверьте ошибки заполнения формы',
                                    severity: 'error'
                                  })
                                );
                              }
                            }}
                          >
                            Сохранить
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* building dialog */}
      <BuildingDialog
        territory={territory}
        onUpdateBuilding={(value) => onUpdateBuilding(value)}
        onUpdate={(value) => onUpdateIntercom(value)}
        onRemove={(value) => onRemoveIntercom(value)}
        handleClose={() => handleClose()}
      />
    </>
  );
};

TerritoryDialog.propTypes = {
  isArchive: PropTypes.bool,
  handleClose: PropTypes.func
};

TerritoryDialog.defaultProps = {
  isArchive: false,
  handleClose: null
};

export default TerritoryDialog;
