import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Close, Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import getInitials from '../../utils/getInitials';
import { userWritePermission } from '../../store/selectors/account.selector';
import store from '../../store/store';
import { showMessage } from '../../store/actions/messages.actions';
import {
  deleteCustomer,
  getCustomers,
  setCustomersRefresh
} from '../../store/actions/customers.actions';
import UsersMulti from './UsersMulti';
import UsersToolbar from './UsersToolbar';
import UsersConfirm from './UsersConfirm';
import EnhancedTableHead from '../TableHead';

const UsersList = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editPermission = useSelector(userWritePermission);
  const customersIsLoading = useSelector(
    (state) => state.customers.state !== 'success'
  );
  const customers = useSelector((state) => state.customers.list);

  const roles = useSelector((state) => state.roles.list);
  const departments = useSelector((state) => state.departments.list);

  const [usernameFilter, setUsernameFilter] = useState('');
  const [departmentFilter, setGroupFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = [];
      return setOrderBy('');
    }
    sortRef.current.push(property);
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  useEffect(async () => {
    try {
      await store.dispatch(
        getCustomers({
          page,
          size,
          sort: !orderBy
            ? undefined
            : orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}`
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [page, size, orderBy, order]);

  useEffect(() => {
    try {
      let filtered = [...customers];
      if (usernameFilter) {
        const usernameFilterLower = usernameFilter.toLowerCase();
        filtered = filtered.filter((customer) =>
          customer.username.toLowerCase().includes(usernameFilterLower));
      }
      if (departmentFilter) {
        filtered = filtered.filter(
          (customer) => customer.department.name === departmentFilter.name
        );
      }
      if (roleFilter) {
        filtered = filtered.filter(
          (customer) => customer.role.name === roleFilter.name
        );
      }
      setFilteredCustomers(filtered);
    } catch (error) {
      console.log(error);
    }
  }, [page, size, customers, usernameFilter, departmentFilter, roleFilter]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [size, usernameFilter, departmentFilter, roleFilter]);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = filteredCustomers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const editCustomer = (customer) => {
    if (editPermission) {
      navigate(`/app/users/customers/edit/${customer.id}`);
    } else {
      navigate(`/app/users/customers/show/${customer.id}`);
    }
  };

  const onDeleteCustomerClick = (customer) => {
    navigate(`/app/users/customers/delete/${customer.id}`);
  };

  const updateCustomersStatus = async (
    filterFunc,
    updateAction,
    successMessage,
    errorMessage
  ) => {
    try {
      const toUpdate = customers
        .filter((item) => selectedCustomerIds.includes(item.id))
        .filter(filterFunc);
      await Promise.all(
        toUpdate.map((item) => store.dispatch(updateAction(item.id)))
      );
      dispatch(
        showMessage({ open: true, text: successMessage, severity: 'success' })
      );
      setTimeout(() => dispatch(setCustomersRefresh()), 500);
      setSelectedCustomerIds([]);
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          open: true,
          text: errorMessage + ' ' + error.message,
          severity: 'error'
        })
      );
    }
  };

  const onCustomersDeleteClick = () => {
    setConfirmDialog(true);
  };

  const onCustomersConfirmClick = () => {
    updateCustomersStatus(
      (item) => item,
      deleteCustomer,
      'Пользователи успешно удалены.',
      'Не удалось удалить.'
    );
    setConfirmDialog(false);
  };

  return (
    <>
      <UsersToolbar
        customersActive={
          customers.filter((item) => selectedCustomerIds.includes(item.id))
            .length
        }
        customersDeleteClick={() => onCustomersDeleteClick()}
      />
      <Card
        {...rest}
        style={{
          backgroundColor: customersIsLoading ? 'lightyellow' : 'white',
          transition: 'background-color 400ms ease-in'
        }}
      >
        <Helmet>
          <title>Пользователи | Терион</title>
        </Helmet>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <EnhancedTableHead
                isLoading={customersIsLoading}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: true,
                    id: 'username',
                    label: 'Имя'
                  },
                  {
                    sortable: true,
                    id: 'department',
                    label: 'Отдел'
                  },
                  {
                    sortable: true,
                    id: 'role',
                    label: 'Роль',
                    style: { width: '30%' }
                  }
                ]}
                hasActions
              />

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedCustomerIds.length > 0 &&
                        selectedCustomerIds.length === filteredCustomers.length
                      }
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0 &&
                        selectedCustomerIds.length < filteredCustomers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      placeholder="Фильтр по логину"
                      variant="outlined"
                      onChange={(event) =>
                        setUsernameFilter(event.target.value)}
                      /*InputProps={{
                        endAdornment: (
                          <>
                            <IconButton
                              style={{ margin: '-0.5em' }}
                            >
                              <Close style={{ width: '20px' }} />
                            </IconButton>
                          </>
                        )
                      }}*/
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      id="combo-box-department"
                      options={departments}
                      getOptionLabel={(item) => item.name}
                      onChange={(event, value) => setGroupFilter(value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Фильтр по отделу" />
                      )}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Autocomplete
                      disableListWrap
                      id="combo-box-department"
                      options={roles}
                      getOptionLabel={(item) => item.name}
                      onChange={(event, value) => setRoleFilter(value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Фильтр по роли" />
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCustomers
                  .slice(page * size, page * size + size)
                  .map((customer) => (
                    <TableRow
                      hover
                      key={customer.id}
                      selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            selectedCustomerIds.indexOf(customer.id) !== -1
                          }
                          onChange={(event) =>
                            handleSelectOne(event, customer.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar src={customer.avatarUrl} sx={{ mr: 2 }}>
                            {getInitials(customer.role.name)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {customer.username}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{customer.department.name}</TableCell>
                      <TableCell>{customer.role.name}</TableCell>
                      {/*
                    <TableCell>
                      {`${customer.address.city}, ${customer.address.state}, ${customer.address.country}`}
                    </TableCell>
                    <TableCell>
                      {customer.phone}
                    </TableCell>
                    <TableCell>
                      {moment(customer.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    */}
                      <TableCell size="small">
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Tooltip
                            title={
                              editPermission ? 'Редактировать' : 'Смотреть'
                            }
                            placement="top"
                            followCursor
                            enterDelay={1000}
                          >
                            <IconButton
                              aria-label="edit"
                              color="primary"
                              onClick={() => editCustomer(customer)}
                            >
                              <SvgIcon fontSize="medium" color="primary">
                                {editPermission ? (
                                  <Edit />
                                ) : (
                                  <VisibilityOutlined />
                                )}
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                          {editPermission && (
                            <Tooltip
                              title="Удалить"
                              placement="top"
                              followCursor
                              enterDelay={1000}
                            >
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => onDeleteCustomerClick(customer)}
                              >
                                <SvgIcon fontSize="medium" color="secondary">
                                  <Delete />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredCustomers.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      {editPermission && (
        <UsersMulti
          customersActive={
            customers.filter((item) => selectedCustomerIds.includes(item.id))
              .length
          }
          customersDeleteClick={() => onCustomersDeleteClick()}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <UsersConfirm
          showDialog={confirmDialog}
          counter={
            customers.filter((item) => selectedCustomerIds.includes(item.id))
              .length
          }
          handleConfirm={() => onCustomersConfirmClick()}
          handleClose={() => setConfirmDialog(false)}
        />
      )}
    </>
  );
};

UsersList.propTypes = {};

export default UsersList;
