import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Breadcrumbs, Container, Link, Paper, Tabs } from '@material-ui/core';
import DevicesEditor from '../components/devices/DevicesEditor';
import DevicesList from '../components/devices/DevicesList';
import LinkTab from '../components/common/LinkTab';
import TabPanel from '../components/common/TabPanel';
import { showNoAccessMessage } from '../store/actions/messages.actions';
import { deviceReadPermission } from '../store/selectors/account.selector';

const Contracts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = useState(0);

  const readPermission = useSelector(deviceReadPermission);

  const handleChange = (event, newTab) => {
    if (newTab === 1) {
      navigate('/app/devices/archive-intercoms', { replace: true });
    } else {
      navigate('/app/devices/intercoms', { replace: true });
    }
  };

  useEffect(() => {
    if (pathname.includes('/app/devices/archive-intercoms')) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [pathname]);

  useEffect(() => {
    if (!readPermission) {
      dispatch(showNoAccessMessage());
      navigate('/app/dashboard');
    } else {
      // store.dispatch(getCustomersAndSips());
    }
  }, []);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/app/dashboard">Главная</Link>
              <Link color="textPrimary" href="/app/devices/intercoms">Устройства</Link>
              {/*{tab === 1 && <Link color="textPrimary" href="/app/devices/archive-intercoms">Архив устройств</Link>}*/}
            </Breadcrumbs>
          </Box>

          <Paper
            sx={{
              backgroundColor: 'white',
              minHeight: '100%',
              my: 3
            }}
          >
            <Tabs value={tab} onChange={handleChange} aria-label="Устройства, архив устройств">
              <LinkTab label="Устройства" href="/app/devices/intercoms" />
              {/*<LinkTab label="Архив устройств" href="/app/devices/archive-intercoms" />*/}
            </Tabs>
          </Paper>
          <TabPanel value={tab} index={0}>
            <DevicesList />
            <DevicesEditor />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <DevicesList isArchive />
            <DevicesEditor />
          </TabPanel>
        </Container>
      </Box>
    </>
  );
};

export default Contracts;
