import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl, FormControlLabel, FormHelperText,
  Grid, IconButton,
  InputLabel, OutlinedInput, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip,
  Typography
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import PropTypes from 'prop-types';
import { Delete, Edit, RestoreFromTrash, VisibilityOutlined } from '@material-ui/icons';
import store from '../../../../../store/store';
import { createKey, deleteKey, deleteMifareKeyV2, updateKey } from '../../../../../store/actions/devices.actions';
import { deviceWritePermission } from '../../../../../store/selectors/account.selector';
import { showMessage } from '../../../../../store/actions/messages.actions';
import DeviceCipherDialog from './DeviceCipherDialog';

const tagTypes = [{
  id: 'Ultralight',
  name: 'Ultralight',
}, {
  id: 'Mifare Classic',
  name: 'Mifare Classic',
}, {
  id: 'Mifare Plus SE',
  name: 'Mifare Plus SE',
}, {
  id: 'Mifare Plus X',
  name: 'Mifare Plus X',
}];

const types = [{
  id: 'Сервисный',
  name: 'Сервисный',
}, {
  id: 'Пользовательский',
  name: 'Пользовательский',
}];

const keyEmptyState = {
  key: '',
  flatNumber: '',
  owner: '',
  cipherIndex: 1,
  type: types[1],
  tagType: tagTypes[1],
};

const getCipher = (ciphers, index) => ciphers?.find((item) => item.index === index)?.cipher;

const DeviceKeysDialog = ({ tagType, isReadonly, handleClose, keys, ciphers, updateCiphers, updateKeys, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editPermission = useSelector(deviceWritePermission);
  const { action1, actionId1, stepName, action2, actionId2 } = useParams();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');
  const [key, setKey] = useState({ ...keyEmptyState });

  useEffect(async () => {
    let unmounted = tagType !== stepName && tagType !== 'mifare' && stepName !== 'rfid';
    if (!unmounted) {
      // console.log(action2, keys, type, stepName)
      if (action2 === 'edit' || action2 === 'show' || action2 === 'add') {
        setEditDialog(true);
        if (action2 === 'edit') {
          setEditDialogTitle('Редактировать ключ');
        } else if (action2 === 'show') {
          setEditDialogTitle('Смотреть ключ');
        } else if (action2 === 'add') {
          setEditDialogTitle('Добавить ключ');
        }
      } else if (action2 === 'delete') {
        setDeleteDialog(true);
      } else {
        setDeleteDialog(false);
        setEditDialog(false);
        setTimeout(() => {
          if (!unmounted && key.key) {
            setKey({ ...keyEmptyState });
          }
        }, 300);
      }
      if ((action2 === 'edit' || action2 === 'show' || action2 === 'delete') && actionId2) {
        const keyToEdit = keys.find((item) => item.key === actionId2);
        if (keyToEdit) {
          setKey({
            ...keyEmptyState,
            ...keyToEdit,
            tagType: { name: keyToEdit.tagType, code: keyToEdit.tagType },
            type: { name: keyToEdit.type, code: keyToEdit.type }
          });
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, [action2, actionId2, keys]);

  const onDeleteKey = async (values) => {
    try {
      // console.log(key)
      if (tagType === 'mifare') {
        await store.dispatch(deleteMifareKeyV2(actionId1, actionId2));
      } else {
        await store.dispatch(deleteKey(actionId1, actionId2, key.type.name)); // key.type is сервисный или пользовательский
      }
      dispatch(showMessage({ open: true, text: 'Ключ успешно удалён', severity: 'success' }));
      handleClose(null, 'success');
      setTimeout(() => updateKeys(), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось удалить ключ. ${error.message}`, severity: 'error' }));
    }
  }

  const onSaveKey = async (values) => {
    try {
      // console.log(values, tagType)
      const data = {
        key: values.key,
        flatNumber: values.flatNumber,
        // mifare fields:
        owner: values.owner,
        tagType: values.tagType?.name,
        type: values.type?.name,
        cipherIndex: values.cipherIndex
      };
      await store.dispatch(action2 === 'edit' ? updateKey(actionId1, data, tagType) : createKey(actionId1, data, tagType));
      dispatch(showMessage({ open: true, text: 'Ключ успешно сохранён', severity: 'success' }));
      handleClose(null, 'success');
      setTimeout(() => updateKeys(), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось сохранить ключ. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      <Dialog {...props} open={editDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{
            fontSize: '22px',
          }}
          >
            {editDialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={key}
            validationSchema={
              Yup.object().shape({
                key: Yup.string(),
                flatNumber: Yup.string(),
                owner: Yup.string().max(255),
                tagType: Yup.string().max(255),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* key field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.key && errors.key)}
                        htmlFor="outlined-key"
                      >
                        ID
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.key && errors.key)}
                        style={{ backgroundColor: isReadonly || !editPermission || action2 !== 'add' ? '#f5f5f5' : '' }}
                        id="outlined-key"
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={values.key}
                        onChange={handleChange('key')}
                        readOnly={isReadonly || !editPermission || action2 !== 'add'}
                        label="ID"
                      />
                      {touched.key && errors.key && (
                        <FormHelperText error id="outlined-key-error">
                          {errors.key}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* flatNumber field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.flatNumber && errors.flatNumber)}
                        htmlFor="outlined-flatNumber"
                      >
                        Номер квартиры
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: isReadonly || !editPermission || values.type.name === 'Сервисный' ? '#f5f5f5' : '' }}
                        error={Boolean(touched.flatNumber && errors.flatNumber)}
                        id="outlined-flatNumber"
                        type="text"
                        autoComplete="off"
                        value={values.type.name === 'Сервисный' ? '' : values.flatNumber}
                        onChange={handleChange('flatNumber')}
                        readOnly={isReadonly || !editPermission || values.type.name === 'Сервисный'}
                        label="Номер квартиры"
                      />
                      {touched.flatNumber && errors.flatNumber && (
                        <FormHelperText error id="outlined-flatNumber-error">
                          {errors.flatNumber}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {stepName === 'mifare' && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {/* owner field */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-owner" error={Boolean(touched.owner && errors.owner)}>Владелец</InputLabel>
                        <OutlinedInput
                          style={{ backgroundColor: isReadonly || !editPermission || values.type.name === 'Пользовательский' ? '#f5f5f5' : '' }}
                          error={Boolean(touched.owner && errors.owner)}
                          id="outlined-owner"
                          type="text"
                          value={values.type.name === 'Пользовательский' ? '' : values.owner}
                          onChange={handleChange('owner')}
                          readOnly={isReadonly || !editPermission || values.type.name === 'Пользовательский'}
                          label="Владелец"
                        />
                        {touched.owner && errors.owner && (
                          <FormHelperText error id="outlined-owner-error">
                            {errors.owner}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {stepName === 'mifare' && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {/* type autocomplete */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <Autocomplete
                          disableListWrap
                          id="combo-box-type"
                          options={types}
                          disableClearable
                          value={values.type}
                          getOptionLabel={(item) => item.name}
                          onChange={(event, value) => setFieldValue('type', value)}
                          onBlur={handleBlur('type')}
                          disabled={isReadonly || !editPermission}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.type && errors.type)}
                              helperText={touched.type && errors.type}
                              label="Тип ключа"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {stepName === 'mifare' && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {/* tagType autocomplete */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <Autocomplete
                          disableListWrap
                          id="combo-box-tagType"
                          options={tagTypes}
                          disableClearable
                          value={values.tagType}
                          getOptionLabel={(item) => item.name}
                          onChange={(event, value) => setFieldValue('tagType', value)}
                          onBlur={handleBlur('tagType')}
                          disabled={isReadonly || !editPermission}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.tagType && errors.tagType)}
                              helperText={touched.tagType && errors.tagType}
                              label="Тип метки"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {stepName === 'mifare' && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {/* cipher field */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <Autocomplete
                          disableListWrap
                          id="combo-box-cipher"
                          options={ciphers}
                          disableClearable
                          value={getCipher(ciphers, values.cipherIndex) || ''}
                          getOptionLabel={(item) => item?.cipher || item || ''}
                          onChange={(event, value) => setFieldValue('cipherIndex', value.index)}
                          onBlur={handleBlur('type')}
                          disabled={isReadonly || !editPermission || action2 === 'show'}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.type && errors.type)}
                              helperText={touched.type && errors.type}
                              label="Шифр"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                {stepName === 'mifare' && (
                  <Box sx={{ my: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: '30%' }}>
                            Индекс
                          </TableCell>
                          <TableCell style={{ width: '60%' }}>
                            Шифр
                          </TableCell>
                          <TableCell style={{ width: '120px' }}>
                            {/* actions */}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ciphers && ciphers.map((cipherItem, cipherIndex) => (
                          <TableRow
                            hover
                            key={cipherItem.id || cipherIndex}
                          >
                            <TableCell>
                              {cipherItem.index}
                            </TableCell>
                            <TableCell>
                              {cipherItem.cipher}
                            </TableCell>
                            <TableCell size="small">
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                {/*<Tooltip title={editPermission ? 'Редактировать' : 'Смотреть'} placement="top" followCursor enterDelay={1000}>
                                  <IconButton aria-label="edit" color="primary">
                                    <SvgIcon
                                      fontSize="medium"
                                      color="primary"
                                    >
                                      {editPermission ? <Edit /> : <VisibilityOutlined />}
                                    </SvgIcon>
                                  </IconButton>
                                </Tooltip>*/}
                                {editPermission && !isReadonly && (
                                  <Tooltip title="Удалить" placement="top" followCursor enterDelay={1000}>
                                    <IconButton aria-label="delete" color="secondary" onClick={() => navigate(`delete/${cipherItem.index}`)}>
                                      <SvgIcon
                                        fontSize="medium"
                                        color="secondary"
                                        /* sx={{ color: '#ef6c00' }} */
                                      >
                                        <Delete />
                                      </SvgIcon>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                )}
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Grid
                    item
                  >
                    <Grid
                      container
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      {stepName === 'mifare' && !isReadonly && editPermission && (
                        <Grid
                          item
                        >
                          <Button
                            variant="contained"
                            color="purple"
                            onClick={() => navigate('add/cipher')}
                          >
                            Добавить шифр
                          </Button>
                        </Grid>
                      )}
                      {!isReadonly && editPermission && (
                        <Grid
                          item
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                              const validationErrors = await validateForm();
                              if (Object.keys(validationErrors).length === 0) {
                                onSaveKey(values);
                              } else {
                                setTouched(setNestedObjectValues(validationErrors, true));
                                dispatch(showMessage({ open: true, text: 'Проверьте ошибки заполнения формы', severity: 'error' }));
                              }
                            }}
                          >
                            Сохранить
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* key delete dialog */}
      <Dialog {...props} open={deleteDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px', }}>
            Удалить ключ?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          {key.type?.name === 'Сервисный' ? (
            <Typography
              color="textSecondary"
              variant="body1"
            >
              <span>Сервисный ключ </span>
              <strong>{key.owner}</strong>
              <span> будет удалён навсегда!</span>
            </Typography>
          ) : (
            <Typography
              color="textSecondary"
              variant="body1"
            >
              <span>Ключ </span>
              {/*<strong>{key.type.name}</strong>*/}
              <span> от квартиры </span>
              <strong>{key.flatNumber}</strong>
              <span> будет удалён навсегда!</span>
            </Typography>
          )}

          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                variant="contained"
                color="purple"
                fullWidth
                onClick={onDeleteKey}
              >
                Удалить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {tagType === 'mifare' && editPermission && (
        <DeviceCipherDialog
          tagType={tagType}
          ciphers={ciphers}
          updateCiphers={updateCiphers}
          handleClose={handleClose}
          isReadonly={action2 === 'show'}
        />
      )}
    </>
  );
};

DeviceKeysDialog.propTypes = {
  tagType: PropTypes.string,
  isReadonly: PropTypes.bool,
  handleClose: PropTypes.func,
  keys: PropTypes.array,
  ciphers: PropTypes.array,
  updateKeys: PropTypes.func,
  updateCiphers: PropTypes.func,
};

DeviceKeysDialog.defaultProps = {
  isReadonly: false,
  handleClose: null,
  ciphers: [],
  keys: [],
};

export default DeviceKeysDialog;
