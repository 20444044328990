import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { placeWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const HomesMulti = ({ buildingsToArchive, buildingsToArchiveClick, ...props }) => {
  const editPermission = useSelector(placeWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {editPermission && !!buildingsToArchive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => buildingsToArchiveClick()}
          >
            Архивировать&nbsp;
            { buildingsToArchive }
            &nbsp;
            { decOfNum(buildingsToArchive, ['дом', 'дома', 'домов']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

HomesMulti.propTypes = {
  buildingsToArchive: PropTypes.number,
  buildingsToArchiveClick: PropTypes.func,
};

HomesMulti.defaultProps = {
  buildingsToArchive: 0,
  buildingsToArchiveClick: null,
};

export default HomesMulti;
