export function handleServerError(error) {
  console.log(error.response, error.response?.status);
  let message = 'Не удалось получить данные. Ошибка сервера ' + error.response?.status;
  if (error.response && error.response.data && error.response.data.details && error.response.data.details.length) {
    [message] = error.response.data.details;
  }
  return Promise.reject(new Error(message));
}

export function handleUnknownError(error) {
  console.log(error.response);
  let message = 'Неизвестная ошибка';
  if (error.response && error.response.data && error.response.data.details && error.response.data.details.length) {
    [message] = error.response.data.details;
  }
  return Promise.reject(new Error(message));
}
