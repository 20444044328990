const formatPhone = (phone) => {
  if (phone) {
    try {
      const re = /(?:([\d]{1,}?))??(?:([\d]{1,3}?))??(?:([\d]{1,3}?))??(?:([\d]{2}))??([\d]{2})$/;
      const formatted = phone.replace(re, (all, a, b, c, d, e) => (a ? a + ' (' : (b ? '(' : '')) + (b ? b + ') ' : '') + (c ? c + '-' : '') + (d ? d + '-' : '') + e);
      return '+' + formatted;
    } catch (error) {
      console.log(error);
    }
  }
  return phone;
};

export default formatPhone;
