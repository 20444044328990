import api from '../../api/client';
import fias from '../../api/fias';
import { handleServerError } from './utils';
import { showInternetErrorMessage, showServerErrorMessage } from './messages.actions';

export function setIntercomTypes(data) {
  return { type: 'buildings/intercomTypes', data };
}

export function setBuildingTypes(data) {
  return { type: 'buildings/buildingTypes', data };
}

export function setBuildings(data) {
  return { type: 'buildings/list', data };
}

export function setBuildingsArchive(data) {
  return { type: 'buildings/archive', data };
}

export function setBuildingsCache(data) {
  return { type: 'buildings/cache', data };
}

export function setBuildingsRefresh(data) {
  return { type: 'buildings/refresh', data };
}

export function setBuildingsListState(data) {
  return { type: 'buildings/listState', data };
}

export function setBuildingsArchiveState(data) {
  return { type: 'buildings/archiveState', data };
}

// async thunk actions

export function getBuildingTypes() {
  return async (dispatch) => {
    try {
      Promise.all([api.getIntercomTypes({ placeType: 'BUILDING' }), api.getBuildingTypes()])
        .then(([intercomTypesResponse, buildingTypesResponse]) => {
          dispatch(setIntercomTypes(intercomTypesResponse.data));
          dispatch(setBuildingTypes(buildingTypesResponse.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(showInternetErrorMessage());
        });
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getBuildings(data) {
  return async (dispatch) => {
    try {
      dispatch(data.active ? setBuildingsListState('loading') : setBuildingsArchiveState('loading'));
      const response = await api.getBuildings(data);
      dispatch(data.active ? setBuildings(response.data) : setBuildingsArchive(response.data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getBuilding(id) {
  return async () => {
    try {
      const response = await api.getBuilding(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createBuilding(data) {
  return async () => {
    try {
      const response = await api.createBuilding(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putBuilding(data) {
  return async () => {
    try {
      const response = await api.putBuilding(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function archiveBuilding(data) {
  return async () => {
    try {
      const response = await api.archiveBuilding(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function unarchiveBuilding(data) {
  return async () => {
    try {
      const response = await api.unarchiveBuilding(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function searchArea(data) {
  return async () => {
    try {
      const response = await api.searchArea(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function searchBuilding(data) {
  return async () => {
    try {
      const response = await api.searchBuilding(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function searchAddress(data) {
  return async () => {
    try {
      const response = await api.searchAddress(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function addBuildingToArea(areaId, buildingId) {
  return async () => {
    try {
      const { data } = await api.getTerritory(areaId);
      const response = await api.putTerritory({ ...data, buildings: [...data.buildings, { id: buildingId }] });
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function removeBuildingFromArea(areaId, buildingId) {
  return async () => {
    try {
      const { data } = await api.getTerritory(areaId);
      const response = await api.putTerritory({ ...data, buildings: [...data.buildings.filter((item) => item.id !== buildingId)] });
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function searchSuggestions(data) {
  return async () => {
    try {
      const response = await fias.addressSuggest(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
