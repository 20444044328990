import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userWritePermission } from '../../../store/selectors/account.selector';
import decOfNum from '../../../utils/decOfNum';

const DepartmentsToolbar = ({
  departmentsActive,
  departmentsDeleteClick,
  ...props
}) => {
  const editPermission = useSelector(userWritePermission);

  return (
    <Box {...props}>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ mx: 1 }}>
          Экспорт
        </Button>*/}
        {editPermission && (
          <RouterLink to="/app/users/departments/add">
            <Button
              color="primary"
              variant="contained"
            >
              Добавить отдел
            </Button>
          </RouterLink>
        )}
        {editPermission && !!departmentsActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => departmentsDeleteClick()}
          >
            Удалить&nbsp;
            { departmentsActive }
            &nbsp;
            { decOfNum(departmentsActive, ['отдел', 'отдела', 'отделов']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

DepartmentsToolbar.propTypes = {
  departmentsActive: PropTypes.number,
  departmentsDeleteClick: PropTypes.func,
};

DepartmentsToolbar.defaultProps = {
  departmentsActive: 0,
  departmentsDeleteClick: null,
};

export default DepartmentsToolbar;
