import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/ru';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Delete,
  Edit,
  Unarchive,
  VisibilityOutlined
} from '@material-ui/icons';
import { deviceWritePermission } from '../../store/selectors/account.selector';
import store from '../../store/store';
import {
  archiveDevice,
  getDevices,
  restoreDevice,
  setDevicesRefresh
} from '../../store/actions/devices.actions';
import DevicesMulti from './DevicesMulti';
import useDebounce from '../../utils/useDebounce';
import { showMessage } from '../../store/actions/messages.actions';
import copyToClipboard from '../../utils/copyToClipboard';
import shortAddress from '../../utils/shortAddress';
import DevicesToolbar from './DevicesToolbar';
import DevicesConfirm from './DevicesConfirm';
import EnhancedTableHead from '../TableHead';

const statuses = [
  { code: '', name: 'Все' },
  /*{ code: 'NEW', name: 'Активные' },*/
  { code: 'PENDING_CONFIGURATION', name: 'Требуют настройки' },
  { code: 'CONFIGURED', name: 'Настроенные' }
];

const connectionColors = {
  OFFLINE: 'red',
  ONLINE: 'green'
};

const connectionTexts = {
  OFFLINE: 'off',
  ONLINE: 'on'
};

const sipColors = {
  NOT_REGISTERED: 'red',
  REGISTERED: 'green'
};

const statusColors = {
  NEW: 'red',
  PENDING_CONFIGURATION: 'orange',
  CONFIGURED: 'green'
};

const statusTexts = {
  NEW: 'настроить',
  PENDING_CONFIGURATION: 'настроить',
  CONFIGURED: 'настроено'
};

const statusActions = {
  NEW: 'add',
  PENDING_CONFIGURATION: 'add',
  CONFIGURED: 'edit'
};

const DevicesList = ({ isArchive, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editPermission = useSelector(deviceWritePermission);
  const devicesIsLoading = useSelector(
    (state) => state.devices.listState !== 'success'
  );
  const devicesVersion = useSelector((state) => state.devices.listVersion);
  const devicesTotal = useSelector((state) => state.devices.listTotal);
  const devices = useSelector((state) => state.devices.list);

  const [host, setHost] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [address, setAddress] = useState('');
  const [debouncedHost] = useDebounce(host, 800);
  const [debouncedAddress] = useDebounce(address, 800);
  const [debouncedMacAddress] = useDebounce(macAddress, 800);

  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = [];
      return setOrderBy('');
    }
    sortRef.current.push(property);
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      try {
        await store.dispatch(
          getDevices({
            page,
            size,
            address,
            macAddress,
            host,
            status,
            sort: !orderBy
              ? undefined
              : orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}`
          })
        );
      } catch (error) {
        console.log(error);
      }
    };
    if (!unmounted) {
      getData();
    }
    return () => {
      unmounted = true;
    };
  }, [
    devicesVersion,
    size,
    page,
    debouncedAddress,
    debouncedMacAddress,
    debouncedHost,
    status,
    order,
    orderBy
  ]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [debouncedAddress, debouncedMacAddress, debouncedHost, status, size]);

  const handleSelectAll = (event) => {
    let newSelectedDeviceIds;

    if (event.target.checked) {
      newSelectedDeviceIds = devices.map((device) => device.id);
    } else {
      newSelectedDeviceIds = [];
    }

    setSelectedDeviceIds(newSelectedDeviceIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedDeviceIds.indexOf(id);
    let newSelectedDeviceIds = [];

    if (selectedIndex === -1) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(selectedDeviceIds, id);
    } else if (selectedIndex === 0) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(
        selectedDeviceIds.slice(1)
      );
    } else if (selectedIndex === selectedDeviceIds.length - 1) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(
        selectedDeviceIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(
        selectedDeviceIds.slice(0, selectedIndex),
        selectedDeviceIds.slice(selectedIndex + 1)
      );
    }

    setSelectedDeviceIds(newSelectedDeviceIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const deviceColor = (code) => {
    if (code === 'ACTIVE') {
      return '#4caf50';
    }
    if (code === 'BLOCKED') {
      return 'secondary';
    }
    return 'primary';
  };

  const onOpenDeviceClick = (device, action = 'show', section = 'review') => {
    if (editPermission) {
      navigate(`/app/devices/intercoms/${action}/${device.id}/${section}`);
    } else {
      navigate(`/app/devices/intercoms/show/${device.id}/${section}`);
    }
  };

  const onDeleteDeviceClick = (device) => {
    if (!isArchive) {
      navigate(`/app/devices/intercoms/delete/${device.id}`);
    } else {
      navigate(`/app/devices/archive-intercoms/restore/${device.id}`);
    }
  };

  const updateDevicesStatus = async (
    filterFunc,
    updateAction,
    successMessage,
    errorMessage
  ) => {
    try {
      const toUpdate = devices
        .filter((item) => selectedDeviceIds.includes(item.id))
        .filter(filterFunc);
      await Promise.all(
        toUpdate.map((item) => store.dispatch(updateAction(item.id)))
      );
      dispatch(
        showMessage({ open: true, text: successMessage, severity: 'success' })
      );
      setTimeout(() => dispatch(setDevicesRefresh()), 500);
      setSelectedDeviceIds([]);
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          open: true,
          text: errorMessage + ' ' + error.message,
          severity: 'error'
        })
      );
    }
  };

  const onDevicesRestoreClick = (device) => {
    updateDevicesStatus(
      (item) => item.status.code === 'DELETED',
      restoreDevice,
      'Девайсы успешно восстановлены.',
      'Не удалось восстановить.'
    );
  };

  const onDevicesDeleteClick = (device) => {
    setConfirmDialog(true);
  };

  const onDevicesConfirmClick = () => {
    updateDevicesStatus(
      (item) => item,
      archiveDevice,
      'Девайсы успешно удалены.',
      'Не удалось удалить.'
    );
    setConfirmDialog(false);
  };

  const onDevicesMessageClick = () => {
    navigate('/app/devices/intercoms/message/all');
  };

  return (
    <>
      <Helmet>
        <title>Устройства | Терион</title>
      </Helmet>
      <DevicesToolbar
        devicesActive={
          devices.filter((item) => selectedDeviceIds.includes(item.id)).length
        }
        devicesDeleteClick={() => onDevicesDeleteClick()}
        devicesMessageClick={() => onDevicesMessageClick()}
      />
      <Card
        {...rest}
        style={{
          backgroundColor: devicesIsLoading ? 'lightyellow' : 'white',
          transition: 'background-color 400ms ease-in'
        }}
      >
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <EnhancedTableHead
                isLoading={devicesIsLoading}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: true,
                    id: 'address',
                    label: 'Адрес',
                    style: { width: 230 }
                  },
                  {
                    sortable: true,
                    id: 'mac_address',
                    label: 'MAC',
                    style: { width: 170 }
                  },
                  {
                    sortable: true,
                    id: 'host',
                    label: 'Сетевой адрес',
                    style: { width: 210 }
                  },
                  {
                    sortable: true,
                    id: 'created',
                    label: 'Создано'
                  },
                  {
                    sortable: true,
                    id: 'updated',
                    label: 'Изменено'
                  },
                  {
                    sortable: false,
                    id: 'status',
                    label: 'Статус'
                  }
                ]}
                hasActions
              />

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedDeviceIds.length > 0 &&
                        selectedDeviceIds.length === devices.length
                      }
                      color="primary"
                      indeterminate={
                        selectedDeviceIds.length > 0 &&
                        selectedDeviceIds.length < devices.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по адресу"
                      variant="outlined"
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по MAC"
                      variant="outlined"
                      onChange={(event) => setMacAddress(event.target.value)}
                    />
                  </TableCell>
                  <TableCell colSpan={1}>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по сетевому адресу"
                      variant="outlined"
                      onChange={(event) => setHost(event.target.value)}
                    />
                  </TableCell>
                  <TableCell colSpan={1}>{/*padding*/}</TableCell>
                  <TableCell colSpan={1}>{/*padding*/}</TableCell>
                  <TableCell colSpan={2}>
                    <Autocomplete
                      sx={{
                        marginLeft: '-16px'
                      }}
                      id="combo-box-status"
                      options={statuses}
                      disableClearable
                      disableListWrap
                      defaultValue={statuses[0]}
                      getOptionLabel={(item) => item.name}
                      onChange={(event, value) => setStatus(value.code)}
                      renderInput={(params) => (
                        <TextField {...params} label="Статусы" />
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.slice(0, size).map((device) => (
                  <TableRow
                    hover
                    key={device.id}
                    selected={selectedDeviceIds.indexOf(device.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedDeviceIds.indexOf(device.id) !== -1}
                        onChange={(event) => handleSelectOne(event, device.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        title={device.address}
                        onClick={() =>
                          copyToClipboard(device.address, () =>
                            dispatch(
                              showMessage({
                                open: true,
                                text: 'Адрес скопирован в буфер обмена',
                                severity: 'success'
                              })
                            ))}
                      >
                        {shortAddress(device.address) + ', ' + device.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          flexDirection: 'column',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          onClick={() =>
                            copyToClipboard(device.macAddress, () =>
                              dispatch(
                                showMessage({
                                  open: true,
                                  text: 'MAC адрес скопирован в буфер обмена',
                                  severity: 'success'
                                })
                              ))}
                        >
                          {device.macAddress || 'Нет хоста'}
                        </Typography>
                        <Typography color="textPrimary" variant="caption">
                          {device.deviceModel || ''}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        onClick={() =>
                          copyToClipboard(device.host, () =>
                            dispatch(
                              showMessage({
                                open: true,
                                text: 'Хост скопирован в буфер обмена',
                                severity: 'success'
                              })
                            ))}
                      >
                        {device.host || 'Нет хоста'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        {moment(device.created).format('D MMMM YYYY') ||
                          '01.01.2000'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        style={{ whiteSpace: '' }}
                        title={moment(device.created).format(
                          'dddd, DD MMMM, HH:mm'
                        )}
                      >
                        {moment(device.updated).format('DD MMMM, HH:mm') ||
                          '01.01.2000'}
                      </Typography>
                    </TableCell>
                    <TableCell padding="none">
                      <Box
                        sx={{
                          flexDirection: 'column',
                          display: 'flex'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex'
                          }}
                        >
                          {!isArchive &&
                            (device?.status.code === 'CONFIGURED' ? (
                              <span>
                                <Button
                                  color={
                                    connectionColors[
                                      device?.connectionStatus?.code
                                    ] || 'purple'
                                  }
                                  size="small"
                                  variant="contained"
                                  style={{
                                    minWidth: '40px',
                                    maxWidth: '40px',
                                    maxHeight: '22px',
                                    textTransform: 'none'
                                  }}
                                  onClick={(event) =>
                                    onOpenDeviceClick(device, 'show', 'lan')}
                                >
                                  {
                                    connectionTexts[
                                      device.connectionStatus.code
                                    ]
                                  }
                                </Button>
                                <Button
                                  color={
                                    sipColors[device?.sipStatus?.code] ||
                                    'purple'
                                  }
                                  size="small"
                                  variant="contained"
                                  style={{
                                    minWidth: '40px',
                                    maxWidth: '40px',
                                    maxHeight: '22px',
                                    textTransform: 'none',
                                    marginLeft: '6px'
                                  }}
                                  onClick={(event) =>
                                    onOpenDeviceClick(device, 'show', 'sip')}
                                >
                                  sip
                                </Button>
                              </span>
                            ) : (
                              <span>
                                <Button
                                  color={
                                    connectionColors[
                                      device?.connectionStatus?.code
                                    ] || 'purple'
                                  }
                                  size="small"
                                  variant="contained"
                                  style={{
                                    minWidth: '40px',
                                    maxWidth: '40px',
                                    maxHeight: '22px',
                                    textTransform: 'none'
                                  }}
                                  onClick={(event) =>
                                    onOpenDeviceClick(device, 'add', 'host')}
                                >
                                  {
                                    connectionTexts[
                                      device.connectionStatus.code
                                    ]
                                  }
                                </Button>
                                <Button
                                  color={
                                    sipColors[device?.sipStatus?.code] ||
                                    'purple'
                                  }
                                  size="small"
                                  variant="contained"
                                  style={{
                                    minWidth: '40px',
                                    maxWidth: '40px',
                                    maxHeight: '22px',
                                    textTransform: 'none',
                                    marginLeft: '6px'
                                  }}
                                  onClick={(event) =>
                                    onOpenDeviceClick(device, 'add', 'host')}
                                >
                                  sip
                                </Button>
                              </span>
                            ))}
                        </Box>
                        {device?.status.code === 'CONFIGURED' ? (
                          <Button
                            color={
                              statusColors[device?.status.code] || 'purple'
                            }
                            size="small"
                            variant="contained"
                            style={{
                              minWidth: '86px',
                              maxWidth: '86px',
                              maxHeight: '22px',
                              textTransform: 'none',
                              marginTop: '6px'
                            }}
                            onClick={(event) =>
                              onOpenDeviceClick(device, 'show', 'review')}
                          >
                            {statusTexts[device?.status?.code]}
                          </Button>
                        ) : (
                          <Button
                            color={
                              statusColors[device?.status.code] || 'purple'
                            }
                            size="small"
                            variant="contained"
                            style={{
                              minWidth: '86px',
                              maxWidth: '86px',
                              maxHeight: '22px',
                              textTransform: 'none',
                              marginTop: '6px'
                            }}
                            onClick={(event) =>
                              onOpenDeviceClick(device, 'add', 'host')}
                          >
                            {statusTexts[device?.status?.code]}
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell size="small">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Tooltip
                          title={editPermission ? 'Редактировать' : 'Смотреть'}
                          placement="top"
                          followCursor
                          enterDelay={1000}
                        >
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            onClick={(event) =>
                              onOpenDeviceClick(
                                device,
                                device?.status.code === 'CONFIGURED'
                                  ? 'edit'
                                  : 'add',
                                device?.status.code === 'CONFIGURED'
                                  ? 'review'
                                  : 'host'
                              )}
                          >
                            <SvgIcon fontSize="medium" color="primary">
                              {editPermission && !isArchive ? (
                                <Edit />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                        {editPermission && (
                          <Tooltip
                            title={!isArchive ? 'Удалить' : 'Восстановить'}
                            placement="top"
                            followCursor
                            enterDelay={1000}
                          >
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              onClick={() => onDeleteDeviceClick(device)}
                            >
                              <SvgIcon
                                fontSize="medium"
                                color="secondary"
                                /* sx={{ color: '#ef6c00' }} */
                              >
                                {device.status.code !== 'DELETED' ? (
                                  <Delete />
                                ) : (
                                  <Unarchive />
                                )}
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={devicesTotal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page > 0 && devicesTotal < size ? 0 : page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      {editPermission && (
        <DevicesMulti
          devicesActive={
            devices.filter((item) => selectedDeviceIds.includes(item.id)).length
          }
          devicesConfigured={
            devices.filter(
              (item) =>
                selectedDeviceIds.includes(item.id) &&
                item.status.code === 'CONFIGURED'
            ).length
          }
          devicesRestoreClick={() => onDevicesRestoreClick()}
          devicesDeleteClick={() => onDevicesDeleteClick()}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <DevicesConfirm
          showDialog={confirmDialog}
          counter={
            devices.filter((item) => selectedDeviceIds.includes(item.id)).length
          }
          handleConfirm={() => onDevicesConfirmClick()}
          handleClose={() => setConfirmDialog(false)}
        />
      )}
    </>
  );
};

DevicesList.propTypes = {
  isArchive: PropTypes.bool
};

DevicesList.defaultProps = {
  isArchive: false
};

export default DevicesList;
