import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import 'moment/locale/ru';
import { useCallback, useEffect, useState } from 'react';
import store from '../../../../store/store';
import { getSnapshot, keepOpenDoor, openDoor } from '../../../../store/actions/devices.actions';
import { showMessage } from '../../../../store/actions/messages.actions';

const DeviceCamera = ({ isReadonly, isSingle, device, ...props }) => {
  const dispatch = useDispatch();
  const { action1, actionId1 } = useParams();

  const [showSteam, setShowSteam] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [streamImageLoaded, setStreamImageLoaded] = useState('');
  // const [streamImageHidden, setStreamImageHidden] = useState('');

  useEffect(() => {
    const getData = async () => {
      const { data } = await store.dispatch(getSnapshot(actionId1));
      const encodedImage = URL.createObjectURL(data);
      setStreamImageLoaded(encodedImage);
    }
    let interval;
    let unmounted = false;
    if (showSteam && !unmounted) {
      getData();
      // setStreamImageHidden(`http://${device.host || '10.10.0.9'}:${device.port || '80'}/cgi-bin/images_cgi?channel=0&${Math.random()}&user=${device.username || 'admin'}&pwd=${device.password || 'admin'}`);
      interval = setInterval(() => {
        getData();
      }, 1000);
    }
    return () => {
      clearInterval(interval);
      unmounted = true;
    };
  }, [showSteam, device]);

  useEffect(() => {
    if (device?.keepDoorOpenEnabled) {
      setIsBlocked(device.keepDoorOpenEnabled);
    }
  }, [device?.keepDoorOpenEnabled]);

  const onOpenDoorClick = useCallback(async () => {
    try {
      await store.dispatch(openDoor(actionId1, { value: true }));
      setIsOpened(true);
      setTimeout(() => setIsOpened(false), 3000);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось открыть дверь', severity: 'error' }));
    }
  }, [actionId1]);

  const onLookClick = useCallback(() => {
    setShowSteam((value) => !value);
  }, []);

  const onBlockDoorClick = useCallback(async () => {
    try {
      await store.dispatch(keepOpenDoor(actionId1, { value: !isBlocked }));
      setIsBlocked((value) => !value);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось открыть дверь', severity: 'error' }));
    }
  }, [actionId1, isBlocked]);

  const onLoad = useCallback((event) => {
    setStreamImageLoaded(event.target.src);
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={6}
    >
      {/*<Typography
              mt={-1}
              ml={1}
              color="textSecondary"
              variant="body1"
            >
              <h3>Домофон</h3>
            </Typography>*/}
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: 'space-between', marginTop: '-2px', pl: 0 }}
      >
        <Grid item>
          <Grid
            container
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item>
              <Button
                color="primary"
                fullWidth
                size="small"
                variant="contained"
                onClick={onOpenDoorClick}
                disabled={isOpened}
              >
                Открыть
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="green"
                fullWidth
                size="small"
                variant="contained"
                onClick={onLookClick}
              >
                {showSteam ? 'Остановить' : 'Смотреть'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {(isSingle === false) && (
          <Grid item>
            <Button
              color={isBlocked ? 'red' : 'orange'}
              fullWidth
              size="small"
              variant="contained"
              onClick={onBlockDoorClick}
            >
              {isBlocked ? 'Закрыть дверь' : 'Держать открытой'}
            </Button>
          </Grid>
        )}
      </Grid>
      <img
        alt="intercom preview"
        src={(showSteam && streamImageLoaded) || '/static/images/table.jpg'}
        style={{
          marginTop: 20,
          display: 'inline-block',
          maxWidth: '100%',
          width: '100%'
        }}
      />
      {/*<img
        alt="intercom preview"
        src={(showSteam && streamImageHidden) || '/static/images/table.jpg'}
        onLoad={onLoad}
        style={{
          marginTop: 20,
          display: 'none',
          maxWidth: '100%',
          width: '100%'
        }}
      />*/}
    </Grid>
  );
};

DeviceCamera.propTypes = {
  isReadonly: PropTypes.bool,
  isSingle: PropTypes.bool,
  device: PropTypes.object,
};

DeviceCamera.defaultProps = {
  isReadonly: false,
  isSingle: false,
  device: null,
}

export default DeviceCamera;
