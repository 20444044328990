import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Step,
  StepButton,
  Stepper
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/ru';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import TabPanelWizard from '../../common/TabPanelWizard';
import DeviceSummary from './common/DeviceSummary';
import DeviceSip from './steps/DeviceSip';
import DeviceLan from './steps/DeviceLan';
import store from '../../../store/store';
import {
  getDeviceConfiguration,
  getDeviceConfigurationAudio,
  getDeviceConfigurationDate,
  getDeviceConfigurationDetector,
  getDeviceConfigurationDisplay,
  getDeviceConfigurationEmail,
  getDeviceConfigurationRfid,
  getDeviceConfigurationLan,
  getDeviceConfigurationLevels,
  getDeviceConfigurationOverlay,
  getDeviceConfigurationSwitch,
  getDeviceConfigurationSysLog,
  getDeviceConfigurationVideo,
  getDeviceConnection,
  getDeviceSip,
  setDevicesCache,
  getDeviceConfigurationMifare,
  getDeviceConfigurationUser,
  getDeviceConfigurationSingle,
  getDeviceConfigurationLog
} from '../../../store/actions/devices.actions';
import { deviceDialogStyles, deviceEmptyState } from './common/DeviceDialogObjects';
import DevicePermissions from './steps/DevicePermissions';
import { deviceWritePermission } from '../../../store/selectors/account.selector';
import DeviceAudio from './steps/DeviceAudio';
import DeviceVideo from './steps/DeviceVideo';
import DeviceEmail from './steps/DeviceEmail';
import DeviceDetector from './steps/DeviceDetector';
import DeviceOverlay from './steps/DeviceOverlay';
import DevicesDisplay from './steps/DeviceDisplay';
import DevicesDateTime from './steps/DeviceDateTime';
import DevicesSysLog from './steps/DeviceSysLog';
import { showServerErrorMessage } from '../../../store/actions/messages.actions';
import DeviceLevel from './steps/DeviceLevel';
import DeviceAddress from './steps/DeviceAddress';
import DeviceSwitch from './steps/DeviceSwitch';
import DeviceKeys from './steps/DeviceKeys';
import DeviceLog from './steps/DeviceLog';

moment.locale('ru');

const useStyles = makeStyles(() => createStyles(deviceDialogStyles));

const titles = ['устройство', 'настройки адреса', 'настройки SIP', 'настройки ключей', 'настройки LAN', 'настройки Email', 'настройки детектора движения', 'настройки текста', 'настройки дисплея', 'настройки даты и времени', 'настройки SysLog', 'настройки доступа', 'настройки аудио', 'настройки видео', 'настройки уровней', 'настройки ККМ', 'журнал событий'];
const steps = ['Инфо', 'Адрес', 'SIP', 'RFID', 'Mifare', 'LAN', 'Email', 'Детектор', 'Текст', 'Дисплей', 'Дата', 'SysLog', 'Права', 'Аудио', 'Видео', 'Уровни', 'ККМ', 'Журнал'];
const stepsName = ['review', 'address', 'sip', 'rfid', 'mifare', 'lan', 'email', 'detector', 'overlay', 'display', 'date', 'syslog', 'permissions', 'audio', 'video', 'levels', 'kkm', 'log'];

const DeviceShowDialog = ({ isArchive, handleClose, ...props }) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { action1, actionId1, stepName } = useParams();

  const keyTimeout = useRef(0);
  const keyCodeLast = useRef(0);
  const keyCodeLastTime = useRef(0);
  const currentStep = useRef(0);
  const step = useRef(stepName);

  const editPermission = useSelector(deviceWritePermission);

  const [isSingle, setIsSingle] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState({ ...deviceEmptyState });
  const [device, setDevice] = useState({ ...deviceEmptyState });
  const [showDialog, setEditDialog] = useState(false);
  const [showDialogTitle, setEditDialogTitle] = useState('');

  const devicesCache = useSelector((state) => state.devices.cache);
  const devices = useSelector((state) => state.devices.list);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [empty, setEmpty] = useState({});

  const totalSteps = () => (steps.length);

  const updateStep = (value) => {
    currentStep.current = value;
    updateActiveButton(value);
    clearTimeout(keyTimeout.current);
    keyTimeout.current = setTimeout(() => {
      setActiveStep(currentStep.current);
      navigateStep(stepsName[currentStep.current]);
    }, 500);
  };

  const updateActiveButton = (index) => {
    const activeElement = document.querySelector('.MuiStepIcon-root.Mui-active');
    const iconElement = document.querySelectorAll('.MuiStepIcon-root')[index];
    if (activeElement) {
      activeElement.classList.remove('Mui-active');
    }
    if (iconElement) {
      iconElement.classList.add('Mui-active');
    }
  };

  const navigateStep = (name) => {
    // console.log(stepName, name, location.pathname.replace(stepName, '') + name);
    navigate(location.pathname.replace(stepName, '') + name, { replace: true });
  };

  const handleStep = (value) => () => {
    currentStep.current = value;
    step.current = value;
    setActiveStep(value);
    navigateStep(stepsName[value]);
  };

  const handleReset = () => {
    if (activeStep !== 0) {
      currentStep.current = 0;
      setActiveStep(0);
      navigateStep(stepsName[0]);
    }
    setCompleted({});
    setEmpty({});
  };

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action1, actionId1, device.intercoms[0], devicesCache);
    const deviceFromList = actionId1 && (devices.find((item) => item.id === actionId1));
    if (action1 === 'show') {
      setEditDialog(true);
      if (action1 === 'show') {
        setEditDialogTitle('Карточка устройства ' + (device.macAddress || deviceFromList?.macAddress || ''));
      }
    } else if (showDialog) {
      setEditDialog(false);
      setTimeout(() => {
        if (!unmounted && device.id) {
          handleReset();
          if (deviceDetails.address) {
            setDeviceDetails({ ...deviceEmptyState });
          }
          if (devicesCache.device && (devicesCache.device.id || devicesCache.device.address)) {
            // clear device cache
            setDeviceDetails({ ...deviceEmptyState });
            dispatch(setDevicesCache(deviceEmptyState));
          }
        }
      }, 200);
    }
    if ((action1 === 'show') && actionId1) {
      // console.log(device)
      setDevice({
        ...(devicesCache[actionId1] || deviceEmptyState),
        ...deviceFromList,
        ...(deviceDetails),
      });
    }
    // register key listener
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      unmounted = true;
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [action1, actionId1, devices, deviceDetails]);

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode, altKey } = event;
    if (keyCode === 33) {
      if (currentStep.current > 0) {
        if (empty[currentStep.current - 1] && empty[currentStep.current - 2]) {
          updateStep(currentStep.current - 3);
        } else if (empty[currentStep.current - 1]) {
          updateStep(currentStep.current - 2);
        } else {
          updateStep(currentStep.current - 1);
        }
      }
    } else if (keyCode === 34) {
      if (currentStep.current < totalSteps() - 1) {
        if (empty[currentStep.current + 1] && empty[currentStep.current + 2]) {
          updateStep(currentStep.current + 3);
        } else if (empty[currentStep.current + 1]) {
          updateStep(currentStep.current + 2);
        } else {
          updateStep(currentStep.current + 1);
        }
      }
    } else if (altKey && (keyCode >= 48 && keyCode <= 58)) {
      if (keyCodeLast.current === 49 && Date.now() - keyCodeLastTime.current < 500) {
        if (currentStep.current !== keyCode - 49 + 10 && keyCode - 49 + 10 < totalSteps()) {
          if (!empty[keyCode - 49 + 10]) {
            updateStep(keyCode - 49 + 10);
          }
        }
      } else if (currentStep.current !== keyCode - 49) {
        if (!empty[keyCode - 49]) {
          updateStep(keyCode - 49);
        }
      }
      keyCodeLastTime.current = Date.now();
      keyCodeLast.current = keyCode;
    }
  }, [setActiveStep, handleStep]);

  // device details effect
  useEffect(() => {
    let unmounted = !location.pathname.includes('/devices/intercoms/show');
    const getData = async () => {
      try {
        step.current = stepName;
        // dispatch(setDevicesCache(data));
        // console.log(1111, step, device)
        if (isSingle === null) {
          const { data: single } = await store.dispatch(getDeviceConfigurationSingle(actionId1));
          setIsSingle(single)
          if (single) {
            setEmpty({ 0: false, 1: false, 2: false, 3: true, 4: true, 5: false, 6: false, 7: false, 8: false, 9: true, 10: false, 11: false, 12: false, 13: false, 14: false, 15: true, 16: true, 17: false });
          } else {
            setEmpty({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false, 12: false, 13: false, 14: false, 15: false, 16: false, 17: false });
          }
        }
        setCompleted({ 0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true, 9: true, 10: true, 11: true, 12: true, 13: true, 14: true, 15: true, 16: true, 17: true });
        if (step.current === 'review') {
          const { data } = await store.dispatch(getDeviceConfiguration(actionId1));
          setDeviceDetails({ ...deviceDetails, ...data });
        } else if (step.current === 'address') {
          const { data } = await store.dispatch(getDeviceConnection(actionId1));
          setDeviceDetails({ ...deviceDetails, ...data });
        } else if (step.current === 'sip') {
          const { data } = await store.dispatch(getDeviceSip(actionId1));
          setDeviceDetails({ ...deviceDetails, sip: { ...data } });
        } else if (step.current === 'rfid') {
          const { data } = await store.dispatch(getDeviceConfigurationRfid(actionId1));
          setDeviceDetails({ ...deviceDetails, rfid: { ...data } });
        } else if (step.current === 'lan') {
          const { data } = await store.dispatch(getDeviceConfigurationLan(actionId1));
          setDeviceDetails({ ...deviceDetails, lan: { ...data } });
        } else if (step.current === 'email') {
          const { data } = await store.dispatch(getDeviceConfigurationEmail(actionId1));
          setDeviceDetails({ ...deviceDetails, email: { ...data } });
        } else if (step.current === 'audio') {
          const { data } = await store.dispatch(getDeviceConfigurationAudio(actionId1));
          setDeviceDetails({ ...deviceDetails, audio: { ...data } });
        } else if (step.current === 'video') {
          const { data } = await store.dispatch(getDeviceConfigurationVideo(actionId1));
          setDeviceDetails({ ...deviceDetails, video: { ...data } });
        } else if (step.current === 'display') {
          const { data } = await store.dispatch(getDeviceConfigurationDisplay(actionId1));
          setDeviceDetails({ ...deviceDetails, display: { ...data } });
        } else if (step.current === 'date') {
          const { data } = await store.dispatch(getDeviceConfigurationDate(actionId1));
          setDeviceDetails({ ...deviceDetails, dateTime: { ...data } });
        } else if (step.current === 'overlay') {
          const { data } = await store.dispatch(getDeviceConfigurationOverlay(actionId1));
          setDeviceDetails({ ...deviceDetails, videoTextOverlay: { ...data } });
        } else if (step.current === 'detector') {
          const { data } = await store.dispatch(getDeviceConfigurationDetector(actionId1));
          setDeviceDetails({ ...deviceDetails, motionAlarm: { ...data } });
        } else if (step.current === 'kkm') {
          const { data } = await store.dispatch(getDeviceConfigurationSwitch(actionId1));
          setDeviceDetails({ ...deviceDetails, switch: { ...data } });
        } else if (step.current === 'levels') {
          const { data } = await store.dispatch(getDeviceConfigurationLevels(actionId1));
          setDeviceDetails({ ...deviceDetails, levels: { ...data } });
        } else if (step.current === 'syslog') {
          const { data } = await store.dispatch(getDeviceConfigurationSysLog(actionId1));
          setDeviceDetails({ ...deviceDetails, systemLog: { ...data } });
        } else if (step.current === 'permissions') {
          const { data } = await store.dispatch(getDeviceConfigurationUser(actionId1));
          setDeviceDetails({ ...deviceDetails, users: [...data.users] });
        } else if (step.current === 'log') {
          const { data } = await store.dispatch(getDeviceConfigurationLog(actionId1));
          setDeviceDetails({ ...deviceDetails, log: data });
        }
      } catch (error) {
        dispatch(showServerErrorMessage(error));
        console.log(error.message);
      }
    }
    if (!unmounted && actionId1 && actionId1 !== 'device') {
      getData();
    }
    if (stepsName[activeStep] !== stepName) {
      const stepIndex = stepsName.indexOf(stepName);
      if (stepIndex !== -1) {
        currentStep.current = stepIndex;
        setActiveStep(stepIndex);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, stepName]);

  const onUpdateLog = useCallback(async () => {
    const { data } = await store.dispatch(getDeviceConfigurationLog(actionId1));
    setDeviceDetails({ ...deviceDetails, log: data });
  }, []);

  const onUpdate = useCallback((data) => {
    // nothing here
  }, []);

  const onClose = async (reason) => {
    handleClose();
  }

  return (
    <Dialog
      {...props}
      open={showDialog}
      onClose={(e, reason) => onClose(reason)}
      fullWidth
      disableRestoreFocus
      disableEscapeKeyDown
      scroll="body"
      PaperProps={{ sx: { maxWidth: '1024px' } }}
    >
      {/* <DialogTitle sx={{ paddingBottom: '8px' }}>
        <Box sx={{ fontSize: '22px' }}>
          {showDialogTitle}
        </Box>
      </DialogTitle>*/}
      <DialogContent className={classes.content}>
        {/*<PerfectScrollbar className={classes.scroll_long} options={{ scrollXMarginOffset: 5, suppressScrollY: true }}>*/}
        <Stepper
          orientation="vertical"
          className={classes.stepperBig}
          nonLinear
          activeStep={activeStep}
          sx={{
          }}
        >
          {steps.map((label, index) => (
            <Step key={label} completed>
              <StepButton
                className={(isSingle !== null && empty[index]) ? 'empty' : ''}
                title={'Alt-' + (index + 1)}
                color="inherit"
                onClick={handleStep(index)}
                disabled={(index > 0 && !completed[0]) || (empty[index])}
                sx={{
                  '& .MuiStepIcon-root.Mui-completed:not(.Mui-active)': {
                    color: empty[index] ? '#c4c4c4' : 'green.main',
                  },
                  '& .MuiStepLabel-label': {
                    marginTop: '3px'
                  },
                  '& .MuiStepLabel-label.Mui-completed:not(.Mui-active)': {
                    fontWeight: '400'
                  }
                }}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {/*</PerfectScrollbar>*/}
        <Box className={classes.container}>
          <SwipeableViews className={classes.view} index={activeStep} disabled>
            <TabPanelWizard value={activeStep} index={0} className={classes.panel}>
              <DeviceSummary device={device} isReadonly isSingle={isSingle} onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={1} className={classes.panel}>
              <DeviceAddress device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={2} className={classes.panel}>
              <DeviceSip device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={3} className={classes.panel}>
              <DeviceKeys device={device} isReadonly onUpdate={onUpdate} tagType="rfid" />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={4} className={classes.panel}>
              <DeviceKeys device={device} isReadonly onUpdate={onUpdate} tagType="mifare" />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={5} className={classes.panel}>
              <DeviceLan device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={6} className={classes.panel}>
              <DeviceEmail device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={7} className={classes.panel}>
              <DeviceDetector device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={8} className={classes.panel}>
              <DeviceOverlay device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={9} className={classes.panel}>
              <DevicesDisplay device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={10} className={classes.panel}>
              <DevicesDateTime device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={11} className={classes.panel}>
              <DevicesSysLog device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={12} className={classes.panel}>
              <DevicePermissions device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={13} className={classes.panel}>
              <DeviceAudio device={device} isReadonly isSingle={isSingle} onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={14} className={classes.panel}>
              <DeviceVideo device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={15} className={classes.panel}>
              <DeviceLevel device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={16} className={classes.panel}>
              <DeviceSwitch device={device} isReadonly onUpdate={onUpdate} />
            </TabPanelWizard>
            <TabPanelWizard value={activeStep} index={17} className={classes.panel}>
              <DeviceLog device={device} isReadonly={isArchive} onUpdate={onUpdate} />
            </TabPanelWizard>
          </SwipeableViews>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 3,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            {editPermission && !isArchive && action1 === 'show' && stepName !== 'log' && (
              <Grid
                item
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate(location.pathname.replace('show', 'edit'), { replace: true });
                  }}
                >
                  Редактировать
                </Button>
              </Grid>
            )}
            {stepName === 'log' && (
              <Grid
                item
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onUpdateLog}
                >
                  Обновить
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DeviceShowDialog.propTypes = {
  isArchive: PropTypes.bool,
  handleClose: PropTypes.func,
};

DeviceShowDialog.defaultProps = {
  isArchive: false,
  handleClose: null,
}

export default memo(DeviceShowDialog);
