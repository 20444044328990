import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { placeWritePermission } from '../../../store/selectors/account.selector';
import decOfNum from '../../../utils/decOfNum';

const ArchiveTerritoriesToolbar = ({ territoriesFromArchive, territoriesFromArchiveClick, ...props }) => {
  const editPermission = useSelector(placeWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ ml: 1 }}>
          Экспорт
        </Button>*/}
        {editPermission && !!territoriesFromArchive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => territoriesFromArchiveClick()}
          >
            Восстановить из архива&nbsp;
            { territoriesFromArchive }
            &nbsp;
            { decOfNum(territoriesFromArchive, ['территорию', 'территории', 'территорий']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

ArchiveTerritoriesToolbar.propTypes = {
  territoriesFromArchive: PropTypes.number,
  territoriesFromArchiveClick: PropTypes.func,
};

ArchiveTerritoriesToolbar.defaultProps = {
  territoriesFromArchive: 0,
  territoriesFromArchiveClick: null,
};

export default ArchiveTerritoriesToolbar;
