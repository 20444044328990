import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Paper,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import store from '../../../../store/store';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';
import { showMessage } from '../../../../store/actions/messages.actions';
import DeviceKeysMulti from './dialogs/DeviceKeysMulti';
import DeviceKeysConfirm from './dialogs/DeviceKeysConfirm';
import DeviceKeysToolbar from './dialogs/DeviceKeysToolbar';
import {
  autoCollect,
  autoCollectMifare,
  deleteKey,
  deleteMifareKeyV2
} from '../../../../store/actions/devices.actions';
import DeviceKeysEditor from './dialogs/DeviceKeysEditor';
import getInitials from '../../../../utils/getInitials';

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getTagType(device, selectedTab) {
  if (device.mifareSupported === false) return 'rfid';
  return selectedTab === 0 ? 'mifare' : 'extrfid';
}

const DeviceKeys = ({ tagType, isReadonly, device, onUpdate, updateKeys, updateExtKeys, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action1, actionId1, stepName, action2, actionId2 } = useParams();
  const [rfidTab, setRfidTab] = useState(0);
  const deviceKeys = useMemo(() => (device.mifareSupported === true && rfidTab === 0 ? device.mifare : device.rfid), [device, rfidTab]);

  const editPermission = useSelector(deviceWritePermission);

  const [keyFilter, setKeyFilter] = useState('');
  const [ownerFilter, setOwnerFilter] = useState('');
  const [flatFilter, setFlatFilter] = useState('');
  const [filteredKeys, setFilteredKeys] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isAutoCollect, setIsAutoCollect] = useState(deviceKeys?.autoCollect);
  const [isMifareAutoCollect, setIsMifareAutoCollect] = useState(deviceKeys?.mifareAutoCollect);

  const [selectedKeyIds, setSelectedKeyIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);

  const isRfidTab = device.mifareSupported === false;
  const isMifareTab = device.mifareSupported === true && rfidTab === 0;
  const isExtRfidTab = device.mifareSupported === true && rfidTab === 1;

  const handleRfidTabChange = (event, newValue) => {
    setRfidTab(newValue);
  };

  useEffect(() => {
    try {
      let filtered = [...deviceKeys?.keys || []];
      if (keyFilter) {
        const keyFilterLower = keyFilter.toLowerCase();
        filtered = filtered.filter((key) => key.key.toLowerCase().includes(keyFilterLower));
      }
      if (ownerFilter) {
        filtered = filtered.filter((key) => key.owner?.includes(ownerFilter));
      }
      if (flatFilter) {
        filtered = filtered.filter((key) => String(key.flatNumber).includes(flatFilter));
      }
      if (page && filtered.length <= size) {
        setPage(0);
      }
      setFilteredKeys(filtered);
      setIsAutoCollect(deviceKeys?.autoCollect);
      setIsMifareAutoCollect(deviceKeys?.mifareAutoCollect);
    } catch (error) {
      console.log(error);
    }
  }, [page, size, deviceKeys?.keys, deviceKeys?.autoCollect, deviceKeys?.mifareAutoCollect, keyFilter, ownerFilter, flatFilter]);

  const handleSelectAll = (event) => {
    let newSelectedKeyIds;

    if (filteredKeys.slice(page * size, page * size + size).some((item) => !selectedKeyIds.includes(item.key))) {
      newSelectedKeyIds = filteredKeys.slice(page * size, page * size + size).map((key) => key.key);
    } else {
      newSelectedKeyIds = [];
    }

    setSelectedKeyIds(newSelectedKeyIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedKeyIds.indexOf(id);
    let newSelectedKeyIds = [];

    if (selectedIndex === -1) {
      newSelectedKeyIds = newSelectedKeyIds.concat(selectedKeyIds, id);
    } else if (selectedIndex === 0) {
      newSelectedKeyIds = newSelectedKeyIds.concat(selectedKeyIds.slice(1));
    } else if (selectedIndex === selectedKeyIds.length - 1) {
      newSelectedKeyIds = newSelectedKeyIds.concat(selectedKeyIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedKeyIds = newSelectedKeyIds.concat(
        selectedKeyIds.slice(0, selectedIndex),
        selectedKeyIds.slice(selectedIndex + 1)
      );
    }

    setSelectedKeyIds(newSelectedKeyIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const editKey = (key) => {
    if (!isReadonly && editPermission && !isExtRfidTab) {
      navigate(`edit/${key.key}`);
    } else {
      navigate(`show/${key.key}`);
    }
  };

  const onDeleteKeyClick = (key) => {
    navigate(`delete/${key.key}`);
  };

  const updateKeysStatus = async (filterFunc, updateAction, successMessage, errorMessage) => {
    try {
      const toUpdate = deviceKeys?.keys?.filter((item) => selectedKeyIds.includes(item.key)).filter(filterFunc);
      // console.log(toUpdate)
      await Promise.all(toUpdate.map((item) => store.dispatch(updateAction(actionId1, item.key, item.type))));
      dispatch(showMessage({ open: true, text: successMessage, severity: 'success' }));
      setTimeout(() => updateKeys(), 500);
      setSelectedKeyIds([]);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: errorMessage + ' ' + error.message, severity: 'error' }));
    }
  };

  const onKeysDeleteClick = () => {
    setConfirmDialog(true);
  };

  const onKeysConfirmClick = () => {
    if (getTagType(device, rfidTab) === 'mifare') {
      updateKeysStatus((item) => (item), deleteMifareKeyV2, 'Ключи успешно удалены.', 'Не удалось удалить.');
    } else {
      updateKeysStatus((item) => (item), deleteKey, 'Ключи успешно удалены.', 'Не удалось удалить.');
    }
    setConfirmDialog(false);
  };

  const onAutoCollectClick = useCallback(async () => {
    try {
      await store.dispatch(autoCollect(actionId1, { id: actionId1, active: !isAutoCollect }));
      setIsAutoCollect((value) => !value);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось включить автосбор', severity: 'error' }));
    }
  }, [actionId1, isAutoCollect]);

  const onMifareAutoCollectClick = useCallback(async () => {
    try {
      await store.dispatch(autoCollectMifare(actionId1, { id: actionId1, active: !isMifareAutoCollect }));
      setIsMifareAutoCollect((value) => !value);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось включить автосбор', severity: 'error' }));
    }
  }, [actionId1, isMifareAutoCollect]);

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={
        Yup.object().shape({
          dhcp: Yup.boolean(),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {getTagType(device, rfidTab).toUpperCase() + ' ключи устройства ' + (device.macAddress || '')}
          </Typography>

          {device.mifareSupported ?
            <Paper
              sx={{
              backgroundColor: 'white',
              minHeight: '100%',
              my: 3
            }}
            >
              <Tabs
                value={rfidTab}
                onChange={handleRfidTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Основные" {...tabProps(0)} />
                <Tab label="Внешние" {...tabProps(1)} />
              </Tabs>
            </Paper> : null
          }
          <DeviceKeysToolbar
            tagType={getTagType(device, rfidTab)}
            keysActive={filteredKeys.filter((item) => selectedKeyIds.includes(item.key)).length}
            keysDeleteClick={() => onKeysDeleteClick()}
            updateKeys={updateKeys}
            updateExtKeys={updateExtKeys}
            isReadonly={isReadonly}
          />

          <PerfectScrollbar>
            <Box sx={{ minWidth: 550 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* padding */}
                    </TableCell>
                    <TableCell>
                      Ключ
                    </TableCell>
                    {tagType === 'mifare' && (
                      <TableCell style={{ width: 250 }}>
                        Владелец
                      </TableCell>
                    )}
                    <TableCell style={{ width: 130 }}>
                      Квартира
                    </TableCell>
                    <TableCell style={{ width: 120 }}>
                      {/* Действия */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedKeyIds.length > 0 && selectedKeyIds.length === filteredKeys.length}
                        color="primary"
                        disabled={isReadonly || !editPermission || isExtRfidTab}
                        indeterminate={
                          selectedKeyIds.length > 0
                          && filteredKeys.slice(page * size, page * size + size).some((item) => selectedKeyIds.includes(item.key))
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        placeholder="Фильтр по ключу"
                        variant="outlined"
                        onChange={(event) => setKeyFilter(event.target.value)}
                      />
                    </TableCell>
                    {tagType === 'mifare' && (
                      <TableCell>
                        <TextField
                          sx={{ ml: -1 }}
                          placeholder="Фильтр по владельцу"
                          variant="outlined"
                          onChange={(event) => setOwnerFilter(event.target.value)}
                        />
                      </TableCell>
                    )}
                    <TableCell colSpan={2}>
                      <TextField
                        sx={{ ml: -1 }}
                        placeholder="Фильтр по квартире"
                        variant="outlined"
                        onChange={(event) => setFlatFilter(event.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredKeys.slice(page * size, page * size + size).map((key) => (
                    <TableRow
                      hover
                      key={key.key}
                      selected={selectedKeyIds.indexOf(key.key) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={isReadonly || !editPermission || isExtRfidTab}
                          checked={selectedKeyIds.indexOf(key.key) !== -1}
                          onChange={(event) => handleSelectOne(event, key.key)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          {isRfidTab || isExtRfidTab ? (
                            <Avatar
                              sx={{ mr: 1, width: 48, height: 26, bgcolor: '#ef6c00' }}
                            >
                              RFID
                            </Avatar>
                          ) : (
                            <Avatar
                              sx={{ mr: 1, width: 48, height: 26, bgcolor: key.type === 'Сервисный' ? '#8332b6' : '#377d3a' }}
                            >
                              {getInitials(key.tagType)}
                            </Avatar>
                          )}
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {key.key}
                          </Typography>
                        </Box>
                      </TableCell>
                      {tagType === 'mifare' && (
                        <TableCell>
                          {key.owner || 'неизвестен'}
                        </TableCell>
                      )}
                      <TableCell>
                        {key.flatNumber}
                      </TableCell>
                      <TableCell size="small">
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Tooltip title={!isReadonly && editPermission && !isExtRfidTab ? 'Редактировать' : 'Смотреть'} placement="top" followCursor enterDelay={1000}>
                            <IconButton sx={{ py: 0 }} aria-label="edit" color="primary" onClick={() => editKey(key)}>
                              <SvgIcon
                                fontSize="medium"
                                color="primary"
                              >
                                {!isReadonly && editPermission && !isExtRfidTab ? <Edit /> : <VisibilityOutlined />}
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                          {!isReadonly && editPermission && !isExtRfidTab && (
                            <Tooltip title="Удалить" placement="top" followCursor enterDelay={1000}>
                              <IconButton sx={{ py: 0 }} aria-label="delete" color="secondary" onClick={() => onDeleteKeyClick(key)}>
                                <SvgIcon
                                  fontSize="medium"
                                  color="secondary"
                                >
                                  <Delete />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={filteredKeys.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={size}
                rowsPerPageOptions={[5, 10, 100, 1000]}
              />
            </Box>
          </PerfectScrollbar>

          {!isReadonly && editPermission && !isExtRfidTab && (
            <DeviceKeysMulti
              keysActive={filteredKeys.filter((item) => selectedKeyIds.includes(item.key)).length}
              keysDeleteClick={() => onKeysDeleteClick()}
              showAutoCollect={!isExtRfidTab}
              onAutoCollectClick={isMifareTab ? onMifareAutoCollectClick : onAutoCollectClick}
              isAutoCollect={isMifareTab ? isMifareAutoCollect : isAutoCollect}
              tagType={getTagType(device, rfidTab)}
            />
          )}

          {!isReadonly && editPermission && !isExtRfidTab && (
            <DeviceKeysConfirm
              showDialog={confirmDialog}
              counter={filteredKeys.filter((item) => selectedKeyIds.includes(item.key)).length}
              handleConfirm={() => onKeysConfirmClick()}
              handleClose={() => setConfirmDialog(false)}
            />
          )}

          <DeviceKeysEditor
            tagType={getTagType(device, rfidTab)}
            ciphers={deviceKeys?.ciphers}
            keys={deviceKeys?.keys}
            handleClose={() => setConfirmDialog(false)}
            updateKeys={updateKeys}
            updateCiphers={updateKeys}
          />
        </form>
      )}
    </Formik>
  );
};

DeviceKeys.propTypes = {
  tagType: PropTypes.string,
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  updateKeys: PropTypes.func,
  updateExtKeys: PropTypes.func,
  updateMifareAutoCollect: PropTypes.func
};

DeviceKeys.defaultProps = {
  tagType: '',
  isReadonly: false,
  device: null,
  updateKeys: null,
  updateExtKeys: null
}

export default memo(DeviceKeys, isDevicePropEqual);
