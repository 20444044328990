import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Badge, Box, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';
import { logout } from '../store/actions/account.actions';

const DashboardNavbar = ({ onNavIconClick, isOpen, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [notifications] = useState([]);

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink
          to="/"
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Logo />
            <Typography
              color="white"
              variant="h2"
              sx={{
                ml: 1
              }}
            >
              Терион
            </Typography>
          </Box>
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {/*<Tooltip title="Нотификации" placement="bottom" followCursor enterDelay={1000}>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>*/}
        <Tooltip title="Выйти из аккаунта" placement="bottom" followCursor enterDelay={1000}>
          <IconButton
            color="inherit"
            onClick={() => dispatch(logout(location.pathname))}
          >
            <InputIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={isOpen ? 'Закрыть меню' : 'Раскрыть меню'} placement="bottom" followCursor enterDelay={1000}>
          <IconButton
            sx={{ transition: 'transform 225ms cubic-bezier(0, 0, 0.6, 1)', transform: isOpen ? 'rotate(-90deg)' : 'rotate(0deg)' }}
            color="inherit"
            onClick={onNavIconClick}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onNavIconClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default DashboardNavbar;
