import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { deviceWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const DevicesToolbar = ({
  devicesActive,
  devicesDeleteClick,
  devicesMessageClick,
  ...props
}) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      {...props}
    >
      <Box>
        {editPermission && (
          <Button
            sx={{ mr: 1 }}
            color="orange"
            variant="contained"
            onClick={() => devicesMessageClick()}
          >
            Отправить всем
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ ml: 1 }}>
          Экспорт
        </Button>*/}
        {editPermission && (
          <RouterLink to="/app/devices/intercoms/add/device/address">
            <Button
              sx={{ ml: 1 }}
              variant="contained"
            >
              Добавить новое устройство
            </Button>
          </RouterLink>
        )}
        {editPermission && !!devicesActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => devicesDeleteClick()}
          >
            Удалить&nbsp;
            { devicesActive }
            &nbsp;
            { decOfNum(devicesActive, ['устройство', 'устройства', 'устройств']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

DevicesToolbar.propTypes = {
  devicesActive: PropTypes.number,
  devicesDeleteClick: PropTypes.func,
  devicesMessageClick: PropTypes.func,
};

DevicesToolbar.defaultProps = {
}

export default DevicesToolbar;
