/* eslint-disable import/no-cycle */
import axios from 'axios';
import store from '../store/store';

const http = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/' : 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/',
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : '/api',
  headers: { Accept: 'application/json' }
});

http.interceptors.request.use((res) => {
  // console.log(res, res.url === '/auth/signIn')
  // if (res.url !== '/auth/signIn') {
  //   res.headers.Authorization = 'Token b2474bb66079a1be261575388cddd659588d07c3';
  // }
  const { token } = store.getState().account;
  // console.log(res, res.url === '/auth/signIn')
  if (res.url !== '/auth/signIn' && token) {
    res.headers.Authorization = `Bearer ${token}`;
  }
  return res;
});

http.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    // store.dispatch(expired());
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

// const addressSuggest = (data) => http.post('suggest/address', data);

const addressSuggest = (data) => http.get('fias/autocomplete', { params: data });

export default {
  addressSuggest,
};
