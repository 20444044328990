import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import store from '../../store/store';
import { update } from '../../store/actions/account.actions';
import { showMessage } from '../../store/actions/messages.actions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useQuery().get('token');
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  async function onSubmit(data) {
    try {
      await store.dispatch(update({ ...data, token }));
      setIsError(false);
      // setMessage('Пароль успешно изменён');
      // setOpen(true);
      dispatch(showMessage({ open: true, text: 'Пароль успешно изменён', severity: 'success' }));
    } catch (error) {
      console.log(error.message);
      dispatch(showMessage({ open: true, text: error?.message || 'Не удалось сменить пароль', severity: 'error' }));
    }
  }

  const onCloseDialog = useCallback(async () => {
    if (isError) {
      setOpen(false);
    } else {
      await navigate('/login', { replace: true });
    }
  }, [isError])

  return (
    <>
      <Helmet>
        <title>Обновить пароль | Терион</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8, 'Слишком короткий пароль')
                .max(12, 'Слишком длинный пароль')
                .matches(/^[a-zA-Z0-9]+$/, 'Только цифры и латинские буквы')
                .required('Пароль забыли ввести'),
            })}
            onSubmit={async (data, { setSubmitting }) => {
              await onSubmit(data);
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Обновление пароля
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle1"
                  >
                    Введите новый пароль
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Пароль"
                  margin="normal"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pt: 2
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                  >
                    Изменить
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Dialog
        open={open}
      >
        <DialogTitle>
          { isError ? 'Ошибка' : 'Успешно'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={onCloseDialog}>ОК</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetPassword;
