import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { ArrowBack, ArrowForward, Mic, VolumeDown, VolumeUp } from '@material-ui/icons';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

function simpleValues(values) {
  return Object.fromEntries(Object.entries(values).map((entrie) => {
    if (entrie[0] === 'encodings') {
      return ['encoding', entrie[1]?.find((item) => item.active)?.code || ''];
    }
    if (typeof entrie[1] === 'object' && entrie[1].value) {
      return [entrie[0], entrie[1].value];
    }
    return entrie;
  }));
}

function getMarks(min, max) {
  if (max - min < 30) {
    return Array(max - min + 1).fill(0).map((item, index) => ({ value: index + min, label: index + min }))
  }
  if (max - min < 100) {
    return Array((max - min) / 10).fill(0).map((item, index) => ({ value: index + min, label: index + min }))
  }
  return [];
}

const emptyAudioData = {
  encodings: [],
  echoCancellation: false,
  secondTrack: false,
  inVolume: { from: 1, to: 15, value: 0 },
  outVolume: { from: 1, to: 15, value: 0 },
  ahsSensitivity: { from: 1, to: 20, value: 0 },
  ahsVolume: { from: 1, to: 20, value: 0 },
  systemVolume: { from: 1, to: 4, value: 0 },
  isSingle: false,
}

const emptyAudioDataSingle = {
  encodings: [],
  inVolume: { from: 1, to: 15, value: 0 },
  outVolume: { from: 1, to: 15, value: 0 },
  isSingle: true,
}

const DevicesAudio = ({ isReadonly, isSingle, device, onUpdate, ...props }) => {
  const location = useLocation();
  const editPermission = useSelector(deviceWritePermission);

  const { action1, actionId1, stepName } = useParams();
  const [values, setValues] = useState(device.audio?.ahsVolume ? device.audio : isSingle ? emptyAudioDataSingle : emptyAudioData);

  // device change effect
  useEffect(() => {
    let unmounted = !location.pathname.includes('/devices/intercoms/') || !location.pathname.includes('/audio');
    if (!unmounted && actionId1) {
      setValues({ ...(device.audio || (isSingle ? emptyAudioDataSingle : emptyAudioData)), isSingle }); // copy isSingle inside
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, stepName, isSingle, device.audio]);

  const setFieldValue = (key, value) => {
    setValues({ ...values, [key]: value });
  }

  return (
    <>
      <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
        {'Настройки Аудио устройства ' + (device.macAddress || '')}
      </Typography>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          {/* encodings field */}
          <Autocomplete
            id="combo-box-encodings"
            disableClearable
            disabled={!editPermission || isReadonly}
            options={values.encodings || []}
            getOptionLabel={(item) => item.name}
            getOptionDisabled={(option) => (option.code !== 'G.711A')}
            value={values.encodings?.find((item) => item.active) || { name: '' }}
            onChange={(event, value) => {
              const update = values.encodings?.map((item) => ({ ...item, active: item === value }));
              setFieldValue('encodings', update);
              onUpdate({ audio: { ...simpleValues(values), encoding: value.code } });
            }}
            renderInput={(params) => <TextField {...params} label="Тип кодирования" />}
          />
        </Grid>
        {isSingle === false && (
          <Grid
            item
            xs={12}
            md={4}
            textAlign="right"
          >
            {/* echoCancellation field */}
            <FormControlLabel
              control={(
                <Checkbox
                  sx={{ ml: 1 }}
                  checked={values.echoCancellation}
                  disabled={!editPermission || isReadonly}
                  onChange={(event) => {
                    setFieldValue('echoCancellation', event.target.checked);
                    onUpdate({ audio: { ...simpleValues(values), echoCancellation: event.target.checked } });
                  }}
                />
              )}
              label="Эхоподавление IP"
            />
          </Grid>
        )}
        {isSingle === false && (
          <Grid
            item
            xs={12}
            md={4}
          >
            {/* secondTrack field */}
            <FormControlLabel
              control={(
                <Checkbox
                  sx={{ ml: 1 }}
                  checked={values.secondTrack}
                  disabled={!editPermission || isReadonly}
                  onChange={(event) => {
                    setFieldValue('secondTrack', event.target.checked);
                    onUpdate({ audio: { ...simpleValues(values), echoCancellation: event.target.checked } });
                  }}
                />
              )}
              label="Вторая аудиодорожка"
            />
          </Grid>
        )}
        {/* placeholder */}
        {isSingle !== false && (
          <Grid
            item
            xs={12}
            sm={6}
          />
        )}
        <Grid
          item
          xs={12}
          sm={6}
        >
          {/* inVolume field */}
          <Typography gutterBottom>
            Чувствительность входа IP:&nbsp;
            <strong>{values.inVolume.value}</strong>
          </Typography>
          <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
            <ArrowBack />
            <Slider
              size="small"
              aria-label="Restricted values"
              disabled={!editPermission || isReadonly}
              value={values.inVolume.value}
              step={1}
              min={values.inVolume.from}
              max={values.inVolume.to}
              marks={getMarks(values.inVolume.from, values.inVolume.to)}
              onChange={(event, value) => {
                setFieldValue('inVolume', { ...values.inVolume, value });
                onUpdate({ audio: { ...simpleValues(values), inVolume: value } });
              }}
              valueLabelDisplay="auto"
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          {/* outVolume field */}
          <Typography gutterBottom>
            Мощность выхода IP:&nbsp;
            <strong>{values.outVolume.value}</strong>
          </Typography>
          <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
            <ArrowForward />
            <Slider
              size="small"
              aria-label="Restricted values"
              disabled={!editPermission || isReadonly}
              value={values.outVolume.value}
              step={1}
              min={values.outVolume.from}
              max={values.outVolume.to}
              marks={getMarks(values.outVolume.from, values.outVolume.to)}
              onChange={(event, value) => {
                setFieldValue('outVolume', { ...values.outVolume, value });
                onUpdate({ audio: { ...simpleValues(values), outVolume: value } });
              }}
              valueLabelDisplay="auto"
            />
          </Stack>
        </Grid>
        {isSingle === false && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            {/* ahsSensitivity field */}
            <Typography gutterBottom>
              Чувствительность микрофона трубки КМС:&nbsp;
              <strong>{values.ahsSensitivity?.value}</strong>
            </Typography>
            <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
              <Mic />
              <Slider
                size="small"
                aria-label="Restricted values"
                disabled={!editPermission || isReadonly}
                value={values.ahsSensitivity?.value}
                step={1}
                min={values.ahsSensitivity?.from}
                max={values.ahsSensitivity?.to}
                marks={getMarks(values.ahsSensitivity?.from, values.ahsSensitivity?.to)}
                onChange={(event, value) => {
                  setFieldValue('ahsSensitivity', { ...values.ahsSensitivity, value });
                  onUpdate({ audio: { ...simpleValues(values), ahsSensitivity: value } });
                }}
                valueLabelDisplay="auto"
              />
            </Stack>
          </Grid>
        )}
        {isSingle === false && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            {/* ahsVolume field */}
            <Typography gutterBottom>
              Громкость динамика трубки КМС:&nbsp;
              <strong>{values.ahsVolume?.value}</strong>
            </Typography>
            <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
              <VolumeUp />
              <Slider
                size="small"
                aria-label="Restricted values"
                disabled={!editPermission || isReadonly}
                value={values.ahsVolume?.value}
                step={1}
                min={values.ahsVolume?.from}
                max={values.ahsVolume?.to}
                marks={getMarks(values.ahsVolume?.from, values.ahsVolume?.to)}
                onChange={(event, value) => {
                  setFieldValue('ahsVolume', { ...values.ahsVolume, value });
                  onUpdate({ audio: { ...simpleValues(values), ahsVolume: value } });
                }}
                valueLabelDisplay="auto"
              />
            </Stack>
          </Grid>
        )}
        {isSingle === false && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            {/* systemVolume field */}
            <Typography gutterBottom>
              Громкость системных звуков:&nbsp;
              <strong>{values.systemVolume?.value}</strong>
            </Typography>
            <Stack spacing={2} direction="row" sx={{ my: 2, mr: 3 }} alignItems="center">
              <VolumeDown />
              <Slider
                size="small"
                aria-label="Restricted values"
                disabled={!editPermission || isReadonly}
                value={values.systemVolume?.value}
                step={1}
                min={values.systemVolume?.from}
                max={values.systemVolume?.to}
                marks={getMarks(values.systemVolume?.from, values.systemVolume?.to)}
                onChange={(event, value) => {
                  setFieldValue('systemVolume', { ...values.systemVolume, value });
                  onUpdate({ audio: { ...simpleValues(values), systemVolume: value } });
                }}
                valueLabelDisplay="auto"
              />
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  )
};

DevicesAudio.propTypes = {
  isReadonly: PropTypes.bool,
  isSingle: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesAudio.defaultProps = {
  isReadonly: false,
  isSingle: false,
  device: {},
}

export default memo(DevicesAudio, isDevicePropEqual);
