import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const PhoneMask = forwardRef(({ onChange, ...props }, ref) => (
  <NumberFormat
    {...props}
    getInputRef={ref}
    onValueChange={(values) => {
      onChange(values.value);
    }}
    format="+7 (###) ###-##-##"
    allowEmptyFormatting
    mask="_"
  />
));

PhoneMask.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PhoneMask;
