import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Breadcrumbs, Container, Link } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { faqReadPermission } from '../store/selectors/account.selector';
import { showNoAccessMessage } from '../store/actions/messages.actions';
import FaqList from '../components/faq/FaqList';
import FaqEditor from '../components/faq/FaqEditor';

const Faq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const readPermission = useSelector(faqReadPermission);

  useEffect(() => {
    if (!readPermission) {
      dispatch(showNoAccessMessage());
      navigate('/app/dashboard');
    }
  }, []);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Helmet>
        <title>ЧаВо | Терион</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/app/dashboard">Главная</Link>
              <Link color="textPrimary" href="/app/faq">Настройка популярных вопросов мобильного приложения</Link>
            </Breadcrumbs>
          </Box>

          <FaqList />

          <FaqEditor />

        </Container>
      </Box>
    </>
  );
};

export default Faq;
