import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Archive, Edit, VisibilityOutlined } from '@material-ui/icons';
import { placeWritePermission } from '../../store/selectors/account.selector';
import store from '../../store/store';
import {
  archiveTerritory,
  getTerritories,
  setTerritoriesRefresh
} from '../../store/actions/territories.actions';
import useDebounce from '../../utils/useDebounce';
import { showMessage } from '../../store/actions/messages.actions';
import TerritoriesMulti from './TerritoriesMulti';
import TerritoryMultiArchiveDialog from './dialogs/TerritoryMultiArchiveDialog';
import TerritoriesToolbar from './TerritoriesToolbar';
import EnhancedTableHead from '../TableHead';

const TerritoriesList = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editPermission = useSelector(placeWritePermission);
  const territoriesIsLoading = useSelector(
    (state) => state.territories.listState !== 'success'
  );
  const territoriesVersion = useSelector(
    (state) => state.territories.listVersion
  );
  const territoriesTotal = useSelector((state) => state.territories.listTotal);
  const territories = useSelector((state) => state.territories.list);

  const [area, setArea] = useState('');
  const [debouncedArea] = useDebounce(area, 800);

  const [selectedTerritoryIds, setSelectedTerritoryIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = [];
      return setOrderBy('');
    }
    sortRef.current.push(property);
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  useEffect(async () => {
    try {
      await store.dispatch(
        getTerritories({
          page,
          size,
          name: area,
          active: true,
          sort: !orderBy
            ? undefined
            : orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}`
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [territoriesVersion, size, page, debouncedArea, order, orderBy]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [size, debouncedArea]);

  const handleSelectAll = (event) => {
    let newSelectedTerritoryIds;

    if (event.target.checked) {
      newSelectedTerritoryIds = territories.map((territory) => territory.id);
    } else {
      newSelectedTerritoryIds = [];
    }

    setSelectedTerritoryIds(newSelectedTerritoryIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedTerritoryIds.indexOf(id);
    let newSelectedTerritoryIds = [];

    if (selectedIndex === -1) {
      newSelectedTerritoryIds = newSelectedTerritoryIds.concat(
        selectedTerritoryIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedTerritoryIds = newSelectedTerritoryIds.concat(
        selectedTerritoryIds.slice(1)
      );
    } else if (selectedIndex === selectedTerritoryIds.length - 1) {
      newSelectedTerritoryIds = newSelectedTerritoryIds.concat(
        selectedTerritoryIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedTerritoryIds = newSelectedTerritoryIds.concat(
        selectedTerritoryIds.slice(0, selectedIndex),
        selectedTerritoryIds.slice(selectedIndex + 1)
      );
    }

    setSelectedTerritoryIds(newSelectedTerritoryIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const openTerritoryClick = (territory) => {
    if (editPermission) {
      navigate(`/app/places/territories/edit/${territory.id}`);
    } else {
      navigate(`/app/places/territories/show/${territory.id}`);
    }
  };

  const onArchiveTerritoryClick = (territory) => {
    navigate(`/app/places/territories/archive/${territory.id}`);
  };

  const onArchiveTerritories = async () => {
    try {
      await Promise.all(
        selectedTerritoryIds.map((item) =>
          store.dispatch(archiveTerritory(item)))
      );
      dispatch(
        showMessage({
          open: true,
          text: `${
            selectedTerritoryIds.length > 1
              ? 'Территории успешно архивированы'
              : 'Территория успешно архивирована'
          }`,
          severity: 'success'
        })
      );
      setTimeout(() => dispatch(setTerritoriesRefresh()), 500);
      setTimeout(() => setSelectedTerritoryIds([]), 200);
      setArchiveConfirmDialog(false);
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          open: true,
          text: `Не удалось архивировать. ${error.message}`,
          severity: 'error'
        })
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Территории | Терион</title>
      </Helmet>
      <TerritoriesToolbar
        territoriesToArchive={selectedTerritoryIds.length}
        territoriesToArchiveClick={() => setArchiveConfirmDialog(true)}
      />
      <Card
        {...rest}
        style={{
          backgroundColor: territoriesIsLoading ? 'lightyellow' : 'white',
          transition: 'background-color 400ms ease-in'
        }}
      >
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <EnhancedTableHead
                isLoading={territoriesIsLoading}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: true,
                    id: 'name',
                    label: 'Территория',
                    style: { width: 230 }
                  }
                ]}
                hasActions
              />

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedTerritoryIds.length > 0 &&
                        selectedTerritoryIds.length === territories.length
                      }
                      color="primary"
                      indeterminate={
                        selectedTerritoryIds.length > 0 &&
                        selectedTerritoryIds.length < territories.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по названию территории"
                      variant="outlined"
                      onChange={(event) => setArea(event.target.value)}
                    />
                  </TableCell>
                  <TableCell style={{ width: 120 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {territories.slice(0, size).map((territory) => (
                  <TableRow
                    hover
                    key={territory.id}
                    selected={selectedTerritoryIds.indexOf(territory.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedTerritoryIds.indexOf(territory.id) !== -1
                        }
                        onChange={(event) =>
                          handleSelectOne(event, territory.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {territory.name || 'Нет имени'}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell style={{ width: 120 }}>
                      <Box
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          display: 'flex'
                        }}
                      >
                        <Tooltip
                          title={editPermission ? 'Редактировать' : 'Смотреть'}
                          placement="top"
                          followCursor
                          enterDelay={1000}
                        >
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            onClick={() => openTerritoryClick(territory)}
                          >
                            <SvgIcon fontSize="medium" color="primary">
                              {editPermission ? (
                                <Edit />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                        {editPermission && (
                          <Tooltip
                            title="Добавить в архив"
                            placement="top"
                            followCursor
                            enterDelay={1000}
                          >
                            <IconButton
                              aria-label="archive"
                              color="secondary"
                              onClick={() => onArchiveTerritoryClick(territory)}
                            >
                              <SvgIcon
                                fontSize="medium"
                                color="secondary"
                                /* sx={{ color: '#ef6c00' }} */
                              >
                                <Archive />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={territoriesTotal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      {editPermission && (
        <TerritoriesMulti
          territoriesToArchive={selectedTerritoryIds.length}
          territoriesToArchiveClick={() => setArchiveConfirmDialog(true)}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <TerritoryMultiArchiveDialog
          showDialog={archiveConfirmDialog}
          counter={selectedTerritoryIds.length}
          handleConfirm={() => onArchiveTerritories()}
          handleClose={() => setArchiveConfirmDialog(false)}
        />
      )}
    </>
  );
};

TerritoriesList.propTypes = {};

export default TerritoriesList;
