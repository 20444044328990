import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const types = ['Бегущая строка', 'Строки'];

function convertToNative(date) {
  return date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';
}

function convertFromNative(date) {
  return date ? moment(date).format() : '';
}

function correctTextLines(arr) {
  const updated = [...arr];
  while (updated.length < 5) {
    updated.push({ active: false, text: '' });
  }
  while (updated.length > 5) {
    updated.pop();
  }
  return updated;
}

const DeviceDisplay = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Formik
      {...props}
      enableReinitialize
      /*initialValues={device.display || { active: false, text: '', timeout: 200, actualMessage: { tickerTextLine: undefined } }}*/
      initialValues={device.display || {
        mainMessageType: undefined, // internal message type
        temporaryMessageType: undefined, // internal message type
        mainMessage: {
          linesTimeout: 200,
          tickerTimeout: 200,
          tickerTextLine: { active: false, text: '' },
          textLines: [{ active: false, text: '' }]
        },
        temporaryMessage: {
          linesTimeout: 200,
          tickerTimeout: 200,
          tickerTextLine: { active: false, text: '' },
          textLines: [],
          fromDateTime: moment().format(),
          toDateTime: moment().add(1, 'days').format()
        }
      }}
      validationSchema={
        Yup.object().shape({
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 2 }}>
            {'Настройки дисплея устройства ' + (device.macAddress || '')}
          </Typography>
          {/*version for base config*/}
          {values.active !== undefined && (
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={3}
              >
                {/* active field */}
                <FormControlLabel
                  control={(
                    <Checkbox
                      sx={{ ml: 1 }}
                      checked={values.active}
                      onChange={
                        (event) => {
                          setFieldValue('active', event.target.checked);
                          onUpdate({ display: { ...values, active: event.target.checked } });
                        }
                      }
                    />
                  )}
                  label="Разрешить"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                {/* text field */}
                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-overlay-text"
                  >
                    Бегущая строка
                  </InputLabel>
                  <OutlinedInput
                    style={{ backgroundColor: 'white' }}
                    id="outlined-overlay-text"
                    type="text"
                    autoFocus
                    autoComplete="off"
                    value={values.text}
                    onChange={(event) => {
                      setFieldValue('text', event.target.value);
                      onUpdate({ display: { ...values, text: event.target.value } });
                    }}
                    readOnly={!editPermission}
                    disabled={!editPermission}
                    label="Бегущая строка"
                  />
                  {touched.text && errors.text && (
                    <FormHelperText error id="outlined-text-error">
                      {errors.text}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
              >
                {/* timeout field */}
                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-overlay-timeout"
                  >
                    Таймаут смены символов
                  </InputLabel>
                  <OutlinedInput
                    style={{ backgroundColor: 'white' }}
                    id="outlined-overlay-timeout"
                    type="timeout"
                    autoFocus
                    autoComplete="off"
                    value={values.timeout}
                    onChange={(event) => {
                      setFieldValue('timeout', event.target.value);
                      onUpdate({ display: { ...values, timeout: event.target.value } });
                    }}
                    readOnly={!editPermission}
                    label="Таймаут смены символов"
                  />
                  {touched.timeout && errors.timeout && (
                    <FormHelperText error id="outlined-timeout-error">
                      {errors.timeout}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          )}

          {/*version for main config*/}
          {values.actualMessage && (
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: !values.temporaryMessage.active ? 'bold' : 'normal' }}>
                      Основное сообщение
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* type elector */}
                    <Autocomplete
                      disableListWrap
                      id="combo-box-type"
                      options={types}
                      disableClearable
                      filterOptions={(options) => options}
                      value={values.mainMessageType || (values.mainMessage?.tickerTextLine?.active ? types[0] : types[1])}
                      onChange={(event, value) => {
                        setFieldValue('mainMessageType', value);
                        let arr = correctTextLines(values.mainMessage.textLines);
                        if (value === 'Бегущая строка') {
                          arr = arr.map((item) => ({ ...item, active: false }));
                          setFieldValue('mainMessage', { ...values.mainMessage, tickerTextLine: { ...values.mainMessage.tickerTextLine, active: true }, textLines: arr });
                          onUpdate({ display: { ...values, mainMessage: { ...values.mainMessage, tickerTextLine: { ...values.mainMessage.tickerTextLine, active: true }, textLines: arr } } });
                        } else {
                          if (!values.mainMessage.textLines.find((item) => item.active)) {
                            arr[0].active = true;
                          }
                          setFieldValue('mainMessage', { ...values.mainMessage, tickerTextLine: { ...values.mainMessage.tickerTextLine, active: false }, textLines: arr });
                          onUpdate({ display: { ...values, mainMessage: { ...values.mainMessage, tickerTextLine: { ...values.mainMessage.tickerTextLine, active: false }, textLines: arr } } });
                        }
                      }}
                      disabled={isReadonly || !editPermission}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.type && errors.type)}
                          helperText={touched.type && errors.type}
                          label="Тип сообщения"
                        />
                      )}
                    />
                  </Grid>
                  {(values.mainMessageType || (values.mainMessage?.tickerTextLine?.active ? types[0] : types[1])) === 'Бегущая строка' && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                      >
                        {/* text field */}
                        <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                          <InputLabel
                            htmlFor="outlined-overlay-text"
                          >
                            Бегущая строка
                          </InputLabel>
                          <OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            id="outlined-overlay-text"
                            type="text"
                            autoFocus
                            autoComplete="off"
                            value={values.mainMessage.tickerTextLine.text}
                            onChange={(event) => {
                              setFieldValue('mainMessage', { ...values.mainMessage, tickerTextLine: { ...values.mainMessage.tickerTextLine, text: event.target.value } });
                              onUpdate({ display: { ...values, mainMessage: { ...values.mainMessage, tickerTextLine: { ...values.mainMessage.tickerTextLine, text: event.target.value } } } });
                            }}
                            readOnly={isReadonly || !editPermission}
                            disabled={isReadonly || !editPermission}
                            label="Бегущая строка"
                          />
                          {touched.text && errors.text && (
                            <FormHelperText error id="outlined-text-error">
                              {errors.text}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                      >
                        {/* timeout field */}
                        <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                          <InputLabel
                            htmlFor="outlined-overlay-timeout"
                          >
                            Скорость бегущей строки
                          </InputLabel>
                          <OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            id="outlined-overlay-timeout"
                            type="number"
                            inputProps={{ min: 0, max: 1000 }}
                            autoComplete="off"
                            value={values.mainMessage.tickerTimeout || '200'}
                            onChange={(event) => {
                              setFieldValue('mainMessage', { ...values.mainMessage, tickerTimeout: event.target.value });
                              onUpdate({ display: { ...values, mainMessage: { ...values.mainMessage, tickerTimeout: event.target.value } } });
                            }}
                            readOnly={isReadonly || !editPermission}
                            disabled={isReadonly || !editPermission}
                            label="Скорость бегущей строки"
                          />
                          {touched.timeout && errors.timeout && (
                            <FormHelperText error id="outlined-timeout-error">
                              {errors.timeout}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {(values.mainMessageType || (values.mainMessage?.tickerTextLine?.active ? types[0] : types[1])) === 'Строки' && (
                    correctTextLines(values.mainMessage.textLines).map((line, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        key={'grid' + index}
                      >
                        <Stack spacing={1} direction="row" alignItems="center">
                          {/* text field */}
                          <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                            <InputLabel
                              htmlFor="outlined-overlay-text"
                            >
                              Строка&nbsp;
                              {index + 1}
                            </InputLabel>
                            <OutlinedInput
                              style={{ backgroundColor: 'white' }}
                              id="outlined-overlay-text"
                              type="text"
                              autoFocus={index === 0}
                              autoComplete="off"
                              value={line.text}
                              onChange={(event) => {
                                const arr = correctTextLines(values.mainMessage.textLines);
                                arr[index] = { active: event.target.value.length > 0, text: event.target.value };
                                setFieldValue('mainMessage', { ...values.mainMessage, textLines: arr });
                                onUpdate({ display: { ...values, mainMessage: { ...values.mainMessage, textLines: arr } } });
                              }}
                              disabled={isReadonly || !editPermission}
                              readOnly={isReadonly || !editPermission}
                              label="Строка i"
                            />
                            {touched.text && errors.text && (
                              <FormHelperText error id="outlined-text-error">
                                {errors.text}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                sx={{ ml: 1 }}
                                checked={line.active}
                                disabled={isReadonly || !editPermission}
                                onChange={
                                  (event) => {
                                    const arr = correctTextLines(values.mainMessage.textLines);
                                    arr[index] = { ...arr[index], active: event.target.checked };
                                    setFieldValue('mainMessage', { ...values.mainMessage, textLines: arr });
                                    onUpdate({ display: { ...values, mainMessage: { ...values.mainMessage, textLines: arr } } });
                                  }
                                }
                              />
                            )}
                            label="Включить"
                          />
                        </Stack>
                      </Grid>
                    ))
                  )}
                  {(values.mainMessageType || (values.mainMessage?.tickerTextLine?.active ? types[0] : types[1])) === 'Строки' && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                    >
                      {/* timeout field */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <InputLabel
                          htmlFor="outlined-overlay-timeout"
                        >
                          Скорость смены строк
                        </InputLabel>
                        <OutlinedInput
                          style={{ backgroundColor: 'white' }}
                          id="outlined-overlay-timeout"
                          type="number"
                          autoComplete="off"
                          value={values.mainMessage.linesTimeout || '1000'}
                          onChange={(event) => {
                            setFieldValue('mainMessage', { ...values.mainMessage, linesTimeout: event.target.value });
                            onUpdate({ display: { ...values, mainMessage: { ...values.mainMessage, linesTimeout: event.target.value } } });
                          }}
                          readOnly={isReadonly || !editPermission}
                          label="Скорость смены строк"
                        />
                        {touched.timeout && errors.timeout && (
                          <FormHelperText error id="outlined-timeout-error">
                            {errors.timeout}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* temporaryMessage active field */}
                  <FormControlLabel
                    sx={{ mt: 2, ml: 0 }}
                    control={(
                      <Checkbox
                        checked={values.temporaryMessage.active ?? false}
                        disabled={isReadonly || !editPermission}
                        onChange={
                          (event) => {
                            // set default values (may be undefined)
                            const fromDateTime = values.temporaryMessage.fromDateTime || moment(Date.now()).format();
                            const toDateTime = values.temporaryMessage.toDateTime || values.temporaryMessage.toDateTime || moment(Date.now()).add(1, 'days').format();
                            const tickerTimeout = values.temporaryMessage.tickerTimeout || 200;
                            const linesTimeout = values.temporaryMessage.linesTimeout || 1000;
                            setFieldValue('temporaryMessage', { ...values.temporaryMessage, active: event.target.checked, fromDateTime, toDateTime, linesTimeout, tickerTimeout });
                            onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, active: event.target.checked, fromDateTime, toDateTime, linesTimeout, tickerTimeout } } });
                          }
                        }
                      />
                    )}
                    label="Показывать временное сообщение"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: values.temporaryMessage.active ? 'bold' : 'normal' }}>
                      Временное сообщение
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* type elector */}
                    <Autocomplete
                      disableListWrap
                      id="combo-box-type"
                      options={types}
                      disableClearable
                      filterOptions={(options) => options}
                      value={values.temporaryMessageType || (values.temporaryMessage?.tickerTextLine?.active ? types[0] : types[1])}
                      onChange={(event, value) => {
                        setFieldValue('temporaryMessageType', value);
                        let arr = correctTextLines(values.temporaryMessage.textLines);
                        if (value === 'Бегущая строка') {
                          arr = arr.map((item) => ({ ...item, active: false }));
                          setFieldValue('temporaryMessage', { ...values.temporaryMessage, tickerTextLine: { ...values.temporaryMessage.tickerTextLine, active: true }, textLines: arr });
                          onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, tickerTextLine: { ...values.temporaryMessage.tickerTextLine, active: true }, textLines: arr } } });
                        } else {
                          if (!values.temporaryMessage.textLines.find((item) => item.active)) {
                            arr[0].active = true;
                          }
                          setFieldValue('temporaryMessage', { ...values.temporaryMessage, tickerTextLine: { ...values.temporaryMessage.tickerTextLine, active: false }, textLines: arr });
                          onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, tickerTextLine: { ...values.temporaryMessage.tickerTextLine, active: false }, textLines: arr } } });
                        }
                      }}
                      disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.type && errors.type)}
                          helperText={touched.type && errors.type}
                          label="Тип сообщения"
                        />
                      )}
                    />
                  </Grid>
                  {(values.temporaryMessageType || (values.temporaryMessage?.tickerTextLine?.active ? types[0] : types[1])) === 'Бегущая строка' && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                      >
                        {/* text field */}
                        <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                          <InputLabel
                            htmlFor="outlined-overlay-text"
                          >
                            Бегущая строка
                          </InputLabel>
                          <OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            id="outlined-overlay-text"
                            type="text"
                            autoFocus
                            autoComplete="off"
                            value={values.temporaryMessage.tickerTextLine.text}
                            onChange={(event) => {
                              setFieldValue('temporaryMessage', { ...values.temporaryMessage, tickerTextLine: { ...values.temporaryMessage.tickerTextLine, text: event.target.value } });
                              onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, tickerTextLine: { ...values.temporaryMessage.tickerTextLine, text: event.target.value } } } });
                            }}
                            readOnly={isReadonly || !editPermission || !values.temporaryMessage.active}
                            disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                            label="Бегущая строка"
                          />
                          {touched.text && errors.text && (
                            <FormHelperText error id="outlined-text-error">
                              {errors.text}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                      >
                        {/* timeout field */}
                        <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                          <InputLabel
                            htmlFor="outlined-overlay-timeout"
                          >
                            Скорость бегущей строки
                          </InputLabel>
                          <OutlinedInput
                            style={{ backgroundColor: 'white' }}
                            id="outlined-overlay-timeout"
                            type="number"
                            inputProps={{ min: 0, max: 1000 }}
                            autoComplete="off"
                            value={values.temporaryMessage.tickerTimeout || '200'}
                            onChange={(event) => {
                              setFieldValue('temporaryMessage', { ...values.temporaryMessage, tickerTimeout: event.target.value });
                              onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, tickerTimeout: event.target.value } } });
                            }}
                            readOnly={isReadonly || !editPermission || !values.temporaryMessage.active}
                            disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                            label="Скорость бегущей строки"
                          />
                          {touched.timeout && errors.timeout && (
                            <FormHelperText error id="outlined-timeout-error">
                              {errors.timeout}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {(values.temporaryMessageType || (values.temporaryMessage?.tickerTextLine?.active ? types[0] : types[1])) === 'Строки' && (
                    correctTextLines(values.temporaryMessage.textLines).map((line, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        key={'grid' + index}
                      >
                        <Stack spacing={1} direction="row" alignItems="center">
                          {/* text field */}
                          <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                            <InputLabel
                              htmlFor="outlined-overlay-text"
                            >
                              Строка&nbsp;
                              {index + 1}
                            </InputLabel>
                            <OutlinedInput
                              style={{ backgroundColor: 'white' }}
                              id="outlined-overlay-text"
                              type="text"
                              autoFocus={index === 0}
                              autoComplete="off"
                              value={line.text}
                              onChange={(event) => {
                                const arr = correctTextLines(values.temporaryMessage.textLines);
                                arr[index] = { active: event.target.value.length > 0, text: event.target.value };
                                setFieldValue('temporaryMessage', { ...values.temporaryMessage, textLines: arr });
                                onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, textLines: arr } } });
                              }}
                              readOnly={isReadonly || !editPermission || !values.temporaryMessage.active}
                              disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                              label="Строка i"
                            />
                            {touched.text && errors.text && (
                              <FormHelperText error id="outlined-text-error">
                                {errors.text}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                sx={{ ml: 1 }}
                                checked={line.active}
                                disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                                onChange={
                                  (event) => {
                                    const arr = correctTextLines(values.temporaryMessage.textLines);
                                    arr[index] = { ...arr[index], active: event.target.checked };
                                    setFieldValue('temporaryMessage', { ...values.temporaryMessage, textLines: arr });
                                    onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, textLines: arr } } });
                                  }
                                }
                              />
                            )}
                            label="Включить"
                          />
                        </Stack>
                      </Grid>
                    ))
                  )}
                  {(values.temporaryMessageType || (values.temporaryMessage?.tickerTextLine?.active ? types[0] : types[1])) === 'Строки' && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                    >
                      {/* timeout field */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <InputLabel
                          htmlFor="outlined-overlay-timeout"
                        >
                          Скорость смены строк
                        </InputLabel>
                        <OutlinedInput
                          style={{ backgroundColor: 'white' }}
                          id="outlined-overlay-timeout"
                          type="number"
                          autoComplete="off"
                          value={values.temporaryMessage.linesTimeout || '1000'}
                          onChange={(event) => {
                            setFieldValue('temporaryMessage', { ...values.temporaryMessage, linesTimeout: event.target.value });
                            onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, linesTimeout: event.target.value } } });
                          }}
                          readOnly={isReadonly || !editPermission || !values.temporaryMessage.active}
                          disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                          label="Скорость смены строк"
                        />
                        {touched.timeout && errors.timeout && (
                          <FormHelperText error id="outlined-timeout-error">
                            {errors.timeout}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* ntpActive field */}
                    <TextField
                      id="datetime-local-from"
                      label="Начало показа сообщения"
                      type="datetime-local"
                      value={convertToNative(values.temporaryMessage.fromDateTime)}
                      disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                      onChange={(event) => {
                        const from = convertFromNative(event.target.value);
                        setFieldValue('temporaryMessage', { ...values.temporaryMessage, fromDateTime: from });
                        onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, fromDateTime: from } } });
                      }}
                      sx={{ width: '100%', mt: 1 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* ntpActive field */}
                    <TextField
                      id="datetime-local-to"
                      label="Конец показа сообщения"
                      type="datetime-local"
                      value={convertToNative(values.temporaryMessage.toDateTime)}
                      disabled={isReadonly || !editPermission || !values.temporaryMessage.active}
                      onChange={(event) => {
                        const to = convertFromNative(event.target.value);
                        setFieldValue('temporaryMessage', { ...values.temporaryMessage, toDateTime: to });
                        onUpdate({ display: { ...values, temporaryMessage: { ...values.temporaryMessage, toDateTime: to } } });
                      }}
                      sx={{ width: '100%', mt: 1 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
      )}
    </Formik>
  );
};

DeviceDisplay.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DeviceDisplay.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DeviceDisplay, isDevicePropEqual);
