import { combineReducers } from 'redux';
import accountReducer from './account.reducer';
import customersReducer from './customers.reducer';
import departmentsReducer from './departments.reducer';
import devicesReducer from './devices.reducer';
import rolesReducer from './roles.reducer';
import buildingsReducer from './buildings.reducer';
import territoriesReducer from './territories.reducer';
import messagesReducer from './messages.reducer';
import subscribersReducer from './subscribers.reducer';
import sipsReducer from './sips.reducer';
import faqReducer from './faq.reducer';
import logsReducer from './logs.reducer';

const rootReducers = combineReducers({
  account: accountReducer,
  buildings: buildingsReducer,
  customers: customersReducer,
  departments: departmentsReducer,
  devices: devicesReducer,
  logs: logsReducer,
  messages: messagesReducer,
  roles: rolesReducer,
  sips: sipsReducer,
  subscribers: subscribersReducer,
  territories: territoriesReducer,
  faq: faqReducer,
});

export default rootReducers;
