import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel, FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DevicesLog = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <>
      <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
        {'Журнал событий устройства ' + (device.macAddress || '')}
      </Typography>
      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
        <InputLabel
          htmlFor="outlined-message"
        >
          Последние события
        </InputLabel>
        <OutlinedInput
          style={{ backgroundColor: '#f5f5f5' }}
          id="outlined-message"
          type="text"
          multiline
          minRows={28}
          maxRows={28}
          autoComplete="off"
          value={device.log || ' '}
          readOnly
          label="Последние события"
        />
      </FormControl>
    </>
  );
};

DevicesLog.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesLog.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DevicesLog, isDevicePropEqual);
