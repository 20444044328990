import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { contractWritePermission } from '../../../store/selectors/account.selector';

const SipsToolbar = (props) => {
  const editPermission = useSelector(contractWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ ml: 1 }}>
          Экспорт
        </Button>*/}
        {/*{editPermission && (
          <RouterLink to="/app/contracts/sips/add/sip">
            <Button
              sx={{ ml: 1 }}
              variant="contained"
            >
              Создать абонента
            </Button>
          </RouterLink>
        )}*/}
      </Box>
    </Box>
  );
};

export default SipsToolbar;
