import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DeviceLevel = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  // console.log(device.levels)

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.levels || { active: false, text: '', doorOpenLevel: 500, handsetUpLevel: 300 }}
      validationSchema={
        Yup.object().shape({
          active: Yup.bool(),
          text: Yup.string(),
          timeout: Yup.string(),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки уровней устройства ' + (device.macAddress || '')}
          </Typography>
          {/*version for base config*/}
          {values.doorOpenLevel !== undefined && (
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={4}
              >
                {/* active field */}
                <FormControlLabel
                  control={(
                    <Checkbox
                      sx={{ ml: 1 }}
                      checked={values.active}
                      onChange={
                        (event) => {
                          setFieldValue('active', event.target.checked);
                          onUpdate({ levels: { ...values, active: event.target.checked } });
                        }
                      }
                    />
                  )}
                  label="Использовать индивидуальные настройки уровней"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                {/* doorOpenLevel field */}
                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                  <InputLabel
                    error={Boolean(touched.doorOpenLevel && errors.doorOpenLevel)}
                    htmlFor="outlined-levels-doorOpenLevel"
                  >
                    Уровень открытия двери
                  </InputLabel>
                  <OutlinedInput
                    style={{ backgroundColor: 'white' }}
                    error={Boolean(touched.doorOpenLevel && errors.doorOpenLevel)}
                    id="outlined-levels-doorOpenLevel"
                    type="text"
                    autoFocus
                    autoComplete="off"
                    value={values.doorOpenLevel}
                    onChange={(event) => {
                      setFieldValue('doorOpenLevel', event.target.value);
                      onUpdate({ levels: { ...values, doorOpenLevel: event.target.value } });
                    }}
                    readOnly={!editPermission}
                    label="Уровень открытия двери"
                  />
                  {touched.doorOpenLevel && errors.doorOpenLevel && (
                    <FormHelperText error id="outlined-doorOpenLevel-error">
                      {errors.doorOpenLevel}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                {/* handsetUpLevel field */}
                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                  <InputLabel
                    error={Boolean(touched.handsetUpLevel && errors.handsetUpLevel)}
                    htmlFor="outlined-levels-handsetUpLevel"
                  >
                    Уровень снятия трубки
                  </InputLabel>
                  <OutlinedInput
                    style={{ backgroundColor: 'white' }}
                    error={Boolean(touched.handsetUpLevel && errors.handsetUpLevel)}
                    id="outlined-levels-handsetUpLevel"
                    type="handsetUpLevel"
                    autoComplete="off"
                    value={values.handsetUpLevel}
                    onChange={(event) => {
                      setFieldValue('handsetUpLevel', event.target.value);
                      onUpdate({ levels: { ...values, handsetUpLevel: event.target.value } });
                    }}
                    readOnly={!editPermission}
                    label="Уровень снятия трубки"
                  />
                  {touched.handsetUpLevel && errors.handsetUpLevel && (
                    <FormHelperText error id="outlined-handsetUpLevel-error">
                      {errors.handsetUpLevel}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          )}
        </form>
      )}
    </Formik>
  );
};

DeviceLevel.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DeviceLevel.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DeviceLevel, isDevicePropEqual);
