import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import moment from 'moment';
import 'moment/locale/ru';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { v4 as uuid } from 'uuid';
import {
  checkLicenseSubscriber,
  createSubscriber,
  getSubscriber,
  putSubscriber,
  setSubscribersCache,
  setSubscribersRefresh
} from '../../../store/actions/subscribers.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import { contractWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import useDebounce from '../../../utils/useDebounce';
import PhoneMask from '../../common/PhoneMask';
import SubscriberPhoneDeleteDialog from './SubscriberPhoneDeleteDialog';
import SubscriberPhoneDialog from './SubscriberPhoneDialog';
import formatPhone from '../../../utils/formatPhone';
import { searchAddress, searchBuilding } from '../../../store/actions/buildings.actions';
import { checkLicenseIntercom } from '../../../store/actions/devices.actions';

moment.locale('ru');

const statuses = [
  { code: 'ACTIVE', name: 'Активный' },
  { code: 'BLOCKED', name: 'Заблокированный' },
  { code: 'DELETED', name: 'Удалённый' },
];

const subscriberEmptyState = {
  id: 'subscriber',
  name: '',
  phone: '',
  number: '',
  created: moment().format(),
  flatNumber: '',
  flatNumberOriginal: '',
  status: statuses[0],
  building: { address: '', id: '' },
  contacts: [],
};

const checkLicense = async (navigate) => {
  try {
    const { data } = await store.dispatch(checkLicenseSubscriber());
  } catch (error) {
    console.log(error);
    navigate('/app/contracts/subscribers/license', { replace: true });
  }
};

const getShortAddress = (address) => {
  const addArray = address.split(/,[\s]*/ig);
  return addArray.length < 4 ? addArray.join(', ') : addArray.splice(2).join(', ');
};

const SubscriberDialog = ({ isArchive, handleClose, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { action1, actionId1, action2, actionId2 } = useParams();

  const editPermission = useSelector(contractWritePermission);

  const [addresses, setAddresses] = useState([]);
  const [addressSearch, setAddressSearch] = useState(null);
  const [debouncedAddress] = useDebounce(addressSearch, 300);

  const [subscriberDetails, setSubscriberDetails] = useState({ ...subscriberEmptyState });
  const [subscriber, setSubscriber] = useState({ ...subscriberEmptyState });
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const subscribersCache = useSelector((state) => state.subscribers.cache);
  const subscribers = useSelector((state) => state.subscribers.list);
  const subscriberTypes = useSelector((state) => state.subscribers.subscriberTypes);

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action1, actionId1, subscriber.intercoms[0], subscribersCache);
    const subscriberFromList = actionId1 && (subscribers.find((item) => item.id === actionId1));
    if (action1 === 'edit' || action1 === 'show' || action1 === 'add') {
      setEditDialog(true);
      if (action1 === 'edit') {
        setEditDialogTitle('Редактировать абонента');
      } else if (action1 === 'show') {
        setEditDialogTitle('Смотреть абонента');
      } else if (action1 === 'add') {
        setEditDialogTitle('Создать абонента');
        checkLicense(navigate);
      }
    } else {
      setEditDialog(false);
      setTimeout(() => {
        if (!unmounted && subscriber.id) {
          if (addressSearch !== null) {
            setAddressSearch(null);
          }
          if (subscribersCache.subscriber && (subscribersCache.subscriber.name || subscribersCache.subscriber.contacts?.length)) {
            // clear subscriber cache
            setSubscriberDetails({ ...subscriberEmptyState, contacts: [] });
            dispatch(setSubscribersCache(subscriberEmptyState));
          }
        }
      }, 200);
    }
    if ((action1 === 'edit' || action1 === 'show' || action1 === 'add') && actionId1) {
      setSubscriber({
        ...(subscribersCache[actionId1] || subscriberEmptyState),
        ...subscriberFromList,
        ...(subscriberDetails.id === actionId1 && subscriberDetails),
      });
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, subscribers, subscriberDetails, subscriberTypes]);

  // address details effect
  useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      try {
        const { data } = await store.dispatch(searchBuilding({ term: debouncedAddress }));
        if (data && !unmounted) {
          const currentId = subscriber.building.id;
          const filteredData = data.filter((item) => !item.id !== currentId);
          setAddresses(filteredData.map((item) => ({
            ...item,
            matches: match(item.address.toLowerCase(), addressSearch.toLowerCase())
          })));
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (debouncedAddress !== null) {
      getData();
    } else {
      setAddresses([]);
    }
    return () => {
      unmounted = true;
    };
  }, [debouncedAddress]);

  // subscriber details effect
  useEffect(() => {
    let unmounted = !location.pathname.includes('/contracts/subscribers');
    const getData = async () => {
      try {
        const { data } = await store.dispatch(getSubscriber(actionId1));
        dispatch(setSubscribersCache(data));
        if (data && data.id === actionId1) {
          setSubscriberDetails({
            ...data,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (!unmounted && actionId1 && actionId1 !== 'subscriber') {
      getData();
    }
    return () => {
      unmounted = true;
    };
  }, [actionId1]);

  const onAddContactClick = (values) => {
    if (action2 === undefined) {
      setSubscriberDetails({
        ...subscriber,
        ...values,
      });
      navigate('add/phone');
    }
  };

  const onOpenContactClick = (phoneItem) => {
    if (action2 === undefined) {
      if (editPermission) {
        navigate(`edit/${phoneItem.id || 'phone'}`);
      } else {
        navigate(`show/${phoneItem.id || 'phone'}`);
      }
    }
  };

  const onDeleteContactClick = (phoneItem) => {
    if (action2 === undefined) {
      navigate(`delete/${phoneItem.id}`);
    }
  };

  const updateContacts = (contacts) => {
    setSubscriberDetails({
      ...subscriberDetails,
      contacts
    });
  };

  const onSaveSubscriber = async (values) => {
    try {
      const data = {
        id: values.id !== 'subscriber' ? values.id : '',
        name: values.name,
        phone: values.phone.length === 10 ? '7' + values.phone : values.phone,
        number: values.number,
        created: moment(values.created).format(),
        flatNumber: values.flatNumber,
        flatNumberOriginal: values.flatNumberOriginal,
        status: values.status.code,
        building: values.building,
        contacts: values.contacts.map((item) => (item.idIsLocal ? { ...item, id: '', idIsLocal: undefined } : item)) || [],
      };
      const response = await store.dispatch(data.id ? putSubscriber(data) : createSubscriber(data));
      dispatch(showMessage({ open: true, text: 'Абонент успешно сохранен.', severity: 'success' }));
      dispatch(setSubscribersCache(values));
      handleClose(null, 'success');
      setTimeout(() => dispatch(setSubscribersRefresh()), 500);
    } catch (error) {
      dispatch(showMessage({ open: true, text: `Не удалось сохранить абонента. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      <Dialog
        {...props}
        open={editDialog}
        onClose={handleClose}
        disableRestoreFocus
        fullWidth
        scroll="body"
        PaperProps={{ sx: { maxWidth: '750px' } }}
        /* PaperProps={{ sx: { backgroundColor: 'background.default' } }} */
      >
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{ fontSize: '22px' }}>
            {editDialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={subscriber}
            validationSchema={
              Yup.object().shape({
                id: Yup.string(),
                name: Yup.string().max(255).required('Забыли указать название'),
                number: Yup.string().max(255).required('Забыли указать номер контракта'),
                phone: Yup.string().min(10, 'Неверный телефон').required('Забыли указать телефон'),
                flatNumber: Yup.string().max(255),
                created: Yup.string().max(255),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* name field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.name && errors.name)}
                        htmlFor="outlined-name"
                      >
                        Имя абонента
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.name && errors.name)}
                        id="outlined-name"
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange('name')}
                        readOnly={!editPermission}
                        label="Имя абонента"
                      />
                      {touched.name && errors.name && (
                        <FormHelperText error id="outlined-name-error">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* number field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.number && errors.number)}
                        htmlFor="outlined-number"
                      >
                        Номер договора
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.number && errors.number)}
                        id="outlined-number"
                        type="text"
                        autoComplete="off"
                        value={values.number}
                        onChange={handleChange('number')}
                        readOnly={!editPermission}
                        label="Номер SIP аккаунта"
                      />
                      {touched.number && errors.number && (
                        <FormHelperText error id="outlined-number-error">
                          {errors.number}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* phone field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.phone && errors.phone)}
                        htmlFor="outlined-phone"
                        shrink
                      >
                        Телефон
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.phone && errors.phone)}
                        id="outlined-phone"
                        type="text"
                        autoComplete="off"
                        notched
                        value={values.phone.length > 10 ? values.phone.substr(-10) : values.phone}
                        onChange={handleChange('phone')}
                        readOnly={!editPermission}
                        label="Телефон"
                        inputComponent={PhoneMask}
                      />
                      {touched.phone && errors.phone && (
                        <FormHelperText error id="outlined-phone-error">
                          {errors.phone}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* flatNumber field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.flatNumber && errors.flatNumber)}
                        htmlFor="outlined-flatNumber"
                      >
                        Номер квартиры
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.flatNumber && errors.flatNumber)}
                        id="outlined-flatNumber"
                        type="number"
                        autoComplete="off"
                        value={values.flatNumber || ''}
                        onChange={handleChange('flatNumber')}
                        readOnly={!editPermission}
                        label="Номер квартиры"
                      />
                      {touched.flatNumber && errors.flatNumber && (
                        <FormHelperText error id="outlined-flatNumber-error">
                          {errors.flatNumber}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* flatNumberOriginal field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.flatNumberOriginal && errors.flatNumberOriginal)}
                        htmlFor="outlined-flatNumberOriginal"
                      >
                        Настоящий номер квартиры
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.flatNumberOriginal && errors.flatNumberOriginal)}
                        id="outlined-flatNumberOriginal"
                        type="text"
                        autoComplete="off"
                        value={values.flatNumberOriginal || ''}
                        onChange={handleChange('flatNumberOriginal')}
                        readOnly={!editPermission}
                        label="Настоящий номер квартиры"
                      />
                      {touched.flatNumberOriginal && errors.flatNumberOriginal && (
                        <FormHelperText error id="outlined-flatNumberOriginal-error">
                          {errors.flatNumberOriginal}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* created field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.created && errors.created)}
                        htmlFor="outlined-created"
                      >
                        Дата создания
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: '#F4F6F8' }}
                        error={Boolean(touched.created && errors.created)}
                        id="outlined-created"
                        type="text"
                        autoComplete="off"
                        value={moment(values.created).format('DD MMMM, HH:mm')}
                        onChange={handleChange('created')}
                        readOnly
                        label="Дата создания"
                      />
                      {touched.created && errors.created && (
                        <FormHelperText error id="outlined-created-error">
                          {errors.created}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* status field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <Autocomplete
                        id="combo-box-status"
                        options={statuses}
                        disableClearable
                        disableListWrap
                        value={values.status}
                        onChange={(event, value) => setFieldValue('status', value)}
                        getOptionLabel={(item) => item.name}
                        renderInput={(params) => <TextField {...params} label="Статус" />}
                      />
                      {touched.status && errors.status && (
                        <FormHelperText error id="outlined-status-error">
                          {errors.status}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* address field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <Autocomplete
                        title={values.building.address}
                        id="size-small-outlined"
                        disabled={!editPermission}
                        options={addresses.slice(0, 9) || []}
                        disableClearable
                        filterOptions={(options) => options}
                        getOptionLabel={(option) => getShortAddress(option.address) || ''}
                        value={values.building || subscriberEmptyState.buiding}
                        onOpen={() => setAddressSearch('')}
                        onInput={($event) => setAddressSearch($event.target.value)}
                        onChange={(event, value) => {
                          setFieldValue('building', value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Адрес" placeholder={!editPermission || values.building.length ? '' : ''} />
                        )}
                        renderOption={(renderProps, option) => {
                          const parts = parse(
                            option.address,
                            option.matches
                          );
                          return (
                            <li {...renderProps}>
                              <Grid container alignItems="center">
                                <Grid item xs>
                                  {parts.map((part) => (
                                    <span
                                      key={uuid()}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                        color: part.highlight ? '#00AFF3' : '',
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />
                      {touched.building && errors.building && (
                        <FormHelperText error id="outlined-building-error">
                          {errors.building}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* phones table */}
                <Box sx={{ my: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '40%' }}>
                          Имя
                        </TableCell>
                        <TableCell style={{ width: '40%' }}>
                          Телефон
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }}>
                          {/* Редактирование */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscriber.contacts.map((contactItem, contactIndex) => (
                        <TableRow
                          hover
                          key={contactItem.id || contactIndex}
                        >
                          <TableCell>
                            {contactItem.name}
                          </TableCell>
                          <TableCell>
                            {formatPhone(contactItem.phone)}
                          </TableCell>
                          <TableCell size="small">
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex'
                              }}
                            >
                              <Tooltip title={editPermission && !isArchive ? 'Редактировать' : 'Смотреть'} placement="top" followCursor enterDelay={1000}>
                                <IconButton aria-label="edit" color="primary" onClick={() => onOpenContactClick(contactItem)}>
                                  {contactItem.id && (
                                    <SvgIcon
                                      fontSize="medium"
                                      color="primary"
                                    >
                                      {editPermission && !isArchive ? <Edit /> : <VisibilityOutlined />}
                                    </SvgIcon>
                                  )}
                                </IconButton>
                              </Tooltip>
                              {editPermission && !isArchive && (
                                <Tooltip title="Удалить" placement="top" followCursor enterDelay={1000}>
                                  <IconButton aria-label="delete" color="secondary" onClick={() => onDeleteContactClick(contactItem)}>
                                    {contactItem.id && (
                                      <SvgIcon
                                        fontSize="medium"
                                        color="secondary"
                                      >
                                        <Delete />
                                      </SvgIcon>
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Grid
                    item
                  >
                    <Grid
                      container
                      justifyContent="flex-start"
                      spacing={2}
                    >
                      {editPermission && !isArchive && (
                        <Grid
                          item
                        >
                          <Button
                            variant="contained"
                            color="purple"
                            onClick={() => onAddContactClick(values)}
                          >
                            добавить жильца
                          </Button>
                        </Grid>
                      )}
                      {editPermission && !isArchive && (
                        <Grid
                          item
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            aria-disabled={
                              !isValid || isSubmitting || !values.name || values.phone.length < 10
                            }
                            onClick={async () => {
                              const validationErrors = await validateForm();
                              if (Object.keys(validationErrors).length === 0) {
                                onSaveSubscriber(values);
                              } else {
                                setTouched(setNestedObjectValues(validationErrors, true));
                                dispatch(showMessage({ open: true, text: 'Проверьте ошибки заполнения формы', severity: 'error' }));
                              }
                            }}
                          >
                            Сохранить
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <SubscriberPhoneDeleteDialog contacts={subscriberDetails.contacts} onChange={(value) => updateContacts(value)} handleClose={() => handleClose()} />
      <SubscriberPhoneDialog contacts={subscriberDetails.contacts} onChange={(value) => updateContacts(value)} handleClose={() => handleClose()} />
    </>
  );
};

SubscriberDialog.propTypes = {
  isArchive: PropTypes.bool,
  handleClose: PropTypes.func,
};

SubscriberDialog.defaultProps = {
  isArchive: false,
  handleClose: null,
};

export default SubscriberDialog;
