import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Archive,
  DomainOutlined,
  Edit,
  HouseOutlined,
  MarkunreadMailboxOutlined,
  VisibilityOutlined
} from '@material-ui/icons';
import { placeWritePermission } from '../../store/selectors/account.selector';
import store from '../../store/store';
import {
  archiveBuilding,
  getBuildings,
  setBuildingsRefresh
} from '../../store/actions/buildings.actions';
import HomesMulti from './HomesMulti';
import useDebounce from '../../utils/useDebounce';
import { showMessage } from '../../store/actions/messages.actions';
import BuildingMultiArchiveDialog from './dialogs/BuildingMultiArchiveDialog';
import HomesToolbar from './HomesToolbar';
import EnhancedTableHead from '../TableHead';

const HomesList = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editPermission = useSelector(placeWritePermission);
  const buildingsIsLoading = useSelector(
    (state) => state.buildings.listState !== 'success'
  );
  const buildingsVersion = useSelector((state) => state.buildings.listVersion);
  const buildingsTotal = useSelector((state) => state.buildings.listTotal);
  const buildings = useSelector((state) => state.buildings.list);

  const [address, setAddress] = useState('');
  const [area, setArea] = useState('');
  const [debouncedAddress, cancelAddressDebounce] = useDebounce(address, 800);
  const [debouncedArea, cancelAreaDebounce] = useDebounce(area, 800);

  const [selectedBuildingIds, setSelectedBuildingIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [archiveConfirmDialog, setArchiveConfirmDialog] = useState(false);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = [];
      return setOrderBy('');
    }
    sortRef.current.push(property);
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  useEffect(async () => {
    try {
      await store.dispatch(
        getBuildings({
          page,
          size,
          address,
          area,
          active: true,
          sort: !orderBy
            ? undefined
            : orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}`
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [
    buildingsVersion,
    size,
    page,
    debouncedAddress,
    debouncedArea,
    orderBy,
    order
  ]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [size, debouncedAddress, debouncedArea]);

  const handleSelectAll = (event) => {
    let newSelectedBuildingIds;

    if (event.target.checked) {
      newSelectedBuildingIds = buildings.map((building) => building.id);
    } else {
      newSelectedBuildingIds = [];
    }

    setSelectedBuildingIds(newSelectedBuildingIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBuildingIds.indexOf(id);
    let newSelectedBuildingIds = [];

    if (selectedIndex === -1) {
      newSelectedBuildingIds = newSelectedBuildingIds.concat(
        selectedBuildingIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedBuildingIds = newSelectedBuildingIds.concat(
        selectedBuildingIds.slice(1)
      );
    } else if (selectedIndex === selectedBuildingIds.length - 1) {
      newSelectedBuildingIds = newSelectedBuildingIds.concat(
        selectedBuildingIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedBuildingIds = newSelectedBuildingIds.concat(
        selectedBuildingIds.slice(0, selectedIndex),
        selectedBuildingIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBuildingIds(newSelectedBuildingIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const buildingAvatar = (code) => {
    if (code === 'PRIVATE_HOUSE') {
      return <HouseOutlined />;
    }
    if (code === 'APARTMENT_HOUSE') {
      return <DomainOutlined />;
    }
    return <MarkunreadMailboxOutlined />;
  };

  const buildingColor = (code) => {
    if (code === 'PRIVATE_HOUSE') {
      return '#a127ee';
    }
    if (code === 'APARTMENT_HOUSE') {
      return '#4caf50';
    }
    return 'primary';
  };

  const openBuildingClick = (building) => {
    if (editPermission) {
      navigate(`/app/places/homes/edit/${building.id}`);
    } else {
      navigate(`/app/places/homes/show/${building.id}`);
    }
  };

  const onArchiveBuildingClick = (building) => {
    navigate(`/app/places/homes/archive/${building.id}`);
  };

  const copyToClipboard = (text) => {
    try {
      const ta = document.createElement('textarea');
      ta.innerText = text;
      document.body.appendChild(ta);
      ta.select();
      document.execCommand('copy');
      ta.remove();
      dispatch(
        showMessage({
          open: true,
          text: 'Адрес дома скопирован в буфер обмена',
          severity: 'success'
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onArchiveBuildings = async () => {
    try {
      await Promise.all(
        selectedBuildingIds.map((item) => store.dispatch(archiveBuilding(item)))
      );
      dispatch(
        showMessage({
          open: true,
          text: 'Дома успешно архивированы',
          severity: 'success'
        })
      );
      setTimeout(() => setSelectedBuildingIds([]), 500);
      setTimeout(() => dispatch(setBuildingsRefresh()), 200);
      setArchiveConfirmDialog(false);
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          open: true,
          text: `Не удалось архивировать. ${error.message}`,
          severity: 'error'
        })
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Дома | Терион</title>
      </Helmet>
      <HomesToolbar
        buildingsToArchive={selectedBuildingIds.length}
        buildingsToArchiveClick={() => setArchiveConfirmDialog(true)}
      />
      <Card
        {...rest}
        style={{
          backgroundColor: buildingsIsLoading ? 'lightyellow' : 'white',
          transition: 'background-color 400ms ease-in'
        }}
      >
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <EnhancedTableHead
                isLoading={buildingsIsLoading}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: true,
                    id: 'address',
                    label: 'Адрес'
                  },
                  {
                    sortable: true,
                    id: 'name',
                    label: 'Территория',
                    style: { width: 230 }
                  }
                ]}
                hasActions
              />

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedBuildingIds.length > 0 &&
                        selectedBuildingIds.length === buildings.length
                      }
                      color="primary"
                      indeterminate={
                        selectedBuildingIds.length > 0 &&
                        selectedBuildingIds.length < buildings.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по адресу"
                      variant="outlined"
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField
                      sx={{
                        minWidth: '100%'
                      }}
                      placeholder="Поиск по территории"
                      variant="outlined"
                      onChange={(event) => setArea(event.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buildings.slice(0, size).map((building) => (
                  <TableRow
                    hover
                    key={building.id}
                    selected={selectedBuildingIds.indexOf(building.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedBuildingIds.indexOf(building.id) !== -1
                        }
                        onChange={(event) =>
                          handleSelectOne(event, building.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={building.avatarUrl}
                          sx={{
                            mr: 2,
                            bgcolor: buildingColor(building.type.code)
                          }}
                        >
                          {buildingAvatar(building.type.code)}
                          {/* {getInitials(building.type.name)} */}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          onClick={() =>
                            copyToClipboard(building.address, () =>
                              dispatch(
                                showMessage({
                                  open: true,
                                  text: 'Адрес скопирован в буфер обмена',
                                  severity: 'success'
                                })
                              ))}
                        >
                          {building.address || 'Нет адреса'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        {building.area?.name || 'Без территории'}
                      </Typography>
                    </TableCell>
                    <TableCell size="small">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Tooltip
                          title={editPermission ? 'Редактировать' : 'Смотреть'}
                          placement="top"
                          followCursor
                          enterDelay={1000}
                        >
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            onClick={() => openBuildingClick(building)}
                          >
                            <SvgIcon fontSize="medium" color="primary">
                              {editPermission ? (
                                <Edit />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                        {editPermission && (
                          <Tooltip
                            title="Добавить в архив"
                            placement="top"
                            followCursor
                            enterDelay={1000}
                          >
                            <IconButton
                              aria-label="archive"
                              color="secondary"
                              onClick={() => onArchiveBuildingClick(building)}
                            >
                              <SvgIcon
                                fontSize="medium"
                                color="secondary"
                                /* sx={{ color: '#ef6c00' }} */
                              >
                                <Archive />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={buildingsTotal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      {editPermission && (
        <HomesMulti
          buildingsToArchive={selectedBuildingIds.length}
          buildingsToArchiveClick={() => setArchiveConfirmDialog(true)}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <BuildingMultiArchiveDialog
          showDialog={archiveConfirmDialog}
          counter={selectedBuildingIds.length}
          handleConfirm={() => onArchiveBuildings()}
          handleClose={() => setArchiveConfirmDialog(false)}
        />
      )}
    </>
  );
};

HomesList.propTypes = {};

export default HomesList;
