import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import DeviceEditDialog from './dialogs/DeviceEditDialog';
import DeviceDeleteDialog from './dialogs/DeviceDeleteDialog';
import DeviceRestoreDialog from './dialogs/DeviceRestoreDialog';
import DeviceShowDialog from './dialogs/DeviceShowDialog';
import DeviceAddDialog from './dialogs/DeviceAddDialog';
import DeviceMessageDialog from './dialogs/DeviceMessageDialog';

const DevicesEditor = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action1, actionId1, stepName } = useParams();

  const [navigationDepth, setNavigationDepth] = useState(0);
  const [actionLast, setActionLast] = useState('');
  const [pathname, setPathname] = useState('');

  // navigation effect
  useEffect(() => {
    if (pathname === '') {
      // first call
    } else if (pathname.includes(location.pathname)) {
      setNavigationDepth((depth) => Math.max(0, depth - 1));
    } else {
      setNavigationDepth((depth) => 0);
    }
    setPathname(location.pathname);
  }, [location]);

  const handleClose = useCallback(() => {
    let unmounted = false;

    if (navigationDepth) {
      setNavigationDepth((depth) => depth - 1);
      navigate(-1);
    } else if (stepName || actionId1) {
      navigate(-1, { replace: true });
      // navigate('../../', { replace: true });
    } else {
      navigate(-1, { replace: true });
    }
    // else {
    //   navigate('../', { replace: true });
    // }
    setActionLast(action1);
    setTimeout(() => {
      if (!unmounted) {
        setActionLast('');
      }
    }, 300);
    return () => {
      unmounted = true;
    };
  }, [navigationDepth, action1, actionId1, stepName]);

  return (
    <>
      {(action1 === 'add' || actionLast === 'add') && (
        <DeviceAddDialog
          handleClose={handleClose}
        />
      )}

      {(action1 === 'show' || actionLast === 'show') && (
        <DeviceShowDialog
          handleClose={handleClose}
        />
      )}

      {(action1 === 'edit' || actionLast === 'edit') && (
        <DeviceEditDialog
          handleClose={handleClose}
        />
      )}

      {(action1 === 'delete' || actionLast === 'delete') && (
        <DeviceDeleteDialog
          handleClose={handleClose}
        />
      )}

      {(action1 === 'restore' || actionLast === 'restore') && (
        <DeviceRestoreDialog
          handleClose={handleClose}
        />
      )}

      {(action1 === 'message' || actionLast === 'message') && (
        <DeviceMessageDialog
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default DevicesEditor;
