import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userWritePermission } from '../../../store/selectors/account.selector';
import decOfNum from '../../../utils/decOfNum';

const RolesToolbar = ({
  rolesActive,
  rolesDeleteClick,
  ...props
}) => {
  const editPermission = useSelector(userWritePermission);

  return (
    <Box {...props}>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ mx: 1 }}>
          Экспорт
        </Button>*/}
        {editPermission && (
          <RouterLink to="/app/users/roles/add">
            <Button
              color="primary"
              variant="contained"
            >
              Добавить роль
            </Button>
          </RouterLink>
        )}
        {editPermission && !!rolesActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => rolesDeleteClick()}
          >
            Удалить&nbsp;
            { rolesActive }
            &nbsp;
            { decOfNum(rolesActive, ['роль', 'роли', 'ролей']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

RolesToolbar.propTypes = {
  rolesActive: PropTypes.number,
  rolesDeleteClick: PropTypes.func,
};

RolesToolbar.defaultProps = {
  rolesActive: 0,
  rolesDeleteClick: null,
};

export default RolesToolbar;
