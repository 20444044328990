import { useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { logsReadPermission } from '../../store/selectors/account.selector';

const LogsToolbar = ({
  ...props
}) => {
  const readPermission = useSelector(logsReadPermission);

  return (
    <Box sx={{ my: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 1,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ ml: 1 }}>
          Экспорт
        </Button>*/}
      </Box>
    </Box>
  );
};

export default LogsToolbar;
