import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { userWritePermission } from '../../../store/selectors/account.selector';
import decOfNum from '../../../utils/decOfNum';

const RolesMulti = ({
  rolesActive,
  rolesDeleteClick,
  ...props
}) => {
  const editPermission = useSelector(userWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {editPermission && !!rolesActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => rolesDeleteClick()}
          >
            Удалить&nbsp;
            { rolesActive }
            &nbsp;
            { decOfNum(rolesActive, ['роль', 'роли', 'ролей']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

RolesMulti.propTypes = {
  rolesActive: PropTypes.number,
  rolesDeleteClick: PropTypes.func,
};

RolesMulti.defaultProps = {
  rolesActive: 0,
  rolesDeleteClick: null,
};

export default RolesMulti;
