const initialState = {
  list: [],
  listState: 'idle',
  listVersion: 0,
};

function faqReducer(state = initialState, action) {
  switch (action.type) {
    case 'faq/list': {
      return {
        ...state,
        list: action.data,
        listState: 'success'
      };
    }
    case 'faq/refresh': {
      return {
        ...state,
        listVersion: state.listVersion + 1,
        listState: 'refresh',
      };
    }
    default:
      return state;
  }
}

export default faqReducer;
