import { blue } from '@material-ui/core/colors';
import { DEVICE_MODE } from '../steps/DeviceGate';

const deviceEmptyState = {
  id: 'device',
  type: '',
  flatRanges: [
    {
      from: '',
      to: ''
    }
  ],
  sip: {
    host: '',
    port: '',
    number: '',
    password: ''
  },
  sip2: {
    enabled: false
  },
  callOnMobileWhileActivation: false,
  gate: {
    mode: DEVICE_MODE.MODE_1.value,
    entrancesCount: 0,
    houses: [],
    openDoor: false,
    isIntercomOnGate: false
  },
  created: '',
  updated: '',
  status: { name: '' },
  mifareSupported: false
};

const deviceDialogStyles = {
  paper: {
    maxWidth: '1024px'
  },
  content: {
    paddingBottom: '8px !important',
    display: 'flex',
    width: '100%'
  },
  stepperBig: {
    minWidth: '120px',
    minHeight: '760px',
    borderRight: '1px solid #ddd',
    marginRight: '16px',
    marginBottom: '10px',
    '& .MuiStepConnector-vertical': {
      maxHeight: '5px',
    },
    '& .MuiStepConnector-lineVertical': {
      marginTop: '-5px',
      marginLeft: '-1px',
      minHeight: '15px',
    },
  },
  scroll: {
    // maxWidth: '100%',
    // width: '1002px',
    // height: '48px',
  },
  scroll_long: {
    // maxWidth: '100%',
    // width: '1432px',
    // height: '48px',
  },
  container: {
    marginLeft: '8px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
  },
  view: {
    marginTop: '4px',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 0 0',
  },
  button: {
    marginLeft: '12px !important',
  },
  spacer: {
    flex: '1 1 auto',
  },
  completed: {
    display: 'inline-block',
  },
  panel: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '2px',
  }
};

export { deviceDialogStyles, deviceEmptyState };
