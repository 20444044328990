import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import { userWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import { deleteRole, getRoles } from '../../../store/actions/roles.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import RolesMulti from './RolesMulti';
import RolesConfirm from './RolesConfirm';
import RolesToolbar from './RolesToolbar';
import EnhancedTableHead from '../../TableHead';

const RolesList = ({ ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rolesIsLoading = useSelector(
    (state) => state.roles.state !== 'success'
  );
  const roles = useSelector((state) => state.roles.list);

  const editPermission = useSelector(userWritePermission);
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const [size, setLSize] = useState(10);
  const [page, setPage] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const sortRef = useRef([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortRef.current.filter((v) => v === property)?.length > 1) {
      sortRef.current = [];
      return setOrderBy('');
    }
    sortRef.current.push(property);
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  const handleSelectAll = (event) => {
    let newSelectedRoleIds;

    if (event.target.checked) {
      newSelectedRoleIds = roles.map((role) => role.id);
    } else {
      newSelectedRoleIds = [];
    }

    setSelectedRoleIds(newSelectedRoleIds);
  };

  useEffect(async () => {
    try {
      await store.dispatch(
        getRoles({
          page,
          size,
          sort: !orderBy
            ? undefined
            : orderBy && `${orderBy},${order === 'asc' ? 'ASC' : 'DESC'}`
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [page, size, orderBy, order]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [size]);

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRoleIds.indexOf(id);
    let newSelectedRoleIds = [];

    if (selectedIndex === -1) {
      newSelectedRoleIds = newSelectedRoleIds.concat(selectedRoleIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRoleIds = newSelectedRoleIds.concat(selectedRoleIds.slice(1));
    } else if (selectedIndex === selectedRoleIds.length - 1) {
      newSelectedRoleIds = newSelectedRoleIds.concat(
        selectedRoleIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedRoleIds = newSelectedRoleIds.concat(
        selectedRoleIds.slice(0, selectedIndex),
        selectedRoleIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRoleIds(newSelectedRoleIds);
  };

  const handleLimitChange = (event) => {
    setLSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const editRole = (role) => {
    if (editPermission) {
      navigate(`/app/users/roles/edit/${role.id}`);
    } else {
      navigate(`/app/users/roles/show/${role.id}`);
    }
  };

  const onDeleteRoleClick = (role) => {
    navigate(`/app/users/roles/delete/${role.id}`);
  };

  const updateRolesStatus = async (
    filterFunc,
    updateAction,
    successMessage,
    errorMessage
  ) => {
    try {
      const toUpdate = roles
        .filter((item) => selectedRoleIds.includes(item.id))
        .filter(filterFunc);
      await Promise.all(
        toUpdate.map((item) => store.dispatch(updateAction(item.id)))
      );
      dispatch(
        showMessage({ open: true, text: successMessage, severity: 'success' })
      );
      setTimeout(() => store.dispatch(getRoles()), 500);
      setSelectedRoleIds([]);
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          open: true,
          text: errorMessage + ' ' + error.message,
          severity: 'error'
        })
      );
    }
  };

  const onRolesDeleteClick = () => {
    setConfirmDialog(true);
  };

  const onRolesConfirmClick = () => {
    updateRolesStatus(
      (item) => item,
      deleteRole,
      'Роли успешно удалены.',
      'Не удалось удалить.'
    );
    setConfirmDialog(false);
  };

  return (
    <>
      <RolesToolbar
        rolesActive={
          roles.filter((item) => selectedRoleIds.includes(item.id)).length
        }
        rolesDeleteClick={() => onRolesDeleteClick()}
      />
      <Card
        {...rest}
        style={{
          backgroundColor: rolesIsLoading ? 'lightyellow' : 'white',
          transition: 'background-color 400ms ease-in'
        }}
      >
        <Helmet>
          <title>Роли | Терион</title>
        </Helmet>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <EnhancedTableHead
                ActionBox={() => (
                  <Checkbox
                    checked={
                      selectedRoleIds.length > 0 &&
                      selectedRoleIds.length === roles.length
                    }
                    color="primary"
                    indeterminate={
                      selectedRoleIds.length > 0 &&
                      selectedRoleIds.length < roles.length
                    }
                    onChange={handleSelectAll}
                  />
                )}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={[
                  {
                    sortable: false,
                    id: 'id',
                    label: 'ID'
                  },
                  {
                    sortable: true,
                    id: 'name',
                    label: 'Название',
                    style: { width: '30%' }
                  }
                ]}
                hasActions
              />

              <TableBody>
                {roles.slice(page * size, page * size + size).map((role) => (
                  <TableRow
                    hover
                    key={role.id}
                    selected={selectedRoleIds.indexOf(role.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRoleIds.indexOf(role.id) !== -1}
                        onChange={(event) => handleSelectOne(event, role.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{role.id}</TableCell>
                    <TableCell>{role.name}</TableCell>
                    <TableCell size="small">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Tooltip
                          title={editPermission ? 'Редактировать' : 'Смотреть'}
                          placement="top"
                          followCursor
                          enterDelay={1000}
                        >
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            onClick={() => editRole(role)}
                          >
                            <SvgIcon fontSize="medium" color="primary">
                              {editPermission ? (
                                <Edit />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                        {editPermission && (
                          <Tooltip
                            title="Удалить"
                            placement="top"
                            followCursor
                            enterDelay={1000}
                          >
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              onClick={() => onDeleteRoleClick(role)}
                            >
                              <SvgIcon fontSize="medium" color="secondary">
                                <Delete />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={roles.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={size}
          rowsPerPageOptions={[5, 10, 100, 1000]}
        />
      </Card>

      {editPermission && (
        <RolesMulti
          rolesActive={
            roles.filter((item) => selectedRoleIds.includes(item.id)).length
          }
          rolesDeleteClick={() => onRolesDeleteClick()}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <RolesConfirm
          showDialog={confirmDialog}
          counter={
            roles.filter((item) => selectedRoleIds.includes(item.id)).length
          }
          handleConfirm={() => onRolesConfirmClick()}
          handleClose={() => setConfirmDialog(false)}
        />
      )}
    </>
  );
};

RolesList.propTypes = {};

export default RolesList;
