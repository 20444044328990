import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

const DeviceOverlay = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.videoTextOverlay || { active: true, title: '' }}
      validationSchema={
        Yup.object().shape({
          mailTitle: Yup.string(),
          smtpPort: Yup.string(),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки текста поверх видео устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={4}
            >
              {/* active field */}
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{ ml: 1 }}
                    checked={values.active}
                    onChange={
                      (event) => {
                        if (!editPermission || isReadonly) {
                          return
                        }
                        setFieldValue('active', event.target.checked);
                        onUpdate({ videoTextOverlay: { ...values, active: event.target.checked } });
                      }
                    }
                  />
                )}
                label="Разрешить"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
            >
              {/* title field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.title && errors.title)}
                  htmlFor="outlined-overlay-title"
                >
                  Текст на видео
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.title && errors.title)}
                  id="outlined-overlay-title"
                  type="text"
                  autoComplete="off"
                  value={values.title || ''}
                  onChange={(event) => {
                    setFieldValue('title', event.target.value);
                    onUpdate({ videoTextOverlay: { ...values, title: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="Текст на видео"
                />
                {touched.title && errors.title && (
                  <FormHelperText error id="outlined-title-error">
                    {errors.title}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DeviceOverlay.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DeviceOverlay.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DeviceOverlay, isDevicePropEqual);
