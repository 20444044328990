const initialState = {
  list: [{ id: '', name: 'Супер админ' }],
  state: 'idle',
  version: 0,
};

function rolesReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case 'roles/loaded': {
      return { ...state, list: action.data, state: 'success' };
    }
    case 'roles/refresh': {
      return {
        ...state,
        state: 'refresh',
        version: state.version + 1,
      };
    }
    default:
      return state;
  }
}

export default rolesReducer;
