const initialState = {
  list: [],
  listTotal: 0,
  listState: 'idle',
  customersList: [],
  customersListState: 'idle',
  types: [{ name: 'All', code: '' }, { name: 'CREATE', code: 'CREATE' }, { name: 'UPDATE', code: 'UPDATE' }, { name: 'ARCHIVE', code: 'ARCHIVE' }, { name: 'UNARCHIVE', code: 'UNARCHIVE' }, { name: 'DELETE', code: 'DELETE' }, { name: 'LOGIN', code: 'LOGIN' }],
  sections: [{ name: 'Все логи', code: '', path: '' }, { name: 'Дома', code: 'HOUSING_STOCK', path: 'places' }, { name: 'Пользователи', code: 'USERS', path: 'users' }, { name: 'Абоненты', code: 'ABONENTS', path: 'subscribers' }, { name: 'Устройства', code: 'INTERCOMS', path: 'devices' }],
};

function logsReducer(state = initialState, action) {
  // console.log(action.type, action.data?.pageable?.totalElements)
  switch (action.type) {
    case 'events/list': {
      return {
        ...state,
        list: action.data.content,
        listTotal: action.data?.pageable?.totalElements,
        listState: 'success'
      };
    }
    case 'events/customersList': {
      return {
        ...state,
        customersList: action.data,
        customersListState: 'success'
      };
    }
    case 'events/loading': {
      return {
        ...state,
        listState: 'loading'
      };
    }
    default:
      return state;
  }
}

export default logsReducer;
