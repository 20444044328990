import api from '../../api/client';
import { handleServerError } from './utils';
import { showServerErrorMessage } from './messages.actions';

export function setSips(data) {
  return { type: 'sips/list', data };
}

export function setSipsCache(data) {
  return { type: 'sips/cache', data };
}

export function setSipsRefresh(data) {
  return { type: 'sips/refresh', data };
}

export function setSipsListState(data) {
  return { type: 'sips/listState', data };
}

// async thunk actions

export function getSips(data) {
  return async (dispatch) => {
    try {
      dispatch(setSipsListState('loading'));
      const response = await api.getSips(data);
      dispatch(setSips(response.data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getSip(id) {
  return async () => {
    try {
      const response = await api.getSip(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createSip(data) {
  return async () => {
    try {
      const response = await api.createSip(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putSip(data) {
  return async () => {
    try {
      const response = await api.putSip(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteSip(data) {
  return async () => {
    try {
      const response = await api.deleteSip(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function blockSip(data) {
  return async () => {
    try {
      const response = await api.blockSip(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function restoreSip(data) {
  return async () => {
    try {
      const response = await api.restoreSip(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
