import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const TimeMask = forwardRef(({ onChange, ...props }, ref) => (
  <NumberFormat
    {...props}
    getInputRef={ref}
    onValueChange={(values) => {
      onChange(values.formattedValue);
    }}
    format="##:##"
    allowEmptyFormatting
    mask="_"
  />
));

TimeMask.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TimeMask;
