const initialState = {
  cache: {},
  list: [],
  total: 0,
  state: 'idle',
  version: 0,
};

function accountReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case 'customers/list': {
      return { ...state, list: action.data, total: action.data.length, state: 'success' };
    }
    case 'customers/refresh': {
      return {
        ...state,
        state: 'refresh',
        version: state.version + 1,
      };
    }
    case 'customers/cache': {
      return {
        ...state,
        cache: { ...state.cache, [action.data.id]: action.data },
      };
    }
    default:
      return state;
  }
}

export default accountReducer;
