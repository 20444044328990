import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField, Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';
import store from '../../../../store/store';
import { getTimeZones } from '../../../../store/actions/devices.actions';

const DevicesDateTime = ({ isReadonly, device, onUpdate, ...props }) => {
  const editPermission = useSelector(deviceWritePermission);
  const timezones = useSelector((state) => state.devices.timezones);
  const timezonesStatus = useSelector((state) => state.devices.timezonesStatus);

  if (!timezones.length && timezonesStatus === 'idle') {
    store.dispatch(getTimeZones());
  }

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.dateTime || { ntpActive: false, ntpHost: '', ntpPort: '', timeZone: { id: 21, name: '()' } }}
      validationSchema={
        Yup.object().shape({
          // name: Yup.string().max(255).required('Забыли указать название'),
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки даты и времени устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={4}
            >
              {/* ntpActive field */}
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{ ml: 1 }}
                    checked={values.ntpActive}
                    onChange={
                      (event) => {
                        if (!editPermission || isReadonly) {
                          return
                        }
                        setFieldValue('ntpActive', event.target.checked);
                        onUpdate({ dateTime: { ...values, ntpActive: event.target.checked } });
                      }
                    }
                  />
                )}
                label="Использовать NTP"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
            >
              {/* ntpHost field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.ntpHost && errors.ntpHost)}
                  htmlFor="outlined-ntpHost"
                >
                  NTP хост
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.ntpHost && errors.ntpHost)}
                  id="outlined-ntpHost"
                  type="text"
                  autoComplete="off"
                  value={values.ntpHost}
                  onChange={(event) => {
                    setFieldValue('ntpHost', event.target.value);
                    onUpdate({ dateTime: { ...values, ntpHost: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="NTP хост"
                />
                {touched.ntpHost && errors.ntpHost && (
                  <FormHelperText error id="outlined-ntpHost-error">
                    {errors.ntpHost}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
            >
              {/* ntpPort field */}
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(touched.ntpPort && errors.ntpPort)}
                  htmlFor="outlined-ntpPort"
                >
                  NTP port
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(touched.ntpPort && errors.ntpPort)}
                  id="outlined-ntpPort"
                  type="text"
                  autoComplete="off"
                  value={values.ntpPort}
                  onChange={(event) => {
                    setFieldValue('ntpPort', event.target.value);
                    onUpdate({ dateTime: { ...values, ntpPort: event.target.value } });
                  }}
                  readOnly={!editPermission || isReadonly}
                  label="NTP port"
                />
                {touched.ntpPort && errors.ntpPort && (
                  <FormHelperText error id="outlined-ntpPort-error">
                    {errors.ntpPort}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              {/* encoding field */}
              <Autocomplete
                id="combo-box-video-main-encoding"
                disableClearable
                disabled={!editPermission || isReadonly}
                options={timezones}
                getOptionLabel={(item) => item.name}
                isOptionEqualToValue={(option, value) => (option?.id === value.id || option.name === value.name)}
                value={values.timeZone || { name: '' }}
                onChange={(event, value) => {
                  setFieldValue('timeZone', value);
                  onUpdate({ dateTime: { ...values, timeZone: value } });
                }}
                renderInput={(params) => <TextField {...params} label="Часовой пояс" />}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

DevicesDateTime.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesDateTime.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DevicesDateTime, isDevicePropEqual);
