import store from '../store';

export function showMessage(data) {
  const prevMessage = store.getState().messages.message;
  const type = prevMessage.open === true ? 'messages/nextMessages' : 'messages/showMessage';
  return { type, data };
}

export function showNoAccessMessage() {
  return { type: 'messages/showMessage', data: { open: true, text: 'У вас нет доступа к этому разделу, обратитесь к администратору.', severity: 'error' } };
}

export function showInternetErrorMessage() {
  return { type: 'messages/showMessage', data: { open: true, text: 'Не удалось соединиться с сервером. Проверьте VPN и интернет-соединение.', severity: 'error' } };
}

export function showServerErrorMessage(error = null) {
  return { type: 'messages/showMessage', data: { open: true, text: error?.message || 'Не удалось получить данные. Ошибка сервера.', severity: 'error' } };
}

export function hideMessage() {
  return { type: 'messages/hideMessage' };
}

export function showNextMessages() {
  return { type: 'messages/showNextMessages' };
}
