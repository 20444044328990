import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import 'moment/locale/ru';
import { createSip, getSip, putSip, setSipsCache, setSipsRefresh } from '../../../store/actions/sips.actions';
import { showMessage } from '../../../store/actions/messages.actions';
import { contractWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import PhoneMask from '../../common/PhoneMask';

moment.locale('ru');

const statuses = [
  { code: 'ACTIVE', name: 'Активный' },
  { code: 'BLOCKED', name: 'Заблокированный' },
  { code: 'DELETED', name: 'Удалённый' },
];

const sipEmptyState = {
  id: 'sip',
  phone: '',
  number: '',
  password: '',
  contracts: [],
};

const SipDialog = ({ isArchive, handleClose, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action1, actionId1, action2, actionId2 } = useParams();

  const editPermission = useSelector(contractWritePermission);

  const [sipDetails, setSipDetails] = useState({ ...sipEmptyState });
  const [sip, setSip] = useState({ ...sipEmptyState });
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const sipsCache = useSelector((state) => state.sips.cache);
  const sips = useSelector((state) => state.sips.list);

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action1, actionId1, sip.intercoms[0], sipsCache);
    const sipFromList = actionId1 && (sips.find((item) => item.id === actionId1));
    if (action1 === 'edit' || action1 === 'show' || action1 === 'add') {
      setEditDialog(true);
      if (action1 === 'edit') {
        setEditDialogTitle('Редактировать SIP-аккаунт');
      } else if (action1 === 'show') {
        setEditDialogTitle('Смотреть SIP-аккаунт');
      } else if (action1 === 'add') {
        setEditDialogTitle('Создать SIP-аккаунт');
      }
    } else {
      setEditDialog(false);
      setTimeout(() => {
        if (!unmounted && sip.id) {
          if (sipsCache.sip && (sipsCache.sip.sip)) {
            // clear sip cache
            setSipDetails({ ...sipEmptyState, contracts: [] });
            dispatch(setSipsCache(sipEmptyState));
          }
        }
      }, 200);
    }
    if ((action1 === 'edit' || action1 === 'show' || action1 === 'add') && actionId1) {
      setSip({
        ...(sipsCache[actionId1] || sipEmptyState),
        ...sipFromList,
        ...(sipDetails.id === actionId1 && sipDetails),
      });
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, sips, sipDetails]);

  // sip details effect
  useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      try {
        const { data } = await store.dispatch(getSip(actionId1));
        dispatch(setSipsCache(data));
        if (data && data.id === actionId1) {
          setSipDetails({
            ...data,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (actionId1 && actionId1 !== 'sip') {
      getData();
    }
    return () => {
      unmounted = true;
    };
  }, [actionId1]);

  const onSaveSip = async (values) => {
    try {
      const data = {
        id: values.id !== 'sip' ? values.id : '',
        phone: values.phone,
        number: values.number,
        contracts: values.contracts.map((item) => (item.idIsLocal ? { ...item, id: '', idIsLocal: undefined } : item)) || [],
      };
      const response = await store.dispatch(data.id ? putSip(data) : createSip(data));
      dispatch(showMessage({ open: true, text: 'SIP аккаунт успешно сохранен.', severity: 'success' }));
      dispatch(setSipsCache(values));
      handleClose(null, 'success');
      setTimeout(() => dispatch(setSipsRefresh()), 500);
    } catch (error) {
      dispatch(showMessage({ open: true, text: `Не удалось сохранить SIP аккаунт. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      <Dialog
        {...props}
        open={editDialog}
        onClose={handleClose}
        fullWidth
        disableRestoreFocus
        scroll="body"
        PaperProps={{ sx: { maxWidth: '750px' } }}
        /* PaperProps={{ sx: { backgroundColor: 'background.default' } }} */
      >
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{ fontSize: '22px' }}>
            {editDialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={sip}
            validationSchema={
              Yup.object().shape({
                id: Yup.string(),
                number: Yup.string().max(255).required('Забыли указать номер контракта'),
                phone: Yup.string().min(10, 'Неверный телефон').required('Забыли указать телефон'),
                password: Yup.string().max(255).required('Забыли указать пароль'),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* phone field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.phone && errors.phone)}
                        htmlFor="outlined-phone"
                        shrink
                      >
                        Телефон
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.phone && errors.phone)}
                        id="outlined-phone"
                        type="text"
                        autoComplete="off"
                        notched
                        value={values.phone.length > 10 ? values.phone.substr(-10) : values.phone}
                        onChange={handleChange('phone')}
                        readOnly={!editPermission || true}
                        label="Телефон"
                        inputComponent={PhoneMask}
                      />
                      {touched.phone && errors.phone && (
                        <FormHelperText error id="outlined-phone-error">
                          {errors.phone}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* number field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.number && errors.number)}
                        htmlFor="outlined-number"
                      >
                        Номер SIP аккаунта
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.number && errors.number)}
                        id="outlined-number"
                        type="text"
                        autoComplete="off"
                        value={values.number}
                        onChange={handleChange('number')}
                        readOnly={!editPermission || true}
                        label="Номер SIP аккаунта"
                      />
                      {touched.number && errors.number && (
                        <FormHelperText error id="outlined-number-error">
                          {errors.number}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* password field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.password && errors.password)}
                        htmlFor="outlined-password"
                      >
                        Пароль
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.password && errors.password)}
                        id="outlined-password"
                        type="text"
                        autoComplete="off"
                        value={values.password}
                        onChange={handleChange('password')}
                        readOnly={!editPermission || true}
                        label="Пароль"
                      />
                      {touched.password && errors.password && (
                        <FormHelperText error id="outlined-password-error">
                          {errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* phones table */}
                <Box sx={{ my: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '20%' }}>
                          Номер контракта
                        </TableCell>
                        <TableCell style={{ width: '15%' }}>
                          Роль
                        </TableCell>
                        <TableCell style={{ width: '60%' }}>
                          Адрес
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sip.contracts && sip.contracts.map((contractItem, contactIndex) => (
                        <TableRow
                          hover
                          key={contractItem.id || contactIndex}
                        >
                          <TableCell>
                            {contractItem.number}
                          </TableCell>
                          <TableCell>
                            {contractItem.userContractRole === 'CONTACT' ? 'Жилец' : 'Абонент'}
                          </TableCell>
                          <TableCell>
                            {contractItem.fullAddress}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

SipDialog.propTypes = {
  isArchive: PropTypes.bool,
  handleClose: PropTypes.func,
};

SipDialog.defaultProps = {
  isArchive: false,
  handleClose: null,
};

export default SipDialog;
