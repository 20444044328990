import api from '../../api/client';
import { handleServerError } from './utils';
import { showInternetErrorMessage, showServerErrorMessage } from './messages.actions';

export function setIntercomTypes(data) {
  return { type: 'territories/intercomTypes', data };
}

export function setTerritoryTypes(data) {
  return { type: 'territories/territoryTypes', data };
}

export function setTerritories(data) {
  return { type: 'territories/list', data };
}

export function setTerritoriesArchive(data) {
  return { type: 'territories/archive', data };
}

export function setTerritoriesCache(data) {
  return { type: 'territories/cache', data };
}

export function setTerritoriesRefresh(data) {
  return { type: 'territories/refresh', data };
}

export function setTerritoriesListState(data) {
  return { type: 'territories/listState', data };
}

export function setTerritoriesArchiveState(data) {
  return { type: 'territories/archiveState', data };
}

// async thunk actions

export function getTerritoryTypes() {
  return async (dispatch) => {
    try {
      Promise.all([api.getIntercomTypes({ placeType: 'AREA' })])
        .then(([intercomTypesResponse]) => {
          dispatch(setIntercomTypes(intercomTypesResponse.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(showInternetErrorMessage());
        });
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getTerritories(data) {
  return async (dispatch) => {
    try {
      dispatch(data.active ? setTerritoriesListState('loading') : setTerritoriesArchiveState('loading'));
      const response = await api.getTerritories(data);
      dispatch(data.active ? setTerritories(response.data) : setTerritoriesArchive(response.data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getTerritory(id) {
  return async () => {
    try {
      const response = await api.getTerritory(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createTerritory(data) {
  return async () => {
    try {
      const response = await api.createTerritory(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putTerritory(data) {
  return async () => {
    try {
      const response = await api.putTerritory(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function archiveTerritory(data) {
  return async () => {
    try {
      const response = await api.archiveTerritory(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function unarchiveTerritory(data) {
  return async () => {
    try {
      const response = await api.unarchiveTerritory(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
