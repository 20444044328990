const isEqual = require('react-fast-compare');

const isDeviceEqual = (prev, next) => isEqual(prev.device, next.device);

// const isDeviceEqual = (prev, next) => {
//   console.log(isEqual(prev.device, next.device), prev.onUpdate === next.onUpdate)
//   return isEqual(prev.device, next.device);
// };

export default isDeviceEqual;
