import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { showMessage } from '../../../store/actions/messages.actions';
import { deviceWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import { restoreDevice, setDevicesRefresh } from '../../../store/actions/devices.actions';

function addressAndName(address, name) {
  return address + ', ' + name;
}

const DeviceRestoreDialog = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const { action1, actionId1 } = useParams();

  const editPermission = useSelector(deviceWritePermission);

  const devices = useSelector((state) => state.devices.list);
  const [deviceSelected, setDeviceSelected] = useState({});
  const [restoreDialog, setRestoreDialog] = useState(false);

  // state effect
  useEffect(() => {
    let unmounted = false;
    const deviceFromList = actionId1 && (devices.find((item) => item.id === actionId1));
    if (action1 === 'restore') {
      setRestoreDialog(true);
    } else {
      setRestoreDialog(false);
    }
    if (action1 === 'restore' && actionId1) {
      setDeviceSelected({
        ...deviceFromList,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, devices]);

  const onRestoreDevice = async () => {
    try {
      await store.dispatch(restoreDevice(actionId1));
      dispatch(showMessage({ open: true, text: 'Абонент успешно восстановлен', severity: 'success' }));
      handleClose(null, 'success');
      setTimeout(() => dispatch(setDevicesRefresh()), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось восстановить. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      {/* restore dialog */}
      <Dialog {...props} open={restoreDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Восстановить устройство?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Устройство с адресом </span>
            <strong>{addressAndName(deviceSelected.address, deviceSelected.name)}</strong>
            <span> будет восстановлено</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              {editPermission && (
                <Button
                  variant="contained"
                  color="purple"
                  fullWidth
                  onClick={onRestoreDevice}
                >
                  Восстановить
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeviceRestoreDialog.propTypes = {
  handleClose: PropTypes.func,
};

DeviceRestoreDialog.defaultProps = {
  handleClose: null,
};

export default memo(DeviceRestoreDialog);
