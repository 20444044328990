/* eslint-disable import/no-cycle */
import axios from 'axios';
import store from '../store/store';

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : '/api',
  headers: { Accept: 'image/jpeg' }
});

http.interceptors.request.use((res) => {
  const { token } = store.getState().account;
  // console.log(res, res.url === '/auth/signIn')
  if (res.url !== '/auth/signIn' && token) {
    res.headers.Authorization = `Bearer ${token}`;
  }
  return res;
});

http.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    // store.dispatch(expired());
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

const getSnapshot = (id) => http.get(`intercom/${id}/snapshot`, { responseType: 'blob' });

export default {
  getSnapshot,
};
