import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useParams } from 'react-router';
import { saveAs } from 'file-saver';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deviceWritePermission } from '../../../../../store/selectors/account.selector';
import decOfNum from '../../../../../utils/decOfNum';
import store from '../../../../../store/store';
import { showMessage } from '../../../../../store/actions/messages.actions';
import { copyMifareKeys, exportKeys, importKeys } from '../../../../../store/actions/devices.actions';

const DevicesToolbar = ({
  tagType,
  keysActive,
  keysDeleteClick,
  updateKeys,
  updateExtKeys,
  isReadonly,
  ...props
}) => {
  const editPermission = useSelector(deviceWritePermission);
  const dispatch = useDispatch();
  const { actionId1, stepName } = useParams();

  const onFileChange = useCallback(async () => {
    try {
      const fileElem = document.getElementById('fileElem' + tagType);
      const file = fileElem.files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      await store.dispatch(importKeys(actionId1, tagType, formData));
      setTimeout(() => updateKeys(), 500);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось экспортировать файл', severity: 'error' }));
    }
  }, [actionId1, tagType]);

  const onImportClick = useCallback(async () => {
    try {
      const fileElem = document.getElementById('fileElem' + tagType);
      fileElem.click();
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось импортировать файл', severity: 'error' }));
    }
  }, [actionId1, tagType]);

  const onExportClick = useCallback(async () => {
    try {
      const csv = await store.dispatch(exportKeys(actionId1, tagType));
      saveAs(new Blob([csv.data], { tagType: 'text/csv;charset=utf-8' }), `${actionId1}.csv`);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось экспортировать файл', severity: 'error' }));
    }
  }, [actionId1, tagType]);

  const onCopyClick = useCallback(async () => {
    try {
      await store.dispatch(copyMifareKeys(actionId1));
      updateExtKeys();
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: 'Не удалось скопировать основные метки', severity: 'error' }));
    }
  }, [actionId1, tagType]);

  return (
    <Box {...props}>
      <input type="file" id={'fileElem' + tagType} multiple accept="text/csv" style={{ display: 'none' }} onChange={() => onFileChange()} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 1,
        }}
      >
        {tagType === 'extrfid' ?
          <Button
            sx={{ ml: 1 }}
            color="primary"
            variant="contained"
            onClick={() => onCopyClick()}
            disabled={isReadonly || !editPermission}
          >
            Копировать из основной
          </Button>
          : null}

        {tagType !== 'extrfid' && !isReadonly && editPermission && (
        <Button onClick={() => onImportClick()} disabled={isReadonly || !editPermission}>
          Импорт
        </Button>)}
        {tagType !== 'extrfid' && !isReadonly && editPermission && (
          <Button sx={{ ml: 1 }} onClick={() => onExportClick()}>
            Экспорт
          </Button>)}
        {tagType !== 'extrfid' && !isReadonly && editPermission && (
          <RouterLink to="add/key">
            <Button
              sx={{ ml: 1 }}
              color="primary"
              variant="contained"
            >
              Добавить ключ
            </Button>
          </RouterLink>
        )}
        {tagType !== 'extrfid' && !isReadonly && editPermission && !!keysActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => keysDeleteClick()}
          >
            Удалить
            {' '}
            { keysActive }
            &nbsp;
            { decOfNum(keysActive, ['ключ', 'ключа', 'ключей']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

DevicesToolbar.propTypes = {
  tagType: PropTypes.string,
  keysActive: PropTypes.number,
  keysDeleteClick: PropTypes.func,
  updateKeys: PropTypes.func,
  updateExtKeys: PropTypes.func,
  isReadonly: PropTypes.bool
};

DevicesToolbar.defaultProps = {
  keysActive: false,
  keysDeleteClick: null,
  updateKeys: null,
  updateExtKeys: null,
  isReadonly: false
}

export default DevicesToolbar;
