import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import theme from './theme';
import routes from './routes';
import { hideMessage, showNextMessages } from './store/actions/messages.actions';
import { redirectUrl } from './store/actions/account.actions';
import Messages from './components/common/Messages';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const isLoggedExpired = !account.expirationDate || (new Date(account.expirationDate) < new Date());
  const isLoggedIn = !!account.token && !isLoggedExpired;
  const routing = useRoutes(routes(isLoggedIn, account.redirectUrl));
  if (!isLoggedIn && location.pathname.includes('/app') && account.redirectUrl !== location.pathname) {
    // console.log(account.redirectUrl, location.pathname)
    dispatch(redirectUrl(location.pathname));
  }

  if (isLoggedIn && location.pathname.includes('/login')) {
    setTimeout(() => navigate(account.redirectUrl || '/app/users/customers', { replace: true }), 100);
  }

  const message = useSelector((state) => state.messages.message);
  const nextMessages = useSelector((state) => state.messages.nextMessages);
  const handleClose = (_, reason) => {
    dispatch(hideMessage());
    if (nextMessages.length !== 0) {
      setTimeout(() => dispatch(showNextMessages()), 35);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Messages message={message} handleClose={handleClose} />
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
