import { createSelector } from 'reselect';

const selectPermissions = (state) => state.account.permissions;

const selectNavbarIsOpen = (state) => state.account.navbarIsOpen;

export const navbarIsOpen = createSelector(
  selectNavbarIsOpen, (opened) => opened
);

export const deviceReadPermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'INTERCOM');
    return found && found.attribute.includes('READ');
  }
);

export const deviceWritePermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'INTERCOM');
    return found && found.attribute.includes('READ_WRITE');
  }
);

export const keyReadPermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'KEY');
    return found && found.attribute.includes('READ');
  }
);

export const keyWritePermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'KEY');
    return found && found.attribute.includes('READ_WRITE');
  }
);

export const contractReadPermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'CONTRACT');
    return found && found.attribute.includes('READ');
  }
);

export const contractWritePermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'CONTRACT');
    return found && found.attribute.includes('READ_WRITE');
  }
);

export const placeReadPermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'PLACE');
    return found && found.attribute.includes('READ');
  }
);

export const placeWritePermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'PLACE');
    return found && found.attribute.includes('READ_WRITE');
  }
);

export const userReadPermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'USER');
    return found && found.attribute.includes('READ');
  }
);

export const userWritePermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'USER');
    return found && found.attribute.includes('READ_WRITE');
  }
);

export const faqReadPermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'FAQ');
    return found && found.attribute.includes('READ');
  }
);

export const faqWritePermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'FAQ');
    return found && found.attribute.includes('READ_WRITE');
  }
);

export const logsReadPermission = createSelector(
  selectPermissions,
  (permissions) => {
    const found = permissions.find((permission) => permission.code === 'LOGGING');
    return found && found.attribute.includes('READ');
  }
);
