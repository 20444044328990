import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import QuestionDialog from './dialogs/QuestionDialog';
import QuestionDeleteDialog from './dialogs/QuestionDeleteDialog';

const FaqEditor = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action1, actionId1 } = useParams();

  const [navigationDepth, setNavigationDepth] = useState(0);
  const [actionLast, setActionLast] = useState('');
  const [pathname, setPathname] = useState('');

  // navigation effect
  useEffect(() => {
    if (pathname === '') {
      // first call
    } else if (pathname.includes(location.pathname)) {
      setNavigationDepth((depth) => Math.max(0, depth - 1));
    } else {
      setNavigationDepth((depth) => depth + 1);
    }
    setPathname(location.pathname);
  }, [location]);

  const handleClose = useCallback(() => {
    let unmounted = false;
    if (navigationDepth) {
      navigate(-1);
    } else if (actionId1) {
      navigate('../../', { replace: true });
    } else {
      navigate('../', { replace: true });
    }
    setActionLast(action1);
    setTimeout(() => {
      if (!unmounted) {
        setActionLast('');
      }
    }, 300);
    return () => {
      unmounted = true;
    };
  }, [navigationDepth, action1, actionId1]);

  return (
    <>
      {(action1 !== '') && (
        <QuestionDialog
          handleClose={handleClose}
        />
      )}
      {(action1 === 'delete') && (
        <QuestionDeleteDialog
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default FaqEditor;
