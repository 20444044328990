import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { login } from '../store/actions/account.actions';
import store from '../store/store';

const Login = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  async function onSubmit(data, setFieldError) {
    try {
      await store.dispatch(login(data));
    } catch (error) {
      // console.log(error.message);
      setMessage(error.message || 'Неизвестная ошибка');
      setFieldError('password', error.message || 'Неизвестная ошибка');
      setOpen(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>Войти | Терион</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('Логин забыли ввести'),
              password: Yup.string().max(255).required('Пароль забыли ввести')
            })}
            onSubmit={async (data, { setSubmitting, setFieldError }) => {
              await onSubmit(data, setFieldError);
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Typography color="textPrimary" variant="h2">
                    Войти в систему
                  </Typography>
                  <Typography color="textPrimary" variant="subtitle1">
                    Введите свой логин и пароль
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Логин"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="username"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Пароль"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {isSubmitting && (
                      <CircularProgress
                        sx={{ mr: 1 }}
                        size="20px"
                        color="circular"
                      />
                    )}
                    Войти
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Забыли пароль?
                  <Link component={RouterLink} to="/reset" variant="h6">
                    Восстановить
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Dialog open={open}>
        <DialogTitle>Ошибка</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={() => setOpen(false)}>
            ОК
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Login;
