const initialState = {
  cache: {},
  list: [],
  listTotal: 0,
  listVersion: 0,
  listState: 'idle',
  archive: [],
  archiveTotal: 0,
  archiveVersion: 0,
  archiveState: 'idle',
  intercomTypes: [],
  territoryTypes: [],
};

function territoriesReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case 'territories/intercomTypes': {
      return {
        ...state,
        intercomTypes: action.data,
      };
    }
    case 'territories/territoryTypes': {
      return {
        ...state,
        territoryTypes: action.data,
      };
    }
    case 'territories/list': {
      return {
        ...state,
        list: action.data.content,
        listTotal: action.data.pageable.totalElements,
        listState: 'success'
      };
    }
    case 'territories/refresh': {
      return {
        ...state,
        listVersion: state.listVersion + 1,
        listState: 'refresh',
        archiveVersion: state.archiveVersion + 1,
        archiveState: 'refresh',
      };
    }
    case 'territories/archive': {
      return {
        ...state,
        archive: action.data.content,
        archiveTotal: action.data.pageable.totalElements,
        archiveState: 'success',
      };
    }
    case 'territories/cache': {
      return {
        ...state,
        cache: { ...state.cache, [action.data.id]: action.data },
      };
    }
    case 'territories/listState': {
      return {
        ...state,
        listState: 'refresh',
      };
    }
    case 'territories/archiveState': {
      return {
        ...state,
        archiveState: 'refresh',
      };
    }
    default:
      return state;
  }
}

export default territoriesReducer;
