import api from '../../api/client';
import { handleServerError } from './utils';
import { showServerErrorMessage } from './messages.actions';
import images from '../../api/images';

export function setDevices(data) {
  return { type: 'devices/list', data };
}

export function setDevicesCache(data) {
  return { type: 'devices/cache', data };
}

export function setDevicesRefresh(data) {
  return { type: 'devices/refresh', data };
}

export function setDevicesListState(data) {
  return { type: 'devices/listState', data };
}

export function setTimeZones(data) {
  return { type: 'devices/timezones', data };
}

export function setTimeZonesLoading() {
  return { type: 'devices/timezones/loading' };
}

export function setConfigurationStatus(data) {
  return { type: 'devices/configurationStatus', data };
}

// async thunk actions

export function getDevices(data) {
  return async (dispatch) => {
    try {
      dispatch(setDevicesListState('loading'));
      const response = await api.getDevices(data);
      dispatch(setDevices(response.data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getDevice(id) {
  return async () => {
    try {
      const response = await api.getDevice(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceBase(id) {
  return async () => {
    try {
      const response = await api.getDeviceBase(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putDeviceBase(id, data) {
  return async (dispatch) => {
    try {
      dispatch(setConfigurationStatus('loading'));
      const response = await api.putDeviceBase(id, data);
      dispatch(setConfigurationStatus('success'));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(setConfigurationStatus('error'));
      return handleServerError(error);
    }
  };
}

export function getDeviceSip(id) {
  return async () => {
    try {
      const response = await api.getDeviceSip(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceSip(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceSip(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceSip2(id) {
  return async () => {
    try {
      const response = await api.getDeviceSip2(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceSip2(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceSip2(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceGate(id) {
  return async () => {
    try {
      const response = await api.getDeviceGate(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
export function getDeviceGateEntrances(id) {
  return async () => {
    try {
      const response = await api.getDeviceGateEntrances(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceGate(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceGate(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConnection(id) {
  return async () => {
    try {
      const response = await api.getDeviceConnection(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putDeviceConnection(id, data) {
  return async () => {
    try {
      const response = await api.putDeviceConnection(id, data);
      return Promise.resolve(response);
    } catch (error) {
      console.log(error)
      return handleServerError(error);
    }
  };
}

export function getDeviceConfiguration(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfiguration(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceCallOnMobileWhileActivationInfo(id) {
  return async () => {
    try {
      const response = await api.getCanActivateMP(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationSingle(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationSingle(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfiguration(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfiguration(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationRfid(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationRfid(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationExtRfid(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationExtRfid(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationMifare(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationMifare(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationMifare(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationMifare(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationMifareV2(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationMifareV2(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationMifareV2(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationMifareV2(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationLan(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationLan(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationLan(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationLan(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationEmail(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationEmail(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationEmail(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationEmail(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationAudio(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationAudio(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationAudio(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationAudio(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationVideo(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationVideo(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationVideo(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationVideo(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationDisplay(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationDisplay(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationDisplay(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationDisplay(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationDate(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationDate(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationDate(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationDate(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationOverlay(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationOverlay(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationOverlay(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationOverlay(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationDetector(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationDetector(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationDetector(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationDetector(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationSwitch(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationSwitch(id);
      const responseArray = await api.getDeviceConfigurationSwitchArray(id);
      return Promise.resolve({ data: { ...response?.data, ...responseArray?.data } });
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationSwitchArray(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationSwitchArray(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationSwitch(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationSwitch(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationSwitchFill(id, data) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationSwitchFill(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationLevels(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationLevels(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function startDeviceConfigurationLevels(id) {
  return async () => {
    try {
      const response = await api.startDeviceConfigurationLevels(id);
      return Promise.resolve({});
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationLevels(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationLevels(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationLineLevel(id, data) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationLineLevel(id, data);
      return Promise.resolve(response.data);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationSysLog(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationSysLog(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationSysLog(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationSysLog(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationUser(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationUser(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function setDeviceConfigurationUser(id, data) {
  return async () => {
    try {
      const response = await api.setDeviceConfigurationUser(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getDeviceConfigurationLog(id) {
  return async () => {
    try {
      const response = await api.getDeviceConfigurationLog(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createDevice(data) {
  return async () => {
    try {
      const response = await api.createDevice(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function putDevice(data) {
  return async () => {
    try {
      const response = await api.putDevice(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function archiveDevice(data) {
  return async () => {
    try {
      const response = await api.archiveDevice(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function restoreDevice(data) {
  return async () => {
    try {
      const response = await api.restoreDevice(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getTimeZones() {
  return async (dispatch) => {
    try {
      dispatch(setTimeZonesLoading());
      const response = await api.getTimeZones();
      dispatch(setTimeZones(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function openDoor(id, data) {
  return async (dispatch) => {
    try {
      const response = await api.openDoor(id, data);
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function keepOpenDoor(id, data) {
  return async (dispatch) => {
    try {
      const response = await api.keepOpenDoor(id, data);
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function autoCollect(id, data) {
  return async (dispatch) => {
    try {
      const response = await api.autoCollect(id, data);
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function getAutoCollectMifare(id) {
  return async (dispatch) => {
    try {
      const response = await api.getAutoCollectMifare(id);
      return Promise.resolve(response);
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function autoCollectMifare(id, data) {
  return async (dispatch) => {
    try {
      const response = await api.autoCollectMifare(id, data);
      return Promise.resolve();
    } catch (error) {
      dispatch(showServerErrorMessage(error));
      return handleServerError(error);
    }
  };
}

export function createKey(id, data, tagType) {
  return async () => {
    try {
      let response;
      if (tagType === 'rfid') {
        response = await api.createKey(id, data);
      } else {
        response = await api.createMifareKey(id, data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function updateKey(id, data, tagType) {
  return async () => {
    try {
      let response;
      if (tagType === 'rfid') {
        response = await api.updateKey(id, data);
      } else {
        response = await api.updateMifareKey(id, data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteKey(id, key, type) {
  // console.log(id, key, type)
  return async () => {
    let response;
    try {
      if (type === 'Сервисный') {
        response = await api.deleteServiceKey(id, key);
      } else if (type === 'Пользовательский') {
        response = await api.deleteCustomerKey(id, key);
      } else {
        response = await api.deleteKey(id, key);
      }
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteMifareKeyV2(id, key) {
  return async () => {
    let response;
    try {
      response = await api.deleteMifareKey(id, key);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function createCipher(id, data) {
  return async () => {
    try {
      const response = await api.createCipher(id, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function deleteCipher(id, index) {
  return async () => {
    try {
      const response = await api.deleteCipher(id, index);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function importKeys(id, tagName, file) {
  return async () => {
    try {
      const response = tagName === 'rfid' ? await api.importKeys(id, file) : await api.importMifareKeys(id, file);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function copyMifareKeys(id) {
  return async () => {
    try {
      const response = await api.copyMifareKeys(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function exportKeys(id, tagName) {
  return async () => {
    try {
      const response = tagName === 'rfid' ? await api.exportKeys(id) : await api.exportMifareKeys(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function sendDeviceMessage(data) {
  return async () => {
    try {
      const response = await api.sendDeviceMessage(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function checkLicenseIntercom() {
  return async () => {
    try {
      const response = await api.checkLicenseIntercom();
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function getSnapshot(id) {
  return async () => {
    try {
      const response = await images.getSnapshot(id);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
