/* eslint-disable import/no-cycle */
import axios from 'axios';
import store from '../store/store';

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : '/api',
  headers: { Accept: 'application/json' }
});

http.interceptors.request.use((res) => {
  const { token } = store.getState().account;
  // console.log(res, res.url === '/auth/signIn')
  if (res.url !== '/auth/signIn' && token) {
    res.headers.Authorization = `Bearer ${token}`;
  }
  return res;
});

http.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    // store.dispatch(expired());
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

/* auth */

const login = (data) => http.post('/login', data);

const reset = (data) => http.post('user/forgot', data);

const update = (data) => http.put('user/reset', data);

/* logs */

const getLogs = (data) => http.get('logs', { params: data });

/* customers */

const getCustomers = (data) => http.get('users', { params: data });

const getCustomer = (id) => http.get(`user/${id}`);

const createCustomer = (data) => http.post('users', data);

const putCustomer = (data) => http.put(`user/${data.id}`, data);

const deleteCustomer = (id) => http.delete(`user/${id}`);

/* roles */

const getRoles = (data) => http.get('roles', { params: data });

const getRole = (id) => http.get(`role/${id}`);

const createRole = (data) => http.post('roles', data);

const putRole = (data) => http.put(`role/${data.id}`, data);

const deleteRole = (id) => http.delete(`role/${id}`);

/* departments */

const getDepartments = (data) => http.get('departments', { params: data });

const getDepartment = (id) => http.get(`department/${id}/users`);

const createDepartment = (data) => http.post('departments', data);

const putDepartment = (data) => http.put(`department/${data.id}`, data);

const deleteDepartment = (id) => http.delete(`department/${id}`);

/* buildings */

const getTimeZones = () => http.get('catalog/intercomTimeZones');

const getBuildingTypes = () => http.get('catalog/buildingTypes');

const getTerritoryTypes = () => http.get('catalog/territoryTypes');

const getIntercomTypes = (data) =>
  http.get('catalog/intercomPlacingTypes', { params: data });

const getBuildings = (data) => http.get('buildings', { params: data });

const getBuilding = (id) => http.get(`building/${id}`);

const createBuilding = (data) => http.post('buildings', data);

const putBuilding = (data) => http.put(`building/${data.id}`, data);

const archiveBuilding = (id) => http.post(`building/${id}/archive`);

const unarchiveBuilding = (id) => http.post(`building/${id}/unarchive`);

const searchArea = (data) => http.get('areas/autocomplete', { params: data });

const searchBuilding = (data) =>
  http.get('buildings/autocomplete', { params: data });

const searchAddress = (data) =>
  http.get('places/autocomplete', { params: data });

const getTerritories = (data) => http.get('areas', { params: data });

const getTerritory = (id) => http.get(`area/${id}`);

const createTerritory = (data) => http.post('areas', data);

const putTerritory = (data) => http.put(`area/${data.id}`, data);

const archiveTerritory = (id) => http.post(`area/${id}/archive`);

const unarchiveTerritory = (id) => http.post(`area/${id}/unarchive`);

const getSubscribers = (data) => http.get('contracts', { params: { ...data } });

const getSubscriber = (id) => http.get(`contract/${id}`);

const createSubscriber = (data) => http.post('contracts', data);

const putSubscriber = (data) => http.put(`contract/${data.id}`, data);

const deleteSubscriber = (id) => http.delete(`contract/${id}`);

const restoreSubscriber = (id) =>
  http.patch(`contract/${id}`, { status: 'ACTIVE' });

const unblockSubscriber = (id) =>
  http.patch(`contract/${id}`, { status: 'ACTIVE' });

const blockSubscriber = (id) =>
  http.patch(`contract/${id}`, { status: 'BLOCKED' });

const getSips = (data) => http.get('contracts/sipAccounts', { params: data });

const getSip = (id) => http.get(`contracts/sipAccount/${id}`);

const getDevices = (data) => http.get('intercoms', { params: data });

const getDevice = (id) => http.get(`intercom/${id}`);

const getDeviceBase = (id) => http.get(`intercom/${id}/configuration/base`);
const getCanActivateMP = (id) =>
  http.get(`intercom/${id}/configuration/call_on_mobile`);

const putDeviceBase = (id, data) =>
  http.put(`intercom/${id}/configuration/base`, data);

const getDeviceSip = (id) => http.get(`intercom/${id}/sipConfiguration`);

const setDeviceSip = (id, data) =>
  http.put(`intercom/${id}/sipConfiguration`, data);

const getDeviceSip2 = (id) => http.get(`intercom/${id}/sip2Configuration`);

const setDeviceSip2 = (id, { enabled }) =>
  http.put(`intercom/${id}/sip2Configuration`, undefined, {
    params: {
      enableSip2: enabled ? 'true' : 'false'
    }
  });

const getDeviceGate = (id) => http.get(`intercom/${id}/configuration/gate`);

const getDeviceGateEntrances = (id) =>
  http.get(`intercom/${id}/configuration/gate/entrances`);

const setDeviceGate = (id, data) =>
  http.put(`intercom/${id}/configuration/gate`, data);

const getDeviceConnection = (id) =>
  http.get(`intercom/${id}/connection/configuration`);

const putDeviceConnection = (id, data) =>
  http.put(`intercom/${id}/connection/configuration`, data);

const getDeviceConfigurationSingle = (id) =>
  http.get(`intercom/${id}/configuration/single`);

const getDeviceConfiguration = (id) => http.get(`intercom/${id}/configuration`);

const setDeviceConfiguration = (id, data) =>
  http.put(`intercom/${id}/configuration`, data);

const getDeviceConfigurationRfid = (id) =>
  http.get(`intercom/${id}/configuration/keyRFID`);

const getDeviceConfigurationExtRfid = (id) =>
  http.get(`intercom/${id}/configuration/v2/keyRFID/ext`);

const setDeviceConfigurationRfid = (id, data) =>
  http.put(`intercom/${id}/configuration/keyRFID`, data);

const getDeviceConfigurationMifare = (id) =>
  http.get(`intercom/${id}/configuration/keyMIFARE`);

const setDeviceConfigurationMifare = (id, data) =>
  http.put(`intercom/${id}/configuration/keyMIFARE`, data);

const getDeviceConfigurationMifareV2 = (id) =>
  http.get(`intercom/${id}/configuration/v2/keyMIFARE`);

const setDeviceConfigurationMifareV2 = (id, data) =>
  http.put(`intercom/${id}/configuration/v2/keyMIFARE`, data);

const getDeviceConfigurationLan = (id) =>
  http.get(`intercom/${id}/configuration/lan`);

const setDeviceConfigurationLan = (id, data) =>
  http.put(`intercom/${id}/configuration/lan`, data);

const getDeviceConfigurationEmail = (id) =>
  http.get(`intercom/${id}/configuration/email`);

const setDeviceConfigurationEmail = (id, data) =>
  http.put(`intercom/${id}/configuration/email`, data);

const getDeviceConfigurationAudio = (id) =>
  http.get(`intercom/${id}/configuration/audio`);

const setDeviceConfigurationAudio = (id, data) =>
  http.put(`intercom/${id}/configuration/audio`, data);

const getDeviceConfigurationVideo = (id) =>
  http.get(`intercom/${id}/configuration/video`);

const setDeviceConfigurationVideo = (id, data) =>
  http.put(`intercom/${id}/configuration/video`, data);

const getDeviceConfigurationDisplay = (id) =>
  http.get(`intercom/${id}/configuration/display`);

const setDeviceConfigurationDisplay = (id, data) =>
  http.put(`intercom/${id}/configuration/display`, data);

const getDeviceConfigurationDate = (id) =>
  http.get(`intercom/${id}/configuration/dateTime`);

const setDeviceConfigurationDate = (id, data) =>
  http.put(`intercom/${id}/configuration/dateTime`, data);

const getDeviceConfigurationOverlay = (id) =>
  http.get(`intercom/${id}/configuration/videoTextOverlay`);

const setDeviceConfigurationOverlay = (id, data) =>
  http.put(`intercom/${id}/configuration/videoTextOverlay`, data);

const getDeviceConfigurationDetector = (id) =>
  http.get(`intercom/${id}/configuration/motionAlarm`);

const setDeviceConfigurationDetector = (id, data) =>
  http.put(`intercom/${id}/configuration/motionAlarm`, data);

const getDeviceConfigurationSwitch = (id) =>
  http.get(`intercom/${id}/configuration/switch`);

const setDeviceConfigurationSwitch = (id, data) =>
  http.put(`intercom/${id}/configuration/switch`, data);

const getDeviceConfigurationSwitchFill = (id, data) =>
  http.post(`intercom/${id}/configuration/switch/fill`, data);

const getDeviceConfigurationLevels = (id) =>
  http.get(`intercom/${id}/configuration/level`);

const startDeviceConfigurationLevels = (id) =>
  http.post(`intercom/${id}/configuration/lineLevels`);

const getDeviceConfigurationSwitchArray = (id) =>
  http.get(`intercom/${id}/configuration/lineLevels`);

const setDeviceConfigurationLevels = (id, data) =>
  http.put(`intercom/${id}/configuration/level`, data);

const getDeviceConfigurationLineLevel = (id, data) =>
  http.get(`intercom/${id}/configuration/lineLevel`, { params: data });

const getDeviceConfigurationLog = (id) =>
  http.get(`intercom/${id}/configuration/eventLog`);

const getDeviceConfigurationSysLog = (id) =>
  http.get(`intercom/${id}/configuration/systemLog`);

const setDeviceConfigurationSysLog = (id, data) =>
  http.put(`intercom/${id}/configuration/systemLog`, data);

const getDeviceConfigurationUser = (id) =>
  http.get(`intercom/${id}/configuration/users`);

const setDeviceConfigurationUser = (id, data) =>
  http.put(`intercom/${id}/configuration/users`, data);

const createDevice = (data) => http.post('intercoms', data);

const putDevice = (data) => http.put(`intercom/${data.id}`, data);

const archiveDevice = (id) => http.delete(`intercom/${id}`);

const restoreDevice = (id) => http.post(`intercom/${id}/unarchive`);

const getFaqs = (data) => http.get('faqs', { params: data });

const getFaq = (id) => http.get(`faq/${id}`);

const createFaq = (data) => http.post('faq', data);

const putFaq = (id, data) => http.put(`faq/${id}`, data);

const archiveFaq = (id) => http.delete(`faq/${id}`);

const openDoor = (id, data) => http.post(`intercom/${id}/openDoor`, data);

const keepOpenDoor = (id, data) =>
  http.post(`intercom/${id}/keepDoorOpen?value=${data.value}`, data);

const autoCollect = (id, data) =>
  http.put(
    `intercom/${id}/configuration/keyRFID/autoCollect/${data.active}`,
    data
  );

const getAutoCollectMifare = (id) =>
  http.get(`intercom/${id}/configuration/v2/keyMIFARE/autoCollect`);

const autoCollectMifare = (id, data) =>
  http.put(
    `intercom/${id}/configuration/v2/keyMIFARE/autoCollect/${data.active}`,
    data
  );

const createKey = (id, data) =>
  http.post(`intercom/${id}/configuration/keyRFID`, data);

const createMifareKey = (id, data) =>
  http.post(`intercom/${id}/configuration/v2/keyMIFARE`, data);

const updateKey = (id, data) =>
  http.put(`intercom/${id}/configuration/keyRFID`, data);

const updateMifareKey = (id, data) =>
  http.put(`intercom/${id}/configuration/v2/keyMIFARE`, data);

const deleteKey = (id, key) =>
  http.delete(`intercom/${id}/configuration/keyRFID/${key}`);

const deleteMifareKey = (id, key) =>
  http.delete(`intercom/${id}/configuration/v2/keyMIFARE/${key}`);

const deleteServiceKey = (id, key) =>
  http.delete(`intercom/${id}/configuration/keyMIFARE/srv/${key}`);

const deleteCustomerKey = (id, key) =>
  http.delete(`intercom/${id}/configuration/keyMIFARE/usr/${key}`);

const createCipher = (id, data) =>
  http.post(`intercom/${id}/configuration/cipher`, data);

const deleteCipher = (id, index) =>
  http.delete(`intercom/${id}/configuration/cipher/${index}`);

const exportKeys = (id) =>
  http.get(`intercom/${id}/configuration/keyRFID/save`);

const exportMifareKeys = (id) =>
  http.get(`intercom/${id}/configuration/v2/keyMIFARE/save`);

const importKeys = (id, file) =>
  http.post(`intercom/${id}/configuration/keyRFID/upload`, file);

const importMifareKeys = (id, file) =>
  http.post(`intercom/${id}/configuration/v2/keyMIFARE/upload`, file);

const copyMifareKeys = (id, file) =>
  http.post(`intercom/${id}/configuration/v2/keyMIFARE/copy`);

const sendDeviceMessage = (data) => http.post('intercoms/sendMessage', data);

const checkLicenseIntercom = () => http.get('license/check/intercom');

const checkLicenseContract = () => http.get('license/check/contract');

export default {
  login,
  reset,
  update,
  getLogs,
  getCustomers,
  getCustomer,
  createCustomer,
  putCustomer,
  deleteCustomer,
  getRoles,
  getRole,
  createRole,
  putRole,
  deleteRole,
  getDepartments,
  getDepartment,
  createDepartment,
  putDepartment,
  deleteDepartment,
  getTimeZones,
  getBuildingTypes,
  getTerritoryTypes,
  getIntercomTypes,
  getBuildings,
  archiveBuilding,
  unarchiveBuilding,
  getBuilding,
  createBuilding,
  putBuilding,
  searchArea,
  searchBuilding,
  searchAddress,
  getTerritories,
  getTerritory,
  createTerritory,
  putTerritory,
  archiveTerritory,
  unarchiveTerritory,
  getSubscribers,
  getSubscriber,
  createSubscriber,
  putSubscriber,
  deleteSubscriber,
  restoreSubscriber,
  unblockSubscriber,
  blockSubscriber,
  getSips,
  getSip,
  getDevices,
  getDevice,
  getDeviceConnection,
  putDeviceConnection,
  getDeviceSip,
  setDeviceSip,
  getDeviceSip2,
  setDeviceSip2,
  getDeviceGate,
  getDeviceGateEntrances,
  setDeviceGate,
  getDeviceConfigurationSingle,
  getDeviceConfiguration,
  setDeviceConfiguration,
  getDeviceConfigurationRfid,
  setDeviceConfigurationRfid,
  getDeviceConfigurationExtRfid,
  getDeviceConfigurationMifare,
  setDeviceConfigurationMifare,
  getDeviceConfigurationMifareV2,
  setDeviceConfigurationMifareV2,
  getDeviceConfigurationLan,
  setDeviceConfigurationLan,
  getDeviceConfigurationEmail,
  setDeviceConfigurationEmail,
  getDeviceConfigurationAudio,
  setDeviceConfigurationAudio,
  getDeviceConfigurationVideo,
  setDeviceConfigurationVideo,
  getDeviceConfigurationDisplay,
  setDeviceConfigurationDisplay,
  getDeviceConfigurationDate,
  setDeviceConfigurationDate,
  getDeviceConfigurationOverlay,
  setDeviceConfigurationOverlay,
  getDeviceConfigurationDetector,
  setDeviceConfigurationDetector,
  getDeviceConfigurationSwitch,
  setDeviceConfigurationSwitch,
  getDeviceConfigurationSwitchFill,
  getDeviceConfigurationLevels,
  startDeviceConfigurationLevels,
  getDeviceConfigurationSwitchArray,
  setDeviceConfigurationLevels,
  getDeviceConfigurationSysLog,
  setDeviceConfigurationSysLog,
  getDeviceConfigurationUser,
  setDeviceConfigurationUser,
  getDeviceConfigurationLineLevel,
  getDeviceConfigurationLog,
  getDeviceBase,
  putDeviceBase,
  getCanActivateMP,
  createDevice,
  putDevice,
  archiveDevice,
  restoreDevice,
  getFaqs,
  getFaq,
  createFaq,
  putFaq,
  archiveFaq,
  openDoor,
  keepOpenDoor,
  autoCollect,
  autoCollectMifare,
  getAutoCollectMifare,
  createKey,
  createMifareKey,
  updateKey,
  updateMifareKey,
  deleteKey,
  deleteMifareKey,
  deleteServiceKey,
  deleteCustomerKey,
  createCipher,
  deleteCipher,
  exportKeys,
  exportMifareKeys,
  importKeys,
  importMifareKeys,
  copyMifareKeys,
  sendDeviceMessage,
  checkLicenseIntercom,
  checkLicenseContract
};
