/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css, keyframes } from '@emotion/react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';
import store from '../../../../store/store';
import { showMessage } from '../../../../store/actions/messages.actions';
import {
  getDeviceConfigurationLineLevel, getDeviceConfigurationSwitch,
  getDeviceConfigurationSwitchArray,
  getDeviceConfigurationSwitchFill,
  startDeviceConfigurationLevels
} from '../../../../store/actions/devices.actions';

const moveBackground = keyframes`
  0% { background-position: 0 }
  100% { background-position: 100px }
`;

const animatedItem = css`
    animation: ${moveBackground} 2000ms linear infinite;
`;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  margin: 'auto',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#fb7259',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#00aff3',
    boxSizing: 'border-box',
  },
}));

const isLevelsReady = (levels, model) => {
  try {
    const tableLast = model.tablesCount - 1;
    const rowLast = model.rowsCount - 1;
    const columnLast = model.columnsCount - 1;
    const last = levels[tableLast][rowLast][columnLast];
    if (last) {
      return true;
    }
    return false;
  } catch (error) {
    // levels table is not ready
    // console.log(error)
    return false;
  }
}

const tableColors = {
  STRIPES: 'repeating-linear-gradient(135deg, #c7c7c7, #c7c7c7 10px, #fce154 10px, #fce154 14.1px);background-size:100px 100px;',
  DEFAULT: '#c7c7c7',
  GREEN: '#27ae60',
  YELLOW: '#fce154',
  RED: '#fb7259',
};

/* eslint-disable */
function setCaretAtStartEnd(node, atEnd) {
  const sel = document.getSelection();
  node = node.firstChild;
  if (sel.rangeCount){
    ['Start', 'End'].forEach((pos) => sel.getRangeAt(0)["set" + pos](node, atEnd ? node.length : 0))
  }
}
const DevicesSwitch = ({ isReadonly, device, onUpdate, ...props }) => {
  const dispatch = useDispatch();
  const editPermission = useSelector(deviceWritePermission);
  const [edited, setEdited] = useState('');
  const [animated, setAnimated] = useState([]);
  const [autoFill, setAutofill] = useState(false);
  const [showFlats, setShowFlats] = useState(true);
  const checkingLevelsRef = useRef(false);
  const [levels, setLevels] = useState(device?.switch?.lineLevels);
  const { actionId1 } = useParams();

  // check levels is ready
  useEffect(() => {
    let interval;
    let unmounted = !location.pathname.includes('/devices/intercoms/') || !location.pathname.includes('/kkm');
    if (!unmounted) {
      if (!levels && device?.switch?.lineLevels) {
        setLevels((levels) => device?.switch?.lineLevels)
      }
      if (device?.switch && !isLevelsReady(levels || device?.switch?.lineLevels, device?.switch?.switchModel) && device?.switch?.inProgress) {
        checkingLevelsRef.current = true;
      }
      if (checkingLevelsRef.current) {
        interval = setInterval(async () => {
          const response = await store.dispatch(getDeviceConfigurationSwitchArray(actionId1));
          if (checkingLevelsRef.current && !unmounted) {
            setLevels((levels) => {
              if (isLevelsReady(levels, device?.switch?.switchModel) || !response.data?.inProgress) {
                clearInterval(interval);
                checkingLevelsRef.current = false;
              }
              return response.data?.lineLevels;
            });
          }
        }, 1000);
      }
    }
    return () => {
      clearInterval(interval);
      unmounted = true;
    };
  }, [levels, device?.switch]);

  const getLevelValue = useCallback((values, indexTable, indexRow, indexColumn) => {
    let value = 0;
    try {
      value = values[indexTable][indexRow][indexColumn] ?? 0;
    } catch (error) {
      value = 0;
    }
    return value;
  }, [device?.switch?.lineLevels, levels]);

  const getLevelColor = useCallback((values, indexTable, indexRow, indexColumn) => {
    let value = 0;
    try {
      value = values[indexTable][indexRow][indexColumn] ?? -1;
    } catch (error) {
      value = -1;
    }
    if (value === '') {
      return tableColors.STRIPES;
    }
    if (value < 1) {
      return tableColors.DEFAULT;
    }
    if (value < 330) {
      return tableColors.GREEN;
    }
    if (value < 530) {
      return tableColors.YELLOW;
    }
    return tableColors.RED;
  }, [device?.switch?.lineLevels, levels]);

  const onCheckClick = useCallback(async (values) => {
    if (checkingLevelsRef.current) {
      dispatch(showMessage({ open: true, text: 'Проверка уже запущена, это может занять несколько минут.', severity: 'success' }));
      return;
    }
    try {
      setLevels([]);
      checkingLevelsRef.current = true;
      await store.dispatch(startDeviceConfigurationLevels(actionId1));
      // const response = await store.dispatch(getDeviceConfigurationSwitchFill(actionId1, data));
      dispatch(showMessage({ open: true, text: 'Проверка запущена, это может занять несколько минут.', severity: 'success' }));
    } catch (error) {
      dispatch(showMessage({ open: true, text: `Не удалось получить уровни. ${error.message}`, severity: 'error' }));
    }
  }, []);

  const onAutofillClick = useCallback(async (values, setFieldValue) => {
    try {
      const autofillData = {
        startFrom: values.switchModel.startFrom || (values.flatNumbers[0] && values.flatNumbers[0][1] && values.flatNumbers[0][1][0]) || 1,
        switchModel: {
          id: values.switchModel.id
        }
      }
      setAutofill(true);
      // disable previous changes
      onUpdate({ switch: null });
      const response = await store.dispatch(getDeviceConfigurationSwitchFill(actionId1, autofillData));
      // onUpdate({ switch: { ...values, switchModel: { ...values.switchModel, startFrom: data.startFrom } } });
      dispatch(showMessage({ open: true, text: 'Автозаполнение выполнено.', severity: 'success' }));
      const { data } = await store.dispatch(getDeviceConfigurationSwitch(actionId1));
      setFieldValue('flatNumbers', data.flatNumbers)
    } catch (error) {
      dispatch(showMessage({ open: true, text: `Не удалось заполнить. ${error.message}`, severity: 'error' }));
    }
    setAutofill(false);
  }, []);

  /* eslint-disable */
  const onChange = useCallback((target, indexTable, indexRow, indexColumn, values, setFieldValue) => {
    const num = target.innerText.replace(/\D/g,'');
    if (num !== target.innerText) {
      target.innerText = num;
      setCaretAtStartEnd(target, true);
    }
    if (target.innerText === '') {
      target.innerText = '0';
      setCaretAtStartEnd(target, true);
    }
    if (target.innerText[0] === '0' && target.innerText > 1) {
      target.innerText = target.innerText.substring(1);
      setCaretAtStartEnd(target, true);
    }
    values.flatNumbers[indexTable][indexRow][indexColumn] = +target.innerText;
    setFieldValue('flatNumbers', values.flatNumbers)
    onUpdate({ switch: { ...values } });
  }, []);

  const onEdit = useCallback((target, indexTable, indexRow, indexColumn) => {
    if (isReadonly) {
      return
    }
    if (autoFill) {
      dispatch(showMessage({ open: true, text: 'Дождитесь окончания автозаполнения.', severity: 'warning' }));
      return;
    }
    if (checkingLevelsRef.current) {
      dispatch(showMessage({ open: true, text: 'Дождитесь окончания проверки уровней перед тем, как редактировать квартиры.', severity: 'warning' }));
      return;
    }
    setEdited(indexTable + '-' + indexRow + '-' + indexColumn);
    setTimeout(() => target.focus(), 10);
  }, [checkingLevelsRef.current, autoFill]);

  const onCellDoubleClick = useCallback((target, indexTable, indexRow, indexColumn, values, column, setFieldValue) => {
    async function getLevel() {
      const data = {
        id: actionId1,
        flatNumber: column,
      }
      const { level } = await store.dispatch(getDeviceConfigurationLineLevel(actionId1, data));
      dispatch(showMessage({ open: true, text: 'Уровень обновлён.', severity: 'success' }));
      values.lineLevels[indexTable][indexRow][indexColumn] = level
      setFieldValue('lineLevels', values.lineLevels)
      setAnimated((animated) => {
        return animated.filter((item) => item !== column)
      })
    }
    if (autoFill) {
      dispatch(showMessage({ open: true, text: 'Дождитесь окончания автозаполнения.', severity: 'warning' }));
      return;
    }
    if (checkingLevelsRef.current) {
      dispatch(showMessage({ open: true, text: 'Дождитесь окончания проверки уровней перед тем, как обновлять уровни.', severity: 'warning' }));
      return;
    }
    setAnimated((animated) => {
      return [...animated, column]
    })
    getLevel()
    if (values.lineLevels.length === 0) {
      for (let i=0; i < values.switchModel.tablesCount; i++) {
        values.lineLevels[i] = []
        for (let j = 0; j < values.switchModel.rowsCount; j++) {
          values.lineLevels[i][j] = []
          for (let k = 0; k < values.switchModel.columnsCount; k++) {
            values.lineLevels[i][j][k] = 0
          }
        }
      }
    }
    values.lineLevels[indexTable][indexRow][indexColumn] = ''
    setFieldValue('lineLevels', values.lineLevels)
  }, [checkingLevelsRef.current, levels, autoFill]);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device.switch || { flatNumbers: [[], []], switchModel: { columnIndexStart: 0, columnsCount: 10, id: 15, name: '', rowIndexStart: 0, rowsCount: 10, tablesCount: 2 } }}
      validationSchema={
        Yup.object().shape({
        })
      }
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
            {'Настройки KKM устройства ' + (device.macAddress || '')}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* name field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      error={Boolean(touched.name && errors.name)}
                      htmlFor="outlined-switch-name"
                    >
                      Модель
                    </InputLabel>
                    <OutlinedInput
                      style={{ backgroundColor: 'white' }}
                      error={Boolean(touched.name && errors.name)}
                      id="outlined-switch-name"
                      type="text"
                      autoFocus
                      autoComplete="off"
                      value={values?.switchModel?.name || ''}
                      onChange={(event) => {
                        setFieldValue('switchModel', { ...values.switchModel, name: event.target.value });
                        onUpdate({ switch: { ...values, switchModel: { ...values.switchModel, name: event.target.value } } });
                      }}
                      readOnly
                      label="Модель"
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="outlined-switch-name-error">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      mb: 1,
                    }}
                  >
                    {editPermission && (
                      <Button
                        sx={{ mt: 2 }}
                        color={checkingLevelsRef.current ? 'secondary' : 'primary'}
                        variant="contained"
                        onClick={() => onCheckClick(values)}
                      >
                        {checkingLevelsRef.current && <CircularProgress sx={{ mr: 1 }} size="20px" color="circular" />}
                        Проверить уровни
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  {/* start field */}
                  <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel
                      error={Boolean(touched.start && errors.start)}
                      htmlFor="outlined-switch-start"
                    >
                      Смещение
                    </InputLabel>
                    <OutlinedInput
                      style={{ backgroundColor: 'white' }}
                      error={Boolean(touched.start && errors.start)}
                      id="outlined-switch-start"
                      type="number"
                      autoComplete="off"
                      value={values.switchModel?.startFrom || (values.flatNumbers[0] && values.flatNumbers[0][1] && values.flatNumbers[0][1][0]) || 1}
                      onChange={(event) => {
                        setFieldValue('switchModel', { ...values.switchModel, startFrom: event.target.value });
                        // onUpdate({ switch: { ...values, switchModel: { ...values.switchModel, startFrom: event.target.value } } });
                      }}
                      readOnly={!editPermission || isReadonly || checkingLevelsRef.current}
                      label="Смещение"
                    />
                    {touched.start && errors.start && (
                      <FormHelperText error id="outlined-switch-start-error">
                        {errors.start}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      mb: 1,
                    }}
                  >
                    {editPermission && (
                      <Button
                        sx={{ mt: 2 }}
                        color="orange"
                        variant="contained"
                        disabled={isReadonly || checkingLevelsRef.current || autoFill}
                        onClick={() => onAutofillClick(values, setFieldValue)}
                      >
                        Автозаполнение
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!!values.flatNumbers && !!values.flatNumbers[0].length && values.flatNumbers.map((table, indexTable) => (
            <TableContainer component={Paper} sx={{ mt: 2 }} key={indexTable} style={{ width: 'calc(100% - 2px)', marginLeft: '1px' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" align="center" style={{ width: '10%' }}>
                      <Tooltip title={showFlats ? 'Смортеть уровни' : 'Смортеть номера квартир'} placement="top" followCursor enterDelay={1000}>
                        <AntSwitch checked={!showFlats} onChange={() => setShowFlats(value => !value)} />
                      </Tooltip>
                    </TableCell>
                    {(
                      values.flatNumbers[indexTable][0].map((item, index) => (
                        <TableCell padding="checkbox" key={index} align="center" style={{ width: '9%' }}>
                          <strong>{'D' + index}</strong>
                        </TableCell>
                      ))
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(
                    values.flatNumbers[indexTable].map((row, indexRow) => (
                      <TableRow key={indexRow}>
                        <TableCell padding="checkbox" align="center">
                          <strong>{'E' + indexRow}</strong>
                        </TableCell>
                        {(
                          values.flatNumbers[indexTable][indexRow].map((column, indexColumn) => (
                            <TableCell padding="checkbox" key={indexColumn} align="center" sx={{ background: getLevelColor(levels, indexTable, indexRow, indexColumn), transition: 'backgroundColor, 1s' }}
                                       onDoubleClick={(event) => onCellDoubleClick(event.target, indexTable, indexRow, indexColumn, values, column, setFieldValue)}
                                       css={animated.includes(column) ? animatedItem : null}
                            >
                              {showFlats ?
                                (edited === indexTable + '-' + indexRow + '-' + indexColumn
                                  /* editable field */
                                  ? <span contentEditable style={{ minHeight: '17px' }} onInput={(event) => onChange(event.target, indexTable, indexRow, indexColumn, values, setFieldValue)}>{values.flatNumbers[indexTable][indexRow][indexColumn]}</span>
                                  /* eslint-disable */
                                  : <span style={{ minHeight: '17px' }} onClick={(event) => onEdit(event.target, indexTable, indexRow, indexColumn)}>{values.flatNumbers[indexTable][indexRow][indexColumn]}</span>
                                ) : <span style={{ minHeight: '17px' }}>{getLevelValue(levels, indexTable, indexRow, indexColumn)}</span>
                              }
                            </TableCell>
                          ))
                        )}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
          <Box sx={{ mt: 2 }}>
            <Typography variant="caption">
              {showFlats && !isReadonly ? '* Один клик по ячейке таблицы - редактировать номер квартиры, двойной клик - обновить уровень.' : '* Двойной клик по ячейке таблицы - обновить уровень.'}
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

DevicesSwitch.propTypes = {
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

DevicesSwitch.defaultProps = {
  isReadonly: false,
  device: null,
}

export default memo(DevicesSwitch, isDevicePropEqual);
