import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography } from '@material-ui/core';
import Logo from './Logo';

const MainNavbar = (props) => (
  <AppBar
    elevation={0}
    {...props}
  >
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Logo />
          <Typography
            color="white"
            variant="h2"
            sx={{
              ml: 1
            }}
          >
            Терион
          </Typography>
        </Box>
      </RouterLink>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
