import api from '../../api/client';
import { showMessage } from './messages.actions';
import { handleServerError } from './utils';

export function navbarSwitch() {
  return { type: 'account/navbarSwitch' };
}

export function logged(data) {
  return { type: 'account/logged', data };
}

export function logout(data) {
  return { type: 'account/logout', data };
}

export function expired() {
  return { type: 'account/expired' };
}

export function message(data) {
  return { type: 'account/message', data };
}

export function redirectUrl(data) {
  return { type: 'account/redirectUrl', data };
}

export function state(data) {
  return { type: 'account/state', data };
}

// async thunk actions

export function login(data) {
  return async (dispatch) => {
    const longOperationTimeout = setTimeout(() => dispatch(showMessage({ open: true, text: 'Сервер не отвечает дольше обычного...', severity: 'warning', duration: 2900 })), 3000);
    const longOperationTimeout2 = setTimeout(() => dispatch(showMessage({ open: true, text: 'Возможно, он упал или перезагружается...', severity: 'warning', duration: 2900 })), 6000);
    const longOperationTimeout3 = setTimeout(() => dispatch(showMessage({ open: true, text: 'На всякий случай проверьте ваше VPN соединение!', severity: 'warning', duration: 2900 })), 9000);
    try {
      const response = await api.login(data);
      clearTimeout(longOperationTimeout);
      clearTimeout(longOperationTimeout2);
      clearTimeout(longOperationTimeout3);
      if (response && response.data && response.data.token) {
        dispatch(logged(response.data));
      }
      return Promise.resolve(response);
    } catch (error) {
      clearTimeout(longOperationTimeout);
      clearTimeout(longOperationTimeout2);
      clearTimeout(longOperationTimeout3);
      console.log(error.response);
      let msg = 'Ошибка сервера';
      if (!error.response) {
        msg = 'Сервер не отвечает, проверьте CORS';
      } else if (error.response.statusText === 'Unauthorized') {
        msg = 'Неверный логин или пароль';
      }
      return Promise.reject(new Error(msg));
    }
  };
}

export function reset(data) {
  return async () => {
    try {
      const response = await api.reset(data);
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}

export function update(data) {
  return async (dispatch) => {
    try {
      const response = await api.update(data);
      dispatch(logged(response.data));
      return Promise.resolve(response);
    } catch (error) {
      return handleServerError(error);
    }
  };
}
