import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import 'moment/locale/ru';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Delete, Edit, VisibilityOutlined } from '@material-ui/icons';
import { faqWritePermission } from '../../store/selectors/account.selector';
import store from '../../store/store';
import { archiveFaq, getFaqs, setFaqsRefresh } from '../../store/actions/faq.actions';
import { showMessage } from '../../store/actions/messages.actions';
import FaqMulti from './FaqMulti';
import useDebounce from '../../utils/useDebounce';
import { match, parse } from '../../utils/highlight';
import FaqToolbar from './FaqToolbar';
import QuestionsDeleteConfirm from './dialogs/QuestionsDeleteConfirm';

const FaqList = ({ isArchive, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editPermission = useSelector(faqWritePermission);
  const faqs = useSelector((state) => state.faq.list);
  const faqsIsLoading = useSelector((state) => state.faq.listState !== 'success');
  const faqsVersion = useSelector((state) => state.faq.listVersion);
  const faqsTotal = useSelector((state) => state.faq.list.length);

  const [selectedFaqIds, setSelectedFaqIds] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);

  const [questionSearch, setQuestionSearch] = useState('');
  const [debouncedQuestionSearch] = useDebounce(questionSearch, 300);

  const [answerSearch, setAnswerSearch] = useState('');
  const [debouncedAnswerSearch] = useDebounce(answerSearch, 300);

  const [filtered, setFiltered] = useState(faqs);
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const getData = async () => {
      try {
        await store.dispatch(getFaqs({}));
      } catch (error) {
        console.log(error);
      }
    }
    if (!unmounted) {
      getData();
    }
    return () => {
      unmounted = true;
    };
  }, [faqsVersion]);

  // reset page
  useEffect(() => {
    setPage(0);
  }, [size]);

  // search effect
  useEffect(() => {
    if (debouncedQuestionSearch && debouncedAnswerSearch) {
      setFiltered(faqs.filter((item) => match(item.title.toLowerCase(), debouncedQuestionSearch.toLowerCase(), { insideWords: true, requireMatchAll: true }).length && match(item.text.toLowerCase(), debouncedAnswerSearch.toLowerCase(), { insideWords: true, requireMatchAll: true }).length)
        .map((item) => ({
          ...item,
          partsQuestion: parse(
            item.title,
            match(item.title.toLowerCase(), debouncedQuestionSearch.toLowerCase(), { insideWords: true, requireMatchAll: true })
          ),
          partsAnswer: parse(
            item.text,
            match(item.text.toLowerCase(), debouncedAnswerSearch.toLowerCase(), { insideWords: true, requireMatchAll: true })
          )
        })));
    } else if (debouncedQuestionSearch) {
      setFiltered(faqs.filter((item) => match(item.title.toLowerCase(), debouncedQuestionSearch.toLowerCase(), { insideWords: true, requireMatchAll: true }).length)
        .map((item) => ({
          ...item,
          partsQuestion: parse(
            item.title,
            match(item.title.toLowerCase(), debouncedQuestionSearch.toLowerCase(), { insideWords: true, requireMatchAll: true })
          )
        })));
    } else if (debouncedAnswerSearch) {
      setFiltered(faqs.filter((item) => match(item.text.toLowerCase(), debouncedAnswerSearch.toLowerCase(), { insideWords: true, requireMatchAll: true }).length)
        .map((item) => ({
          ...item,
          partsAnswer: parse(
            item.text,
            match(item.text.toLowerCase(), debouncedAnswerSearch.toLowerCase(), { insideWords: true, requireMatchAll: true })
          )
        })));
      if (page && filtered.length <= size) {
        setPage(0);
      }
    } else {
      setFiltered(faqs);
    }
    if (selectedFaqIds.length) {
      const ids = faqs.map((item) => item.id);
      const newSelectedFaqIds = selectedFaqIds.filter((item) => ids.includes(item));
      setSelectedFaqIds(newSelectedFaqIds);
    }
  }, [faqs, debouncedQuestionSearch, debouncedAnswerSearch]);

  const handleSelectAll = (event) => {
    let newSelectedFaqIds;

    if (event.target.checked) {
      newSelectedFaqIds = faqs.map((faq) => faq.id);
    } else {
      newSelectedFaqIds = [];
    }

    setSelectedFaqIds(newSelectedFaqIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedFaqIds.indexOf(id);
    let newSelectedFaqIds = [];

    if (selectedIndex === -1) {
      newSelectedFaqIds = newSelectedFaqIds.concat(selectedFaqIds, id);
    } else if (selectedIndex === 0) {
      newSelectedFaqIds = newSelectedFaqIds.concat(selectedFaqIds.slice(1));
    } else if (selectedIndex === selectedFaqIds.length - 1) {
      newSelectedFaqIds = newSelectedFaqIds.concat(selectedFaqIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedFaqIds = newSelectedFaqIds.concat(
        selectedFaqIds.slice(0, selectedIndex),
        selectedFaqIds.slice(selectedIndex + 1)
      );
    }

    setSelectedFaqIds(newSelectedFaqIds);
  };

  const handleLimitChange = (event) => {
    setSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onOpenFaqClick = (faq) => {
    if (editPermission) {
      navigate(`/app/faq/edit/${faq.id}`);
    } else {
      navigate(`/app/faq/show/${faq.id}`);
    }
  };

  const onDeleteFaqClick = (faq) => {
    navigate(`/app/faq/delete/${faq.id}`);
  };

  const updateFaqsStatus = async (filterFunc, updateAction, successMessage, errorMessage) => {
    try {
      const toUpdate = faqs.filter((item) => selectedFaqIds.includes(item.id)).filter(filterFunc);
      await Promise.all(toUpdate.map((item) => store.dispatch(updateAction(item.id))));
      dispatch(showMessage({ open: true, text: successMessage, severity: 'success' }));
      setTimeout(() => dispatch(setFaqsRefresh()), 500);
      setSelectedFaqIds([]);
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: errorMessage + ' ' + error.message, severity: 'error' }));
    }
  };

  const onFaqsDeleteClick = () => {
    setConfirmDialog(true);
  };

  const onFaqsConfirmClick = () => {
    updateFaqsStatus((item) => (item), archiveFaq, 'Вопросы успешно удалены.', 'Не удалось удалить.');
    setConfirmDialog(false);
  };

  return (
    <>
      <FaqToolbar
        faqsActive={faqs.filter((item) => selectedFaqIds.includes(item.id)).length}
        faqsDeleteClick={() => onFaqsDeleteClick()}
      />

      <Card style={{ backgroundColor: faqsIsLoading ? 'lightyellow' : 'white', transition: 'background-color 400ms ease-in' }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 550 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    {/* padding */}
                  </TableCell>
                  <TableCell style={{ width: '30%' }}>
                    Вопрос
                  </TableCell>
                  <TableCell colSpan={2}>
                    Ответ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedFaqIds.length > 0 && selectedFaqIds.length === faqs.length}
                      color="primary"
                      indeterminate={
                        selectedFaqIds.length > 0
                        && selectedFaqIds.length < faqs.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        minWidth: '100%',
                      }}
                      placeholder="Поиск по вопросам"
                      variant="outlined"
                      onChange={(event) => setQuestionSearch(event.target.value)}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField
                      sx={{
                        minWidth: '100%',
                      }}
                      placeholder="Поиск по ответам"
                      variant="outlined"
                      onChange={(event) => setAnswerSearch(event.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtered.slice(page * size, page * size + size).map((faq) => (
                  <TableRow
                    hover
                    key={faq.id}
                    selected={selectedFaqIds.indexOf(faq.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedFaqIds.indexOf(faq.id) !== -1}
                        onChange={(event) => handleSelectOne(event, faq.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {faq?.partsQuestion?.map((part) => (
                          <span
                            key={Math.random()}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                              color: part.highlight ? '#00AFF3' : '',
                            }}
                          >
                            {part.text}
                          </span>
                        )) || faq.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                        {faq?.partsAnswer?.map((part) => (
                          <span
                            key={Math.random()}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                              color: part.highlight ? '#00AFF3' : '',
                            }}
                          >
                            {part.text}
                          </span>
                        )) || faq.text}
                      </Typography>
                    </TableCell>
                    <TableCell size="small" style={{ width: 120 }} align="center">
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Tooltip title={editPermission ? 'Редактировать' : 'Смотреть'} placement="top" followCursor enterDelay={1000}>
                          <IconButton aria-label="edit" color="primary" onClick={(event) => onOpenFaqClick(faq)}>
                            <SvgIcon
                              fontSize="medium"
                              color="primary"
                            >
                              {editPermission && !isArchive ? <Edit /> : <VisibilityOutlined />}
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                        {editPermission && (
                          <Tooltip title={!isArchive ? 'Удалить' : 'Восстановить'} placement="top" followCursor enterDelay={1000}>
                            <IconButton aria-label="delete" color="secondary" onClick={() => onDeleteFaqClick(faq)}>
                              <SvgIcon
                                fontSize="medium"
                                color="secondary"
                                /* sx={{ color: '#ef6c00' }} */
                              >
                                <Delete />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filtered.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={size}
              rowsPerPageOptions={[5, 10, 100, 1000]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      {editPermission && (
        <FaqMulti
          faqsActive={faqs.filter((item) => selectedFaqIds.includes(item.id)).length}
          faqsDeleteClick={() => onFaqsDeleteClick()}
          sx={{ my: 2 }}
        />
      )}

      {editPermission && (
        <QuestionsDeleteConfirm
          showDialog={confirmDialog}
          counter={faqs.filter((item) => selectedFaqIds.includes(item.id)).length}
          handleConfirm={() => onFaqsConfirmClick()}
          handleClose={() => setConfirmDialog(false)}
        />
      )}
    </>
  );
};

FaqList.propTypes = {
  isArchive: PropTypes.bool,
};

FaqList.defaultProps = {
  isArchive: false,
}

export default FaqList;
