const initialState = {
  list: [{ id: 'cef33223-2bd8-4f76-b8a3-94e80bc2625c', name: 'Администраторы' }],
  state: 'idle',
};

function departmentsReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case 'departments/loaded': {
      return { ...state, list: action.data, state: 'success' };
    }
    default:
      return state;
  }
}

export default departmentsReducer;
