import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { contractWritePermission } from '../../../store/selectors/account.selector';
import formatPhone from '../../../utils/formatPhone';

const SubscriberPhoneDeleteDialog = ({ handleClose, onChange, contacts, ...props }) => {
  const dispatch = useDispatch();
  const { action2, actionId2 } = useParams();

  const editPermission = useSelector(contractWritePermission);

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);

  // state effect
  useEffect(() => {
    let unmounted = false;
    if (action2 === 'delete') {
      setDeleteDialog(true);
    } else {
      setDeleteDialog(false);
    }
    if (action2 === 'delete' && actionId2) {
      const contact = contacts.find((item) => item.id === actionId2) || { phone: '', name: '' }
      setName(contact.name);
      setPhone(contact.phone);
    }
    return () => {
      unmounted = true;
    };
  }, [action2, actionId2]);

  const onDeletePhone = async () => {
    try {
      const updated = [...contacts.filter((item) => item.id !== actionId2)];
      onChange(updated)
      handleClose(null, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* delete dialog */}
      <Dialog {...props} open={deleteDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Удалить привязанный телефон?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Телефон </span>
            <strong>{formatPhone(phone)}</strong>
            <span> будет удалён из списка</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              {editPermission && (
                <Button
                  variant="contained"
                  color="purple"
                  fullWidth
                  onClick={onDeletePhone}
                >
                  Удалить
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

SubscriberPhoneDeleteDialog.propTypes = {
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
  contacts: PropTypes.array,
};

SubscriberPhoneDeleteDialog.defaultProps = {
  handleClose: null,
  onChange: null,
  contacts: [],
};

export default SubscriberPhoneDeleteDialog;
