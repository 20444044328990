import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const LicenseErrorDialog = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const { action1, actionId1 } = useParams();

  const [errorDialog, setErrorDialog] = useState(false);

  // state effect
  useEffect(() => {
    let unmounted = false;
    if (action1 === 'license') {
      setErrorDialog(true);
    } else {
      setErrorDialog(false);
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1]);

  return (
    <>
      {/* license error dialog */}
      <Dialog {...props} open={errorDialog} onClose={handleClose} disableRestoreFocus PaperProps={{ sx: { minWidth: '800px' } }}>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Alert severity="error" sx={{ mt: 1 }}>
            Невозможно добавить абонента, проверьте вашу лицензию!
          </Alert>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="License error"
              src="/static/images/license.svg"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 1000
              }}
            />
          </Box>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 0,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

LicenseErrorDialog.propTypes = {
  handleClose: PropTypes.func,
};

LicenseErrorDialog.defaultProps = {
  handleClose: null,
};

export default LicenseErrorDialog;
