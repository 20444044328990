import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import BuildingDialog from '../dialogs/BuildingDialog';
import BuildingUnArchiveDialog from '../dialogs/BuildingUnArchiveDialog';

const ArchiveHomesEditor = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { action1, actionId1, action2, actionId2 } = useParams();

  const [navigationDepth, setNavigationDepth] = useState(0);
  const [pathname, setPathname] = useState('');

  // navigation effect
  useEffect(() => {
    if (pathname === '') {
      // first call
    } else if (pathname.includes(location.pathname)) {
      setNavigationDepth((depth) => Math.max(0, depth - 1));
    } else {
      setNavigationDepth((depth) => depth + 1);
    }
    setPathname(location.pathname);
  }, [location]);

  const handleClose = () => {
    if (navigationDepth) {
      navigate(-1);
    } else if (actionId2 || (!action2 && actionId1)) {
      navigate('../../', { replace: true });
    } else {
      navigate('../', { replace: true });
    }
  };

  return (
    <>
      <BuildingDialog
        isArchive
        handleClose={() => handleClose()}
      />

      <BuildingUnArchiveDialog
        handleClose={() => handleClose()}
      />
    </>
  );
};

export default ArchiveHomesEditor;
