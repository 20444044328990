import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { placeWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const TerritoriesToolbar = ({ territoriesToArchive, territoriesToArchiveClick, ...props }) => {
  const editPermission = useSelector(placeWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ ml: 1 }}>
          Экспорт
        </Button>*/}
        {editPermission && (
          <RouterLink to="/app/places/territories/add/territory">
            <Button
              sx={{ ml: 1 }}
              color="primary"
              variant="contained"
            >
              Добавить территорию
            </Button>
          </RouterLink>
        )}
        {editPermission && !!territoriesToArchive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => territoriesToArchiveClick()}
          >
            Архивировать&nbsp;
            { territoriesToArchive }
            &nbsp;
            { decOfNum(territoriesToArchive, ['территорию', 'территории', 'территорий']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

TerritoriesToolbar.propTypes = {
  territoriesToArchive: PropTypes.number,
  territoriesToArchiveClick: PropTypes.func,
};

TerritoriesToolbar.defaultProps = {
  territoriesToArchive: 0,
  territoriesToArchiveClick: null,
};

export default TerritoriesToolbar;
