import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Breadcrumbs, Container, Link, Paper, Tabs } from '@material-ui/core';
import LinkTab from '../components/common/LinkTab';
import TabPanel from '../components/common/TabPanel';
import store from '../store/store';
import { showNoAccessMessage } from '../store/actions/messages.actions';
import { placeReadPermission } from '../store/selectors/account.selector';
import HomesToolbar from '../components/place/HomesToolbar';
import HomesList from '../components/place/HomesList';
import HomesEditor from '../components/place/HomesEditor';
import { getBuildingTypes } from '../store/actions/buildings.actions';
import TerritoriesToolbar from '../components/place/TerritoriesToolbar';
import TerritoriesList from '../components/place/TerritoriesList';
import TerritoriesEditor from '../components/place/TerritoriesEditor';
import ArchiveHomesToolbar from '../components/place/archive/ArchiveHomesToolbar';
import ArchiveHomesList from '../components/place/archive/ArchiveHomesList';
import ArchiveHomesEditor from '../components/place/archive/ArchiveHomesEditor';
import { getTerritoryTypes } from '../store/actions/territories.actions';
import ArchiveTerritoriesToolbar from '../components/place/archive/ArchiveTerritoriesToolbar';
import ArchiveTerritoriesList from '../components/place/archive/ArchiveTerritoriesList';
import ArchiveTerritoriesEditor from '../components/place/archive/ArchiveTerritoriesEditor';

const Places = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = useState(0);

  const readPermission = useSelector(placeReadPermission);

  const handleChange = (event, newTab) => {
    if (newTab === 3) {
      navigate('/app/places/archive-territories', { replace: true });
    } else if (newTab === 2) {
      navigate('/app/places/archive-homes', { replace: true });
    } else if (newTab === 1) {
      navigate('/app/places/territories', { replace: true });
    } else {
      navigate('/app/places/homes', { replace: true });
    }
  };

  useEffect(() => {
    if (pathname.includes('/app/places/archive-territories')) {
      setTab(3);
    } else if (pathname.includes('/app/places/archive-homes')) {
      setTab(2);
    } else if (pathname.includes('/app/places/territories')) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [pathname]);

  useEffect(() => {
    if (!readPermission) {
      dispatch(showNoAccessMessage());
      navigate('/app/dashboard');
    } else {
      store.dispatch(getBuildingTypes());
      store.dispatch(getTerritoryTypes());
    }
  }, []);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/app/dashboard">Главная</Link>
              <Link color="textPrimary" href="/app/places/homes">Жилфонд</Link>
              {tab === 0 && <Link color="textPrimary" href="/app/places/homes">Дома</Link>}
              {tab === 1 && <Link color="textPrimary" href="/app/places/territories">Территории</Link>}
              {tab === 2 && <Link color="textPrimary" href="/app/places/archive-homes">Архив домов</Link>}
              {tab === 3 && <Link color="textPrimary" href="/app/places/archive-territories">Архив территорий</Link>}
            </Breadcrumbs>
          </Box>

          <Paper
            sx={{
              backgroundColor: 'white',
              minHeight: '100%',
              my: 3
            }}
          >
            <Tabs value={tab} onChange={handleChange} aria-label="Жилфонд, дома, территории">
              <LinkTab label="Дома" href="/app/places/homes" />
              <LinkTab label="Территории" href="/app/places/territories" />
              <LinkTab label="Архив домов" href="/app/places/archive-homes" />
              <LinkTab label="Архив территорий" href="/app/places/archive-territories" />
            </Tabs>
          </Paper>
          <TabPanel value={tab} index={0}>
            <HomesList />
            <HomesEditor />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <TerritoriesList />
            <TerritoriesEditor />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <ArchiveHomesList />
            <ArchiveHomesEditor />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <ArchiveTerritoriesList />
            <ArchiveTerritoriesEditor />
          </TabPanel>
        </Container>
      </Box>
    </>
  );
};

export default Places;
