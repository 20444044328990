import { Alert, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Messages(props) {
  const { message, handleClose } = props;
  return (
    <Snackbar open={message.open} autoHideDuration={message.duration || 6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity={message.severity}>
        {message.text}
      </Alert>
    </Snackbar>
  );
}

Messages.propTypes = {
  message: PropTypes.object,
  handleClose: PropTypes.func
};
