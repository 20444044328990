import {
  Box, Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField
} from '@material-ui/core';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { showMessage } from '../../../store/actions/messages.actions';
import { deviceWritePermission } from '../../../store/selectors/account.selector';
import store from '../../../store/store';
import { sendDeviceMessage } from '../../../store/actions/devices.actions';

function convertToNative(date) {
  return date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';
}

function convertFromNative(date) {
  return date ? moment(date).format() : '';
}

const DeviceMessageDialog = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const { action1, actionId1 } = useParams();

  const editPermission = useSelector(deviceWritePermission);

  const devices = useSelector((state) => state.devices.list);
  const [deviceSelected, setDeviceSelected] = useState({});
  const [messageDialog, setMessageDialog] = useState(false);

  // state effect
  useEffect(() => {
    let unmounted = false;
    const deviceFromList = actionId1 && (devices.find((item) => item.id === actionId1));
    if (action1 === 'message') {
      setMessageDialog(true);
    } else {
      setMessageDialog(false);
    }
    if (action1 === 'message' && actionId1) {
      setDeviceSelected({
        ...deviceFromList,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [action1, actionId1, devices]);

  const onMessageDevice = async (values) => {
    try {
      await store.dispatch(sendDeviceMessage({ tickerTimeout: values.tickerTimeout, fromDateTime: values.from, toDateTime: values.to, text: values.message }));
      dispatch(showMessage({ open: true, text: 'Сообщение успешно отправлено', severity: 'success' }));
      handleClose(null, 'success');
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось отправить. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      {/* message dialog */}
      <Dialog {...props} open={messageDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>
            Показать сообщение на всех домофонах
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Formik
            {...props}
            enableReinitialize
            initialValues={{
              tickerTimeout: 200,
              from: moment().format(),
              to: moment().add(1, 'days').format(),
              message: ''
            }}
            validationSchema={
              Yup.object().shape({
                tickerTimeout: Yup.number().max(1000, 'Не может быть больше 1000 (слишком быстро)').min(0, 'Не может быть меньше 0').required('Забыли указать скорость бегущей строки'),
                message: Yup.string().required('Забыли указать текст сообщения'),
                // name: Yup.string().max(255).required('Забыли указать название'),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* ntpActive field */}
                    <TextField
                      id="datetime-local-from"
                      label="Начало показа сообщения"
                      type="datetime-local"
                      value={convertToNative(values.from)}
                      onChange={(event) => {
                        setFieldValue('from', event.target.value);
                      }}
                      sx={{ width: '100%', mt: 1 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* ntpActive field */}
                    <TextField
                      id="datetime-local-to"
                      label="Конец показа сообщения"
                      type="datetime-local"
                      value={convertToNative(values.to)}
                      onChange={(event) => {
                        setFieldValue('to', event.target.value);
                      }}
                      sx={{ width: '100%', mt: 1 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* tickerTimeout field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.tickerTimeout && errors.tickerTimeout)}
                        htmlFor="outlined-tickerTimeout"
                      >
                        Скорость
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.tickerTimeout && errors.tickerTimeout)}
                        id="outlined-tickerTimeout"
                        type="number"
                        inputProps={{ min: 0, max: 1000 }}
                        autoComplete="off"
                        value={values.tickerTimeout}
                        onChange={(event) => {
                          setFieldValue('tickerTimeout', event.target.value);
                        }}
                        readOnly={!editPermission}
                        label="Скорость"
                      />
                      {touched.tickerTimeout && errors.tickerTimeout && (
                        <FormHelperText error id="outlined-tickerTimeout-error">
                          {errors.tickerTimeout}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                  >
                    {/* message field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.message && errors.message)}
                        htmlFor="outlined-message"
                      >
                        Сообщение
                      </InputLabel>
                      <OutlinedInput
                        style={{ backgroundColor: 'white' }}
                        error={Boolean(touched.message && errors.message)}
                        id="outlined-message"
                        type="text"
                        multiline
                        minRows={2}
                        autoComplete="off"
                        value={values.message}
                        onChange={(event) => {
                          // setFieldValue('message', event.target.value);
                          setFieldValue('message', event.target.value);
                        }}
                        readOnly={!editPermission}
                        label="Сообщение"
                      />
                      {touched.message && errors.message && (
                        <FormHelperText error id="outlined-message-error">
                          {errors.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 4,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  <Grid
                    item
                  >
                    {editPermission && (
                      <Button
                        variant="contained"
                        color="purple"
                        fullWidth
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (Object.keys(validationErrors).length === 0) {
                            onMessageDevice(values);
                          } else {
                            setTouched(setNestedObjectValues(validationErrors, true));
                            // dispatch(showMessage({ open: true, text: 'Проверьте ошибки заполнения формы', severity: 'error' }));
                          }
                        }}
                      >
                        Отправить
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeviceMessageDialog.propTypes = {
  handleClose: PropTypes.func,
};

DeviceMessageDialog.defaultProps = {
  handleClose: null,
};

export default memo(DeviceMessageDialog);
