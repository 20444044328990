import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import { memo, useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';

import {
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { deviceWritePermission } from '../../../../store/selectors/account.selector';
import isDevicePropEqual from '../../../../utils/isDeviceEqual';

export const DEVICE_MODE = {
  MODE_1: {
    value: 0,
    label: 'Режим #1'
  },
  MODE_2: {
    value: 1,
    label: 'Режим #2'
  }
};

const DeviceGate = ({
  tagType,
  isReadonly,
  device,
  onUpdate,
  updateKeys,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { action1, actionId1, stepName, action2, actionId2 } = useParams();

  const editPermission = useSelector(deviceWritePermission);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={device?.gate}
      validationSchema={Yup.object().shape({
        mode: Yup.number(),
        entrancesCount: Yup.number(),
        houses: Yup.array().of(
          Yup.object().shape({
            address: Yup.string().required('Обязательное поле'),
            startNumber: Yup.number().required('Обязательное поле'),
            endNumber: Yup.number().required('Обязательное поле'),
            isActive: Yup.boolean().required('Обязательное поле')
          })
        ),
        openDoor: Yup.boolean(),
        isIntercomOnGate: Yup.boolean()
      })}
      onSubmit={() => {
        // nothing
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => {
        if (!values) return null;
        const isHouseMode = values.mode === DEVICE_MODE.MODE_2.value;
        const isEntrancesMode = values.mode === DEVICE_MODE.MODE_1.value;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Typography variant="h3" sx={{ mt: -1, mb: 3 }}>
              {`Калитка устройства ${device.macAddress || ''}`}
            </Typography>

            <Grid item xs={12} sm={12}>
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(
                    touched.entrancesCount && errors.entrancesCount
                  )}
                  htmlFor="mode-select"
                >
                  Режим
                </InputLabel>
                <Select
                  style={{ width: '100%' }}
                  labelId="mode-select"
                  className=""
                  id="mode-select"
                  value={values?.mode}
                  label="Режим"
                  onChange={(e) => {
                    setFieldValue('mode', e.target.value);
                    onUpdate({
                      gate: { ...values, mode: e.target.value }
                    });
                  }}
                >
                  {Object.keys(DEVICE_MODE).map((key) => (
                    <MenuItem key={key} value={DEVICE_MODE[key].value}>
                      {DEVICE_MODE[key].label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={8} sx={{ mt: 4, width: '100%' }}>
              {/* active field */}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ ml: 1 }}
                    checked={values?.isIntercomOnGate}
                    value={values?.isIntercomOnGate}
                    onChange={(event) => {
                      const isIntercomOnGate = event.target.checked;

                      setFieldValue('isIntercomOnGate', isIntercomOnGate);
                      if (!isIntercomOnGate) {
                        if (!values.openDoor) {
                          const houses = values.houses?.map((h) => ({
                            ...h,
                            isActive: false
                          }));
                          setFieldValue('houses', houses);
                          onUpdate({
                            gate: {
                              ...values,
                              houses,
                              isIntercomOnGate
                            }
                          });
                        } else {
                          onUpdate({
                            gate: {
                              ...values,
                              isIntercomOnGate
                            }
                          });
                        }
                      } else {
                        onUpdate({
                          gate: {
                            ...values,
                            isIntercomOnGate
                          }
                        });
                      }
                    }}
                  />
                }
                label="Этот домофон установлен на калитке"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ ml: 1 }}
                    checked={values?.openDoor}
                    value={values?.openDoor}
                    onChange={(event) => {
                      const isOpenDoor = event.target.checked;
                      setFieldValue('openDoor', isOpenDoor);

                      if (!values.isOpenDoor && !values.isIntercomOnGate) {
                        const houses = values.houses?.map((h) => ({
                          ...h,
                          isActive: false
                        }));
                        setFieldValue('houses', houses);
                        onUpdate({
                          gate: {
                            ...values,
                            openDoor: isOpenDoor,
                            houses
                          }
                        });
                      } else {
                        onUpdate({
                          gate: { ...values, openDoor: isOpenDoor }
                        });
                      }
                    }}
                  />
                }
                label="Открывать дверь"
              />
            </Grid>
            <Grid item xs={12} sm={5} sx={{ mt: 4, width: '100%' }}>
              <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                <InputLabel
                  error={Boolean(
                    touched.entrancesCount && errors.entrancesCount
                  )}
                  htmlFor="entrancesCount"
                >
                  {isHouseMode && 'Количество домов'}
                  {isEntrancesMode && 'Количество подъездов'}
                </InputLabel>
                <OutlinedInput
                  style={{ backgroundColor: 'white' }}
                  error={Boolean(
                    touched.entrancesCount && errors.entrancesCount
                  )}
                  id="entrancesCount"
                  type="number"
                  autoComplete="off"
                  label={
                    isHouseMode
                      ? 'Количество домов'
                      : isEntrancesMode
                      ? 'Количество подъездов'
                      : ''
                  }
                  disabled
                  value={values?.entrancesCount}
                  onChange={(event) => {
                    const { value } = event.target;
                    const parsed = parseInt(value, 10);
                    if (parsed || !value) {
                      setFieldValue('entrancesCount', Number(value) || value);
                      // handleChange(event)
                      onUpdate({
                        gate: {
                          ...values,
                          entrancesCount: Number(value) || value
                        }
                      });
                    }
                  }}
                  readOnly={
                    !editPermission || isReadonly || !values?.isIntercomOnGate
                  }
                />
                {touched.entrancesCount && errors.entrancesCount && (
                  <FormHelperText error id="entrancesCount">
                    {errors.entrancesCount}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    pb: 0,
                    mt: 4
                  }}
                >
                  {/*////*/}

                  <Grid item xs={12} sm={4}>
                    <Typography variant="h5">
                      {isHouseMode && 'Адрес дома '}
                      {isEntrancesMode && 'Адрес домофона'}
                    </Typography>
                  </Grid>

                  {/*////*/}
                  <Grid item xs={12} sm={2}>
                    <Typography variant="h5">
                      {isHouseMode && 'Префикс дома'}
                      {isEntrancesMode && '№ подъезда '}
                    </Typography>
                  </Grid>
                  {/*////*/}
                  <Grid item xs={12} sm={2}>
                    <Typography variant="h5">№ квартиры от</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="h5">№ квартиры до</Typography>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Typography variant="h5">Активно</Typography>
                  </Grid>
                </Grid>
                <FieldArray name="houses">
                  {() => {
                    const houses = values?.houses;

                    return (
                      houses?.map((house, i) => (
                        <Grid
                          key={i}
                          container
                          justifyContent="space-between"
                          spacing={1}
                          sx={{
                            pb: 1,
                            pt: 1,
                            opacity: values?.houses?.[i]?.isActive ? 1 : 0.4
                          }}
                        >
                          {/*////*/}
                          <Grid item xs={12} sm={4}>
                            <FormControl
                              sx={{ m: 0, width: '100%' }}
                              variant="outlined"
                            >
                              <OutlinedInput
                                disabled
                                style={{ backgroundColor: 'white' }}
                                error={Boolean(
                                  touched.houses?.[i].address &&
                                    errors.houses?.[i].address
                                )}
                                id={`houses.${i}.address`}
                                type="text"
                                autoComplete="off"
                                value={values?.houses?.[i]?.address}
                                onChange={(event) => {
                                  const { value } = event.target;
                                  setFieldValue(`houses.${i}.address`, value);

                                  // handleChange(event)
                                  const updatedValue = values?.houses?.[i]
                                    ? { ...values?.houses?.[i], address: value }
                                    : {
                                        address: value
                                      };

                                  let newValues = [];

                                  if (!values?.houses?.[i]) {
                                    newValues = [
                                      ...values?.houses,
                                      updatedValue
                                    ];
                                  } else {
                                    newValues = [...values?.houses];
                                    newValues[i] = {
                                      ...newValues[i],
                                      ...updatedValue
                                    };
                                  }

                                  onUpdate({
                                    gate: { ...values, houses: newValues }
                                  });
                                }}
                                readOnly={!editPermission || isReadonly}
                              />
                              {touched.houses?.[i].address &&
                                errors.houses?.[i].address && (
                                  <FormHelperText
                                    error
                                    id={`houses.${i}.address-error`}
                                  >
                                    {errors.houses?.[i].address}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <FormControl
                              sx={{ m: 0, width: '100%' }}
                              variant="outlined"
                            >
                              <OutlinedInput
                                key={values?.mode + new Date().getTime()}
                                disabled
                                style={{ backgroundColor: 'white' }}
                                error={Boolean(
                                  touched.houses?.[i].prefix &&
                                    errors.houses?.[i].prefix
                                )}
                                id={`houses.${i}.prefix`}
                                type="number"
                                autoComplete="off"
                                value={
                                  isHouseMode
                                    ? values?.houses?.[i]?.prefix
                                    : isEntrancesMode
                                    ? values?.houses?.[i]?.entranceNumber
                                    : undefined
                                }
                                onChange={(event) => {
                                  const { value } = event.target;
                                  const parsed = parseInt(value, 10);
                                  if (parsed || !value) {
                                    setFieldValue(
                                      `houses.${i}.prefix`,
                                      Number(value) || value
                                    );
                                    // handleChange(event)
                                    const updatedValue = values?.houses?.[i]
                                      ? {
                                          ...values?.houses?.[i],
                                          prefix: Number(value) || value
                                        }
                                      : {
                                          prefix: Number(value) || value
                                        };

                                    let newValues = [];

                                    if (!values?.houses?.[i]) {
                                      newValues = [
                                        ...values?.houses,
                                        updatedValue
                                      ];
                                    } else {
                                      newValues = [...values?.houses];
                                      newValues[i] = {
                                        ...newValues[i],
                                        ...updatedValue
                                      };
                                    }

                                    onUpdate({
                                      gate: { ...values, houses: newValues }
                                    });
                                  }
                                }}
                                readOnly={!editPermission || isReadonly}
                              />
                              {touched.houses?.[i].prefix &&
                                errors.houses?.[i].prefix && (
                                  <FormHelperText
                                    error
                                    id={`houses.${i}.prefix-error`}
                                  >
                                    {errors.houses?.[i].prefix}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <FormControl
                              sx={{ m: 0, width: '100%' }}
                              variant="outlined"
                            >
                              <OutlinedInput
                                disabled
                                style={{ backgroundColor: 'white' }}
                                error={Boolean(
                                  touched.houses?.[i].startNumber &&
                                    errors.houses?.[i].startNumber
                                )}
                                id={`houses.${i}.startNumber`}
                                type="number"
                                autoComplete="off"
                                value={values?.houses?.[i]?.startNumber}
                                onChange={(event) => {
                                  const { value } = event.target;
                                  const parsed = parseInt(value, 10);
                                  if (parsed || !value) {
                                    setFieldValue(
                                      `houses.${i}.startNumber`,
                                      Number(value) || value
                                    );
                                    // handleChange(event)

                                    const updatedValue = values?.houses?.[i]
                                      ? {
                                          ...values?.houses?.[i],
                                          startNumber: Number(value) || value
                                        }
                                      : {
                                          startNumber: Number(value) || value
                                        };

                                    let newValues = [];

                                    if (!values?.houses?.[i]) {
                                      newValues = [
                                        ...values?.houses,
                                        updatedValue
                                      ];
                                    } else {
                                      newValues = [...values?.houses];
                                      newValues[i] = {
                                        ...newValues[i],
                                        ...updatedValue
                                      };
                                    }
                                    onUpdate({
                                      gate: { ...values, houses: newValues }
                                    });
                                  }
                                }}
                                readOnly={!editPermission || isReadonly}
                              />
                              {touched.houses?.[i].startNumber &&
                                errors.houses?.[i].startNumber && (
                                  <FormHelperText
                                    error
                                    id={`houses.${i}.startNumber-error`}
                                  >
                                    {errors.houses?.[i].startNumber}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <FormControl
                              sx={{ m: 0, width: '100%' }}
                              variant="outlined"
                            >
                              <OutlinedInput
                                disabled
                                style={{ backgroundColor: 'white' }}
                                error={Boolean(
                                  touched.houses?.[i].endNumber &&
                                    errors.houses?.[i].endNumber
                                )}
                                id={`houses.${i}.endNumber`}
                                type="number"
                                autoComplete="off"
                                value={values?.houses?.[i]?.endNumber}
                                onChange={(event) => {
                                  const { value } = event.target;
                                  const parsed = parseInt(value, 10);
                                  if (parsed || !value) {
                                    setFieldValue(
                                      `houses.${i}.endNumber`,
                                      Number(value) || value
                                    );
                                    // handleChange(event)

                                    const updatedValue = values?.houses?.[i]
                                      ? {
                                          ...values?.houses?.[i],
                                          endNumber: Number(value) || value
                                        }
                                      : {
                                          endNumber: Number(value) || value
                                        };

                                    let newValues = [];

                                    if (!values?.houses?.[i]) {
                                      newValues = [
                                        ...values?.houses,
                                        updatedValue
                                      ];
                                    } else {
                                      newValues = [...values?.houses];
                                      newValues[i] = {
                                        ...newValues[i],
                                        ...updatedValue
                                      };
                                    }

                                    onUpdate({
                                      gate: { ...values, houses: newValues }
                                    });
                                  }
                                }}
                                readOnly={!editPermission || isReadonly}
                              />
                              {touched.houses?.[i].endNumber &&
                                errors.houses?.[i].endNumber && (
                                  <FormHelperText
                                    error
                                    id={`houses.${i}.endNumber-error`}
                                  >
                                    {errors.houses?.[i].endNumber}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            justifyContent="end"
                            alignItems="end"
                            xs={12}
                            sm={1}
                          >
                            <FormControlLabel
                              sx={{ ml: 'auto', mr: 0 }}
                              control={
                                <Checkbox
                                  key={`houses.${i}.${values?.houses?.[i]?.isActive}`}
                                  sx={{ ml: 1 }}
                                  checked={values?.houses?.[i]?.isActive}
                                  onChange={(event) => {
                                    const value = event.target.checked;
                                    setFieldValue(
                                      `houses.${i}.isActive`,
                                      value
                                    );

                                    const updatedValue = values?.houses?.[i]
                                      ? {
                                          ...values?.houses?.[i],
                                          isActive: value
                                        }
                                      : {
                                          isActive: value
                                        };

                                    let newValues = [];

                                    if (!values?.houses?.[i]) {
                                      newValues = [
                                        ...values?.houses,
                                        updatedValue
                                      ];
                                    } else {
                                      newValues = [...values?.houses];
                                      newValues[i] = {
                                        ...newValues[i],
                                        ...updatedValue
                                      };
                                    }
                                    const updatedInfo = {
                                      ...values,
                                      houses: newValues
                                    };
                                    if (!newValues.some((v) => v.isActive)) {
                                      setFieldValue('isIntercomOnGate', false);
                                      setFieldValue('openDoor', false);
                                      updatedInfo.isIntercomOnGate = false;
                                      updatedInfo.openDoor = false;
                                    }
                                    onUpdate({
                                      gate: updatedInfo
                                    });
                                  }}
                                />
                              }
                              label=""
                            />
                          </Grid>
                        </Grid>
                      )) ?? null
                    );
                  }}
                </FieldArray>
              </>
            }
          </form>
        );
      }}
    </Formik>
  );
};

DeviceGate.propTypes = {
  tagType: PropTypes.string,
  isReadonly: PropTypes.bool,
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  updateKeys: PropTypes.func
};

DeviceGate.defaultProps = {
  tagType: '',
  isReadonly: false,
  device: null
};

export default memo(DeviceGate, isDevicePropEqual);
