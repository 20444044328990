import {
  Autocomplete,
  Box,
  Button, Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl, FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import store from '../../store/store';
import {
  createCustomer,
  deleteCustomer,
  getCustomer,
  getCustomers,
  putCustomer
} from '../../store/actions/customers.actions';
import { showMessage } from '../../store/actions/messages.actions';
import { userWritePermission } from '../../store/selectors/account.selector';

const UsersEditor = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { action } = useParams();
  const { customerId } = useParams();

  const customerEmptyState = {
    id: '',
    username: '',
    email: '',
    fullName: '',
    password: '',
    confirm: '',
    role: null,
    department: null,
    showPassword: false,
    updatePassword: false,
  };

  const editPermission = useSelector(userWritePermission);
  const [customer, setCustomer] = useState({ ...customerEmptyState });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState('');

  const customers = useSelector((state) => state.customers.list);

  const roles = useSelector((state) => state.roles.list);
  const departments = useSelector((state) => state.departments.list);

  useEffect(async () => {
    let unmounted = false;
    if (action === 'edit' || action === 'show' || action === 'add') {
      setEditDialog(true);
      if (action === 'edit') {
        setEditDialogTitle('Редактировать пользователя');
      } else if (action === 'show') {
        setEditDialogTitle('Смотреть пользователя');
      } else if (action === 'add') {
        setEditDialogTitle('Создать пользователя');
      }
    } else if (action === 'delete') {
      setDeleteDialog(true);
    } else {
      setEditDialog(false);
      setDeleteDialog(false);
      setTimeout(() => {
        if (!unmounted && customer.id) {
          setCustomer({ ...customerEmptyState });
        }
      }, 300);
    }
    if (action === 'delete' && customerId) {
      const customerToEdit = customers.find((item) => item.id === customerId);
      setCustomer({
        ...customerToEdit,
      });
    }
    if ((action === 'edit' || action === 'show') && customerId) {
      const customerToEdit = customers.find((item) => item.id === customerId);
      if (customerToEdit) {
        setCustomer({
          lastName: customerToEdit.lastName || '',
          firstName: customerToEdit.firstName || '',
          patronymic: customerToEdit.patronymic || '',
          ...customerEmptyState,
          ...customerToEdit,
        });
        try {
          const { data } = await store.dispatch(getCustomer(customerToEdit.id));
          if (data) {
            // hot fix for splitting the full name
            const fullName = data.firstName.includes(' ') && data.firstName.split(' ');
            setCustomer({
              lastName: customerToEdit.lastName || '',
              firstName: customerToEdit.firstName || '',
              patronymic: customerToEdit.patronymic || '',
              ...customer,
              ...data,
              ...(fullName && { lastName: fullName[0], firstName: fullName.length > 0 && fullName[1], patronymic: fullName.length > 1 && fullName[2] })
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    return () => {
      unmounted = true;
    };
  }, [action, customerId, customers]);

  const handleClose = () => {
    // console.log(reason);
    navigate(-1);
  };

  const saveCustomer = async (values) => {
    try {
      const data = {
        id: values.id,
        username: values.username,
        email: values.email,
        lastName: values.lastName,
        firstName: values.firstName,
        patronymic: values.patronymic,
        password: values.password,
        department: values.department,
        role: values.role,
      };
      await store.dispatch(values.id ? putCustomer(data) : createCustomer(data));
      dispatch(showMessage({ open: true, text: 'Пользователь успешно сохранён.', severity: 'success' }));
      handleClose(null, 'success');
      store.dispatch(getCustomers());
    } catch (error) {
      dispatch(showMessage({ open: true, text: `Не удалось сохранить пользователя. ${error.message}`, severity: 'error' }));
    }
  };

  const removeCustomer = async () => {
    try {
      await store.dispatch(deleteCustomer(customer.id));
      dispatch(showMessage({ open: true, text: 'Пользователь успешно удалёню', severity: 'success' }));
      handleClose(null, 'success');
      store.dispatch(getCustomers());
    } catch (error) {
      console.log(error);
      dispatch(showMessage({ open: true, text: `Не удалось удалить пользователя. ${error.message}`, severity: 'error' }));
    }
  };

  return (
    <>
      <Dialog {...props} open={editDialog} onClose={handleClose} disableRestoreFocus PaperProps={{ sx: { minWidth: '700px' } }}>
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{
            fontSize: '22px',
          }}
          >
            {editDialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}>
          <Formik
            enableReinitialize
            initialValues={customer}
            validationSchema={
              Yup.object().shape({
                id: Yup.string(),
                username: Yup.string().max(255).required('Логин забыли ввести'),
                email: Yup.string().email('Проверьте email').max(255).required('Введите корректный email'),
                lastName: Yup.string().max(255).required('Забыли указать фамилию'),
                firstName: Yup.string().max(255).required('Забыли указать имя'),
                patronymic: Yup.string().max(255),
                password: Yup.string().max(255).when('updatePassword', {
                  is: (val) => (val || !customer.id), // only if 'updatePassword' or new user
                  then: Yup.string()
                    .min(8, 'Слишком короткий пароль')
                    .max(12, 'Слишком длинный пароль')
                    .matches(/^[a-zA-Z0-9]+$/, 'Только цифры и латинские буквы')
                    .required('Пароль забыли ввести')
                }),
                confirm: Yup.string().max(255).when('updatePassword', {
                  is: (val) => (val || !customer.id),
                  then: Yup.string().required('Пароль забыли ввести').when('password', {
                    is: (val) => (val && val.length > 0), // only if 'updatePassword' or new user
                    then: Yup.string().oneOf(
                      [Yup.ref('password')],
                      'Пароли должны совпадать'
                    )
                  })
                }),
                role: Yup.string().nullable().max(255).required('Забыли выбрать роль'),
                department: Yup.string().nullable().max(255).required('Забыли выбрать отдел'),
              })
            }
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* login field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.username && errors.username)}
                        htmlFor="outlined-username"
                      >
                        Логин
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.username && errors.username)}
                        id="outlined-username"
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={values.username}
                        onChange={handleChange('username')}
                        readOnly={!editPermission}
                        label="Логин"
                      />
                      {touched.username && errors.username && (
                        <FormHelperText error id="outlined-username-error">
                          {errors.username}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* email field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel htmlFor="outlined-email" error={Boolean(touched.email && errors.email)}>Email</InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.email && errors.email)}
                        id="outlined-email"
                        type="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        readOnly={!editPermission}
                        label="Email"
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id="outlined-email-error">
                          {errors.email}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* lastName field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.lastName && errors.lastName)}
                        htmlFor="outlined-lastName"
                      >
                        Фамилия
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.lastName && errors.lastName)}
                        id="outlined-lastName"
                        type="text"
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        readOnly={!editPermission}
                        label="Фамилия"
                      />
                      {touched.lastName && errors.lastName && (
                        <FormHelperText error id="outlined-lastName-error">
                          {errors.lastName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* firstName field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.firstName && errors.firstName)}
                        htmlFor="outlined-firstName"
                      >
                        Имя
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.firstName && errors.firstName)}
                        id="outlined-firstName"
                        type="text"
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        readOnly={!editPermission}
                        label="Имя"
                      />
                      {touched.firstName && errors.firstName && (
                        <FormHelperText error id="outlined-firstName-error">
                          {errors.firstName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    {/* patronymic field */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <InputLabel
                        error={Boolean(touched.patronymic && errors.patronymic)}
                        htmlFor="outlined-patronymic"
                      >
                        Отчество
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.patronymic && errors.patronymic)}
                        id="outlined-patronymic"
                        type="text"
                        value={values.patronymic}
                        onChange={handleChange('patronymic')}
                        readOnly={!editPermission}
                        label="Отчество"
                      />
                      {touched.patronymic && errors.patronymic && (
                        <FormHelperText error id="outlined-patronymic-error">
                          {errors.patronymic}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* role autocomplete */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <Autocomplete
                        disableListWrap
                        id="combo-box-role"
                        options={roles}
                        disableClearable
                        value={values.role}
                        getOptionLabel={(item) => item.name}
                        onChange={(event, value) => setFieldValue('role', value)}
                        onBlur={handleBlur('role')}
                        disabled={!editPermission}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(touched.role && errors.role)}
                            helperText={touched.role && errors.role}
                            label="Роль"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    {/* department autocomplete */}
                    <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                      <Autocomplete
                        disableListWrap
                        id="combo-box-department"
                        options={departments}
                        disableClearable
                        value={values.department}
                        getOptionLabel={(item) => item.name}
                        onChange={(event, value) => setFieldValue('department', value)}
                        onBlur={handleBlur('department')}
                        disabled={!editPermission}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(touched.department && errors.department)}
                            helperText={touched.department && errors.department}
                            label="Отдел"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {customer.id && editPermission && (
                    <FormControlLabel
                      sx={{ ml: 2, mt: 1, mb: -2, width: '100%' }}
                      label="Обновить пароль пользователя"
                      control={(
                        <Checkbox
                          id="outlined-password-update"
                          checked={values.updatePassword}
                          onChange={() => setFieldValue('updatePassword', !values.updatePassword)}
                        />
                      )}
                    />
                  )}
                  {(values.updatePassword || !customer.id) && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {/* password field */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <InputLabel
                          error={Boolean(touched.password && errors.password)}
                          htmlFor="outlined-password"
                        >
                          Пароль
                        </InputLabel>
                        <OutlinedInput
                          error={Boolean(touched.password && errors.password)}
                          id="outlined-password"
                          autoComplete="one-time-code"
                          type={values.showPassword ? 'text' : 'password'}
                          value={values.password}
                          onChange={handleChange('password')}
                          onBlur={handleBlur('password')}
                          readOnly={!editPermission}
                          endAdornment={(
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="показать пароль"
                                tabIndex={-1}
                                onClick={() => {
                                  setFieldValue('showPassword', !values.showPassword);
                                }}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                                edge="end"
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )}
                          label="Пароль"
                        />
                        {touched.password && errors.password && (
                          <FormHelperText error id="outlined-password-error">
                            {errors.password}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {(values.updatePassword || !customer.id) && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      {/* confirm password field */}
                      <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                        <InputLabel
                          error={Boolean(touched.confirm && errors.confirm)}
                          htmlFor="outlined-confirm"
                        >
                          Повторите пароль
                        </InputLabel>
                        <OutlinedInput
                          error={Boolean(touched.confirm && errors.confirm)}
                          id="outlined-confirm"
                          autoComplete="one-time-code"
                          value={values.confirm}
                          onChange={handleChange('confirm')}
                          onBlur={handleBlur('confirm')}
                          type={values.showPassword ? 'text' : 'password'}
                          readOnly={!editPermission}
                          endAdornment={(
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="показать пароль"
                                tabIndex={-1}
                                onClick={() => {
                                  setFieldValue('showPassword', !values.showPassword);
                                }}
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                                edge="end"
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )}
                          label="Повторите пароль"
                        />
                        {touched.confirm && errors.confirm && (
                          <FormHelperText error id="outlined-confirm-error">
                            {errors.confirm}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{
                    pb: 2,
                    pt: 3,
                  }}
                >
                  <Grid
                    item
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Отменить
                    </Button>
                  </Grid>
                  {editPermission && (
                    <Grid
                      item
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        aria-disabled={
                          !isValid || isSubmitting || !values.username || !values.email || !values.fullName || !values.role || !values.department
                          || (!customer.id && (!values.password || !values.confirm))
                        }
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (Object.keys(validationErrors).length === 0) {
                            saveCustomer(values);
                          } else {
                            setTouched(setNestedObjectValues(validationErrors, true));
                            dispatch(showMessage({ open: true, text: 'Проверьте ошибки заполнения формы', severity: 'error' }));
                          }
                        }}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog {...props} open={deleteDialog} onClose={handleClose} disableRestoreFocus>
        <DialogTitle>
          <Box sx={{ fontSize: '22px', }}>
            Удалить пользователя?
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            <span>Пользователь </span>
            <strong>{customer.fullName || customer.username}</strong>
            <span> будет удалён навсегда!</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4,
            }}
          >
            <Grid
              item
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                variant="contained"
                color="purple"
                fullWidth
                onClick={removeCustomer}
              >
                Удалить
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UsersEditor;
