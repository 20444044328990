import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducers from './reducers/root.reducer';

const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));

store.subscribe(() => {
});

export default store;
