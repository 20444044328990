import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const UsersToolbar = ({
  customersActive,
  customersDeleteClick,
  ...props
}) => {
  const editPermission = useSelector(userWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*<Button>
          Импорт
        </Button>
        <Button sx={{ mx: 1 }}>
          Экспорт
        </Button>*/}
        {editPermission && (
          <RouterLink to="/app/users/customers/add">
            <Button
              color="primary"
              variant="contained"
            >
              Добавить пользователя
            </Button>
          </RouterLink>
        )}
        {editPermission && !!customersActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => customersDeleteClick()}
          >
            Удалить&nbsp;
            { customersActive }
            &nbsp;
            { decOfNum(customersActive, ['пользователя', 'пользователей', 'пользователей']) }
          </Button>
        )}
      </Box>
      {/* <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="medium"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Поиск пользователя"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box> */}
    </Box>
  );
};

UsersToolbar.propTypes = {
  customersActive: PropTypes.number,
  customersDeleteClick: PropTypes.func,
};

UsersToolbar.defaultProps = {
  customersActive: 0,
  customersDeleteClick: null,
};

export default UsersToolbar;
