import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Breadcrumbs, Container, Link, Paper, Tabs } from '@material-ui/core';
import UsersEditor from '../components/customer/UsersEditor';
import UsersList from '../components/customer/UsersList';
import UsersToolbar from '../components/customer/UsersToolbar';
import LinkTab from '../components/common/LinkTab';
import TabPanel from '../components/common/TabPanel';
import store from '../store/store';
import { getCustomersAndRoles } from '../store/actions/customers.actions';
import RolesList from '../components/customer/roles/RolesList';
import RolesToolbar from '../components/customer/roles/RolesToolbar';
import DepartmentsToolbar from '../components/customer/departments/DepartmentsToolbar';
import DepartmentsList from '../components/customer/departments/DepartmentsList';
import RolesEditor from '../components/customer/roles/RolesEditor';
import DepartmentsEditor from '../components/customer/departments/DepartmentsEditor';
import { showNoAccessMessage } from '../store/actions/messages.actions';
import { userReadPermission } from '../store/selectors/account.selector';

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = useState(0);

  const readPermission = useSelector(userReadPermission);
  const usersVersion = useSelector((state) => state.customers.version);

  const handleChange = (event, newTab) => {
    if (newTab === 1) {
      navigate('/app/users/roles', { replace: true });
    } else if (newTab === 2) {
      navigate('/app/users/departments', { replace: true });
    } else {
      navigate('/app/users/customers', { replace: true });
    }
  };

  useEffect(() => {
    if (pathname.includes('/app/users/roles')) {
      setTab(1);
    } else if (pathname.includes('/app/users/departments')) {
      setTab(2);
    } else {
      setTab(0);
    }
  }, [pathname]);

  useEffect(() => {
    if (!readPermission) {
      dispatch(showNoAccessMessage());
      navigate('/app/dashboard');
    } else {
      store.dispatch(getCustomersAndRoles());
    }
  }, [usersVersion]);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/app/dashboard">Главная</Link>
              <Link color="textPrimary" href="/app/users/customers">Пользователи</Link>
              {tab === 1 && <Link color="textPrimary" href="/app/users/roles">Роли</Link>}
              {tab === 2 && <Link color="textPrimary" href="/app/users/departments">Отделы</Link>}
            </Breadcrumbs>
          </Box>

          <Paper
            sx={{
              backgroundColor: 'white',
              minHeight: '100%',
              my: 3
            }}
          >
            <Tabs value={tab} onChange={handleChange} aria-label="Пользователи, роли, отделу">
              <LinkTab label="Пользователи" href="/app/users/customers" />
              <LinkTab label="Роли" href="/app/users/roles" />
              <LinkTab label="Отделы" href="/app/users/department" />
            </Tabs>
          </Paper>
          <TabPanel value={tab} index={0}>
            <UsersList />
            <UsersEditor />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <RolesList />
            <RolesEditor />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <DepartmentsList />
            <DepartmentsEditor />
          </TabPanel>
        </Container>
      </Box>
    </>
  );
};

export default Users;
