import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { placeWritePermission } from '../../../store/selectors/account.selector';
import decOfNum from '../../../utils/decOfNum';

const ArchiveHomesMulti = ({ buildingsFromArchive, buildingsFromArchiveClick, ...props }) => {
  const editPermission = useSelector(placeWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {editPermission && !!buildingsFromArchive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => buildingsFromArchiveClick()}
          >
            Восстановить из архива&nbsp;
            { buildingsFromArchive }
            &nbsp;
            { decOfNum(buildingsFromArchive, ['дом', 'дома', 'домов']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

ArchiveHomesMulti.propTypes = {
  buildingsFromArchive: PropTypes.number,
  buildingsFromArchiveClick: PropTypes.func,
};

ArchiveHomesMulti.defaultProps = {
  buildingsFromArchive: 0,
  buildingsFromArchiveClick: null,
};

export default ArchiveHomesMulti;
