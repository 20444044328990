import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { v4 as uuid } from 'uuid';
import { Add, Delete } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { placeWritePermission } from '../../../store/selectors/account.selector';
import theme from '../../../theme';
import { showMessage } from '../../../store/actions/messages.actions';
/* eslint-disable camelcase */

const intercomEmptyState = {
  id: 'intercom',
  name: 'Подъезд 1',
  placingType: { code: 'ENTRANCE', name: 'Подъезд', hasRange: true },
  flatRanges: [{ min: 1, max: '' }],
  entranceNumber: 0
};

const intercomPrivateEmptyState = {
  id: 'intercom',
  name: 'Шлагбаум',
  placingType: { code: 'GATE', name: 'Калитка/шлагбаум', hasRange: false },
  flatRanges: []
};

const intercomTerritoryEmptyState = {
  id: 'intercom',
  name: 'Калитка 1',
  placingType: { code: 'GATE', name: 'Калитка/шлагбаум', hasRange: false },
  flatRanges: [],
  isTerritory: true
};

const IntercomDialog = ({
  isArchive,
  isPrivate,
  handleClose,
  intercoms,
  onUpdate,
  onRemove,
  ...props
}) => {
  const dispatch = useDispatch();

  const { action1, actionId1, action2, actionId2 } = useParams();

  const editPermission = useSelector(placeWritePermission);

  const [intercom, setIntercom] = useState({ ...intercomEmptyState });
  const [intercomDialog, setIntercomDialog] = useState(false);
  const [intercomDeleteDialog, setIntercomDeleteDialog] = useState(false);

  const intercomTypes = useSelector((state) => state.buildings.intercomTypes);
  const intercomTypesRestricted = useSelector((state) =>
    (isPrivate
      ? state.buildings.intercomPrivateTypes
      : state.buildings.intercomTypes));

  const editMode = useMemo(() => action2 === 'edit' || action2 === 'add', [action2]);

  // state effect
  useEffect(() => {
    let unmounted = false;
    // console.log(action1, action2, actionId1, actionId2, intercoms[0]);
    const intercomToEdit =
      actionId2 && intercoms?.find((item) => item.id === actionId2);
    if (action2 === 'edit' || action2 === 'show' || action2 === 'add') {
      const placingType = intercomTypes.find(
        (item) => item.code === intercomToEdit?.placingType
      );
      if (intercomToEdit) {
        setIntercom({
          ...(actionId1 !== 'territory'
            ? isPrivate || !intercomToEdit?.flatRanges?.length
              ? intercomPrivateEmptyState
              : intercomEmptyState
            : intercomTerritoryEmptyState),
          ...intercomToEdit,
          placingType
        });
      } else if (action2 === 'add') {
        if (actionId1 !== 'territory' && intercoms?.length) {
          const lastIntercomCopy = {
            ...intercoms[intercoms.length - 1],
            id: ''
          };
          const parts = lastIntercomCopy.name.split(' ');
          if (parts?.length && /^-?\d+$/.test(parts[parts.length - 1])) {
            parts[parts.length - 1] = +parts[parts.length - 1] + 1;
            lastIntercomCopy.name = parts.join(' ');
          } else if (parts[0] === lastIntercomCopy.name) {
            lastIntercomCopy.name += ' 2';
          }
          if (lastIntercomCopy.placingType === 'ENTRANCE') {
            const lastRange =
              lastIntercomCopy.flatRanges[
                lastIntercomCopy.flatRanges.length - 1
              ];
            // const diff = lastRange.max - lastRange.min;
            // lastIntercomCopy.flatRanges = [{ min: lastRange.max + 1, max: lastRange.max + 1 + diff }];
            lastIntercomCopy.flatRanges = [{ min: lastRange.max + 1, max: '' }];
          }
          if (actionId1 === 'territory' && !lastIntercomCopy.isTerritory) {
            lastIntercomCopy.name = intercomTerritoryEmptyState.name;
            lastIntercomCopy.placingType =
              intercomTerritoryEmptyState.placingType.code;
            lastIntercomCopy.isTerritory =
              intercomTerritoryEmptyState.isTerritory;
          }
          setIntercom({
            ...intercomEmptyState,
            ...lastIntercomCopy,
            placingType: intercomTypesRestricted.find(
              (item) => item.code === lastIntercomCopy.placingType
            )
          });
        } else {
          // use another template for territory intercom
          setIntercom({
            ...(actionId1 !== 'territory'
              ? isPrivate
                ? intercomPrivateEmptyState
                : intercomEmptyState
              : intercomTerritoryEmptyState)
          });
        }
      }
      setIntercomDialog(true);
    } else if (action2 === 'delete' && actionId2) {
      setIntercom({
        ...intercomToEdit
      });
      setIntercomDeleteDialog(true);
    } else {
      setIntercomDialog(false);
      setIntercomDeleteDialog(false);
      setTimeout(() => {
        if (!unmounted && intercom.id !== 'intercom') {
          setIntercom({ ...intercomEmptyState });
        }
      }, 200);
    }
    return () => {
      unmounted = true;
    };
  }, [action1, action2, actionId1, actionId2, intercomTypes]);

  const onIntercomNameInput = (name, values) => {
    if (!values.isTerritory && values.name.substr(0, 1) !== name.substr(0, 1)) {
      let code = '';
      const firstLetter = name.substr(0, 1).toLowerCase();
      if (firstLetter === 'п') {
        code = 'ENTRANCE';
      } else if (firstLetter === 'к') {
        code = 'GATE';
      } else if (firstLetter === 'ш') {
        code = 'GATE';
      } else if (firstLetter === 'д') {
        code = 'ADDITIONAL_ENTRANCE';
      }
      if (code) {
        setIntercom({
          ...values,
          name,
          entranceNumber: 0,
          placingType: intercomTypes.find((item) => item.code === code),
          flatRanges: code === 'ENTRANCE' ? [{ min: 1, max: '' }] : []
        });
      }
    }
  };
  const onIntercomEntranceNumberInput = (entranceNumber, values) => {
    setIntercom({
      ...values,
      entranceNumber
    });
  };

  const onRemoveIntercom = () => {
    if (onRemove) {
      onRemove(intercoms.find((item) => item.id === actionId2));
    }
    handleClose();
  };

  const onSaveIntercom = (values) => {
    const intercomUpdated = {
      id: values.id,
      name: values.name,
      entranceNumber: values.entranceNumber,
      ...(values.placingType.hasRange && { flatRanges: values.flatRanges }),
      placingType: values.placingType.code
    };
    if (intercomUpdated.id === '' || intercomUpdated.id === 'intercom') {
      intercomUpdated.id = uuid();
      intercomUpdated.idIsLocal = true;
    }
    if (onUpdate) {
      onUpdate(intercomUpdated);
    }
    handleClose(null, 'success');
  };

  const onClickAdd = useCallback(
    (values, index, setFieldValue, isSingle, isRange) => {
      const size = Math.max(
        1,
        values.flatRanges[index].max - values.flatRanges[index].min
      );
      // console.log(values.flatRanges[index].max, values.flatRanges[index].min)
      const start =
        (values.flatRanges[index].max || values.flatRanges[index].min) + 1;
      const end =
        (values.flatRanges[index].max || values.flatRanges[index].min) +
        1 +
        size;
      const updated = [...values.flatRanges];
      updated.splice(index + 1, 0, {
        min: start,
        max: isRange ? end : isSingle ? start : ''
      });
      setFieldValue('flatRanges', updated);
    },
    []
  );

  const onClickRemove = useCallback((values, index, setFieldValue) => {
    const updated = [...values.flatRanges];
    updated.splice(index, 1);
    setFieldValue('flatRanges', updated);
  }, []);

  const onChangeRange = useCallback(
    (values, key, value, index, setFieldValue) => {
      const positiveOrEmpty = value !== '' ? Math.max(1, value) : '';
      const updated = [...values.flatRanges.map((item) => ({ ...item }))];
      updated[index][key] = positiveOrEmpty;
      setFieldValue('flatRanges', updated);
    },
    []
  );

  return (
    <>
      {/* intercom dialog */}
      <Dialog
        {...props}
        open={intercomDialog}
        onClose={handleClose}
        disableRestoreFocus
        maxWidth="xs"
        scroll="body"
        PaperProps={{
          sx: {
            minWidth: useMediaQuery(theme.breakpoints.up('sm')) ? '450px' : ''
          }
        }}
      >
        <DialogTitle sx={{ paddingBottom: '8px' }}>
          <Box sx={{ fontSize: '22px' }}>Вход</Box>
        </DialogTitle>
        <DialogContent
          sx={{ paddingTop: '8px !important', paddingBottom: '8px' }}
        >
          <Formik
            enableReinitialize
            initialValues={intercom}
            validationSchema={Yup.object().shape({
              id: Yup.string(),
              name: Yup.string()
                .max(255)
                .required('Забыли указать название входа'),
              entranceNumber: Yup.number(),
              placingType: Yup.string()
                .nullable()
                .max(255)
                .required('Забыли указать тип входа'),
              flatRanges: Yup.array().of(Yup.object())
              // Yup.object().shape({
              //   min: Yup.number(),
              //   max: Yup.number()
              // })

              /*minFlatNumber: Yup.number().nullable(true).typeError('Только числа!').positive('Не может быть меньше 1')
                  .required('Забыли указать диапазон'),
                maxFlatNumber: Yup.number().nullable(true).typeError('Только числа!').positive('Не может быть меньше 1')
                  .required('Забыли указать диапазон')
                  .min(Yup.ref('minFlatNumber'), 'Неверный диапазон')*/
            })}
            onSubmit={() => {
              // nothing
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isValid,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              validateForm,
              setTouched,
              setErrors
            }) => {
              const isGatePlacingType =
                values.placingType?.code === 'ADDITIONAL_ENTRANCE' ||
                values.placingType?.code === 'GATE';

              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      {/* intercom name field */}
                      <FormControl
                        sx={{ m: 0, width: '100%' }}
                        variant="outlined"
                      >
                        <InputLabel
                          error={Boolean(touched.name && errors.name)}
                          htmlFor="outlined-name"
                        >
                          Название входа
                        </InputLabel>
                        <OutlinedInput
                          error={Boolean(touched.name && errors.name)}
                          id="outlined-name"
                          type="text"
                          value={values.name}
                          autoFocus
                          onInput={($event) =>
                            onIntercomNameInput($event.target.value, values)}
                          onChange={handleChange('name')}
                          readOnly={!editPermission || isArchive}
                          label="Название входа"
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                          }}
                        />
                        {touched.name && errors.name && (
                          <FormHelperText error id="outlined-name-error">
                            {errors.name}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/* intercom placingType field */}
                      <Autocomplete
                        disableListWrap
                        id="combo-box-placingType"
                        options={intercomTypesRestricted}
                        value={
                          values.placingType || {
                            code: 'GATE',
                            name: 'Калитка/шлагбаум',
                            hasRange: false
                          }
                        }
                        getOptionLabel={(item) => item.name}
                        onChange={(event, value) => {
                          setFieldValue('placingType', value);
                          setFieldValue('entranceNumber', 0);

                          setFieldValue(
                            'flatRanges',
                            value.code === 'ENTRANCE'
                              ? [{ min: 1, max: '' }]
                              : []
                          );
                        }}
                        onBlur={handleBlur('placingType')}
                        disabled={
                          !editPermission || isArchive || !editMode || values.isTerritory
                        }
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.placingType && errors.placingType
                            )}
                            helperText={
                              touched.placingType && errors.placingType
                            }
                            label="Тип входа"
                          />
                        )}
                      />
                    </Grid>
                    {values.placingType?.code === 'ENTRANCE' && (
                      <Grid item xs={12} sm={12}>
                        {/* intercom name field */}
                        <FormControl
                          sx={{ m: 0, width: '100%' }}
                          variant="outlined"
                        >
                          <InputLabel
                            error={Boolean(
                              touched.entranceNumber && errors.entranceNumber
                            )}
                            htmlFor="outlined-name"
                          >
                            Номер подъезда
                          </InputLabel>
                          <OutlinedInput
                            error={Boolean(
                              touched.entranceNumber && errors.entranceNumber
                            )}
                            id="outlined-name"
                            type="text"
                            value={values.entranceNumber}
                            onInput={($event) =>
                              onIntercomEntranceNumberInput(
                                Number($event.target.value),
                                values
                              )}
                            onChange={handleChange('entranceNumber')}
                            readOnly={!editPermission || isArchive}
                            label="Номер подъезда"
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*'
                            }}
                            disabled={
                              !editPermission || isArchive
                            }
                            disableClearable
                          />
                          {touched.entranceNumber && errors.entranceNumber && (
                            <FormHelperText error id="outlined-name-error">
                              {errors.entranceNumber}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    )}

                    {values.flatRanges.map((flat, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        key={'flat' + index}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        {/* intercom min field */}
                        <FormControl
                          sx={{
                            m: 0,
                            width:
                              editPermission && !isArchive && !isPrivate && editMode
                                ? '38%'
                                : '48%'
                          }}
                          variant="outlined"
                        >
                          <InputLabel
                            error={Boolean(
                              !isGatePlacingType &&
                                errors.flatRanges &&
                                errors.flatRanges.length > index &&
                                errors.flatRanges[index]?.min
                            )}
                            htmlFor={'outlined-intercom-flat-min' + index}
                          >
                            От
                          </InputLabel>
                          <OutlinedInput
                            error={Boolean(
                              !isGatePlacingType &&
                                errors.flatRanges &&
                                errors.flatRanges.length > index &&
                                errors.flatRanges[index]?.min
                            )}
                            id={'outlined-intercom-flat-min' + index}
                            type="number"
                            autoFocus={values.flatRanges.length > 1}
                            value={values.placingType?.hasRange ? flat.min : ''}
                            onChange={(event) =>
                              onChangeRange(
                                values,
                                'min',
                                event.target.value,
                                index,
                                setFieldValue
                              )}
                            readOnly={
                              isGatePlacingType || !editPermission || isArchive || !editMode
                            }
                            disabled={
                              isGatePlacingType || !values.placingType?.hasRange || !editMode
                            }
                            label="От"
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*'
                            }}
                          />
                        </FormControl>

                        {/* intercom max field */}
                        <FormControl
                          sx={{
                            m: 0,
                            ml: 1,
                            width:
                              editPermission && !isArchive && !isPrivate
                                ? '38%'
                                : '48%'
                          }}
                          variant="outlined"
                        >
                          <InputLabel
                            error={Boolean(
                              !isGatePlacingType &&
                                errors.flatRanges &&
                                errors.flatRanges.length > index &&
                                errors.flatRanges[index]?.max
                            )}
                            htmlFor={'outlined-intercom-flat-max' + index}
                          >
                            До
                          </InputLabel>
                          <OutlinedInput
                            error={Boolean(
                              !isGatePlacingType &&
                                errors.flatRanges &&
                                errors.flatRanges.length > index &&
                                errors.flatRanges[index]?.max
                            )}
                            id={'outlined-intercom-flat-max' + index}
                            type="number"
                            value={values.placingType?.hasRange ? flat.max : ''}
                            onChange={(event) =>
                              onChangeRange(
                                values,
                                'max',
                                event.target.value,
                                index,
                                setFieldValue
                              )}
                            readOnly={
                              isGatePlacingType || !editPermission || isArchive || !editMode
                            }
                            disabled={
                              isGatePlacingType || !values.placingType?.hasRange || !editMode
                            }
                            label="До"
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*'
                            }}
                          />
                        </FormControl>

                        {!isGatePlacingType &&
                          editPermission &&
                          !isArchive &&
                          !isPrivate &&
                          editMode && (
                            <IconButton
                              variant="contained"
                              color="primary"
                              sx={{ ml: 1 }}
                              aria-disabled={
                                !isValid || isSubmitting || !values.name
                              }
                              onClick={(event) => {
                                onClickAdd(
                                  values,
                                  index,
                                  setFieldValue,
                                  event.ctrlKey,
                                  event.shiftKey
                                );
                              }}
                            >
                              <SvgIcon fontSize="small" color="primary">
                                <Add />
                              </SvgIcon>
                            </IconButton>
                          )}

                        {!isGatePlacingType &&
                          editPermission &&
                          !isArchive &&
                          !isPrivate &&
                          editMode && (
                            <IconButton
                              color="secondary"
                              variant="contained"
                              aria-disabled={
                                !isValid || isSubmitting || !values.name
                              }
                              disabled={values.flatRanges.length < 2}
                              onClick={() => {
                                onClickRemove(values, index, setFieldValue);
                              }}
                            >
                              <SvgIcon fontSize="small">
                                <Delete />
                              </SvgIcon>
                            </IconButton>
                          )}
                      </Grid>
                    ))}
                  </Grid>
                  {/* intercoms buttons */}
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                      pb: 2,
                      pt: 3
                    }}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={handleClose}
                      >
                        Отменить
                      </Button>
                    </Grid>
                    {editPermission && !isArchive && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          aria-disabled={
                            !isValid || isSubmitting || !values.name
                          }
                          onClick={async () => {
                            let wrongRanges = false;
                            const flatErrors = Array(values.flatRanges.length);
                            for (let i = 0; i < flatErrors.length; i++) {
                              const range = values.flatRanges[i];
                              if (isGatePlacingType || !range) {
                                break;
                              } else if (range.min) {
                                if (!range.max || range.min > range.max) {
                                  wrongRanges = true;
                                  flatErrors[i] = { max: 'Слишком мало' };
                                }
                              } else {
                                wrongRanges = true;
                                flatErrors[i] = { min: 'Слишком мало' };
                              }
                            }
                            if (!wrongRanges) {
                              const minSorted = values.flatRanges
                                .map((item, index) => ({ ...item, index }))
                                .sort((item1, item2) => item1.min - item2.min);
                              for (let i = 0; i < minSorted.length - 1; i++) {
                                if (minSorted[i].max >= minSorted[i + 1].min) {
                                  wrongRanges = true;
                                  flatErrors[
                                    Math.max(
                                      minSorted[i].index,
                                      minSorted[i + 1].index
                                    )
                                  ] = {
                                    min: 'Пересечение',
                                    max: 'Пересечение'
                                  };
                                }
                              }
                            }
                            const validationErrors = await validateForm();
                            if (wrongRanges) {
                              setErrors({ flatRanges: flatErrors });
                            } else if (
                              Object.keys(validationErrors).length === 0
                            ) {
                              onSaveIntercom(values);
                            } else {
                              console.log(validationErrors, 'validationErrors');
                              setTouched(
                                setNestedObjectValues(validationErrors, true)
                              );
                              dispatch(
                                showMessage({
                                  open: true,
                                  text: 'Проверьте ошибки заполнения формы',
                                  severity: 'error'
                                })
                              );
                            }
                          }}
                        >
                          {actionId2 || !action2 ? 'Сохранить' : 'Добавить'}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* intercom delete dialog */}
      <Dialog
        {...props}
        open={intercomDeleteDialog}
        onClose={handleClose}
        disableRestoreFocus
        scroll="body"
      >
        <DialogTitle>
          <Box sx={{ fontSize: '22px' }}>Удалить вход?</Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '8px' }}>
          <Typography color="textSecondary" variant="body1">
            <span>Вход </span>
            <strong>{intercom.name}</strong>
            <span> будет удалён навсегда!</span>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
              pb: 2,
              pt: 4
            }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClose}
              >
                Отменить
              </Button>
            </Grid>
            <Grid item>
              {editPermission && !isArchive && (
                <Button
                  variant="contained"
                  color="purple"
                  fullWidth
                  onClick={() => onRemoveIntercom()}
                >
                  Удалить
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

IntercomDialog.propTypes = {
  isArchive: PropTypes.bool,
  isPrivate: PropTypes.bool,
  handleClose: PropTypes.func,
  intercoms: PropTypes.array,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func
};

IntercomDialog.defaultProps = {
  isArchive: false,
  isPrivate: false,
  handleClose: null,
  intercoms: [],
  onUpdate: null,
  onRemove: null
};

export default IntercomDialog;
