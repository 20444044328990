import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { deviceWritePermission } from '../../store/selectors/account.selector';
import decOfNum from '../../utils/decOfNum';

const DevicesMulti = ({
  devicesActive,
  devicesConfigured,
  devicesDeleteClick,
  devicesRestoreClick,
  devicesMessageClick,
  ...props
}) => {
  const editPermission = useSelector(deviceWritePermission);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 3,
        }}
      >
        {/*{editPermission && !!devicesConfigured && (
          <Button
            sx={{ ml: 1 }}
            color="green"
            variant="contained"
            onClick={() => devicesDeleteClick()}
          >
            Отправить на&nbsp;
            { devicesConfigured }
            &nbsp;
            { decOfNum(devicesConfigured, ['устройство', 'устройства', 'устройств']) }
          </Button>
        )}*/}
        {editPermission && !!devicesActive && (
          <Button
            sx={{ ml: 1 }}
            color="secondary"
            variant="contained"
            onClick={() => devicesDeleteClick()}
          >
            Удалить&nbsp;
            { devicesActive }
            &nbsp;
            { decOfNum(devicesActive, ['устройство', 'устройства', 'устройств']) }
          </Button>
        )}
      </Box>
    </Box>
  );
};

DevicesMulti.propTypes = {
  devicesActive: PropTypes.number,
  devicesConfigured: PropTypes.number,
  devicesRestoreClick: PropTypes.func,
  devicesDeleteClick: PropTypes.func,
  devicesMessageClick: PropTypes.func,
};

DevicesMulti.defaultProps = {
  devicesActive: 0,
  devicesConfigured: 0,
  devicesRestoreClick: null,
  devicesDeleteClick: null,
  devicesMessageClick: null,
};

export default DevicesMulti;
