import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Breadcrumbs, Container, Link, Paper, Tabs } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { logsReadPermission } from '../store/selectors/account.selector';
import { showNoAccessMessage } from '../store/actions/messages.actions';
import LogsList from '../components/logs/LogsList';
import LinkTab from '../components/common/LinkTab';

const Logs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = useState(0);

  const readPermission = useSelector(logsReadPermission);

  const handleChange = (event, newTab) => {
    if (newTab === 1) {
      navigate('/app/logs/places', { replace: true });
    } else if (newTab === 2) {
      navigate('/app/logs/users', { replace: true });
    } else if (newTab === 3) {
      navigate('/app/logs/subscribers', { replace: true });
    } else if (newTab === 4) {
      navigate('/app/logs/devices', { replace: true });
    } else {
      navigate('/app/logs', { replace: true });
    }
  };

  useEffect(() => {
    if (pathname.includes('/app/logs/places')) {
      setTab(1);
    } else if (pathname.includes('/app/logs/users')) {
      setTab(2);
    } else if (pathname.includes('/app/logs/subscribers')) {
      setTab(3);
    } else if (pathname.includes('/app/logs/devices')) {
      setTab(4);
    } else {
      setTab(0);
    }
  }, [pathname]);

  useEffect(() => {
    if (!readPermission) {
      dispatch(showNoAccessMessage());
      navigate('/app/dashboard');
    } else {
      // store.dispatch(getCustomersAndSips());
    }
  }, []);

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Helmet>
        <title>Логи системы | Терион</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/app/dashboard">Главная</Link>
              <Link color="textPrimary" href="/app/logs">Логи системы</Link>
              {tab === 0 && <Link color="textPrimary" href="/app/logs">Все логи</Link>}
              {tab === 1 && <Link color="textPrimary" href="/app/logs/places">Дома и территории</Link>}
              {tab === 2 && <Link color="textPrimary" href="/app/logs/users">Пользователи</Link>}
              {tab === 3 && <Link color="textPrimary" href="/app/logs/subscribers">Абоненты</Link>}
              {tab === 4 && <Link color="textPrimary" href="/app/logs/devices">Устройства</Link>}
            </Breadcrumbs>
          </Box>

          <Paper
            sx={{
              backgroundColor: 'white',
              minHeight: '100%',
              my: 3
            }}
          >
            <Tabs value={tab} onChange={handleChange} aria-label="Логи системы">
              <LinkTab label="Все логи" href="/app/logs" />
              <LinkTab label="Дома и территории" href="/app/logs/places" />
              <LinkTab label="Пользователи" href="/app/logs/users" />
              <LinkTab label="Абоненты" href="/app/logs/subscribers" />
              <LinkTab label="Устройства" href="/app/logs/devices" />
            </Tabs>
          </Paper>

          <LogsList />

        </Container>
      </Box>
    </>
  );
};

export default Logs;
